import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  listStatusHistory: [],
  listStatusHistoryFilter: [],

  errorStatusHistory: null,
  successStatusHistory: null,

  totalElementsStatusHistory: 0,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.statusHistory.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        listStatusHistoryFilter: resultList || [],
        totalElementsStatusHistory: pages.totalCount || resultList.length,
      }
    case types.statusHistory.getFilter.error:
      return {
        ...state,
        errorStatusHistory: 'Erro ao filtrar histórico de atividades',
      }

    case types.statusHistory.get.success:
      return {
        ...state,
        listStatusHistory: action.payload.content || action.payload,
      }
    case types.statusHistory.get.error:
      return {
        ...state,
        errorStatusHistory: 'Erro ao buscar histórico de atividade',
      }

    case types.statusHistory.save.success:
      return {
        ...state,
        successStatusHistory: 'Histórico de atividade salva com sucesso',
      }

    case types.statusHistory.save.error:
      return {
        ...state,
        errorStatusHistory: 'Erro ao salvar histórico de atividade',
      }

    default:
      return state
  }
}
