import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.point + '/filter', param)

    return dispatch(trigger(types.point.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.point.getFilter.error, error))
  }
}

export const get = param => dispatch =>
  new Http(dispatch)
    .get(URL.point, param)
    .then(response => dispatch(trigger(types.point.get.success, response.data)))
    .catch(error => dispatch(trigger(types.point.get.error, error)))

export const save = param => dispatch =>
  new Http(dispatch)
    .post(URL.point, param)
    .then(response =>
      dispatch(trigger(types.point.save.success, response.data))
    )
    .catch(error => dispatch(trigger(types.point.save.error, error)))

export const update = param => dispatch =>
  new Http(dispatch)
    .put(URL.point, param)
    .then(response =>
      dispatch(trigger(types.point.update.success, response.data))
    )
    .catch(error => dispatch(trigger(types.point.update.error, error)))

export const remove = param => dispatch =>
  new Http(dispatch)
    .delete(URL.point, param)
    .then(response =>
      dispatch(trigger(types.point.remove.success, response.data))
    )
    .catch(error => dispatch(trigger(types.point.remove.error, error)))
