import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import ReorderIcon from '@material-ui/icons/Reorder'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Date from './components/Date'
import TextArea from './components/TextArea'
import moment from 'moment'
import Select from './components/Select'
import InputNumber2 from './components/InputNumber2'
import Input from './components/Input'

class Filter extends Component {
  state = {
    fields: {},
    wasClear: false,
  }

  componentDidMount() {
    const { fields, fieldsLine2 = {}, fieldsLine3 = {} } = this.props
    const fieldsCompleted = {}

    const totalFields = fields.concat(fieldsLine2).concat(fieldsLine3)

    totalFields.forEach(
      field =>
        (fieldsCompleted[field.name] =
          field.initial || this.createInitialValue(field.type))
    )

    this.setState({
      fields: fieldsCompleted,
    })
  }


  componentDidUpdate() {
    if (this.props.monitoreState) this.props.monitoreState(this.state)
    const { executeClearAllFields = () => {} } = this.props

    if (this.props.limparFields !== null) {
      executeClearAllFields()
      this.clearAllFields()
    }
  }

  clearAllFields = () => {
    const { fields, fieldsLine2 = {}, fieldsLine3 = {} } = this.props
    const fieldsCompleted = {}

    const totalFields = fields.concat(fieldsLine2).concat(fieldsLine3)

    totalFields.forEach(
      field =>
        (fieldsCompleted[field.name] =
          field.disabled && field.initial
            ? field.initial
            : this.createInitialValue(field.type))
    )

    this.setState({
      fields: fieldsCompleted,
      wasClear: true,
    })
  }

  enviarReport() {
    const { onSubmit = () => {} } = this.props
    onSubmit(this.state.fields, true)
    this.clearAllFields()
  }

  setValueToField = (field, value) => {

    this.setState({
      fields: {
        ...this.state.fields,
        [field]: value,
      },
    })
    
  }

  formataDataToState(campo) {
    let campos = campo.split('/')
    let data = campos[1] + '/' + campos[0] + '/' + campos[2]
    return moment(data).format('LL')
  }

  createInitialValue = type => {
    switch (type) {
      case 'select_multiple':
        return []
      case 'checkbox':
        return false
      case 'date_range':
        return [null, null]
      case 'input':
        return ''
      default:
        return null
    }
  }

  buildField(props) {
    switch (props.type) {
      case 'select':
        return (
          <Select
            wasClear={this.state.wasClear}
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'select-data':
        return (
          <Select
            wasClear={this.state.wasClear}
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            setValueToFieldConditional={this.setValueToFieldConditionalPeriod}
            {...props}
          />
        )
      case 'date':
        return (
          <Date
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'inputNumber':
        return (
          <InputNumber2
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )

      case 'textarea':
        return (
          <TextArea
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            cols={160}
            rows={10}
            {...props}
          />
        )

        case 'input':
          return (
            <Input
              stateValues={this.state.fields[props.name]}
              setValueToField={this.setValueToField}
              {...props}
            />
          )

      default:
        return
    }
  }

  render() {
    const { fields } = this.props

    return (
      <>
        <Grid
          container
          className="common-filter"
          direction="row"
          alignItems="center"
          spacing={4}
          rowSpacing={1}
        >
          {(fields || []).map((field, i) => (
            <Grid xs={3} item key={i} className="filter-item" rowSpacing={1}>
              {this.buildField(field)}
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          className="common-filter"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={3}>
            <ButtonGroup fullWidth variant="contained" size="small">
              <Button
                style={{ paddingLeft: 25, paddingRight: 25 }}
                startIcon={<FilterListIcon />}
                color="primary"
                onClick={() => {this.enviarReport()}}
                className="submit-button-fechamento"
              >
                Gerar
              </Button>
              <Button
                style={{ paddingLeft: 25, paddingRight: 25 }}
                startIcon={<ReorderIcon />}
                color="secondary"
                onClick={() => this.clearAllFields()}
                className="submit-button-fechamento"
              >
                Limpar
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </>
    )
  }
}

Filter.propTypes = {
  fields: PropTypes.array.isRequired,
  executionList: PropTypes.array,
  fieldsLine2: PropTypes.array.isRequired,
  fieldsLine3: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  changeFieldFilter: PropTypes.func.isRequired,
  executeClearAllFields: PropTypes.func.isRequired,
}

export default Filter
