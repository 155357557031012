import { isNumber } from '../../../utils/Number'

export const validateValues = values => {
  const {
    desFuncao,
    desSenioridade,
    alocacao,
    vlrHora,
    vlrHora50,
    vlrHora75,
    vlrHora100,
    vlrHoraAdn50,
    vlrHoraAdn75,
    vlrHoraAdn100,
    vlrHoraSobreAviso,
    proposta,
  } = values
  let errors = {}

  if (!desFuncao) {
    errors = { ...errors, erroDesFuncao: 'Informe o nome da função' }
  }

  if (!desSenioridade) {
    errors = { ...errors, erroDesSenioridade: 'Informe a senioridade' }
  }

  if (!isNumber(alocacao)) {
    errors = { ...errors, erroAlocacao: 'Informe o valor da alocação' }
  }

  if (!isNumber(vlrHora)) {
    errors = { ...errors, erroVlrHora: 'Informe o valor da hora extra' }
  }

  if (!isNumber(vlrHora50)) {
    errors = { ...errors, erroVlrHora50: 'Informe o valor da hora extra 50%' }
  }

  if (!isNumber(vlrHora75)) {
    errors = { ...errors, erroVlrHora75: 'Informe o valor da hora extra 75%' }
  }

  if (!isNumber(vlrHora100)) {
    errors = { ...errors, erroVlrHora100: 'Informe o valor da hora extra 100%' }
  }

  if (!isNumber(vlrHoraAdn50)) {
    errors = {
      ...errors,
      erroVlrHoraAdn50: 'Informe o valor da hora adicional 50%',
    }
  }

  if (!isNumber(vlrHoraAdn75)) {
    errors = {
      ...errors,
      erroVlrHoraAdn75: 'Informe o valor da hora adicional 75%',
    }
  }

  if (!isNumber(vlrHoraAdn100)) {
    errors = {
      ...errors,
      erroVlrHoraAdn100: 'Informe o valor da hora adicional 100%',
    }
  }

  if (!isNumber(vlrHoraSobreAviso)) {
    errors = {
      ...errors,
      erroVlrHoraSobreAviso: 'Informe o valor da hora sobreaviso',
    }
  }

  if (!proposta) {
    errors = { ...errors, erroProposta: 'Informe a proposta' }
  }

  if (alocacao < 1) {
    errors = { ...errors, erroPerAlocacao: 'O percentual não deve ser menor do que 1%' }
  }


  if (alocacao > 100) {
    errors ={ ...errors, erroPerAlocacao: 'O percentual não deve ser maior do que 100%' 
    }
  }

  if (alocacao == null || alocacao == null) {
    errors ={ ...errors, erroPerAlocacao: 'O percentual não deve ser menor do que 1%' 
    }
  }

  return errors
}
