export const sortByDate = (data, key, ascending = true) => {
  const sortedData = [...data]

  sortedData.sort((a, b) => {
    const dateA = new Date(a[key])
    const dateB = new Date(b[key])

    if (ascending) {
      return dateA - dateB
    } else {
      return dateB - dateA
    }
  })

  return sortedData
}

export const sortData = (data, sortBy, ascending) => {
  const sortedData = [...data]

  sortedData.sort((a, b) => {
    const dateA = new Date(a[sortBy])
    const dateB = new Date(b[sortBy])

    if (ascending) {
      return dateA - dateB
    } else {
      return dateB - dateA
    }
  })

  return sortedData
}

export const formatDate = dateString => {
  const [year, month, day] = dateString.split('-')
  if (year) {
    return `${day}/${month}`
  }
  return `${day}/${month}`
}
