import React from 'react'
import PropTypes from 'prop-types'
import CreateIcon from '@material-ui/icons/Create'
import DuplicateIcon from '@material-ui/icons/DynamicFeed'
import ArrowUpwardIcon from '@material-ui/icons/KeyboardArrowUp'
import ArrowDownwardIcon from '@material-ui/icons/KeyboardArrowDown'

import MaterialTablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { globalStyles } from '../../styles/global'

import { tableIdentification } from './tableUtils'
const user = JSON.parse(localStorage.getItem('user'))

const useStyles = makeStyles({
    actionIcon: {
        cursor: 'pointer',
    },
})

const TablePagination = ({
    /** Pagination */
    count,
    onChangePage,
    onChangeSize,
    page,
    size,
    order,
    /** Table content */
    titleTable,
    headers,
    columnKeyName,
    rows,
    doNotShow = ['id'],
    customFooter,
    /** Actions */
    hasAction = true,
    startDelete,
    startEdit,
    startDuplicated,
    headersIndexOrd,
    performSorting,
    alignTitle = [],
    alignItens = [],
    maskFunction = {},
    renderCustomActions = () => null,
}) => {
    const styles = globalStyles()
    const actionIcon = useStyles().actionIcon

    const renderHeader = (header, index) => {
        return (
            <TableCell
                style={{
                    textAlign: alignTitle[index] ? alignTitle[index] : 'center',
                    color: '#ffff',
                }}
                className={styles.th}
                key={header}
            >
                {header}

                {performSorting && headersIndexOrd ? (
                    headersIndexOrd.find(
                        elem => elem.index === index && elem.typeOrd === 0
                    ) ? (
                        <>
                            <ArrowDownwardIcon
                                onClick={() => {
                                    performSorting(order[index], 1)
                                }}
                            />
                        </>
                    ) : headersIndexOrd.find(
                        elem => elem.index === index && elem.typeOrd === 1
                    ) ? (
                        <ArrowUpwardIcon
                            onClick={() => {
                                performSorting(order[index], 0)
                            }}
                        />
                    ) : (
                        <></>
                    )
                ) : (
                    <></>
                )}
            </TableCell>
        )
    }

    const renderRow = row => {
        return (
            <TableRow key={columnKeyName ? row[columnKeyName] : row.id}>
                {order
                    ? order.map((key, index) =>
                        renderColumn([key, row[key], index], index)
                    )
                    : Object.entries(row).map(renderColumn)}

                {hasAction && (
                    <TableCell style={{ textAlign: 'center' }}>
                        <CreateIcon className={actionIcon} onClick={() => startEdit(row)} />
                        {startDuplicated && (
                            <DuplicateIcon
                                className={actionIcon}
                                color={row.idUser === user.id || user.office.tipCargo === 'ADMINISTRADOR' ? 'inherit' : 'disabled'}
                                onClick={() => startDuplicated(row)}
                            />
                        )}
                        {renderCustomActions(row)} 
                    </TableCell>
                )}
            </TableRow>
        )
    }

    const renderColumn = (column, index) => {
        const key = column[0]
        const value = column[1]

        if (
            doNotShow.some(item => item === key) ||
            (typeof value === 'object' && value !== null)
        )
            return
        const parse = maskFunction[key] ? maskFunction[key](value) : value
        return (
            <TableCell
                style={{ textAlign: alignItens[index] ? alignItens[index] : 'center' }}
                key={`${key}-${value}`}
            >
                {tableIdentification(titleTable, key, parse)}
            </TableCell>
        )
    }

    if (hasAction && headers[headers.length - 1] !== 'Ações') {
        headers.push('Ações')
    }

    return (
        <Table className={styles.table}>
            {headers && (
                <TableHead className={styles.thead}>
                    <TableRow>
                        {headers.map((item, index) => renderHeader(item, index))}
                    </TableRow>
                </TableHead>
            )}

            <TableBody>
                {rows && rows.length !== 0 ? (
                    rows.map(renderRow)
                ) : (
                    <TableRow>
                        <TableCell align="center" colSpan={headers.length}>
                            <strong>
                                Não existem registros, por favor selecione um período!
                            </strong>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>

            <TableFooter>
                <TableRow>{customFooter}</TableRow>
                <TableRow>
                    <MaterialTablePagination
                        count={count}
                        labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} de ${count}`
                        }
                        labelRowsPerPage="Registros por página"
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeSize}
                        rowsPerPage={size}
                        page={page}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    )
}

TablePagination.propTypes = {
    count: PropTypes.number.isRequired,
    doNotShow: PropTypes.array,
    headers: PropTypes.array.isRequired,
    columnKeyName: PropTypes.string,
    onChangePage: PropTypes.func.isRequired,
    onChangeSize: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rows: PropTypes.array,
    size: PropTypes.number.isRequired,
    hasAction: PropTypes.bool,
    startDelete: PropTypes.func.isRequired,
    startEdit: PropTypes.func.isRequired,
    startDuplicated: PropTypes.func.isRequired,
    performSorting: PropTypes.func.isRequired,
    alignTitle: PropTypes.array,
    alignItens: PropTypes.array,
    maskFunction: PropTypes.object,
    renderCustomActions: PropTypes.func,
}

export default TablePagination