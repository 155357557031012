import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import * as actionEntryReport from '../../actions/entry'
import { get as getUsers } from '../../actions/user'
import { getFilter as getPeriods } from '../../actions/period'
import * as proposalActions from '../../actions/proposal'
import * as proposalUserActions from '../../actions/proposalUser'
import { globalStyles } from '../../styles/global'
import Main from '../Main'
import { Box, Grid } from '@material-ui/core'
import Filter from '../../utils/Filter/FilterFechamentoRD'

import Loading from '../../utils/Loading'
import Toast from '../../utils/Toast'

const EntryReportFechamentoRDSquad = ({
  /** Actions */
  getReportFechamentoSquadRD,
  getPeriods,
  fetchProposals,
  mandatoryFieldsNotCompletedErrorSquadRD = () => {},
  changeProposalFilterPageSize = () => {},
  /** Reducer props */
  listPeriodsFilter,
  errorReportSquadRD = null,
  warningReportSquadRD = null,
  successSquadRD = null,
  cleanToasterSquadRd = null,
  cleanFiltersSquadRd = null,
  cleanToasterProposalRd = null,
  listProposal,
}) => {
  momentDurationFormatSetup(moment)
  const styles = globalStyles()

  const [showLoading, setShowLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const [valuesFilter, setValuesFilter] = useState({
    dtInicial: moment(new Date()).format('LL'),
    dtFinal: moment(new Date()).format('LL'),
  })

  useEffect(() => {
    if (errorReportSquadRD || warningReportSquadRD) {
      setShowToast(true)
    } else {
      setShowToast(false)
    }
  }, [errorReportSquadRD, warningReportSquadRD])

  useEffect(() => {
    if (successSquadRD) {
      filterProps.limparFields = true
    }
  }, [successSquadRD])

  const validarCamposObrigatorios = ({ idPeriod, idProposta }) => {
    const messages = []
    if (!idPeriod) {
      messages.push('Informe um Período')
    }
    if (!idProposta) {
      messages.push('Informe uma Proposta')
    }
    return messages.join('; ')
  }

  const closeTaster = () => {
    cleanToasterSquadRd()
    cleanToasterProposalRd()
    setShowToast(false)
  }

  const cleanFilterReportSquadRd = () => {
    cleanFiltersSquadRd()
  }

  const handleFilter = (values, enviar) => {
    const { idPeriod, idProposta, txtDescricao } = values
    if (!enviar) return

    const errorMessage = validarCamposObrigatorios(values)
    if (errorMessage) {
      mandatoryFieldsNotCompletedErrorSquadRD(errorMessage)
      return
    }

    const dtInicial = moment(values.dtInicial).isValid()
      ? moment.utc(values.dtInicial).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    const dtFinal = moment(values.dtFinal).isValid()
      ? moment.utc(values.dtFinal).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    setValuesFilter({
      ...values,
      dtInicial: dtInicial,
      dtFinal: dtFinal,
    })

    const txtDescription = (txtDescricao || '').trim()
    const reportParams = {
      idPeriod,
      idProposal: idProposta,
    }
    if (txtDescription) {
      reportParams.txtDescription = txtDescription
    }

    getReportFechamentoSquadRD(reportParams)
  }

  let filterProps = {
    onSubmit: handleFilter,
    executeClearAllFields: cleanFilterReportSquadRd,
    enviar: false,
    limparFields: successSquadRD,
    fields: [
      {
        type: 'select-data',
        name: 'idPeriod',
        label: 'Período',
        options: (listPeriodsFilter || []).map(
          ({ id, desPeriodo, dtaInicio, dtaFim }) => ({
            label: desPeriodo,
            value: id,
            dtaInicio,
            dtaFim,
          })
        ),
      },
      {
        type: 'date',
        name: 'dtInicial',
        label: 'Data inicial',
        disabled: true,
      },
      {
        type: 'date',
        name: 'dtFinal',
        label: 'Data final',
        disabled: true,
      },
    ],

    fieldsLine2: [
      {
        type: 'select',
        name: 'idProposta',
        label: 'Proposta',
        options: (listProposal || [])
          .filter(proposta => proposta.desCliente === 'Raia Drogasil')
          .sort((propostaA, propostaB) =>
            propostaA.codProposta.localeCompare(propostaB.codProposta)
          )
          .map(({ id, codProposta }) => ({
            label: codProposta,
            value: id,
          })),
      },
    ],
    fieldsLine3: [
      {
        type: 'textarea',
        name: 'txtDescricao',
        label: 'Descrição',
      },
    ],
  }

  useEffect(async () => {
    setShowLoading(true)

    if (listPeriodsFilter == null || listPeriodsFilter.length === 0) {
      await getPeriods({ indFechado: 1, sort: 'dtaInicio desc' })
    }

    changeProposalFilterPageSize(9999)
    await fetchProposals()
    setShowLoading(false)
  }, [])

  useEffect(() => {
    if (Object.keys(valuesFilter).length !== 0) {
      handleFilter(valuesFilter)
    }
  }, [])

  return (
    <Main title="Fechamento e Relatório RD SQUADs">
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <Grid item xs={12}>
            <Box component="div" className={styles.box} xs={12}></Box>
            <Box component="div" className={styles.filter} xs={12}>
              <Filter {...filterProps} />
            </Box>
          </Grid>
        </>
      )}

      <Toast
        autoHideDuration={2000}
        onClose={() => closeTaster()}
        open={showToast}
        error={errorReportSquadRD}
        success={warningReportSquadRD}
      />
    </Main>
  )
}

const mapStateToProps = state => {
  const { periodReducer, entryReducer, proposalReducer } = state

  return {
    ...periodReducer,
    ...proposalReducer,
    ...entryReducer,
    listProposal: proposalReducer.listProposal,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionEntryReport,
      ...proposalActions,
      ...proposalUserActions,
      changeProposalFilterPageSize: proposalActions.changePageSize,
      getUsers,
      getPeriods,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryReportFechamentoRDSquad)
