import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { useFormik } from 'formik'

import TextField from '@material-ui/core/TextField'

import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import Select from '../../../utils/Form/Select'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
      decimalSeparator=","
      prefix="R$ "
      thousandSeparator="."
      isNumericString={true}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const tipFaturamento = [
  { key: 1, value: 'Horas' },
  { key: 2, value: 'Fechado' },
  { key: 3, value: 'Fechado Mês' },
]

const indInativo = [
  { key: true, value: 'ATIVO' },
  { key: false, value: 'INATIVO' },
]

const validationSchema = yup.object({
  idClient: yup.number().required('Por favor, escolha o cliente!'),
  desProjeto: yup
    .string()
    .max(100, 'O nome deve conter no máximo 100 caracteres!')
    .required('Por favor, informe o nome!'),
  desSigla: yup
    .string()
    .max(20, 'A sigla deve conter no máximo 20 caracteres')
    .required('Por favor, informe a sigla!'),
  tipFaturamento: yup
    .number()
    .required('Por favor, escolha o tipo de faturamento!'),
  indStatus: yup.boolean().required('Por favor, escolha o status!'),
  desLinguagemFront: yup
    .string()
    .max(20, 'A linguagem front end deve conter no máximo 20 caracteres!'),
  desLinguagemBack: yup
    .string()
    .max(20, 'A linguagem back end deve conter no máximo 20 caracteres!'),
  desLinguagemMobile: yup
    .string()
    .max(20, 'A linguagem mobile deve conter no máximo 20 caracteres!'),
})

const Form = ({
  clients = [],
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [listTipStatus, setListTipStatus] = useState([])
  const [listClients, setListClients] = useState([])

  const formik = useFormik({
    initialValues: {
      id: param.id,
      desProjeto: param.desProjeto,
      desSigla: param.desSigla,
      desLinguagemFront: param.desLinguagemFront,
      desLinguagemBack: param.desLinguagemBack,
      desLinguagemMobile: param.desLinguagemMobile,
      tipFaturamento: param.tipFaturamento,
      vlrPrjHor: param.vlrPrjHor ? param.vlrPrjHor : 0,
      indStatus: param.indStatus,
      idClient: param.idClient,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSubmit(values, isDeleteAction)
    },
  })

  useEffect(() => {
    setListTipStatus(
      indInativo.map(status => ({ key: status.key, value: status.value }))
    )
  }, [indInativo])

  useEffect(() => {
    setListClients(
      clients.map(client => ({ key: client.id, value: client.descCliente }))
    )
  }, [clients])

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <Select
                fullWidth
                select
                id="idClient"
                name="idClient"
                label="Cliente"
                options={listClients}
                value={{ value: formik.values.idClient }}
                onChange={formik.handleChange}
                error={
                  formik.touched.idClient && Boolean(formik.errors.idClient)
                }
                helperText={formik.touched.idClient && formik.errors.idClient}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                id="desProjeto"
                name="desProjeto"
                label="Nome"
                value={formik.values.desProjeto}
                onChange={formik.handleChange}
                error={
                  formik.touched.desProjeto && Boolean(formik.errors.desProjeto)
                }
                helperText={
                  formik.touched.desProjeto && formik.errors.desProjeto
                }
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                id="desSigla"
                name="desSigla"
                label="Sigla"
                value={formik.values.desSigla}
                onChange={formik.handleChange}
                error={
                  formik.touched.desSigla && Boolean(formik.errors.desSigla)
                }
                helperText={formik.touched.desSigla && formik.errors.desSigla}
                inputProps={{
                  maxLength: 20,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <Select
                fullWidth
                select
                id="tipFaturamento"
                name="tipFaturamento"
                label="Tipo Faturamento"
                options={tipFaturamento}
                value={{ value: formik.values.tipFaturamento }}
                onChange={formik.handleChange}
                error={
                  formik.touched.tipFaturamento &&
                  Boolean(formik.errors.tipFaturamento)
                }
                helperText={
                  formik.touched.tipFaturamento && formik.errors.tipFaturamento
                }
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                id="vlrPrjHor"
                name="vlrPrjHor"
                label="Valor Proj. Hora"
                value={formik.values.vlrPrjHor}
                onChange={formik.handleChange}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                // eslint-disable-next-line
                inputProps={{
                  maxLength: 40,
                }}
                defaultValue='0'
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <Select
                fullWidth
                select
                id="indStatus"
                name="indStatus"
                label="Status"
                options={listTipStatus}
                value={{ value: formik.values.indStatus }}
                onChange={formik.handleChange}
                error={
                  formik.touched.indStatus && Boolean(formik.errors.indStatus)
                }
                helperText={formik.touched.indStatus && formik.errors.indStatus}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                id="desLinguagemFront"
                name="desLinguagemFront"
                label="Linguagem Front End"
                value={formik.values.desLinguagemFront}
                onChange={formik.handleChange}
                error={
                  formik.touched.desLinguagemFront &&
                  Boolean(formik.errors.desLinguagemFront)
                }
                helperText={
                  formik.touched.desLinguagemFront &&
                  formik.errors.desLinguagemFront
                }
                inputProps={{
                  maxLength: 20,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <TextField
                fullWidth
                id="desLinguagemBack"
                name="desLinguagemBack"
                label="Linguagem Back End"
                value={formik.values.desLinguagemBack}
                onChange={formik.handleChange}
                error={
                  formik.touched.desLinguagemBack &&
                  Boolean(formik.errors.desLinguagemBack)
                }
                helperText={
                  formik.touched.desLinguagemBack &&
                  formik.errors.desLinguagemBack
                }
                inputProps={{
                  maxLength: 20,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <TextField
                fullWidth
                id="desLinguagemMobile"
                name="desLinguagemMobile"
                label="Linguagem Mobile"
                value={formik.values.desLinguagemMobile}
                onChange={formik.handleChange}
                error={
                  formik.touched.desLinguagemMobile &&
                  Boolean(formik.errors.desLinguagemMobile)
                }
                helperText={
                  formik.touched.desLinguagemMobile &&
                  formik.errors.desLinguagemMobile
                }
                inputProps={{
                  maxLength: 20,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <Button
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
            variant="contained"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  isDeleteAction: PropTypes.bool,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
}

export default Form
