import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as entryAutomaticActions from '../../actions/entryAutomatic'
import { get as getUsers } from '../../actions/user'
import { globalStyles } from '../../styles/global'
import Main from '../Main'
import { TableContainer, Paper, Box, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import ReorderIcon from '@material-ui/icons/Reorder'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/FilterEntryAutomatic'
import Table from './components/Table'
import { Loader } from '../../utils/Loading'

const EntryAutomatic = props => {
  const {
    listPeriods,
    getList,
    list,
    getPeriod,
    listProposal,
    getProposal,
    setPeriod,
    setProposal,
    setUser,
    clearList,
    generateEntry,
    feedback,
    clearFilters,
    listUsers,
    getUsers,
  } = props
  const styles = globalStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showToastError, setShowToastError] = useState(false)
  const [isPeriod, setIsPeriod] = useState(false)
  const [isProposal, setIsProposal] = useState(false)
  const [isUser, setIsUser] = useState(false)
  const [filter, setFilter] = useState({})

  const listaProposal = useCallback(
    async period => {
      const selectedPeriod = listPeriods.find(
        item => item.id === period
      )
      await getProposal(selectedPeriod)
    },
    [listPeriods]
  )

  useEffect(() => {
    if (feedback.success) {
      setShowToast(true)
    } else {
      setShowToast(false)
    }

    if (feedback.error) {
      setShowToastError(true)
    } else {
      setShowToastError(false)
    }
  }, [feedback])

  const handleFilterSubmit = async value => {
    const selectedPeriod = listPeriods.find(
      item => item.id === value.idPeriod
    )

    setFilter(value)
    setIsLoading(true)
    if (selectedPeriod)
      if (isProposal === true || isUser === true) {
        await getList(selectedPeriod);
      }
    setIsLoading(false)
  }

  useEffect(() => {
    getPeriod({ indFechado: 0, sort: 'dtaInicio desc' })
  }, [listProposal, isProposal])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  const handleUserFilterChange = async (fieldName, value) => {
    if (fieldName === 'idPeriod') {
      await setPeriod({
        label: fieldName,
        value: value
      })
      if(value === null){
        setIsPeriod(false)
      } else {
        await listaProposal(value)
        setIsPeriod(true)
      }
    } else if (fieldName === 'idProposal') {
      await setProposal({
        label: fieldName,
        value: value
      })
      if(value === null){
        setIsProposal(false)
      } else {
        setIsProposal(true)
      }
    } else if (fieldName === 'idUser') {
      await setUser({
        label: fieldName,
        value: value
      })
      if(value === null){
        setIsUser(false)
      } else {
        setIsUser(true)
      }
    } else {
      setIsPeriod(false)
      setIsProposal(false)
      setIsUser(false)
    }
  }

  const handleClearTable = async () => {
    await clearList();
    await clearFilters();
  }

  const handleGenerateEntry = async () => {
    const selectedPeriod = listPeriods.find(
      item => item.id === filter.idPeriod
    )
    await generateEntry(selectedPeriod);
  }

  const filterProps = useMemo(() => ({
    onSubmit: handleFilterSubmit,
    onValueChange: handleUserFilterChange,
    onClear: handleClearTable,
    fields: [
      {
        type: 'select',
        name: 'idPeriod',
        label: 'Período',
        options: (listPeriods || []).map(({ id, desPeriodo }) => ({
          label: desPeriodo,
          value: id,
        })),
      },
      {
        type: 'select',
        name: 'idProposal',
        label: 'Proposta',
        options:
        (listProposal || [])
        .filter((item) => item.tipStatus === 'ANDAMENTO')
        .map(item => ({
          label: item.codProposta,
          value:  item.id,
        }))
        .sort((a, b) => {
          const propostaA = a.label;
          const propostaB = b.label;
          const alphaComparison = propostaA.localeCompare(propostaB, undefined, { numeric: true });
          return alphaComparison !== 0 ? alphaComparison : (parseInt(propostaA) - parseInt(propostaB)); }),
        disabled: (isPeriod && isUser) || !isPeriod,
        },
      {
        type: 'select',
        name: 'idUser',
        label: 'Usuário',
        // initial: valuesFilter.idUser,
        options: (listUsers || [])
          .sort((userA, userB) =>
            userA.desUsuario.localeCompare(userB.desUsuario)
          )
          .filter((item) => item.indInativo === true)
          .map(({ id, desUsuario }) => ({
            label: desUsuario,
            value: id,
          })),
        // disabled: user.office.tipCargo === 'OPERAÇÃO',
        disabled: (isPeriod && isProposal) || !isPeriod,
      },
    ],
  }), [listPeriods, isPeriod, isProposal, isUser, listProposal, list])

  const renderTable = () => {
    return (
      <>
        <Loader isLoading={isLoading} />
        <Table />
      </>
    )
  }

  const disableGenerate = !isPeriod || (!isProposal && !isUser) || !list.length

  return (
    <Main title="Lançamento automático">
      <Toast
        success={feedback.message}
        open={showToast}
        onClose={() => setShowToast(false)}
        autoHideDuration={2000}
      />
      <Toast
        error={feedback.message}
        open={showToastError}
        onClose={() => setShowToastError(false)}
        autoHideDuration={2000}
      />
      <Grid item xs={12}>
        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>
        <TableContainer component={Paper}>{renderTable()}</TableContainer>
        <Grid
          container
          className="common-filter"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={8}
          style={{ marginTop: 10 }}
        >
          <Grid item xs={3}>
            <ButtonGroup variant="contained" size="large">
              <Button
                style={{ paddingLeft: 25, paddingRight: 25 }}
                startIcon={<FilterListIcon />}
                color="primary"
                onClick={() => handleGenerateEntry()}
                className="submit-button-fechamento"
                disabled={disableGenerate}
              >
                Gerar
              </Button>
              <Button
                style={{ paddingLeft: 25, paddingRight: 25 }}
                startIcon={<ReorderIcon />}
                color="secondary"
                onClick={() => handleClearTable()}
                className="submit-button-fechamento"
              >
                Limpar
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    </Main>
  )
}

const mapStateToProps = state => {
  const {
    entryAutomaticReducer,
    userReducer,
  } = state

  return {
    ...entryAutomaticReducer,
    ...userReducer,
    listPeriods: entryAutomaticReducer.listPeriods,
    listProposal: entryAutomaticReducer.listProposal,
    list: entryAutomaticReducer.list,
    feedback: entryAutomaticReducer.feedback,
    period: entryAutomaticReducer.period,
    listUsers: userReducer.listUsers,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getList: entryAutomaticActions.getList,
      getPeriod: entryAutomaticActions.getPeriod,
      getProposal: entryAutomaticActions.getProposal,
      setPeriod: entryAutomaticActions.setPeriod,
      setProposal: entryAutomaticActions.setProposal,
      setUser: entryAutomaticActions.setUser,
      clearList: entryAutomaticActions.clearList,
      generateEntry: entryAutomaticActions.generateEntry,
      clearFilters: entryAutomaticActions.clearFilters,
      getUsers,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EntryAutomatic)
