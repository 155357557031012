import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.employee + '/filter',
      param
    )
    return dispatch(trigger(types.employee.getFilter.success, response))
  } catch (e) {
    return dispatch(trigger(types.employee.getFilter.error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.employee, param)
    return dispatch(trigger(types.employee.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.employee.get.error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.employee, param)

    return dispatch(trigger(types.employee.save.success))
  } catch (e) {
    const errorMessage = !!e.response && !!e.response.data.message ?
      e.response.data.message :
      'Erro ao salvar colaborador'
    return dispatch(trigger(types.employee.save.error, errorMessage))
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.employee, param)

    return dispatch(trigger(types.employee.delete.success))
  } catch (e) {
    return dispatch(trigger(types.employee.delete.error))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.employee, param)

    return dispatch(trigger(types.employee.update.success))
  } catch (e) {
    const errorMessage = !!e.response && !!e.response.data.message ?
      e.response.data.message :
      'Erro ao atualizar colaborador'
    return dispatch(trigger(types.employee.update.error, errorMessage))
  }
}

export const clearMessages = () => dispatch => {
  dispatch(trigger(types.employee.clearMessages))
}
