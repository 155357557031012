/**
 * Default pagination page
 */
export const DEFAULT_PAGINATION_PAGE = 0

/**
 * Default pagination size
 */
export const DEFAULT_PAGINATION_SIZE = 10

export const DEFAULT_RELEASES_PAGINATION_SIZE = 50
