import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { TextField } from '@material-ui/core'

const TextArea = ({ name, label, setValueToField, stateValues, disabled, inputProps }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <TextField
        value={stateValues && stateValues.length > 0 ? stateValues : ''}
        label={label}
        name={name}
        rows={10}
        variant="outlined"
        onChange={({ target: { value } }) => {
          setValueToField(name, value)
        }}
        disabled={disabled}
        multiline={10}
        inputProps={inputProps}
      />
    </FormControl>
  )
}

export default TextArea
