import { NULL_VALUE } from '../../../constants/other'

/**
 * Value or {@link NULL_VALUE} if not a integer
 *
 * @param {string} value - Value
 */
const valueOrDefault = value => {
  return value || NULL_VALUE
}

const convertValueTrueFalseYesNot = value => {
  return value ? 'SIM' : 'NÃO'
}

const replacingHours = value => {
  return value + ' hora(s)'
}

const replacingPrice = value => {
  return 'R$ ' + value
}

export const tableIdentification = (titleTable, keyColumn, valueComlumn) => {
  switch (titleTable) {
    case 'period':
      return keyColumn === 'indFechado' || keyColumn === 'liberado'
        ? convertValueTrueFalseYesNot(valueComlumn)
        : valueOrDefault(valueComlumn)
    case 'entry':
      return keyColumn === 'indFinalizado'
        ? convertValueTrueFalseYesNot(valueComlumn)
        : valueOrDefault(valueComlumn)
    case 'clientPeriod':
      return keyColumn === 'qtdHoras'
        ? replacingHours(valueComlumn)
        : keyColumn === 'vlrHora'
          ? replacingPrice(valueComlumn)
          : keyColumn === 'vlrTotal'
            ? replacingPrice(valueComlumn)
            : valueOrDefault(valueComlumn)
    default:
      return valueOrDefault(valueComlumn)
  }
}
