import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import AddIcon from '@material-ui/icons/Add'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { TableContainer, Paper, Box, Fab } from '@material-ui/core'

import * as actionClient from '../../actions/client'
import * as actionProject from '../../actions/project'
import * as actionPoint from '../../actions/point'
import * as actionActivity from '../../actions/activity'
import * as actionStatusHistory from '../../actions/statusHistory'
import { get as getUsers } from '../../actions/user'
import Form from './components/Form'
import Main from '../Main'
import { globalStyles } from '../../styles/global'
import Dialog from '../../utils/Dialog'
import TablePagination from '../../utils/Table/TablePagination'
import Filter from '../../utils/Filter/Filter'
import Toast from '../../utils/Toast'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'dthPrevisaoEntrega desc'

const DEFAULT_PARAM = {
  id: null,
  pointId: '',
  projectId: '',
  userId: '',
  tipAtividade: '',
  tipStatus: '',
  sigAtividade: '',
  desAtividade: '',
  indSobreaviso: false,
  dthInclusao: '',
  dthPrevisaoEntrega: '',
  dthConclusao: '',
}

const tipStatus = [
  { label: 'INCLUÍDA', value: 'INCLUÍDA' },
  { label: 'CONCLUÍDA', value: 'CONCLUÍDA' },
  { label: 'EM DESENVOLVIMENTO', value: 'EM DESENVOLVIMENTO' },
  { label: 'ENTREGUE', value: 'ENTREGUE' },
]

const ACTIVITIES_ORDER = [
  'descClient',
  'desProjeto',
  'sigAtividade',
  'desUsuario',
  'tipStatus',
  'dthPrevisaoEntrega',
]

const Tasks = ({
  /** Actions */
  getPoints,
  getClients,
  getProjects,
  getFilter,
  get,
  remove,
  save,
  update,
  clearMessages,
  /** Reducer props */
  points,
  clients,
  projects,
  listActivitiesFilter,
  errorActivities,
  successActivities,
  totalElementsActivities,
  importTasks,
  listUsers,
  getUsers,
}) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState('Nova atividade')

  const [showToast, setShowToast] = useState(false)

  const [selectState, setSelectState] = useState({ fields: [] })

  const [valuesFilter, setValuesFilter] = useState([])

  const [importMessageSuccess, setImportMessageSuccess] = useState(null)
  const [importMessageError, setImportMessageError] = useState(null)

  const [dragModalDisabled, setDragModalDisabled] = useState(false)

  const handleFilter = values => {
    const { idClient, idProject, idUser, tipStatus } = values
    setValuesFilter(values)
    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: size,
      ...(idClient && { idClient }),
      ...(idProject && { idProject }),
      ...(idUser && { idUser }),
      ...(tipStatus && { tipStatus }),
      sort: SORT
    })

    setPage(DEFAULT_PAGINATION_PAGE)
  }

  let filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'idClient',
        label: 'Cliente',
        initial: valuesFilter.idClient,
        options: clients.map(client => ({
          label: client.descCliente,
          value: client.id,
        })),
      },
      {
        type: 'select',
        name: 'idProject',
        label: 'Projeto',
        initial: valuesFilter.idProject,
        options: projects
          .filter(project => project.idClient === selectState.fields.idClient)
          .map(({ id, desProjeto }) => ({
            label: desProjeto,
            value: id,
          })),
        disabled: !selectState.fields.idClient,
      },
      {
        type: 'select',
        name: 'tipStatus',
        label: 'Status',
        initial: valuesFilter.tipStatus,
        options: tipStatus,
      },
      {
        type: 'select',
        name: 'idUser',
        label: 'Usuário',
        initial: valuesFilter.idUser,
        options: listUsers
          .sort((userA, userB) =>
            userA.desUsuario.localeCompare(userB.desUsuario)
          )
          .map(user => ({
            label: user.desUsuario,
            value: user.id,
          })),
        disabled: user.office.tipCargo === 'OPERAÇÃO',
      },
    ],
    monitoreState: state => setSelectState(state),
  }

  const changePage = page => {
    const { idClient, idProject, idUser, tipStatus } = valuesFilter

    setPage(page)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      ...(idClient && { idClient: idClient }),
      ...(idProject && { idProject }),
      ...(idUser && { idUser }),
      ...(tipStatus && { tipStatus }),
      sort: SORT
    })
  }

  const changeSize = size => {
    const { idClient, idProject, idUser, tipStatus } = valuesFilter

    setSize(size)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      ...(idClient && { idClient: idClient }),
      ...(idProject && { idProject }),
      ...(idUser && { idUser }),
      ...(tipStatus && { tipStatus }),
      sort: SORT
    })
  }

  const convertRecord = record => {
    return {
      ...record,
      pointId: record.pointId,
      projectId: record.projectId,
      userId: record.userId,
      tipAtividade: record.tipAtividade,
      tipStatus: record.tipStatus,

      sigAtividade: record.sigAtividade,
      desAtividade: record.desAtividade,

      indSobreaviso: record.indSobreaviso,
      dthInclusao: record.dthInclusao,
      dthPrevisaoEntrega: record.dthPrevisaoEntrega,
      dthConclusao: record.dthConclusao,
    }
  }

  const query = async () => {
    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      sort: SORT
    })
    getProjects()
    getPoints()
    getClients()
    get()
  }

  const handleSubmit = async values => {
    setShowDialog(false)

    await onSubmit(values)

    setShowToast(true)

    handleFilter(valuesFilter)

    return null
  }

  const startSave = () => {
    setParam(convertRecord(DEFAULT_PARAM))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Nova atividade')

    setShowDialog(true)
  }

  const startDelete = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(true)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)
    setTitle(`Você deseja deletar a atividade ${record.sigAtividade}?`)

    setShowDialog(true)
  }

  const startEdit = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)
    setTitle('Alterar registro')

    setShowDialog(true)
  }

  useEffect(() => {
    query()
  }, [])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  const onImport = e => {
    if (validateImportFile(e.target.files)) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('csv', file)

      importTasks(formData)
        .then(data => {
          if (Array.isArray(data) && data.length > 0) {
            const message = data.map(item => (
              <>
                {item} <br />
              </>
            ))
            setImportMessageSuccess(message)
            setShowToast(true)
            if (!data[0].endsWith('ja está cadastrada')) {
              const { idClient, idProject, idUser, tipStatus } = valuesFilter
              getFilter({
              pageFilter: page + 1,
              sizeFilter: size,
              ...(idClient && { idClient: idClient }),
              ...(idProject && { idProject }),
              ...(idUser && { idUser }),
              ...(tipStatus && { tipStatus }),
              sort: SORT
            })}
          }
        })
        .catch(err => {
          setImportMessageError(
            err && err.message && err.message.messageError
              ? err.message.messageError
              : 'Erro ao realizar importação'
          )
          setShowToast(true)
        })
        .finally(() => {
          const inp = document.querySelector('#import-file')
          if (inp) inp.value = ''
        })
    }
  }

  const validateImportFile = e => {
    let valid = true

    if (e.length > 0) {
      const file = e[0]
      let ext = (file.name || '').split('.')
      ext = ext[ext.length - 1].toLowerCase()
      if (['csv'].indexOf(ext) === -1) {
        valid = false
        setImportMessageError('Extensão inválida!')
        setShowToast(true)
      }
    } else {
      valid = false
    }

    return valid
  }

  return (
    <Main title="Atividades">
      <Box component="div" className={styles.box} xs={12}>
        <input
          type="file"
          name="file"
          id="import-file"
          accept=".csv"
          style={{ width: 0, height: 0, opacity: 0 }}
          onChange={onImport}
        />
        <Fab color="primary" variant="extended" className={styles.buttonImport}>
          <InsertDriveFileIcon />
          &nbsp;&nbsp;{'Importar'}
          <label
            htmlFor="import-file"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              borderRadius: 24,
              zIndex: 3,
            }}
          />
        </Fab>
        <Fab color="primary" onClick={startSave}>
          <AddIcon />
        </Fab>
      </Box>

      <Box component="div" className={styles.filter} xs={6}>
        <Filter {...filterProps} />
      </Box>

      <TableContainer component={Paper}>
        <TablePagination
          order={ACTIVITIES_ORDER}
          titleTable="activity"
          count={totalElementsActivities}
          doNotShow={[
            'id',
            'pointId',
            'projectId',
            'userId',
            'numPontuacao',
            'desAtividade',
            'tipAtividade',
            'indSobreaviso',
            'dthInclusao',
            'dthConclusao',
            'perConclusao',
          ]}
          headers={[
            'Cliente',
            'Projeto',
            'Atividade(Sigla)',
            'Usuário',
            'Status',
            'Previsão de Entrega',
          ]}
          onChangePage={(_, page) => changePage(page)}
          onChangeSize={event => changeSize(event.target.value)}
          page={page}
          rows={listActivitiesFilter}
          size={size}
          startDelete={startDelete}
          startEdit={startEdit}
          alignTitle={[
            'center',
            'center',
            'center',
            'center',
            'center',
            'center',
          ]}
          alignItens={[
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            'center',
            'center',
            'center',
            'center',
            'center',
            'center',
          ]}
        />
      </TableContainer>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
        isDragDisabled={dragModalDisabled}
      >
        <Form
          isDeleteAction={isDeleteAction}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          primaryButtonColor={primaryButtonColor}
          primaryButtonLabel={primaryButtonLabel}
          projects={projects}
          clients={clients}
          users={listUsers}
          points={points}
          onOpenDateModal={() => setDragModalDisabled(true)}
          onCloseDateModal={() => setDragModalDisabled(false)}
        />
      </Dialog>

      <Toast
        autoHideDuration={4000}
        error={errorActivities || importMessageError}
        onClose={() => {
          setShowToast(false)
          setImportMessageError(null)
          setImportMessageSuccess(null)
          clearMessages()
        }}
        open={showToast}
        success={successActivities || importMessageSuccess}
      />
    </Main>
  )
}

const mapStateToProps = ({
  clientReducer,
  projectReducer,
  userReducer,
  pointReducer,
  statusHistoryReducer,
  activityReducer,
}) => ({
  ...clientReducer,
  ...projectReducer,
  ...userReducer,
  ...pointReducer,
  ...statusHistoryReducer,
  ...activityReducer,
  listUsers: userReducer.listUsers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionActivity,
      getPoints: actionPoint.get,
      savePoints: actionPoint.save,
      getClients: actionClient.get,
      getProjects: actionProject.get,
      saveStatusHistory: actionStatusHistory.save,
      getUsers,
    },
    dispatch
  )

Tasks.propTypes = {
  /** Actions */
  getUsers: PropTypes.func.isRequired,
  getPoints: PropTypes.func.isRequired,
  savePoints: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  saveStatusHistory: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  /** Reducer props */
  points: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  listActivitiesFilter: PropTypes.array.isRequired,
  listActivities: PropTypes.array.isRequired,
  errorActivities: PropTypes.array.isRequired,
  successActivities: PropTypes.array.isRequired,
  totalElementsActivities: PropTypes.number.isRequired,
  importTasks: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks)
