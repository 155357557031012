import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  list: [],
  executionUserList: [],
  toEdit: {
    executionUserToEdit: null,
    executionToEdit: null,
  },
  period: {
    label: '',
    value: null
  },
  proposal: {
    label:'',
    value: null
  },
  requestFilters: {
    pageFilter: 0,
    sizeFilter: 10,
    periodId: null,
    proposalId: null, 
    executionId: null,
  },
  error: null,
  success: null,
  errorExecution: null,
  successExecution: null,
  totalCount: 0,
  totalCountExecUser: 0,
}

export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {
    case types.execution.get.success:
      return {
        ...state,
        list: [...action.payload],
      }

    case types.execution.getByMonth.success:
      return {
        ...state,
        list: [...action.payload.resultList],
        totalCount: action.payload.pages.totalCount,
      }

      case types.execution.getByMonthProposal.success:
        return {
          ...state,
          list: [...action.payload.resultList],
          totalCount: action.payload.pages.totalCount,
        }
    
    case types.execution.user.success:
      return {
        ...state,
        executionUserList: [...action.payload.resultList],
        totalCountExecUser: action.payload.pages.totalCount,
        successExecution: null,
        errorExecution: null
      }

    case types.execution.setEdit:
      return {
        ...state,
        toEdit: { ...action.payload }
      }
    
    case types.execution.update.success:
      return {
        ...state,
        toEdit: {
          executionUserToEdit: action.payload,
          executionToEdit: {...state.toEdit.executionToEdit}
        },
        success: ["Sucesso"],
        error: null
      }
    
    case types.execution.update.observation:
      return {
        ...state,
        toEdit: {
          executionUserToEdit: {...state.toEdit.executionUserToEdit},
          executionToEdit: action.payload
        },
        successExecution: ["Sucesso"],
        errorExecution: null
      }
    
    case types.execution.update.observationError:
      return {
        ...state,
        successExecution: null,
        errorExecution: ["erro"],
      }

    case types.execution.update.error:
      return {
        ...state,
        success: null,
        error: ["erro"],
      }

    case types.execution.updateFilter:
      return {
        ...state,
        requestFilters: {
          ...action.payload,
        },
        success: null,
        error: null,
      }
    
    case types.execution.updatePeriod:
      return {
        ...state,
        requestFilters: {
          ...state.requestFilters,
          periodId: action.payload.periodId,
          proposalId: action.payload.proposalId,
          pageFilter: 0
        },
        proposal: action.payload.proposalId,
        period: action.payload.period,
        success: null,
        error: null,
      }
  
    case types.execution.updatePageFilter:
      return {
        ...state,
        requestFilters: {
          ...action.payload,
        },
        success: null,
        error: null,
      }
  
    case types.execution.updateSizeFilter:
      return {
        ...state,
        requestFilters: {
          ...action.payload,
        },
        success: null,
        error: null,
      }
    
    case types.execution.clear: 
      return {
        ...state,
        list: [],
        error: null,
        success: null,
        errorExecution: null,
        successExecution: null,
      }

    default:
      return {
        ...state
      }
  }
}