import { types } from '../../config/action_types'

const defaultState = {
  isLoad: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.load.start:
      return {
        isLoad: true,
      }

    case types.load.stop:
      return {
        isLoad: false,
      }

    default:
      return state
  }
}
