import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { useFormik } from 'formik'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import { makeStyles } from '@material-ui/core/styles'

const indInativo = [
  { key: true, value: 'ATIVO' },
  { key: false, value: 'INATIVO' },
]

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
      decimalSeparator=","
      prefix="R$ "
      thousandSeparator="."
      isNumericString={true}
      maxLength={24}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

function NumberFormatCustomCoeficiente(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      fixedDecimalScale={true}
      decimalScale={6}
      decimalSeparator=","
      isNumericString={true}
      maxLength={30}
    />
  )
}

NumberFormatCustomCoeficiente.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const validationSchemaSave = yup.object({
  desEmail: yup
    .string()
    .email('Por favor, informe um e-mail válido!')
    .max(50, 'O e-mail deve conter no máximo 50 caracteres!')
    .required('Por favor, informe o e-mail!'),
  desSenha: yup
    .string()
    .max(30, 'A senha deve conter no mínimo 30 caracteres!')
    .min(6, 'A senha deve conter no mínimo 6 caracteres!')
    .required('Por favor, informe a senha!'),
  desUsuario: yup
    .string()
    .max(200, 'O nome deve conter no máximo 200 caracteres!')
    .required('Por favor, informe o nome!'),
  indInativo: yup.boolean().required('Por favor, escolha o status!'),
  tipContratacao: yup.string().required('Por favor, escolha o contrato!'),
  idOffice: yup.number().required('Por favor, escolha o cargo! '),
})

const validationSchemaEdit = yup.object({
  desEmail: yup
    .string()
    .email('Por favor, informe um e-mail válido!')
    .max(50, 'O e-mail deve conter no máximo 50 caracteres!')
    .required('Por favor, informe o e-mail!'),
  desSenha: yup
    .string()
    .max(30, 'A senha deve conter no mínimo 30 caracteres!')
    .min(6, 'A senha deve conter no mínimo 6 caracteres!'),
  desUsuario: yup
    .string()
    .max(200, 'O nome de conter no máximo 200 caracteres!')
    .required('Por favor, informe o nome!'),
  indInativo: yup.boolean().required('Por favor, escolha o status!'),
  tipContratacao: yup.string().required('Por favor, escolha o contrato!'),
  idOffice: yup.number().required('Por favor, escolha o cargo! '),
})

const validationSchemaDelete = yup.object({
  desUsuario: yup
    .string()
    .max(200, 'O nome de conter no máximo 200 caracteres!')
    .required('Por favor, informe o nome!'),
  indInativo: yup.boolean().required('Por favor, escolha o status!'),
  tipContratacao: yup.string().required('Por favor, escolha o contrato!'),
  idOffice: yup.number().required('Por favor, escolha o cargo! '),
})

/**
 * User form
 */
const Form = ({
  listTipContratacao,
  isDeleteAction = false,
  isUpdateAction = false,
  listOffices = [],
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [offices, setOffices] = useState([])
  const [listTipStatus, setListTipStatus] = useState([])

  const formik = useFormik({
    initialValues: {
      id: param.id,
      idOffice: param.idOffice,
      desEmail: param.desEmail.trim(),
      desSenha: param.desSenha,
      desUsuario: param.desUsuario,
      indInativo: param.indInativo,
      numCoeficienteEntrega: param.numCoeficienteEntrega,
      tipContratacao: param.tipContratacao,
      vlrSalario: param.vlrSalario,
    },
    validationSchema: isUpdateAction
      ? validationSchemaEdit
      : isDeleteAction
      ? validationSchemaDelete
      : validationSchemaSave,
    onSubmit: values => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    setOffices(
      listOffices.map(office => ({ key: office.id, value: office.desCargo }))
    )
  }, [listOffices])

  useEffect(() => {
    setListTipStatus(
      indInativo.map(status => ({ key: status.key, value: status.value }))
    )
  }, [indInativo])

  const classes = makeStyles({
    container: {
      marginTop: 50,
    },
  })()

  // const resolveContract = () => {
  //   const obj = listTipContratacao.find((item) => item.value === formik.values.tipContratacao)
  //   if (obj) return obj.key
  // }

  return (
    <form onSubmit={formik.handleSubmit} autocomplete="new-password">
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                autocomplete="new-password"
                autoFocus
                fullWidth
                id="desUsuario"
                name="desUsuario"
                label="Nome"
                value={formik.values.desUsuario}
                onChange={formik.handleChange}
                error={
                  formik.touched.desUsuario && Boolean(formik.errors.desUsuario)
                }
                helperText={
                  formik.touched.desUsuario && formik.errors.desUsuario
                }
                inputProps={{
                  maxLength: 200,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.container,
                    variant: 'menu',
                  },
                }}
                id="idOffice"
                name="idOffice"
                label="Cargo"
                value={formik.values.idOffice}
                onChange={formik.handleChange}
                error={
                  formik.touched.idOffice && Boolean(formik.errors.idOffice)
                }
                helperText={formik.touched.idOffice && formik.errors.idOffice}
              >
                {offices.map(office => (
                  <MenuItem key={office.key} value={office.key}>
                    {office.value}
                  </MenuItem>
                ))}
              </TextField>
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.container,
                    variant: 'menu',
                  },
                }}
                id="tipContratacao"
                name="tipContratacao"
                label="Contrato"
                value={formik.values.tipContratacao}
                onChange={formik.handleChange}
                error={
                  formik.touched.tipContratacao &&
                  Boolean(formik.errors.tipContratacao)
                }
                helperText={
                  formik.touched.tipContratacao && formik.errors.tipContratacao
                }
              >
                {listTipContratacao.map(contrato => (
                  <MenuItem key={contrato.key} value={contrato.key}>
                    {contrato.value}
                  </MenuItem>
                ))}
              </TextField>
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                id="vlrSalario"
                name="vlrSalario"
                label="Salário"
                value={formik.values.vlrSalario}
                onChange={formik.handleChange}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                id="numCoeficienteEntrega"
                name="numCoeficienteEntrega"
                label="Coef. Entrega"
                value={formik.values.numCoeficienteEntrega}
                onChange={formik.handleChange}
                InputProps={{
                  inputComponent: NumberFormatCustomCoeficiente,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.container,
                    variant: 'menu',
                  },
                }}
                id="indInativo"
                name="indInativo"
                label="Status"
                value={formik.values.indInativo}
                onChange={formik.handleChange}
                error={
                  formik.touched.indInativo && Boolean(formik.errors.indInativo)
                }
                helperText={
                  formik.touched.indInativo && formik.errors.indInativo
                }
              >
                {listTipStatus.map(status => (
                  <MenuItem key={status.key} value={status.key}>
                    {status.value}
                  </MenuItem>
                ))}
              </TextField>
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                autoComplete="off"
                id="desEmail"
                name="desEmail"
                label="E-mail"
                value={formik.values.desEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.desEmail && Boolean(formik.errors.desEmail)
                }
                helperText={formik.touched.desEmail && formik.errors.desEmail}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FlexItemWrapper>
            {!isUpdateAction && (
              <>
                <FlexItemWrapper right>
                  <TextField
                    autoComplete="new-password"
                    fullWidth
                    id="desSenha"
                    name="desSenha"
                    label="Senha"
                    type="password"
                    value={formik.values.desSenha}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.desSenha && Boolean(formik.errors.desSenha)
                    }
                    helperText={
                      formik.touched.desSenha && formik.errors.desSenha
                    }
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </FlexItemWrapper>
              </>
            )}
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <Button
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  isDeleteAction: PropTypes.bool,
  listOffices: PropTypes.array,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
}

export default Form
