import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { buttonsStyles, col } from './useStyles'

const ButtonsCancelAndSubmit = props => {
  const {
    onCancel,
    onSubmit,
    cancelLabel = 'Cancelar',
    submitLabel = 'Salvar',
    submitColor = 'primary',
    saveDisabled = false,
  } = props

  const classes = buttonsStyles()

  return (
    <Box component="div" className={classes.boxButton} xs={col}>
      <Button
        className={classes.button}
        variant="contained"
        color="default"
        onClick={onCancel}
      >
        {cancelLabel}
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        disabled={saveDisabled}
        onClick={onSubmit}
        color={submitColor}
      >
        {submitLabel}
      </Button>
    </Box>
  )
}

export default ButtonsCancelAndSubmit

ButtonsCancelAndSubmit.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  saveDisabled: PropTypes.bool,
}
