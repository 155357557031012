import React from 'react'
import { makeStyles } from '@material-ui/core/'

const useStyles = makeStyles({
  loadingWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff80',
    opacity: 1,
    zIndex: 1,
  },
})

export const Loader = ({ isLoading }) => {
  const classes = useStyles()

  if (!isLoading) return null

  return (
    <div className={classes.loadingWrapper}>
      <Load />
    </div>
  )
}

const Load = _ => <div className="loading">DEVELCODE</div>

export default Load
