import React, { useState } from 'react'
import PropTypes from 'prop-types'

/**
 * Higher order component for forms
 *
 * @param {node} WrappedComponent - child component
 */
const useForm = WrappedComponent => ({
  onSubmit = () => {},
  param = {},
  ...props
}) => {
  /** Value of each field in the form */
  const [values, setValues] = useState(param)

  /** Prevent default action on submit */
  const beforeSubmit = event => {
    event.preventDefault()

    onSubmit(values)
  }

  /** Set value to field */
  const setter = field => value => {
    setValues({ ...values, [field]: value })
  }

  return (
    <WrappedComponent
      beforeSubmit={beforeSubmit}
      setter={setter}
      values={values}
      {...props}
    />
  )
}

useForm.PropTypes = {
  onSubmit: PropTypes.func,
  param: PropTypes.object,
}

export default useForm
