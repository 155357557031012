import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actionClientPeriod from '../../actions/clientPeriod'
import { get as getClients } from '../../actions/client'
import { get as getProjects } from '../../actions/project'
import { get as getPeriods } from '../../actions/period'

import Main from '../Main'
import TablePagination from '../../utils/Table/TablePagination'
import { globalStyles } from '../../styles/global'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '../../utils/Dialog'
import { TableContainer, Paper, Box, Grid, Fab } from '@material-ui/core'
import Form from './components/Form'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/Filter'

import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'id'

const DEFAULT_PARAM = {
  id: null,
  qtdHoras: '',
  vlrHora: '',
  vlrTotal: '',
}

const TABLE_HEADER = [
  'Cliente',
  'Projeto',
  'Período',
  'Horas',
  'Valor Hora',
  'Valor Total',
]

const presentOrder = [
  'descClient',
  'desProjeto',
  'desPeriodo',
  'qtdHoras',
  'vlrHora',
  'vlrTotal',
]

const ClientPeriod = ({
  /** Actions */
  getClients,
  getProjects,
  getPeriods,
  getFilter,
  get,
  remove,
  save,
  update,
  /** Reducer props */
  listClients,
  listProjects,
  listPeriods,
  listClientPeriodFilter,
  errorClientPeriod,
  successClientPeriod,
  totalElementsClientPeriod,
}) => {
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState(
    'Novo registro manutenção cliente por período'
  )

  const [showToast, setShowToast] = useState(false)

  const [selectState, setSelectState] = useState({ fields: [] })
  const [valuesFilter, setValuesFilter] = useState({})

  const handleFilter = values => {
    const { idClient, idProject, idPeriod } = values

    setValuesFilter(values)

    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idClient && { idClient }),
      ...(idProject && { idProject }),
      ...(idPeriod && { idPeriod }),
    })

    setPage(DEFAULT_PAGINATION_PAGE)
  }

  const changePage = page => {
    const { idClient, idProject, idPeriod } = valuesFilter

    setPage(page)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idClient && { idClient }),
      ...(idProject && { idProject }),
      ...(idPeriod && { idPeriod }),
    })
  }

  const changeSize = size => {
    const { idClient, idProject, idPeriod } = valuesFilter

    setSize(size)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idClient && { idClient }),
      ...(idProject && { idProject }),
      ...(idPeriod && { idPeriod }),
    })
  }

  const filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'idClient',
        label: 'Cliente',
        options: (listClients || []).map(({ id, descCliente }) => ({
          label: descCliente,
          value: id,
        })),
      },
      {
        type: 'select',
        name: 'idProject',
        label: 'Projeto',
        options: (listProjects || [])
          .filter(project => project.idClient === selectState.fields.idClient)
          .map(({ id, desProjeto }) => ({
            label: desProjeto,
            value: id,
          })),
        disabled: !selectState.fields.idClient,
      },
      {
        type: 'select',
        name: 'idPeriod',
        label: 'Período',
        options: (listPeriods || []).map(({ id, desPeriodo }) => ({
          label: desPeriodo,
          value: id,
        })),
      },
    ],
    monitoreState: state => setSelectState(state),
  }

  const convertRecord = record => {
    return {
      ...record,
      qtdHoras: record.qtdHoras,
      vlrHora: record.vlrHora,
      vlrTotal: record.vlrTotal,
    }
  }

  const query = () => {
    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      sort: SORT,
    })
    getClients()
    getProjects()
    getPeriods()
    get()
  }

  const handleSubmit = async values => {
    await onSubmit(values)

    setShowDialog(false)

    setShowToast(true)

    handleFilter(valuesFilter)

    query()
  }

  const startSave = () => {
    setParam(convertRecord(DEFAULT_PARAM))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo registro Cliente por Periodo')

    setShowDialog(true)
  }

  const startDelete = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(true)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)
    setTitle(
      `Você deseja deletar à(s) ${record.qtdHoras} hora(s) do período ${record.desPeriodo} para o cliente ${record.descClient}?`
    )

    setShowDialog(true)
  }

  const startEdit = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)
    setTitle('Alterar registro')

    setShowDialog(true)
  }

  useEffect(() => {
    query()
  }, [])

  return (
    <Main title="Manutenção Cliente por Período">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={startSave} color="primary">
            <AddIcon />
          </Fab>
        </Box>

        <Box component="div" className={styles.filter} xs={6}>
          <Filter {...filterProps} />
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            titleTable="clientPeriod"
            count={totalElementsClientPeriod}
            headers={TABLE_HEADER}
            onChangePage={(_, page) => changePage(page)}
            onChangeSize={event => changeSize(event.target.value)}
            page={page}
            rows={listClientPeriodFilter}
            size={size}
            startDelete={startDelete}
            startEdit={startEdit}
            alignTitle={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
            alignItens={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
            order={presentOrder}
          />
        </TableContainer>
      </Grid>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
      >
        <Form
          isDeleteAction={isDeleteAction}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          primaryButtonColor={primaryButtonColor}
          primaryButtonLabel={primaryButtonLabel}
          periods={(listPeriods || []).map(({ id, desPeriodo }) => ({
            value: desPeriodo,
            key: id,
          }))}
          listProjects={listProjects}
          projects={(listProjects || []).map(({ id, desProjeto }) => ({
            value: desProjeto,
            key: id,
          }))}
        />
      </Dialog>

      <Toast
        error={errorClientPeriod}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successClientPeriod}
      />
    </Main>
  )
}

const mapStateToProps = ({
  clientPeriodReducer,
  periodReducer,
  projectReducer,
  clientReducer,
}) => ({
  ...clientPeriodReducer,
  ...periodReducer,
  ...clientReducer,
  ...projectReducer,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionClientPeriod,
      getClients,
      getProjects,
      getPeriods,
    },
    dispatch
  )

ClientPeriod.propTypes = {
  /** Actions */
  getClients: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  getPeriods: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  /** Reducer props */
  listClients: PropTypes.array.isRequired,
  listProjects: PropTypes.array.isRequired,
  listPeriods: PropTypes.array.isRequired,
  listClientPeriodFilter: PropTypes.array.isRequired,
  listClientPeriod: PropTypes.array.isRequired,
  errorClientPeriod: PropTypes.string,
  successClientPeriod: PropTypes.string,
  totalElementsClientPeriod: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPeriod)
