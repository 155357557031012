import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { get as fetchAllClients } from '../../actions/client'
import { fetchAllProposals } from '../../actions/proposal'
import { fetchAllProposalFunctions } from '../../actions/proposalFunction'
import * as proposalUserActions from '../../actions/proposalUser'
import { get as getUsers } from '../../actions/user'

import { globalStyles } from '../../styles/global'
import Main from '../Main'
import ButtonsCancelAndSubmit from '../../utils/ButtonsCancelAndSubmit'

import AddIcon from '@material-ui/icons/Add'
import { TableContainer, Paper, Box, Fab, Grid, Button } from '@material-ui/core'
import Dialog from '../../utils/Dialog'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/Filter'

import Form from './components/Form'
import Table from './components/Table'
import { Loader } from '../../utils/Loading'
import { types } from '../../../config/action_types'
import useFeedback from '../../utils/hooks/useFeedback'
import { truncateString } from '../../utils/String'
import { getObjectFromParams } from '../../utils/Params'

const ProposalUser = props => {
  const {
    fetchAllClients,
    fetchAllProposals,
    fetchAllProposalFunctions,
    fetchProposalUsers,
    removeProposalUser,
    setProposalUserFilterParams,
    listProposal = [],
    listClients = [],
    listProposalFunction = [],
    listProposalUser = [],
    getUsers,
    isLoad
  } = props
  const styles = globalStyles()
  const {
    feedback,
    hasFeedback,
    setError,
    setSuccess,
    clearFeedback,
  } = useFeedback()
  const location = useLocation()
  const history = useHistory()

  const [showDialog, setShowDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [proposalUserToDelete, setProposalUserToDelete] = useState(null)
  const [proposalUserToEdit, setProposalUserToEdit] = useState(null)
  const [selectedClientId, setSelectedClientId] = useState(null)

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  const filterInitialValues = useMemo(() => {
    return getObjectFromParams(location.search)
  }, [location.search])

  const proposalUsers = useMemo(() => {
    return listProposalUser.map(proposalUser => {
      const {
        id,
        desCliente,
        codProposta,
        desProposta,
        desFuncao,
        desUsuario,
        perAlocacao,
      } = proposalUser

      const proposalFunction = listProposalFunction.find(
        p => p.id === proposalUser.funcao
      ) || {}

      return {
        id,
        desCliente,
        desProposta: `${codProposta} - ${desProposta}`,
        desFuncao,
        desSenioridade: proposalFunction.desSenioridade,
        desUsuario,
        perAlocacao: `${perAlocacao}%`,
      }
    })
  }, [listProposalUser, listProposalFunction])

  const handleFilterSubmit = async filterParams => {
    const isProposalFromRightClient = listProposal
      .filter(p => p.seqCliente === filterParams.cliente)
      .some(p => p.id === filterParams.proposta) || !listProposal.length;

    const resolvedParams = {
      ...filterParams,
      proposta: isProposalFromRightClient ? filterParams.proposta : null,
    }
    await setProposalUserFilterParams(resolvedParams)
  }

  const resolveProposalOptions = () => {
    if (!selectedClientId) return []

    return listProposal.filter(p => p.seqCliente === selectedClientId)
  }

  const resolveProposalNoOptionsText = () => {
    const clientHasProposal = !!resolveProposalOptions().length
    if (selectedClientId && !clientHasProposal) {
      return `O cliente não possui propostas`
    }

    if (!selectedClientId) return 'Informe um cliente'

    return 'Sem opções'
  }

  const filterProps = useMemo(
    () => ({
      monitoreState: ({ fields }) => {
        setSelectedClientId(fields.cliente)
      },
      onSubmit: handleFilterSubmit,
      fields: [
        {
          type: 'select',
          name: 'cliente',
          label: 'Cliente',
          initial: filterInitialValues.cliente,
          options: listClients
          .filter((item) => item.descCliente !== null)
          .map(({ id: value, descCliente: label }) => ({
            label: truncateString({ value: label }),
            value,
          })),
        },
        {
          type: 'select',
          name: 'proposta',
          label: 'Proposta',
          initial: filterInitialValues.proposta,
          options: resolveProposalOptions()
          .filter((item) => item.codProposta !== null)
          .map(
            ({ id: value, codProposta: label }) => ({
              label: truncateString({ value: label }),
              value,
            })
          ),
          noOptionsText: resolveProposalNoOptionsText(),
        },
        {
          type: 'select',
          name: 'funcao',
          label: 'Função',
          initial: filterInitialValues.funcao,
          options: listProposalFunction
          .filter((item) => item.desFuncao !== null)
          .map(
            ({ id: value, desFuncao: label }) => ({
              label: truncateString({ value: label }),
              value,
            })
          ),
        },
      ],
    }),
    [listClients, listProposal, listProposalFunction, selectedClientId]
  )

  const handleCloseForm = successMessage => {
    setShowDialog(false)
    setProposalUserToEdit(null)

    if (successMessage) {
      clearFeedback()
      setSuccess(successMessage)
    }
  }

  const resolveFormTitle = () => {
    return proposalUserToEdit ? 'Alterar usuário' : 'Novo usuário'
  }

  const renderForm = () => {
    return (
      <Dialog
        onClose={() => handleCloseForm()}
        open={showDialog || !!proposalUserToEdit}
        title={resolveFormTitle()}
      >
        <Form
          proposalUserToEdit={proposalUserToEdit}
          handleCloseForm={handleCloseForm}
        />
      </Dialog>
    )
  }

  const handleDeleteProposalUser = async () => {
    setProposalUserToDelete(null)
    setIsLoading(true)
    const response = await removeProposalUser(proposalUserToDelete)
    const { type, payload } = response
    const hasError = type === types.proposal.delete.error

    if (hasError) {
      setError(payload)
      setIsLoading(false)
      return
    }

    await fetchProposalUsers()
    setIsLoading(false)
    setSuccess(payload)
  }

  const renderDeleteDialog = () => {
    if (!proposalUserToDelete) return null

    const { desUsuario } = proposalUserToDelete

    return (
      <Dialog
        title={`Você deseja deletar o usuário ${desUsuario}?`}
        open
        onClose={() => setProposalUserToDelete(null)}
        maxWidth="md"
      >
        <ButtonsCancelAndSubmit
          submitLabel="Deletar"
          submitColor="secondary"
          onCancel={() => setProposalUserToDelete(null)}
          onSubmit={handleDeleteProposalUser}
        />
      </Dialog>
    )
  }

  const renderTable = () => {
    return (
      <>
       {isLoading || isLoad ? (<Loader isLoading={isLoading || isLoad} />):
        <Table
          onEdit={p => setProposalUserToEdit(p.id)}
          onDelete={setProposalUserToDelete}
          proposalUsers={proposalUsers}
        />}
      </>
    )
  }

  const renderFeedback = () => {
    if (!hasFeedback) return null

    return (
      <Toast
        error={feedback.error}
        success={feedback.success}
        open
        onClose={clearFeedback}
      />
    )
  }

  useEffect(() => {
    setIsLoading(true)
    async function initialFetch() {
      await Promise.all([
        fetchAllClients(),
        fetchAllProposals(),
        fetchAllProposalFunctions(),
        handleFilterSubmit(filterInitialValues),
      ])
      .then(() => setIsLoading(false))
    }
    initialFetch()
  }, [fetchAllProposals, fetchAllProposalFunctions, fetchAllClients])

  return (
    <Main title="Usuário da proposta">
      <Grid item xs={12}>
        <Box
          component="div"
          className={styles.box}
          xs={12}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Button
            variant="outlined"
            onClick={() => history.push('/proposal-function')}
          >
            Voltar para Função da Proposta
          </Button>
          <Fab onClick={() => setShowDialog(true)} color="primary">
            <AddIcon />
          </Fab>
        </Box>
        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>
        <TableContainer component={Paper}>{renderTable()}</TableContainer>
      </Grid>
      {renderForm()}
      {renderDeleteDialog()}
      {renderFeedback()}
    </Main>
  )
}

const mapStateToProps = state => {
  const {
    clientReducer: { listClients },
    proposalReducer: { listProposal },
    proposalFunctionReducer: { listProposalFunction },
    proposalUserReducer,
    loadReducer
  } = state

  return {
    listClients,
    listProposal,
    listProposalFunction,
    ...proposalUserReducer,
    isLoad: loadReducer.isLoad
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...proposalUserActions,
      fetchAllClients,
      fetchAllProposals,
      fetchAllProposalFunctions,
      getUsers,
    },
    dispatch
  )

ProposalUser.propTypes = {
  /** Actions */
  getUsers: PropTypes.func.isRequired,
  fetchAllClients: PropTypes.func.isRequired,
  fetchAllProposals: PropTypes.func.isRequired,
  fetchAllProposalFunctions: PropTypes.func.isRequired,
  fetchProposalUsers: PropTypes.func.isRequired,
  removeProposalUser: PropTypes.func.isRequired,
  setProposalUserFilterParams: PropTypes.func.isRequired,
  /** Reducer props */
  listClients: PropTypes.array.isRequired,
  listProposal: PropTypes.array.isRequired,
  listProposalFunction: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalUser)
