import { types, trigger } from '../../config/action_types'
import Http from '../../config/Http'
import URL from '../../config/URL'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.userPeriod + '/filter',
      param
    )

    return dispatch(trigger(types.userPeriod.getFilter.success, response))
  } catch (e) {
    return dispatch(trigger(types.userPeriod.getFilter.error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.userPeriod, param)

    return dispatch(trigger(types.userPeriod.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.userPeriod.get.error))
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.userPeriod, param)

    return dispatch(trigger(types.userPeriod.delete.success))
  } catch (error) {
    return dispatch(trigger(types.userPeriod.delete.error, error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.userPeriod, param)

    return dispatch(trigger(types.userPeriod.save.success))
  } catch (e) {
    return dispatch(trigger(types.userPeriod.save.error))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.userPeriod, param)

    return dispatch(trigger(types.userPeriod.update.success))
  } catch (e) {
    return dispatch(trigger(types.userPeriod.update.error))
  }
}
