import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Grid from '@material-ui/core/Grid'

import { get as generateMonthlyClose } from '../../actions/monthlyClose'
import { get as getPeriods } from '../../actions/period'

import Main from '../Main'
import Loading from '../../utils/Loading'
import Form from './components/Form'
import Toast from '../../utils/Toast'
import api from '../../../server'
import { columnMapping, convertJsonToCsv } from './converter'

const DEFAULT_PARAM = {
  idPeriod: 0,
}

const MonthlyClose = ({
  /* Actions */
  getPeriods,
  /* Reducer properties */
  listPeriods,
}) => {
  const [showToast, setShowToast] = useState(false)
  const [showToastError, setShowToastError] = useState(false)

  const [showLoading, setShowLoading] = useState(false)
  const [selectNoOptionsText, setSelectNoOptionsText] = useState(
    'Carregando...'
  )


  const filterOpenPeriods = periods =>
    periods.filter(periods => periods.indFechado === false)

  const handleExportCSV = useCallback(async (seqPeriodo) => {

    try {
      const { data } = await api.get(`period/filter?id=${seqPeriodo}`)
      const namePeriod = data.resultList[0].desPeriodo
      const response = await api.get(`/hour-bank/payroll-closure?seqPeriodo=${seqPeriodo}`, {
        responseType: 'text',
      })

      const jsonData = response.data
      const csvData = convertJsonToCsv(jsonData, columnMapping)
      const blob = new Blob([csvData], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `Fechamento_${namePeriod}.csv`

      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Erro ao exportar o arquivo CSV:', error)
    }
  }, [])

  const fetchData = useCallback(async idPeriod => {
    try {

      setShowLoading(true)
      const response = await api.get(`/monthly-close?idPeriod=${idPeriod}`, {
        responseType: 'text', // Indicate that the response is a text event stream
      })
      const reader = response.data
      let currentData = ''
      let resultList = []

      reader.split('\n').forEach(line => {
        if (line.startsWith('data:')) {
          currentData = line.substring(5)
        } else if (line.trim() === '') {
          if (currentData) {
            const parsedData = JSON.parse(currentData)
            if (parsedData.resultList) {
              resultList = [...resultList, ...parsedData.resultList]
            }

            currentData = ''
          }
        }
      })


      await handleExportCSV(idPeriod)

      return resultList // Return the accumulated resultList
    } catch (error) {
      console.error(error)

      setShowToastError(true)
      return [] // Return an empty array or handle the error as needed
    }
  }, [])

  const handleSubmit = async values => {
    const { value } = values.idPeriod.target

    await fetchData(value)
    setShowLoading(false)
    setShowToast(true)

    await getPeriods()
  }

  useEffect(() => {
    getPeriods().finally(() => {
      setSelectNoOptionsText(
        'Sem registro de período, cadastre um novo ou abra um período para fazer o fechamento!'
      )
    })
  }, [])

  return (
    <Main title="Fechamento Mensal">
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <Grid item xs={12}>
            <Form
              param={DEFAULT_PARAM}
              periods={filterOpenPeriods(listPeriods)}
              primaryButtonLabel="Gerar"
              onSubmit={handleSubmit}
              selectNoOptionsText={selectNoOptionsText}
            />
          </Grid>

          <Toast
            onClose={() => setShowToast(false)}
            open={showToast}
            success="Processamento finalizado"
          />
          <Toast
            onClose={() => setShowToast(false)}
            open={showToastError}
            error="Problema no processamento"
          />
        </>
      )}
    </Main>
  )
}

const mapStateToProps = state => {
  const { monthlyCloseReducer, entryReducer, periodReducer } = state

  return {
    ...monthlyCloseReducer,
    ...entryReducer,
    ...periodReducer,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ generateMonthlyClose, getPeriods }, dispatch)

MonthlyClose.propTypes = {
  /* Actions */
  generateMonthlyClose: PropTypes.func.isRequired,
  getPeriods: PropTypes.func.isRequired,
  /* Reducer properties */
  listPeriods: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired,
  successPeriod: PropTypes.string.isRequired,
  successMonthlyClose: PropTypes.string.isRequired,
  errorMonthlyClose: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyClose)
