import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  period: {
    label: '',
    value: null
  },
  proposal: {
    label:'',
    value: null
  },
  feedback: {
    error: null,
    success: null,
    message: '',
  },
  listProposal: [],
  listPeriods: [],
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.closureClients.setPeriod:
      return {
        ...state,
        period: {
          label: action.payload.label,
          value: action.payload.value
        },
      }

    case types.closureClients.setProposal:
      return {
        ...state,
        proposal: {
          label: action.payload.label,
          value: action.payload.value
        }
      }

    case types.closureClients.clearFilters:
      return {
        ...state,
        period: {
          label: '',
          value: null
        },
        proposal: {
          label:'',
          value: null
        },
      }

    case types.closureClients.generateClosure.success:
      return {
        ...state,
        feedback: {
          success: true,
          error: false,
          message: 'Lançamentos gerados com sucesso!',
        },
      }

    case types.closureClients.generateClosure.error:
      return {
        ...state,
        feedback: {
          success: false,
          error: true,
          message: 'Erro ao gerar lançamento!',
        }
      }

    case types.closureClients.getProposal.success:
      return {
        ...state,
        listProposal: action.payload
      }

    case types.closureClients.getProposal.error:
      return {
        ...state,
        feedback: {
          error: true,
          success: false,
          message: 'Erro ao buscar as propostas!',
        }
      }

    case types.closureClients.getPeriod.success:
      const { resultList } = action.payload
      return {
        ...state,
        listPeriods: resultList || [],
      }

    case types.closureClients.getPeriod.error:
      return {
        ...state,
        feedback: {
          error: true,
          success: false,
          message: 'Erro ao buscar períodos',
        }
      }

    default:
      return {
        ...state
      }
  }
}
