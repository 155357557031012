import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actionPoint from '../../actions/point'

import { globalStyles } from '../../styles/global'
import Main from '../Main'
import TablePagination from '../../utils/Table/TablePagination'

import AddIcon from '@material-ui/icons/Add'
import { TableContainer, Paper, Box, Fab, Grid } from '@material-ui/core'
import Dialog from '../../utils/Dialog'
import Form from './components/Form'
import Filter from '../../utils/Filter/Filter'

import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'
import Toast from '../../utils/Toast'

const SORT = 'id'

const DEFAULT_PARAM = {
  numPontuacao: '',
  qtdHorasInicial: '',
  qtdHorasFinal: '',
}

const Point = ({
  /** Actions */
  getFilter,
  get,
  save,
  update,
  remove,
  /** Action feedback */
  errorPoint,
  pointsFilter,
  points,
  successPoint,
  totalElements,
}) => {
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showModal, setShowModal] = useState(false)
  const [isDeleteAction, setDeleteAction] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [showToast, setShowToast] = useState(false)

  const [dialogTitle, setDialogTitle] = useState(null)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)

  const [selectState, setSelectState] = useState({ fields: [] })

  const [valuesFilter, setValuesFilter] = useState({})

  const handleFilter = values => {
    const { numPontuacao, qtdHorasInicial, qtdHorasFinal } = values

    setValuesFilter(values)

    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: size,
      sort: SORT,
      ...(numPontuacao && { numPontuacao }),
      ...(qtdHorasInicial && { qtdHorasInicial }),
      ...(qtdHorasFinal && { qtdHorasFinal }),
    })

    setPage(DEFAULT_PAGINATION_PAGE)
  }

  let filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'numPontuacao',
        label: 'Pontuação',
        initial: valuesFilter.numPontuacao,
        options: (points || [])
          .filter(data => data.numPontuacao)
          .map(({ numPontuacao }) => ({
            label: String(numPontuacao),
            value: numPontuacao,
          })),
      },
      {
        type: 'select',
        name: 'qtdHorasInicial',
        label: 'Hora inicial',
        initial: valuesFilter.qtdHorasInicial,
        options: (points || [])
          .filter(
            point => point.numPontuacao === selectState.fields.numPontuacao
          )
          .map(({ qtdHorasInicial }) => ({
            label: String(qtdHorasInicial),
            value: qtdHorasInicial,
          })),
        disabled: !selectState.fields.numPontuacao,
      },
      {
        type: 'select',
        name: 'qtdHorasFinal',
        label: 'Hora final',
        initial: valuesFilter.qtdHorasFinal,
        options: (points || [])
          .filter(
            point =>
              point.qtdHorasInicial === selectState.fields.qtdHorasInicial
          )
          .map(({ qtdHorasFinal }) => ({
            label: String(qtdHorasFinal),
            value: qtdHorasFinal,
          })),
        disabled: !selectState.fields.qtdHorasInicial,
      },
    ],
    monitoreState: state => setSelectState(state),
  }

  const query = () => {
    get()
    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      sort: SORT,
    })
  }

  const handleSubmit = async values => {
    await onSubmit(values)

    setShowModal(false)

    handleFilter(valuesFilter)

    query()

    setShowToast(true)
  }

  const startSave = () => {
    setParam(DEFAULT_PARAM)
    setDeleteAction(false)
    setDialogTitle('Novo ponto')
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)

    setShowModal(true)
  }

  const startDelete = record => {
    setParam(record)
    setDeleteAction(true)
    setDialogTitle(`Você deseja deletar a pontuação ${record.numPontuacao}?`)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)

    setShowModal(true)
  }

  const startEdit = record => {
    setParam(record)
    setDeleteAction(false)
    setDialogTitle('Alterar ponto')
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)

    setShowModal(true)
  }

  useEffect(() => {
    query()
  }, [])

  const changePage = page => {
    const { numPontuacao, qtdHorasInicial, qtdHorasFinal } = valuesFilter

    setPage(page)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(numPontuacao && { numPontuacao }),
      ...(qtdHorasInicial && { qtdHorasInicial }),
      ...(qtdHorasFinal && { qtdHorasFinal }),
    })
  }

  const changeSize = size => {
    const { numPontuacao, qtdHorasInicial, qtdHorasFinal } = valuesFilter

    setSize(size)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(numPontuacao && { numPontuacao }),
      ...(qtdHorasInicial && { qtdHorasInicial }),
      ...(qtdHorasFinal && { qtdHorasFinal }),
    })
  }

  return (
    <Main title="Pontos">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={startSave} color="primary">
            <AddIcon />
          </Fab>
        </Box>

        <Box component="div" className={styles.filter} xs={6}>
          <Filter {...filterProps} />
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            count={totalElements}
            doNotShow={['id']}
            headers={['Pontuação', 'Hora inicial', 'Hora final']}
            onChangePage={(_, page) => changePage(page)}
            onChangeSize={event => changeSize(event.target.value)}
            page={page}
            rows={pointsFilter}
            size={size}
            startDelete={startDelete}
            startEdit={startEdit}
            alignTitle={['center', 'center', 'center']}
            alignItens={['center', 'center', 'center', 'center', 'center']}
          />
        </TableContainer>

        <Dialog
          onClose={() => setShowModal(false)}
          open={showModal}
          title={dialogTitle}
        >
          <Form
            onCancel={() => setShowModal(false)}
            onSubmit={handleSubmit}
            param={param}
            isDeleteAction={isDeleteAction}
            primaryButtonColor={primaryButtonColor}
            primaryButtonLabel={primaryButtonLabel}
          />
        </Dialog>

        <Toast
          autoHideDuration={2000}
          onClose={() => setShowToast(false)}
          open={showToast}
          success={successPoint}
          error={errorPoint}
        />
      </Grid>
    </Main>
  )
}

const mapStateToProps = ({ pointReducer }) => ({ ...pointReducer })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actionPoint }, dispatch)

Point.propTypes = {
  /** Actions */
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  /** Action feedback */
  errorPoint: PropTypes.object,
  records: PropTypes.array.isRequired,
  points: PropTypes.array.isRequired,
  pointsFilter: PropTypes.array.isRequired,
  successPoint: PropTypes.object,
  totalElements: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Point)
