import { types } from '../../config/action_types'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../constants/pagination'

const defaultState = {
  listProposalFunction: [],
  errorProposalFunction: null,
  successProposalFunction: null,
  filterParams: {
    pageFilter: DEFAULT_PAGINATION_PAGE,
    sizeFilter: DEFAULT_PAGINATION_SIZE,
  },
  totalElementsProposalFunction: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.proposalFunction.updateFilter:
      return {
        ...state,
        filterParams: {
          sizeFilter: state.filterParams.sizeFilter,
          ...action.payload,
        },
      }

    case types.proposalFunction.clearFilter:
      return {
        ...state,
        filterParams: {
          ...defaultState.filterParams,
        },
      }

    case types.proposalFunction.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        listProposalFunction: resultList || [],
        totalElementsProposalFunction: pages.totalCount || resultList.length,
      }

    case types.proposalFunction.getFilter.error:
      return {
        ...state,
        errorProposalFunction: action.payload,
        successProposalFunction: null,
      }

    case types.proposalFunction.get.success:
      return {
        ...state,
        listProposalFunction: action.payload.content || action.payload,
      }
    
    case types.proposalFunction.emptyList:
      return {
        ...state,
        listProposalFunction: [],
      }

    case types.proposalFunction.get.error:
      return {
        ...state,
        errorProposalFunction: action.payload,
        successProposalFunction: null,
      }

    case types.proposalFunction.save.success:
      return {
        ...state,
        successProposalFunction: action.payload,
        errorProposalFunction: null,
      }

    case types.proposalFunction.save.error:
      return {
        ...state,
        errorProposalFunction: action.payload,
        successProposalFunction: null,
      }

    case types.proposalFunction.delete.success:
      return {
        ...state,
        successProposalFunction: action.payload,
        errorProposalFunction: null,
      }

    case types.proposalFunction.delete.error:
      return {
        ...state,
        errorProposalFunction: action.payload,
        successProposalFunction: null,
      }

    case types.proposalFunction.update.success:
      return {
        ...state,
        successProposalFunction: action.payload,
        errorProposalFunction: null,
      }

    case types.proposalFunction.update.error:
      return {
        ...state,
        errorProposalFunction: action.payload,
        successProposalFunction: null,
      }

    default:
      return state
  }
}
