import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AddIcon from '@material-ui/icons/Add'
import { Box, Fab, Grid, Paper, TableContainer } from '@material-ui/core'
import * as officeActions from '../../actions/office'
import * as userActions from '../../actions/user'
import { get as getUsers } from '../../actions/user'
import Form from './components/Form'
import Main from '../Main'
import { globalStyles } from '../../styles/global'
import Dialog from '../../utils/Dialog'
import TablePagination from '../../utils/Table/TablePagination'
import Filter from '../../utils/Filter/Filter'
import Toast from '../../utils/Toast'
import { convertCurrency } from '../../utils/Currency'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'desUsuario'

const DEFAULT_PARAM = {
  id: null,
  idOffice: '',
  desUsuario: '',
  vlrSalario: '',
  tipContratacao: '',
  desEmail: '',
  desSenha: '',
  numCoeficienteEntrega: '',
}

const doNotShowTable = [
  'desSenha',
  'id',
  'idOffice',
  'indInativo',
  'office',
  'dsToken',
  'indUserWithNoPasswordChangeInTheLastSixMonths',
  'indLastWeekToChangeUserPassword',
  'resetPassword',
  'msgWarning',
]

const headersTable = [
  'Nome',
  'E-mail',
  'Salário',
  'Contrato',
  'Coeficiente de Entrega',
]

const listTipContratacao = [
  { key: 1, value: 'CLT' },
  { key: 2, value: 'ESTAGIO' },
  { key: 3, value: 'TERCEIRO' },
  { key: 4, value: 'SOCIO' },
  { key: 5, value: 'COOPERADO' },
]

const User = ({
  /** Actions */
  getOffices,
  getFilter,
  getUsers,
  remove,
  save,
  update,
  /** Reducer props */
  listOffices,
  listUsersFilter,
  errorUsers,
  successUsers,
  totalElementsUsers,
  listUsers,
}) => {
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [isUpdateAction, setIsUpdateAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState('Novo usuário')
  const [forceUpdate, setForceUpdate] = useState(false)

  const [showToast, setShowToast] = useState(false)

  const [valuesFilter, setValuesFilter] = useState({indInativo:true})

  const handleFilter = values => {
    const { id, tipContratacao, indInativo } = values

    setValuesFilter(values)

    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(id && { id }),
      ...(tipContratacao && { tipContratacao }),
      ...(typeof indInativo === 'boolean' && { indInativo }),
    })
  }

  let filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'id',
        label: 'Nome',
        initial: valuesFilter.id,
        options: listUsers.map(user => ({
          value: user.id,
          label: user.desUsuario,
        })),
      },
      {
        type: 'select',
        name: 'tipContratacao',
        label: 'Contrato',
        initial: valuesFilter.tipContratacao,
        options: listTipContratacao.map(contrato => ({
          value: contrato.key,
          label: contrato.value,
        })),
      },
      {
        type: 'select',
        name: 'indInativo',
        label: 'Status',
        initial: valuesFilter.indInativo,
        options: [
          { value: true, label: 'ATIVO' },
          { value: false, label: 'INATIVO' },
        ],
      },
    ],
  }

  const query = async (lastSubmit = false) => {
    await getOffices()

    const user = JSON.parse(localStorage.getItem('user'))

    if (lastSubmit && lastSubmit.id && lastSubmit.id === user.id) {
      const changed = listUsers.find(item => item.id === user.id)
      if (changed) {
        user.desUsuario = lastSubmit.desUsuario
        localStorage.setItem('user', JSON.stringify(user))
        setForceUpdate(!forceUpdate)
      }
    }
  }

  const resolveContract = (tipC) => {
    const obj = listTipContratacao.find((item) => item.value === tipC)
    if (obj) return obj.key
  }

  const convertRecord = record => {
    return {
      ...record,
      desSenha: '',
      idOffice: record.idOffice,
      indInativo: record.indInativo,
      numCoeficienteEntrega: convertCurrency(record.numCoeficienteEntrega),
      vlrSalario: convertCurrency(record.vlrSalario),
      desUsuario: record.desUsuario,
      tipContratacao: resolveContract(record.tipContratacao),
      desEmail: record.desEmail,
    }
  }

  const handleSubmit = async values => {
    const obj = {
      id: values.id,
      idOffice: values.idOffice,
      desEmail: values.desEmail,
      desSenha: values.desSenha,
      desUsuario: values.desUsuario,
      indInativo: values.indInativo,
      numCoeficienteEntrega: values.numCoeficienteEntrega,
      tipContratacao: values.tipContratacao,
      vlrSalario: values.vlrSalario,
    }

    if (!obj.idOffice) {
      obj.idOffice = null
    }

    await onSubmit(obj).then(() => getUsers());

    setShowDialog(false)

    setShowToast(true)

    handleFilter(valuesFilter)

    query(obj)
  }

  const startSave = () => {
    setParam(DEFAULT_PARAM)

    setIsDeleteAction(false)
    setIsUpdateAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo usuário')

    setShowDialog(true)
  }

  const startDelete = record => {
    setParam(record)

    setIsDeleteAction(true)
    setIsUpdateAction(false)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)
    setTitle(`Você deseja deletar a(o) usuária(o) ${record.desUsuario}?`)

    setShowDialog(true)
  }

  const startEdit = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(false)
    setIsUpdateAction(true)
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)
    setTitle('Alterar usuário')

    setShowDialog(true)
  }

  useEffect(() => {
    if (page === DEFAULT_PAGINATION_PAGE) {
      handleFilter(valuesFilter)
    } else {
      setPage(DEFAULT_PAGINATION_PAGE)
    }
  }, [valuesFilter])

  useEffect(() => {
    Object.keys(valuesFilter).length !== 0
      ? handleFilter(valuesFilter)
      : handleFilter({ indInativo: true })
  }, [page, size])

  useEffect(() => {
    query()
  }, [])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  return (
    <Main title="Usuários">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={startSave} color="primary">
            <AddIcon />
          </Fab>
        </Box>

        <Box component="div" className={styles.filter} xs={6}>
          <Filter {...filterProps} />
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            count={totalElementsUsers}
            doNotShow={doNotShowTable}
            headers={headersTable}
            onChangePage={(event, page) => setPage(page)}
            onChangeSize={event => setSize(event.target.value)}
            page={page}
            rows={listUsersFilter}
            size={size}
            startDelete={startDelete}
            startEdit={startEdit}
            alignTitle={['center', 'center', 'center', 'center', 'center']}
            alignItens={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
          />
        </TableContainer>
      </Grid>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
      >
        <Form
          isDeleteAction={isDeleteAction}
          isUpdateAction={isUpdateAction}
          listOffices={listOffices}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          listTipContratacao={listTipContratacao}
          primaryButtonColor={primaryButtonColor}
          primaryButtonLabel={primaryButtonLabel}
        />
      </Dialog>

      <Toast
        error={errorUsers}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successUsers}
      />
    </Main>
  )
}

const mapStateToProps = state => {
  const {
    officeReducer,
    userReducer,
  } = state

  return {
    ...officeReducer,
    ...userReducer,
    listUsers: userReducer.listUsers,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...userActions,
      getOffices: officeActions.get,
      getUsers,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(User)

User.propTypes = {
  /** Actions */
  getOffices: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  /** Reducer props */
  listOffices: PropTypes.array.isRequired,
  listUsersFilter: PropTypes.array.isRequired,
  listUsers: PropTypes.array.isRequired,
  errorUsers: PropTypes.string.isRequired,
  successUsers: PropTypes.string.isRequired,
  totalElementsUsers: PropTypes.number.isRequired,
}
