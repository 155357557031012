import { makeStyles } from '@material-ui/core/styles'
// import Background from '../../../assets/image/background_login.png'

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: 460,
  },
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // backgroundImage: `url(${Background})`
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    width: '330px',
    marginTop: '50px',
  },
  forgotPasswordLinkContainer: {
    textAlign: 'right',
    margin: '0 auto',
  },
  forgotPasswordLink: {
    color: theme.palette.primary.main,
    ...theme.typography.body2,
    '&:active': {
      color: theme.palette.primary.dark,
    }
  },
}))

export default useStyles
