import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

import Button from '../../../utils/Form/Button'
import Checkbox from '../../../utils/Form/Checkbox'
import Datepicker from '../../../utils/Form/Datepicker'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import TextField from '@material-ui/core/TextField'

import * as yup from 'yup'
import { useFormik } from 'formik'
import createSyntheticEvent from '../../../utils/createSyntheticEvent'
import moment from 'moment'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale={true}
      suffix={' hr(s)'}
      isNumericString={true}
      maxLength={24}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const validationSchema = yup.object({
  desPeriodo: yup
    .string()
    .max(20)
    .required('Por favor, informe a descrição!'),
  dtaInicio: yup
    .date()
    .typeError('Data inválida!')
    .nullable()
    .required('Por favor, informe uma data válida!'),
  dtaFim: yup
    .date()
    .typeError('Data inválida!')
    .nullable()
    .required('Por favor, informe uma data válida!'),
  qtdDiasUteis: yup
    .number()
    .required('Por favor, informe a quantidade de dias úteis!'),
  qtdHorasPrevClt: yup
    .string()
    .required('Por favor, informe a quantidade de horas previstas (CLT)!'),
  qtdHorasPrevEstagio: yup.string(),
  dtaFeriado1: yup.date().nullable(),
  dtaFeriado2: yup.date().nullable(),
  dtaFeriado3: yup.date().nullable(),
  dtaFeriado4: yup.date().nullable(),
})

/**
 * Period form
 */
const Form = ({
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
  onOpenDateModal,
  onCloseDateModal,
}) => {
  const formik = useFormik({
    initialValues: {
      desPeriodo: param.desPeriodo,
      dtaFeriado1: param.dtaFeriado1,
      dtaFeriado2: param.dtaFeriado2,
      dtaFeriado3: param.dtaFeriado3,
      dtaFeriado4: param.dtaFeriado4,
      dtaFim: param.dtaFim,
      dtaInicio: param.dtaInicio,
      id: param.id,
      indFechado: param.indFechado,
      liberado: param.liberado,
      qtdDiasUteis: param.qtdDiasUteis,
      qtdHorasPrevClt: param.qtdHorasPrevClt,
      qtdHorasPrevEstagio: param.qtdHorasPrevEstagio,
    },
    validationSchema: validationSchema,
    validate: values => {
      const errors = {}

      if (values.dtaInicio && values.dtaFim) {
        const dtaInicio = moment(values.dtaInicio)
        const dtaFim = moment(values.dtaFim)

        if (!dtaFim.isAfter(dtaInicio) && !dtaFim.isSame(dtaInicio)) {
          errors.dtaInicio =
            'Data inicial deve ser menor ou igual à data final!'
          errors.dtaFim = 'Data final deve ser maior ou igual à data inicial!'
        }
      }

      return errors
    },
    onSubmit: values => {
      onSubmit(values)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                autoFocus
                id="desPeriodo"
                name="desPeriodo"
                label="Descrição"
                value={formik.values.desPeriodo}
                onChange={formik.handleChange}
                error={
                  formik.touched.desPeriodo && Boolean(formik.errors.desPeriodo)
                }
                helperText={
                  formik.touched.desPeriodo && formik.errors.desPeriodo
                }
                inputProps={{
                  maxLength: 20,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <Datepicker
                autoOK
                id="dtaInicio"
                name="dtaInicio"
                label="Data inicial"
                value={formik.values.dtaInicio}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent('dtaInicio', moment(val).toISOString())
                  )
                }
                error={
                  formik.touched.dtaInicio && Boolean(formik.errors.dtaInicio)
                }
                helperText={formik.touched.dtaInicio && formik.errors.dtaInicio}
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <Datepicker
                autoOK
                id="dtaFim"
                name="dtaFim"
                label="Data final"
                value={formik.values.dtaFim}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent('dtaFim', moment(val).toISOString())
                  )
                }
                error={formik.touched.dtaFim && Boolean(formik.errors.dtaFim)}
                helperText={formik.touched.dtaFim && formik.errors.dtaFim}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <Checkbox
                id="indFechado"
                label="Fechado"
                name="indFechado"
                checked={formik.values.indFechado}
                onChange={e =>
                  formik.handleChange({
                    target: {
                      name: 'indFechado',
                      value: e,
                    },
                  })
                }
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <Checkbox
                id="liberado"
                label="Liberado"
                name="liberado"
                checked={formik.values.liberado}
                onChange={e =>
                  formik.handleChange({
                    target: {
                      name: 'liberado',
                      value: e,
                    },
                  })
                }
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <TextField
                fullWidth
                id="qtdDiasUteis"
                name="qtdDiasUteis"
                label="Qtd. dias úteis"
                value={formik.values.qtdDiasUteis}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdDiasUteis &&
                  Boolean(formik.errors.qtdDiasUteis)
                }
                helperText={
                  formik.touched.qtdDiasUteis && formik.errors.qtdDiasUteis
                }
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <TextField
                fullWidth
                id="qtdHorasPrevClt"
                name="qtdHorasPrevClt"
                label="Qtd. horas prev. CLT"
                value={formik.values.qtdHorasPrevClt}
                onChange={formik.handleChange}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                error={
                  formik.touched.qtdHorasPrevClt &&
                  Boolean(formik.errors.qtdHorasPrevClt)
                }
                helperText={
                  formik.touched.qtdHorasPrevClt &&
                  formik.errors.qtdHorasPrevClt
                }
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                id="qtdHorasPrevEstagio"
                name="qtdHorasPrevEstagio"
                label="Qtd. horas prev. estágio"
                value={formik.values.qtdHorasPrevEstagio}
                onChange={formik.handleChange}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <Datepicker
                autoOK
                id="dtaFeriado1"
                name="dtaFeriado1"
                label="Feriado 1"
                value={formik.values.dtaFeriado1}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={e => {
                  formik.handleChange({
                    target: {
                      name: 'dtaFeriado1',
                      value: e,
                    },
                  })
                }}
                error={
                  formik.touched.dtaFeriado1 &&
                  Boolean(formik.errors.dtaFeriado1)
                }
                helperText={
                  formik.touched.dtaFeriado1 && formik.errors.dtaFeriado1
                }
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <Datepicker
                autoOK
                id="dtaFeriado2"
                name="dtaFeriado2"
                label="Feriado 2"
                value={formik.values.dtaFeriado2}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={e => {
                  formik.handleChange({
                    target: {
                      name: 'dtaFeriado2',
                      value: e,
                    },
                  })
                }}
                error={
                  formik.touched.dtaFeriado2 &&
                  Boolean(formik.errors.dtaFeriado2)
                }
                helperText={
                  formik.touched.dtaFeriado2 && formik.errors.dtaFeriado2
                }
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <Datepicker
                autoOK
                id="dtaFeriado3"
                name="dtaFeriado3"
                label="Feriado 3"
                value={formik.values.dtaFeriado3}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent(
                      'dtaFeriado3',
                      moment(val).toISOString()
                    )
                  )
                }
                error={
                  formik.touched.dtaFeriado3 &&
                  Boolean(formik.errors.dtaFeriado3)
                }
                helperText={
                  formik.touched.dtaFeriado3 && formik.errors.dtaFeriado3
                }
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <Datepicker
                autoOK
                id="dtaFeriado4"
                name="dtaFeriado4"
                label="Feriado 4"
                value={formik.values.dtaFeriado4}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={e => {
                  formik.handleChange({
                    target: {
                      name: 'dtaFeriado4',
                      value: e,
                    },
                  })
                }}
                error={
                  formik.touched.dtaFeriado4 &&
                  Boolean(formik.errors.dtaFeriado4)
                }
                helperText={
                  formik.touched.dtaFeriado4 && formik.errors.dtaFeriado4
                }
              />
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <Button
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  isDeleteAction: PropTypes.bool,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  param: PropTypes.object,
  onOpenDateModal: PropTypes.func.isRequired,
  onCloseDateModal: PropTypes.func.isRequired,
}

export default Form
