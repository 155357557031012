import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  listHourBank: [],
  listHourBankFilter: [],

  errorHourBank: null,
  successHourBank: null,

  totalElementsHourBank: 0,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.hourBank.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        listHourBankFilter: resultList || [],
        totalElementsHourBank: pages.totalCount || resultList.length,
      }
    case types.hourBank.getFilter.error:
      return {
        ...state,
        errorHourBank: 'Erro ao filtrar banco de horas',
      }

    case types.hourBank.get.success:
      return {
        ...state,
        listHourBank: action.payload.content || action.payload,
      }

    case types.hourBank.get.error:
      return {
        ...state,
        errorHourBank: 'Erro ao buscar banco de horas',
      }

    case types.hourBank.save.success:
      return {
        ...state,
        successHourBank: 'Banco de Horas salvo com sucesso',
      }

    case types.hourBank.save.error:
      return {
        ...state,
        errorHourBank: 'Erro ao salvar Banco de Horas',
      }

    case types.hourBank.delete.success:
      return {
        ...state,
        successHourBank: 'Banco de Horas deletado com sucesso',
      }

    case types.hourBank.delete.error:
      return {
        ...state,
        errorHourBank: 'Erro ao deletar Banco de Horas',
      }

    case types.hourBank.update.success:
      return {
        ...state,
        successHourBank: 'Banco de Horas atualizado com sucesso',
      }

    case types.hourBank.update.error:
      return {
        ...state,
        errorHourBank: 'Erro ao atualizar Banco de Horas',
      }

    default:
      return state
  }
}
