import React, { useState, useEffect } from 'react'
import Main from '../Main'
import { Button, TextField, InputAdornment, Grid } from '@material-ui/core'
import { RemoveRedEye } from '@material-ui/icons'
import {
  password,
  dismissError,
  mandatoryFieldsNotCompletedError,
} from '../../actions/login'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loading from '../../utils/Loading'
import { bindActionCreators } from 'redux'
import Toast from '../../utils/Toast'
import { notValidatePassword } from '../../../constants/regex'

const Password = ({
  errorPassword,
  successPassword,
  error = null,
  isLoad = false,
  dismissError = null,
  mandatoryFieldsNotCompletedError = null,
  password = () => {},
}) => {
  const { desUsuario, desEmail, id } = JSON.parse(localStorage.getItem('user'))

  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const [errors, setErrors] = useState({})

  const [values, setValues] = useState({})

  const closeToast = () => {
    dismissError()
    setShowToast(false)
  }

  /**
   * Show toast if error have returned
   */
  useEffect(() => {
    if (!showToast && errorPassword) setShowToast(true)
  }, [errorPassword])

  useEffect(() => {
    if (!showToast && successPassword) setShowToast(true)
  }, [successPassword])

  const changePassword = () => (
    <>
      {!isLoad && (
        <Main title="Alterar Senha">
          <h1 style={{ marginBottom: '0px' }}>Alteração de senha</h1>
          <span>
            Sua senha deve conter no mínimo <b>8</b> caracteres.
          </span>
          <br />
          <span
            className="userEmail"
            style={{ display: 'block', paddingTop: '25px' }}
          >
            {desUsuario} - {desEmail}
          </span>
          <TextField
            error={errors.oldPassword}
            helperText={errors.oldPassword}
            style={{ marginTop: '25px', width: '20%' }}
            variant="standard"
            type={!showOldPassword ? 'password' : 'text'}
            label="Digite a senha atual"
            value={values.oldPassword}
            onChange={e => {
              setValues({
                ...values,
                oldPassword: e.target.value,
              })
              setErrors({
                ...errors,
                oldPassword: !e.target.value.length
                  ? 'Digite sua senha atual'
                  : false,
              })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ color: '#3f51b5', cursor: 'pointer' }}
                >
                  <RemoveRedEye
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  />
                </InputAdornment>
              ),
            }}
          />
          <br />
          <TextField
            error={errors.newPassword}
            helperText={errors.newPassword}
            id="newPassword"
            style={{ marginTop: '25px', width: '20%' }}
            variant="standard"
            type={!showNewPassword ? 'password' : 'text'}
            className="input-newPassword"
            label="Digite a nova senha"
            value={values.newPassword}
            onChange={e => {
              setValues({
                ...values,
                newPassword: e.target.value,
              })
              setErrors({
                ...errors,
                newPassword: notValidatePassword(e.target.value),
              })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ color: '#3f51b5', cursor: 'pointer' }}
                >
                  <RemoveRedEye
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  />
                </InputAdornment>
              ),
            }}
          />
          <br />
          <TextField
            error={errors.confirmPassword}
            helperText={errors.confirmPassword}
            id="confirmPassword"
            style={{ marginTop: '25px', width: '20%' }}
            variant="standard"
            type={!showConfirmPassword ? 'password' : 'text'}
            className="input-newPassword"
            label="Repita a senha"
            value={values.confirmPassword}
            onChange={e => {
              setValues({
                ...values,
                confirmPassword: e.target.value,
              })
              setErrors({
                ...errors,
                confirmPassword:
                  e.target.value === values.newPassword
                    ? false
                    : 'As senhas devem ser semelhantes',
              })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ color: '#3f51b5', cursor: 'pointer' }}
                >
                  <RemoveRedEye
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Grid container spacing={2} style={{ marginTop: '35px' }}>
            <Grid>
              <Button
                variant="contained"
                style={{
                  background: '#DCDCDC',
                  width: '200px',
                }}
                className="passwordCancel"
                href="/home"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid style={{ paddingLeft: '15px' }}>
              <Button
                variant="contained"
                style={{
                  background: '#3f51b5',
                  color: '#f2f2f2',
                  width: '200px',
                }}
                className="passwordSave"
                type="submit"
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
          <Toast
            error={errorPassword}
            onClose={closeToast}
            open={showToast}
            success={successPassword}
          />
        </Main>
      )}
      {isLoad && Loading()}
    </>
  )

  const validarCamposObrigatorios = () => {
    let camposSemPreenchimento = ''
    let qtdCampos = 0
    if (
      values.oldPassword === null ||
      values.oldPassword === undefined ||
      values.oldPassword === ''
    ) {
      camposSemPreenchimento = 'senha atual'
      qtdCampos++
    }
    if (
      values.newPassword === null ||
      values.newPassword === undefined ||
      values.newPassword === ''
    ) {
      camposSemPreenchimento +=
        (camposSemPreenchimento !== '' ? ', ' : '') + 'nova senha'
      qtdCampos++
    }
    if (
      values.confirmPassword === null ||
      values.confirmPassword === undefined ||
      values.confirmPassword === ''
    ) {
      camposSemPreenchimento +=
        (camposSemPreenchimento !== '' ? ' e ' : '') + 'confirmação de senha'
      qtdCampos++
    }

    if (camposSemPreenchimento !== '') {
      if (qtdCampos === 1) {
        mandatoryFieldsNotCompletedError(
          'O campo ' + camposSemPreenchimento + ' deve ser informado.'
        )
      } else {
        mandatoryFieldsNotCompletedError(
          'Os campos ' + camposSemPreenchimento + ' devem ser informados.'
        )
      }
      return false
    }
    return true
  }

  const handleSubmit = () => {
    if (validarCamposObrigatorios()) {
      if (values.confirmPassword === values.newPassword) {
        const params = {
          id,
          desSenha: values.oldPassword,
          newSenha: values.newPassword,
          confirmSenha: values.confirmPassword,
        }
        const notValidPassword = notValidatePassword(values.confirmPassword)
        if (!notValidPassword) {
          password(params)
          setValues({})
        }
      }
    }
  }
  return changePassword()
}

const mapStateToProps = ({ loadReducer, loginReducer }) => ({
  ...loadReducer,
  ...loginReducer,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { password, dismissError, mandatoryFieldsNotCompletedError },
    dispatch
  )

Password.propTypes = {
  error: PropTypes.string,
  password: PropTypes.func,
  isLoad: PropTypes.bool,
  dismissError: PropTypes.func,
  mandatoryFieldsNotCompletedError: PropTypes.func,
  successPassword: PropTypes.string,
  errorPassword: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(Password)
