import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import {
  menus,
  menu_operation,
  menu_coordinator,
  menu_administrator,
  sair
} from './menus'
import Loading from '../../utils/Loading'
import { Link, Tooltip } from '@material-ui/core'

const drawerWidth = 360

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  root: {
    display: 'flex',
  },
  subMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export default function Main(props) {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [openSubMenuCadastros, setOpenSubMenuCadastros] = React.useState(false);
  const [openSubMenuAtividades, setOpenSubMenuAtividades] = React.useState(false);
  const [openSubMenuLancamentos, setOpenSubMenuLancamentos] = React.useState(false);
  const [openSubMenuFechamentoUser, setOpenSubMenuFechamentoUser] = React.useState(false);
  const [openSubMenuFechamentoClient, setOpenSubMenuFechamentoClient] = React.useState(false);

  const { desUsuario, office } = JSON.parse(localStorage.getItem('user'))

  const authorizedMenu = () => {
    return office.tipCargo === 'OPERAÇÃO'
      ? menu_operation
      : office.tipCargo === 'COORDENADOR'
      ? menu_coordinator
      : office.tipCargo === 'ADMINISTRADOR'
      ? menu_administrator
      : null
  }

  if (props.isLoad) {
    return <Loading />
  }

  const handleMenuClose = () => {
    setOpen(false)
    if(!!openSubMenuCadastros) {
      setOpenSubMenuCadastros(!openSubMenuCadastros);
    }
    if(!!openSubMenuAtividades) {
      setOpenSubMenuAtividades(!openSubMenuAtividades)
    }
    if(!!openSubMenuLancamentos) {
      setOpenSubMenuLancamentos(!openSubMenuLancamentos)
    }
    if(!!openSubMenuFechamentoUser) {
      setOpenSubMenuFechamentoUser(!openSubMenuFechamentoUser)
    }
    if(!!openSubMenuFechamentoClient) {
      setOpenSubMenuFechamentoClient(!openSubMenuFechamentoClient)
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const showOpenSubMenu = (text) => {
    switch (text) {
      case 'Cadastros':
        return openSubMenuCadastros

      case 'Atividades':
        return openSubMenuAtividades

      case 'Lançamentos':
        return openSubMenuLancamentos

      case 'Fechamento Usuários':
        return openSubMenuFechamentoUser

      case 'Fechamento Clientes':
        return openSubMenuFechamentoClient

      default: {}
    }
  }

  const handleOpenSubMenu = (text) => {
    switch (text) {
      case 'Cadastros':
        setOpenSubMenuCadastros(!openSubMenuCadastros);
      break

      case 'Atividades':
        setOpenSubMenuAtividades(!openSubMenuAtividades)
      break

      case 'Lançamentos':
        setOpenSubMenuLancamentos(!openSubMenuLancamentos)
      break

      case 'Fechamento Usuários':
        setOpenSubMenuFechamentoUser(!openSubMenuFechamentoUser)
      break

      case 'Fechamento Clientes':
        setOpenSubMenuFechamentoClient(!openSubMenuFechamentoClient)
      break

      default: {}
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ paddingLeft: '16px' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <ListItem button>
            <ListItemText
              primary={
                <>
                  {open ?
                  <div style={{whiteSpace: 'normal'}}>
                    Olá, {desUsuario}
                  </div> :
                  `Olá, ${desUsuario}`
                  }
                  <br />
                  {
                  open ?
                  <span style={{ fontSize: '11px' }}>
                    Seu perfil de acesso é {office.tipCargo.toLowerCase()}
                  </span> :
                  <span style={{  display: 'none' }}>
                    Seu perfil de acesso é {office.tipCargo.toLowerCase()}
                  </span>
                  }
                </>
              }
            />
          </ListItem>
          <IconButton onClick={handleMenuClose}>
            {theme.direction !== 'rtl' && <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menus.map(menu => {
            if (menu.externalLink) {
              return (
                <Tooltip key={menu.text} title={menu.text} arrow placement="right">
                  <div key={menu.text} onClick={() => window.open(menu.link)}>
                    <ListItem
                      button
                      style={{
                        background:
                          menu.link === window.location.pathname.toString() &&
                          '#3f51b5',
                        color:
                          menu.link === window.location.pathname.toString() &&
                          '#ffffff',
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            menu.link === window.location.pathname.toString() &&
                            '#ffffff',
                        }}
                      >
                        {menu.icon}
                      </ListItemIcon>
                      <ListItemText primary={menu.text} />
                    </ListItem>
                  </div>
                </Tooltip>
              )
            } else {
              return (
                <Tooltip key={menu.text} title={menu.text} arrow placement="right">
                  <Link
                    to={menu.link}
                    key={menu.text}
                    href={menu.link}
                    target={menu.target || ''}
                    className={classes.link}
                  >
                    <ListItem
                      button
                      style={{
                        background:
                          menu.link === window.location.pathname.toString() &&
                          '#3f51b5',
                        color:
                          menu.link === window.location.pathname.toString() &&
                          '#ffffff',
                      }}
                    >
                      <ListItemIcon
                        style={{
                          color:
                            menu.link === window.location.pathname.toString() &&
                            '#ffffff',
                        }}
                      >
                        {menu.icon}
                      </ListItemIcon>
                      <ListItemText primary={menu.text} />
                    </ListItem>
                  </Link>
                </Tooltip>
              )
            }
          })}
        </List>
        <Divider />
        <List>
          {authorizedMenu().map(menu => (
            <div key={menu.text} onClick={() => handleOpenSubMenu(menu.text)}>
            <Tooltip key={menu.text} title={menu.text} arrow placement="right">
              <Link
                to={menu.link}
                href={menu.link}
                target={menu.target || ''}
                className={classes.link}
                onClick={menu.logout}
              >
                <ListItem
                  button
                  key={menu.text}
                  style={{
                    background:
                      menu.link === window.location.pathname.toString() &&
                      '#3f51b5',
                    color:
                      menu.link === window.location.pathname.toString() &&
                      '#ffffff',
                  }}
                >
                  <ListItemIcon
                    onClick={handleDrawerOpen}
                    style={{
                      color:
                        menu.link === window.location.pathname.toString() &&
                        '#ffffff',
                    }}
                  >
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText primary={menu.text}/>
                  {showOpenSubMenu(menu.text) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                </Link>
              </Tooltip>
                <Collapse in={showOpenSubMenu(menu.text)} timeout="auto" unmountOnExit>
                  {menu.list.map(list => (
                     <Tooltip key={list.text} title={list.text} arrow placement="right">
                        <Link
                         to={list.link}
                         href={list.link}
                         target={list.target || ''}
                         className={classes.link}
                         onClick={list.logout}
                        >
                        <ListItem
                          button
                          key={list.text}
                          style={{
                          background:
                          list.link === window.location.pathname.toString() &&
                          '#3f51b5',
                          color:
                          list.link === window.location.pathname.toString() &&
                          '#ffffff',
                          }}
                          >
                          <div className={classes.subMenu}>
                          <ListItemIcon
                            onClick={handleDrawerOpen}
                            style={{
                            color:
                            list.link === window.location.pathname.toString() &&
                            '#ffffff',
                            }}
                            >
                            {list.icon}
                          </ListItemIcon>
                          <ListItemText primary={list.text}/>
                          </div>
                          </ListItem>
                          </Link>
                          </Tooltip>
                  ))}
                </Collapse>
              </div>
          ))}
        </List>
        <List >
          {sair.map(menu => (
            <Tooltip key={menu.text} title={menu.text} arrow placement="right">
              <Link
                to={menu.link}
                href={menu.link}
                target={menu.target || ''}
                className={classes.link}
                onClick={menu.logout}
              >
                <ListItem
                  button
                  key={menu.text}
                  style={{
                    background:
                      menu.link === window.location.pathname.toString() &&
                      '#3f51b5',
                    color:
                      menu.link === window.location.pathname.toString() &&
                      '#ffffff',
                  }}
                >
                  <ListItemIcon
                    style={{
                      color:
                        menu.link === window.location.pathname.toString() &&
                        '#ffffff',
                    }}
                  >
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText primary={menu.text}/>
                </ListItem>
              </Link>
            </Tooltip>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Typography component="div" paragraph>
          {props.children}
        </Typography>
      </main>
    </div>
  )
}
