import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.period + '/filter', param)

    return dispatch(trigger(types.period.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.period.getFilter.error, error))
  }
}

export const getProposta = period => async dispatch => {
  try {
    const response = await new Http(dispatch).get(`${URL.execution}/filter?periodoId=${period}`);

    return dispatch(trigger(types.period.getProposta.success, response));
  } catch (error) {
    return dispatch(trigger(types.period.getProposta.error, error));
  }
}


export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.period, param)

    return dispatch(trigger(types.period.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.period.get.error))
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.period, param)

    return dispatch(trigger(types.period.delete.success))
  } catch (error) {
    return dispatch(trigger(types.period.delete.error, error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.period, param)

    return dispatch(trigger(types.period.save.success))
  } catch (e) {
    return dispatch(trigger(types.period.save.error, e.response.data.message))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.period, param)

    return dispatch(trigger(types.period.update.success))
  } catch (e) {
    return dispatch(trigger(types.period.update.error, e.response.data.message))
  }
}
