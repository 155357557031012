import axios from 'axios'
import { trigger, types } from './action_types'

/**
 * Método para requisições Http
 * @author Elton Veiga
 */
class Http {
  constructor(dispatch, isLoad = true, type = false) {
    this.dispatch = dispatch
    this.isLoad = isLoad
    this.type = type
  }

  requestAccept(param) {
    if (!param) {
      this.dispatch(trigger(types.param.error, 'Parâmetro Inválido'))
      return false
    }
    return true
  }

  /**
   * GET Request
   * @param {String} endpoint = API endpoint
   * @param {Object} query = URL query params
   * @param {Boolean} internalErrorHandling = throw error or handle it internally
   * @return {Promise} response
   */
  async get(endpoint, query, internalErrorHandling = true) {
    const queryParams = this.createQueryParams(query)
    if (queryParams) {
      endpoint = endpoint + '?' + queryParams
    }

    this.isLoad && this.dispatch(trigger(types.load.start))

    try {
      const response = await axios.get(endpoint, this.getConfigs())

      return await this.handleResponse(response)
    } catch (e) {
      if (internalErrorHandling) {
        this.handleError(e)
      } else {
        throw e
      }
    } finally {
      this.handleFinally()
    }
  }

  /**
   * POST Request
   * @param {String} endpoint = API endpoint
   * @params {Object} params = params to be sent to API
   * @return {Promise} response
   */
  async put(endpoint, params, disableIdUrl = false) {
    if (!this.requestAccept(params)) {
      return Promise.reject('Parâmetro inválido')
    }
    this.dispatch(trigger(types.load.start))

    const url =
      params.id && !disableIdUrl ? `${endpoint}/${params.id}` : endpoint

    try {
      const response = await axios.put(url, params, this.getConfigs())

      return await this.handleResponse(response)
    } catch (e) {
      throw e
    } finally {
      this.handleFinally()
    }
  }

  /**
   * DELETE Request
   * @param {String} endpoint = API endpoint
   * @params {Object} params = params to be sent to API
   * @return {Promise} response
   */
  async delete(endpoint, params) {
    if (!this.requestAccept(params)) {
      return
    }
    this.dispatch(trigger(types.load.start))
    const url = `${endpoint}/${params.id}`

    try {
      const response = await axios.delete(url, this.getConfigs())
      return await this.handleResponse(response)
    } catch (e) {
      return await this.handleResponse(e)
    } finally {
      this.handleFinally()
    }
  }

  /**
   * PUT Request
   * @param {String} endpoint = API endpoint
   * @params {Object} params = params to be sent to API
   * @return {Promise} response
   */
  async post(endpoint, params) {
    if (!this.requestAccept(params)) {
      return
    }

    this.dispatch(trigger(types.load.start))

    try {
      const response = await axios.post(endpoint, params, this.getConfigs())

      return await this.handleResponse(response)
    } catch (e) {
      throw e
    } finally {
      this.handleFinally()
    }
  }

  /**
   * Sets request headers
   * @return {Object} headers
   */
  headers() {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: user.dsToken,
    }
    return headers
  }

  /**
   * Request's errors handling
   * @return {Promise} response
   */
  handleResponse(response) {
    this.dispatch(trigger(types.toaster.clear))

    if (response && (response.status === 204 || response.data)) {
      if (this.checkStatus(response)) {
        return response.data
      }
    }

    return Promise.reject(response)
  }

  /**
   * Print error in console
   *
   * @param {object} error - Error
   */
  handleError(error) {
    console.error('Error', error)
  }

  /**
   * Stop spinner
   */
  handleFinally() {
    this.dispatch(trigger(types.load.stop))
  }

  /**
   * Checks response status
   * @return {Boolean}
   */
  checkStatus({ status }) {
    return status >= 200 && status < 300
  }

  /**
   * Creates query params
   * @param query {Object}
   * @return {String} query
   * e.g:
   * query = { name: 'username', age: '18' }
   * queryParams 'name=username&age=18'
   */
  createQueryParams(query) {
    if (!query || Object.keys(query).length < 1) {
      return
    }
    const esc = encodeURIComponent
    const queryParams = Object.keys(query)
      .map(k => esc(k) + '=' + esc(query[k]))
      .join('&')

    return queryParams
  }

  getConfigs() {
    return {
      headers: this.headers(),
    }
  }
}

export default Http
