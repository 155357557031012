/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { useFormik } from 'formik'

import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'

import createSyntheticEvent from '../../../utils/createSyntheticEvent'
import Button from '../../../utils/Form/Button'
import Checkbox from '../../../utils/Form/Checkbox'
import Datepicker from '../../../utils/Form/Datepicker'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import { convertDate } from '../../../utils/Date'
import Select from '../../../utils/Form/Select'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      suffix={'%'}
      isNumericString={true}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const tipAtividades = [
  { key: 'FRONTEND', value: 'FRONTEND' },
  { key: 'BACKEND', value: 'BACKEND' },
  { key: 'MOBILE', value: 'MOBILE' },
]

const tipStatus = [
  { key: 'INCLUÍDA', value: 'INCLUÍDA' },
  { key: 'CONCLUÍDA', value: 'CONCLUÍDA' },
  { key: 'EM DESENVOLVIMENTO', value: 'EM DESENVOLVIMENTO' },
  { key: 'ENTREGUE', value: 'ENTREGUE' },
]

const validationSchema = yup.object({
  idClient: yup.number().required('Por favor, escolha o Cliente!'),
  projectId: yup.number().required('Por favor, escolha o projeto!'),
  userId: yup.number().required('Por favor, escolha o usuário!'),
  sigAtividade: yup
    .string()
    .max(20, 'A sigla da ativade deve conter no máximo 20 caracteres!')
    .test((value, ctx) => {
      if (!value) return true
      const project = validationSchema.projects.find(
        p => p.id === ctx.parent.projectId
      )
      if (project !== undefined) {
      if (value.startsWith(project.desSigla)) return true
      return ctx.createError({
        message: `A sigla para o projeto selecionado deve iniciar com ${project.desSigla}`,
      })} else {
        return ctx.createError({
          message: `Informe o Projeto de atuação!`,
        })
      }
    })
    .required('Por favor, informe uma sigla!'),
  desAtividade: yup
    .string()
    .max(100, 'A sigla da ativade deve conter no máximo 100 caracteres!')
    .required('Por favor, informe uma descrição!'),
  tipStatus: yup.string().required('Por favor, escolha o status!'),
  dthPrevisaoEntrega: yup
    .date()
    .required('Por favor, informe uma data válida de previsão de entrega!')
    .nullable(),
})

/**
 * Activity form
 */
const Form = ({
  projects = [],
  users = [],
  points = [],
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
  onOpenDateModal,
  onCloseDateModal,
  clients
}) => {
  const [listDescClient, setListDescClient] = useState([])
  const [listOpenProjects, setListOpenProjects] = useState([])
  const [listUsers, setListUsers] = useState([])
  const user = JSON.parse(localStorage.getItem('user'))
  const [listTipAtividades, setListTipAtividades] = useState([])
  const [listTipStatus, setListTipStatus] = useState([])
  const [listPoints, setLisPoints] = useState([])

  const [disabled, setDisabled] = useState(false)

  const [projectClients, setProjectClients] = useState('')

  const formik = useFormik({
    initialValues: {
      id: param.id,
      userObject: {key: param.userId, value: param.desUsuario},
      pointId: param.pointId,
      projectId: param.projectId,
      descClient: param.descClient,
      idClient: 0,
      userId: user.office.tipCargo === 'OPERAÇÃO' ? user.id : param.userId,
      indSobreaviso: param.indSobreaviso,
      sigAtividade: param.sigAtividade,
      desAtividade: param.desAtividade,
      tipAtividade: param.tipAtividade,
      tipStatus: param.tipStatus === '' ? tipStatus[0].value : param.tipStatus,
      dthInclusao: convertDate(param.dthInclusao),
      dthPrevisaoEntrega: convertDate(param.dthPrevisaoEntrega),
      dthConclusao: convertDate(param.dthConclusao),
      perConclusao: param.perConclusao,
    },
    validationSchema: () => {
      validationSchema.projects = projects
      return validationSchema
    },
    validate: values => {
      const errors = {}

      if (values.dthInclusao && values.dthConclusao) {
        const dthInclusao = moment(values.dthInclusao)
        const dthConclusao = moment(values.dthConclusao)

        if (
          !dthConclusao.isAfter(dthInclusao) &&
          !dthConclusao.isSame(dthInclusao)
        ) {
          errors.dthInclusao =
            'Data inicial deve ser menor ou igual à data final!'
          errors.dthConclusao =
            'Data final deve ser maior ou igual à data inicial!'
        }
      }

      return errors
    },
    onSubmit: values => {
      onSubmit(values)
    },
  })

  const getProjectInitialsHint = projectId => {
    const project = projects.find(p => p.id === projectId)
    return project
      ? `A sigla para o projeto selecionado deve iniciar com ${project.desSigla}`
      : ''
  }

  useEffect(() => {
    if (param.id !== null) {
      const thisProject = projects.find(
        p => p.id === param.projectId
      )
      formik.handleChange(
        createSyntheticEvent('idClient', thisProject.idClient),
      )
      const userObj = {key: thisProject.userId, value: param.desUsuario}
      formik.setFieldValue('userObject', userObj)
    }
  }, [])

  useEffect(() => {
    setListUsers(
      users
      .filter(user => user.indInativo === true)
      .sort((userA, userB) =>
          userA.desUsuario.localeCompare(userB.desUsuario)
            )
      .map(user => ({ key: user.id, value: user.desUsuario }))
    )
    setLisPoints(
      points.map(point => ({
        key: point.id,
        value: Number.parseFloat(point.numPontuacao)
          .toFixed(6)
          .toString()
          .replace('.', ','),
      }))
    )
    setListTipStatus(
      tipStatus.map(status => ({ key: status.key, value: status.value }))
    )
    const objs = clients.filter((item) => item.descCliente !== null)
    setListDescClient(
      objs
        .sort((clientA, clientB) => clientA.descCliente.localeCompare(clientB.descCliente))
        .map(client => ({ key: client.id, value: client.descCliente }))
    )
    setListOpenProjects(
      projects
        .filter(project => project.indStatus === true)
        .sort((projectA, projectB) =>
          projectA.desProjeto.localeCompare(projectB.desProjeto)
            )
        .map(project => ({ key: project.id, value: project.desProjeto }))
    )
    setListTipAtividades(
      tipAtividades.map(tipAtividade => ({
        key: tipAtividade.key,
        value: tipAtividade.value,
      }))
    )
  }, [projects, users])

  useEffect(() => {
    if (formik.values.projectId !== 0) {
      setProjectClients(
        projects
          .filter(project => project.id === formik.values.projectId)
          .sort((projectA, projectB) =>
          projectA.desProjeto.localeCompare(projectB.desProjeto)
            )
          .map(project => project.descClient)
      )
    }
  }, [formik.values.projectId])

  useEffect(() => {
    if (formik.values.idClient !== 0) {
      setListOpenProjects(
        projects
          .filter(project => project.indStatus === true)
          .filter(project => project.idClient === formik.values.idClient )
          .sort((projectA, projectB) =>
          projectA.desProjeto.localeCompare(projectB.desProjeto)
            )
          .map(project => ({ key: project.id, value: project.desProjeto }))
      )
    }
  }, [formik.values.idClient])

  useEffect(() => {
    if (formik.values.tipStatus !== 'EM DESENVOLVIMENTO') {
      formik.values.perConclusao = 100
      setDisabled(true)
    } else {
      formik.values.perConclusao =
        param.perConclusao !== 100 ? param.perConclusao : 0
      setDisabled(false)
    }
  }, [formik.values.tipStatus])

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <Select
                fullWidth
                select
                id="idClient"
                name="idClient"
                label="Cliente"
                options={listDescClient}
                value={{value: formik.values.idClient === 0 ? formik.values.descClient : formik.values.idClient}}
                onChange={val =>
                 {
                  formik.handleChange(
                    createSyntheticEvent('idClient', val.target.value),
                  )
                  formik.handleChange(
                    createSyntheticEvent('projectId', ''),
                  )
                  formik.handleChange(
                    createSyntheticEvent('sigAtividade', ''),
                  )
                  }
                }
                error={
                  formik.touched.idClient && Boolean(formik.errors.idClient)
                }
                helperText={formik.touched.idClient && formik.errors.idClient}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <Select
                fullWidth
                select
                disabled={formik.values.idClient === 0 ? true : false}
                id="projectId"
                name="projectId"
                label="Projeto"
                options={listOpenProjects}
                value={{ value: formik.values.projectId }}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent('projectId', val.target.value)
                  )
                }
                error={
                  formik.touched.projectId && Boolean(formik.errors.projectId)
                }
                helperText={formik.touched.projectId && formik.errors.projectId}
              />
            </FlexItemWrapper>

          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                id="sigAtividade"
                name="sigAtividade"
                label="Atividade (Sigla)"
                value={formik.values.sigAtividade}
                onChange={formik.handleChange}
                error={
                  formik.touched.sigAtividade &&
                  Boolean(formik.errors.sigAtividade)
                }
                helperText={
                  (formik.touched.sigAtividade && formik.errors.sigAtividade) ||
                  getProjectInitialsHint(formik.values.projectId)
                }
                inputProps={{
                  maxLength: 20,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                id="desAtividade"
                name="desAtividade"
                label="Descrição Atividade"
                value={formik.values.desAtividade}
                onChange={formik.handleChange}
                error={
                  formik.touched.desAtividade &&
                  Boolean(formik.errors.desAtividade)
                }
                helperText={
                  formik.touched.desAtividade && formik.errors.desAtividade
                }
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <Autocomplete
                fullWidth
                id="userObject"
                name="userObject"
                label="Destinatário"
                noOptionsText={'Sem usuários'}
                options={listUsers}
                getOptionLabel={(val = {}) => {
                  return val.value
                }}
                value={formik.values.userObject}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Usuário"
                    error={formik.touched.userId && Boolean(formik.errors.userId)}
                    helperText={formik.touched.userId && formik.errors.userId}
                  />
                )}
                onChange = {(_, option) => {
                  formik.setFieldValue('userObject', option)
                  formik.setFieldValue('userId', option.key)
                }}
                error={formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={formik.touched.userId && formik.errors.userId}
                disabled={user.office.tipCargo === 'OPERAÇÃO'}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <Datepicker
                id="dthPrevisaoEntrega"
                label="Data previsão de entrega"
                value={formik.values.dthPrevisaoEntrega}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent(
                      'dthPrevisaoEntrega',
                      moment(val).toISOString()
                    )
                  )
                }
                error={
                  formik.touched.dthPrevisaoEntrega &&
                  Boolean(formik.errors.dthPrevisaoEntrega)
                }
                helperText={
                  formik.touched.dthPrevisaoEntrega &&
                  formik.errors.dthPrevisaoEntrega
                }
                autoOk
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <Select
                fullWidth
                select
                id="tipStatus"
                name="tipStatus"
                label="Status"
                options={listTipStatus}
                value={{ value: formik.values.tipStatus }}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent('tipStatus', val.target.value)
                  )
                }
                error={
                  formik.touched.tipStatus && Boolean(formik.errors.tipStatus)
                }
                helperText={formik.touched.tipStatus && formik.errors.tipStatus}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <Checkbox
                id="indSobreaviso"
                name="indSobreaviso"
                label="Sobreaviso"
                checked={formik.values.indSobreaviso}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent('indSobreaviso', val)
                  )
                }
              />
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <Button
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  isDeleteAction: PropTypes.bool,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
  onOpenDateModal: PropTypes.func.isRequired,
  onCloseDateModal: PropTypes.func.isRequired,
}

export default Form
