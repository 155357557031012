import { types } from '../../config/action_types'

const defaultState = {
  toaster: {
    message: '',
    type: '',
    active: false,
  },
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.toaster.error:
      return {
        ...state,
        toaster: {
          active: true,
          type: 'error',
          message: action.payload,
        },
      }

    case types.toaster.success:
      return {
        ...state,
        toaster: {
          active: true,
          type: 'success',
          message: action.payload,
        },
      }

    case types.toaster.info:
      return {
        ...state,
        toaster: {
          active: true,
          type: 'info',
          message: action.payload,
        },
      }

    case types.toaster.clear:
      return {
        ...state,
        toaster: {
          active: false,
          type: '',
          message: '',
        },
      }

    default:
      return {
        ...state,
      }
  }
}
