import Http from '../../config/Http'
import URL from '../../config/URL'
import { trigger, types } from '../../config/action_types'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.client + '/filter', param)

    return dispatch(trigger(types.client.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.client.getFilter.error, error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.client, param)

    return dispatch(trigger(types.client.get.success, response.data))
  } catch (error) {
    return dispatch(trigger(types.client.get.error, error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.client, param)

    return dispatch(trigger(types.client.save.success))
  } catch (error) {
    return dispatch(trigger(types.client.save.error, error))
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.client, param)

    return dispatch(trigger(types.client.delete.success))
  } catch (error) {
    return dispatch(trigger(types.client.delete.error, error))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.client, param)

    return dispatch(trigger(types.client.update.success))
  } catch (error) {
    return dispatch(trigger(types.client.update.error, error))
  }
}
