import React, { useState, useEffect } from 'react'
import Main from '../Main'
import PropTypes from 'prop-types'
import Toast from '../../utils/Toast'
import { get as getUsers } from '../../actions/user'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const Home = ({
                getUsers,
                userInfo = null,
                listUsers = null,
              }) => {
  const [showToast, setShowToast] = useState(false)

  const closeToast = () => {
    setShowToast(false)
  }

  /**
   * Show toast if error have returned
   */
  useEffect(() => {
    if (
      !showToast &&
      userInfo !== null &&
      userInfo.indLastWeekToChangeUserPassword
    )
      setShowToast(true)
  }, [userInfo])

  useEffect(() => {
    getUsers()
  }, [userInfo])

  useEffect(() => {
    localStorage.setItem('listUsers', JSON.stringify(listUsers))
  }, [listUsers])

  return (
    <Main title="Home">
      <h1>Tela de Home</h1>
      <Toast
        error={userInfo.msgWarning}
        onClose={closeToast}
        open={showToast}
      />
    </Main>
  )
}

const mapStateToProps = ({ userReducer }) => ({
  listUsers: userReducer.listUsers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsers,
    },
    dispatch,
  )

Home.propTypes = {
  userInfo: PropTypes.object,
  getUsers: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

