const REGEX_PERSON_NAME = /^([\u0020])|([\u0021-\u0040-\u005b-\u0060-\u007b-\u007d´~^`])/g
const MORE_THAN_ONE_SPACE = /\s{2,}/g

export const replaceInvalidCharsPersonName = value => {
  let newValue = value.replace(REGEX_PERSON_NAME, '')
  newValue = newValue.replace(MORE_THAN_ONE_SPACE, ' ')
  return newValue
}

export const truncateString = ({ value = '', maxLength = 30 }) => {
  const condition = '(.{' + maxLength + '})..+'
  const regex = new RegExp(condition, 'g')
  return value.replace(regex, '$1…')
}
