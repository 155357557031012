import React, { useCallback, useEffect, useState } from 'react'
import Main from '../Main'
import { Box, Grid, Paper, TableContainer } from '@material-ui/core'
import { globalStyles } from '../../styles/global'
import TablePagination from '../../utils/Table/TablePaginationEmployee'
import moment from 'moment'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'
import FilterDefault from '../../utils/Filter/FilterEmployeeSearch'
import {
  ORDER,
  FILTER_FIELDS,
  TABLE_HEADER,
  TABLE_HEADER_INDEX_ORD,
} from './utils/constants'
import api from '../../../server'
import { formatDate } from './utils/oderingFunctions'
import Load from '../../utils/Loading'
import {
  calculateDiffDatas,
  nameMonthToNumberMonth,
} from './utils/formatterDatas'

const EmployeeSearch = () => {
  const styles = globalStyles()

  const initDateFilter = moment(new Date()).format('LL')

  const [sortingOptions, setSortingOptions] = useState(TABLE_HEADER_INDEX_ORD)
  const [filteredRows, setFilteredRows] = useState(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [countElementTotal, setCountElementTotal] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [params, setParams] = useState()
  const [clearClick, setClearClick] = useState()
  const [notHiringTime, setNotHiringTime] = useState(false)
  const [ordernation, setOrdenation] = useState(false)
  const [monthHiringTime, setMonthHiringTime] = useState('')
  const [monthHiringTimeBeta, setMonthHiringTimeBeta] = useState('')
  const [monthBirthdateBeta, setMonthBirthdateBeta] = useState('')

  const columnIndex = ''
  const typeORD = ''

  const toggleTypeOrd = useCallback((index, newTypeOrd, sortingOptions) => {
    const updatedSortingOptions = sortingOptions.map(option => {
      if (option.index === index) {
        return { ...option, typeOrd: newTypeOrd }
      } else {
        return { ...option, typeOrd: 0 }
      }
    })

    setSortingOptions(updatedSortingOptions)
  }, [])

  const handlePageChange = newPage => {
    setOrdenation(true)
    setPage(newPage)
    setParams({
      ...params,
      page: newPage,
    })
  }

  const handlerRowsChange = event => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setParams({
      ...params,
      size: Number(newRowsPerPage),
    })
    setSize(newRowsPerPage)
  }

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await api.get('/employee-search', { params })
      if (data.content) {
        const newFilteredRows = data.content.map(item => ({
          id: item.id,
          nome: item.name,
          dataAniversario: formatDate(item.birthdayDate),
          dataAdmissao: formatDate(item.startDateJob),
          tempoEmpresa: calculateDiffDatas(
            item.startDateJob,
            initDateFilter,
            notHiringTime,
            monthHiringTime
          ),
          label: item.id,
        }))
        const totalItems = data.numberOfElements
        setRowsPerPage(totalItems)
        const totalPagesBack = data.totalPages
        setTotalPages(totalPagesBack * totalItems)

        const filteredRows = newFilteredRows.filter(
          item => item.tempoEmpresa !== '0 anos'
        )


        if(monthBirthdateBeta === '' && monthHiringTimeBeta === ''){
          setCountElementTotal(data.totalElements)
        }
        else if(monthBirthdateBeta === null && monthHiringTimeBeta === null){
          setCountElementTotal(data.totalElements)
        }else if(monthBirthdateBeta === '' && monthHiringTimeBeta === null){
          setCountElementTotal(data.totalElements)
        }else if(monthBirthdateBeta === null && monthHiringTimeBeta === ''){
          setCountElementTotal(data.totalElements)
        }
        else {
          setCountElementTotal(filteredRows.length)
        }

        if (clearClick === false) {
          setFilteredRows(null)
        } else {
          if (ordernation === true) {
            const sortedRows = [...filteredRows].sort((row1, row2) => {
              for (const option of sortingOptions) {
                const orderKey = ORDER[option.index]
                const dateKey =
                  orderKey === 'dataAniversario' || orderKey === 'dataAdmissao'

                if (option.typeOrd === 0) {
                  if (dateKey) {
                    moment(row1[orderKey], 'DD/MM/YYYY').diff(
                      moment(row2[orderKey], 'DD/MM/YYYY')
                    )
                  } else {
                    return row1[orderKey].localeCompare(row2[orderKey])
                  }
                } else {
                  if (dateKey) {
                    return moment(row2[orderKey], 'DD/MM/YYYY').diff(
                      moment(row1[orderKey], 'DD/MM/YYYY')
                    )
                  } else {
                    return row2[orderKey].localeCompare(row1[orderKey])
                  }
                }
              }
              return 0;
            })
            setFilteredRows(sortedRows)
            setOrdenation(false)
          } else {
            setFilteredRows(filteredRows)
          }
        }
      }
    } catch (error) {
      console.error('Error loading data: ', error)
    } finally {
      setLoading(false)
    }
  }, [params, clearClick, notHiringTime, monthHiringTime])

  const performSorting = useCallback(
    (columnIndex, newTypeOrd) => {
      toggleTypeOrd(columnIndex, newTypeOrd, sortingOptions)
      if (filteredRows === null) {
        return
      }

      const sortedRows = [...filteredRows].sort((row1, row2) => {
        const orderKey = ORDER[columnIndex]
        const dateKey =
          orderKey === 'dataAniversario' || orderKey === 'dataAdmissao'

        if (newTypeOrd === 0) {
          if (dateKey) {
            return moment(row1[orderKey], 'DD/MM/YYYY').diff(
              moment(row2[orderKey], 'DD/MM/YYYY')
            )
          } else {
            return row1[orderKey].localeCompare(row2[orderKey])
          }
        } else {
          if (dateKey) {
            return moment(row2[orderKey], 'DD/MM/YYYY').diff(
              moment(row1[orderKey], 'DD/MM/YYYY')
            )
          } else {
            return row2[orderKey].localeCompare(row1[orderKey])
          }
        }
      })

      setFilteredRows(sortedRows)
    },
    [sortingOptions, filteredRows]
  )

  const handleFilterSubmit = fields => {
    const updatedSortingOptions = sortingOptions.map(option => {
      return { ...option, typeOrd: 0 }
    })
    setSortingOptions(updatedSortingOptions)
    try {
      let newPage = null
      let newSize = null

      if (fields.dateBirthday === null && fields.dateHiring === null) {
        newSize = 10
        newPage = 0
        setPage(0)
        setSize(10)
        setNotHiringTime(false)
      } else if (fields.dateHiring !== null) {
        newSize = 10
        newPage = 0
        setPage(0)
        setSize(10)
        setMonthHiringTime(monthHiringTimeBeta)
        setNotHiringTime(true)
      } else {
        setNotHiringTime(true)
        newSize = 10
        newPage = 0
        setPage(0)
        setSize(10)
      }
      setMonthHiringTime(monthHiringTimeBeta)

      setParams({
        startMonth:
          nameMonthToNumberMonth(fields.dateHiring) === -1
            ? null
            : nameMonthToNumberMonth(fields.dateHiring),
        birthdayMonth:
          nameMonthToNumberMonth(fields.dateBirthday) === -1
            ? null
            : nameMonthToNumberMonth(fields.dateBirthday),
        page: newPage === null ? page : newPage,
        size: newSize === null ? size : newSize,
      })
      setClearClick(true)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleUserFilterChange = (fieldName, value) => {
    if (fieldName === 'dateBirthday') {
      setMonthBirthdateBeta(value)
    } else if (fieldName === 'dateHiring') {
      setMonthHiringTimeBeta(value)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (clearClick === false) {
      setPage(0)
      setSize(10)
      setCountElementTotal(0)
      setFilteredRows(null)
      setMonthBirthdateBeta('')
      setMonthHiringTimeBeta('')
    }
    if (clearClick === true) {
      loadData()
    }

  }, [
    loadData,
    clearClick,
    params,
    page,
    totalPages,
    rowsPerPage,
    countElementTotal,
    notHiringTime,
    columnIndex,
    typeORD,
    ordernation,
    monthHiringTime,
    
  ])

  return (
    <Main title="Consulta Colaborador">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={styles.box}></Box>
          <Box className={styles.filter}>
            <FilterDefault
              fields={FILTER_FIELDS}
              onSubmit={handleFilterSubmit}
              onValueChange={handleUserFilterChange}
              onClear={propToPass => {
                setClearClick(propToPass)
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            {loading ? (
              <Load />
            ) : (
              <TablePagination
                headers={TABLE_HEADER}
                headersIndexOrd={sortingOptions}
                count={Number(countElementTotal)}
                rows={filteredRows} // Renderize com os dados filtrados
                rowsPerPage={rowsPerPage}
                performSorting={performSorting}
                order={ORDER}
                onChangePage={(_, page) => handlePageChange(page)}
                onChangeSize={handlerRowsChange}
                hasAction={false}
                page={page}
                size={size}
              />
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Main>
  )
}

export default EmployeeSearch
