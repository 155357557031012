import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import AddIcon from '@material-ui/icons/Add'
import { Box, TableContainer, Fab, Grid, Paper } from '@material-ui/core'

import * as actionOffice from '../../actions/office'

import Form from './components/Form'
import Main from '../Main'
import TablePagination from '../../utils/Table/TablePagination'
import { globalStyles } from '../../styles/global'
import Dialog from '../../utils/Dialog'
import Filter from '../../utils/Filter/Filter'
import Toast from '../../utils/Toast'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'desCargo'

const DEFAULT_PARAM = {
  id: null,
  desCargo: '',
  tipCargo: '',
  indInativo: '',
}

const doNotShowTable = ['id', 'indInativo']

const headersTable = ['Descrição', 'Perfil do Cargo']

const Office = ({
  /** Actions */
  getFilter,
  get,
  remove,
  save,
  update,
  /** Reducer props */
  listOffices,
  listOfficesFilter,
  errorOffices,
  successOffices,
  totalElementsOffices,
}) => {
  const styles = globalStyles()

  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState('Novo cargo')

  const [showToast, setShowToast] = useState(false)

  const [valuesFilter, setValuesFilter] = useState({})

  const handleFilter = async values => {
    const { id, indInativo } = values

    setValuesFilter(values)

    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(id && { id }),
      ...(typeof indInativo === 'boolean' && { indInativo }),
    })
  }

  let filterProps = {
    onSubmit: values => {
      setValuesFilter(values)
    },
    fields: [
      {
        type: 'select',
        name: 'id',
        label: 'Nome',
        options: listOffices.map(office => ({
          value: office.id,
          label: office.desCargo,
        })),
      },
      {
        type: 'select',
        name: 'indInativo',
        label: 'Status',
        initial: true,
        options: [
          { value: true, label: 'ATIVO' },
          { value: false, label: 'INATIVO' },
        ],
      },
    ],
  }

  const query = async () => {
    await get()
  }

  const convertRecord = record => {
    return {
      ...record,
      desCargo: record.desCargo,
      tipCargo: record.tipCargo,
      indInativo: record.indInativo,
    }
  }

  const handleSubmit = async values => {
    await onSubmit(values)

    setShowDialog(false)

    setShowToast(true)

    handleFilter(valuesFilter)

    return null
  }

  const startSave = () => {
    setParam(convertRecord(DEFAULT_PARAM))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo cargo')

    setShowDialog(true)
  }

  const startDelete = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(true)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)
    setTitle(`Você deseja deletar o cargo de ${record.desCargo}?`)

    setShowDialog(true)
  }

  const startEdit = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)
    setTitle('Alterar cargo')

    setShowDialog(true)
  }

  useEffect(() => {
    if (page === DEFAULT_PAGINATION_PAGE) {
      handleFilter(valuesFilter)
    } else {
      setPage(DEFAULT_PAGINATION_PAGE)
    }
  }, [valuesFilter])

  useEffect(() => {
    Object.keys(valuesFilter).length !== 0
      ? handleFilter(valuesFilter)
      : handleFilter({ indInativo: true })
  }, [page, size])

  useEffect(() => {
    query()
  }, [])

  return (
    <Main title="Cargos">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={startSave} color="primary">
            <AddIcon />
          </Fab>
        </Box>

        <Box component="div" className={styles.filter} xs={6}>
          <Filter {...filterProps} />
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            count={totalElementsOffices}
            doNotShow={doNotShowTable}
            headers={headersTable}
            onChangePage={(_, page) => setPage(page)}
            onChangeSize={event => setSize(event.target.value)}
            page={page}
            rows={listOfficesFilter}
            size={size}
            startDelete={startDelete}
            startEdit={startEdit}
            alignTitle={['left', 'left']}
            alignItens={[null, 'left', 'left']}
          />
        </TableContainer>
      </Grid>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
      >
        <Form
          isDeleteAction={isDeleteAction}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          primaryButtonColor={primaryButtonColor}
          primaryButtonLabel={primaryButtonLabel}
        />
      </Dialog>

      <Toast
        autoHideDuration={3000}
        error={errorOffices}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successOffices}
      />
    </Main>
  )
}

const mapStateToProps = ({ officeReducer }) => ({ ...officeReducer })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actionOffice }, dispatch)

Office.propTypes = {
  /** Actions */
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  /** Reducer props */
  listOffices: PropTypes.array.isRequired,
  listOfficesFilter: PropTypes.array.isRequired,
  errorOffices: PropTypes.string,
  successOffices: PropTypes.string,
  totalElementsOffices: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Office)
