import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  confirmDelete: {
    textAlign: 'center',
    fontSize: '16px',
    marginBottom: '30px',
  },
  link: {
    color: 'black',
  },
  unstyledLink: {
    border: 'none',
    background: 'transparent',
  },
  deleteWarning: {
    marginBottom: '20px',
    marginLeft: '7px'
  }
})

export { useStyles }
