export const containsAtLeastOneNumber = new RegExp('[0-9]+')

export const containsAtLeastOneLowercaseCharacter = new RegExp('[a-z]+')

export const containsAtLeastOneUppercaseCharacter = new RegExp('[A-Z]+')

export const containsAtLeastOneSymbolOrSpecialcharacter = new RegExp('\\W|_')

const msgPassordnotSafe =
  'As senhas deverão ter no mínimo oito caracteres, contendo maiúsculas e minúsculas, números, símbolos ou caracteres especiais!'

export const notValidatePassword = newPassword => {
  if (newPassword.length < 8)
    return 'A senha deve ter no mínimo oito caracteres'

  if (!containsAtLeastOneNumber.test(newPassword)) {
    return msgPassordnotSafe
  }
  if (!containsAtLeastOneLowercaseCharacter.test(newPassword)) {
    return msgPassordnotSafe
  }

  if (!containsAtLeastOneUppercaseCharacter.test(newPassword)) {
    return msgPassordnotSafe
  }
  if (!containsAtLeastOneSymbolOrSpecialcharacter.test(newPassword)) {
    return msgPassordnotSafe
  }
  return false
}
