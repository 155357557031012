import React from "react"

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  flexWrapper: {
    border: '1px solid #000',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    flex: '2',
    padding: '20px',
    gap: '10px'
  },

  label: {
    marginTop: '-30px',
    background: 'white',
    width: 'fit-content',
    padding: '0 10px'
  }
})

export const WrapperWithLabel = ({children}) => {
  const styles = useStyles()

  return (
    <div className={styles.flexWrapper}>
    <label className={styles.label}>Valor Hora Adicional</label>
      {children}
    </div>
  )
}
