import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.hourBank + '/filter',
      param
    )

    return dispatch(trigger(types.hourBank.getFilter.success, response))
  } catch (e) {
    return dispatch(trigger(types.hourBank.getFilter.error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.hourBank, param)

    return dispatch(trigger(types.hourBank.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.hourBank.get.error))
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.hourBank, param)

    return dispatch(trigger(types.hourBank.delete.success))
  } catch (e) {
    return dispatch(trigger(types.hourBank.delete.error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.hourBank, param)

    return dispatch(trigger(types.hourBank.save.success))
  } catch (e) {
    return dispatch(trigger(types.hourBank.save.error))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.hourBank, param)

    return dispatch(trigger(types.hourBank.update.success))
  } catch (e) {
    return dispatch(trigger(types.hourBank.update.error))
  }
}
