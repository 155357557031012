import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const get = () => async (dispatch, getState) => {
  try {
    const response = await new Http(dispatch).get(URL.execution)
    return dispatch(trigger(types.execution.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.execution.get.error))
  }
}

export const getExecutionByMonth = () => async (dispatch, getState) => {
  const {
    executionReducer: { requestFilters },
  } = getState()
  try {
    const periodId = requestFilters.periodId ? `periodoId=${requestFilters.periodId}&` : ''
    const response = await new Http(dispatch).get(
      URL.execution + `/filter?${periodId}pageFilter=${requestFilters.pageFilter + 1}&sizeFilter=${requestFilters.sizeFilter}&sort=id%20asc`,
    )
    return dispatch(
      trigger(types.execution.getByMonth.success, response)
    )
  } catch (e) {
    return dispatch(trigger(types.execution.get.error))
  }
}

export const getExecutionByMonthProposal = (period ,proposalId) => async (dispatch, getState) => {
  const {
    executionReducer: { requestFilters },
  } = getState()
  try {
    const periodId = requestFilters.periodId ? `periodoId=${requestFilters.periodId}&` : ''
    const propostaId = proposalId ? proposalId : ''
    const response = await new Http(dispatch).get(
      URL.execution + `/filter?${periodId}pageFilter=${requestFilters.pageFilter + 1}&propostaId=${propostaId}&sizeFilter=${requestFilters.sizeFilter}&sort=id%20asc`,
    )
    return dispatch(
      trigger(types.execution.getByMonth.success, response)
    )
  } catch (e) {
    return dispatch(trigger(types.execution.get.error))
  }
}

export const changeExecutionId = (newExecutionId, newProposalId) => (dispatch, getState) => {
  const { executionReducer } = getState()
  dispatch(
    trigger(types.execution.updatePageFilter, {
      ...executionReducer.requestFilters,
      executionId: newExecutionId,
      proposalId: newProposalId
    })
  )
}

export const getExecutionUser = () => async (dispatch, getState) => {
  const {
    executionReducer: { requestFilters },
  } = getState()
  try {
    const executionId = requestFilters.executionId ? `executionId=${requestFilters.executionId}&` : ''
    const response = await new Http(dispatch).get(
      URL.execUser +
      `/filter?${executionId}pageFilter=${requestFilters.pageFilter + 1}
      &sizeFilter=${requestFilters.sizeFilter}&proposalId=${requestFilters.proposalId}&sort=id%20asc`,
    )
    return dispatch(
      trigger(types.execution.user.success, response)
    )
  } catch (e) {
    return dispatch(trigger(types.execution.get.error, e))
  }
}

export const handleChangeMonth = newPeriod => async (dispatch, getState) => {

  const { executionReducer } = getState()
  dispatch(
    trigger(types.execution.updatePeriod, {
      ...executionReducer,
      periodId: newPeriod.value,
      period: newPeriod
    })
  )
  dispatch(getExecutionByMonth())
}

export const handleChangeMonthProposal = (newPeriod, proposalId) => async (dispatch, getState) => {
  const { executionReducer } = getState();
  dispatch(
    trigger(types.execution.updatePeriod, {
      ...executionReducer,
      periodId: newPeriod.value,
      period: newPeriod,
      proposalId: proposalId.value, // Adicione o ID da proposta ao estado, se necessário.
    },  proposalId.value)
  );
  dispatch(getExecutionByMonthProposal(newPeriod, proposalId.value));
};

export const handlePagination = newPage => async (dispatch, getState) => {
  const { executionReducer } = getState()
  dispatch(
    trigger(types.execution.updatePageFilter, {
      ...executionReducer.requestFilters,
      pageFilter: newPage,
    })
  )
  dispatch(getExecutionByMonth())
}

export const handlePaginationSize = newPageSize => async (dispatch, getState) => {
  const { executionReducer } = getState()
  dispatch(
    trigger(types.execution.updateSizeFilter, {
      ...executionReducer.requestFilters,
      sizeFilter: newPageSize,
      pageFilter: 0,
    })
  )
  dispatch(getExecutionByMonth())
}

export const execUserPaginationSize = newPageSize => async (dispatch, getState) => {
  const { executionReducer } = getState()
  dispatch(
    trigger(types.execution.updateFilter, {
      ...executionReducer.requestFilters,
      sizeFilter: newPageSize,
      pageFilter: 0,
    })
  )
  dispatch(getExecutionUser())
}

export const execUserPagination = newPage => async (dispatch, getState) => {
  const { executionReducer } = getState()
  dispatch(
    trigger(types.execution.updateFilter, {
      ...executionReducer.requestFilters,
      pageFilter: newPage,
    })
  )
  dispatch(getExecutionUser())
}

export const resetPagination = () => async (dispatch, getState) => {
  const { executionReducer } = getState()
  dispatch(
    trigger(types.execution.updateFilter, {
      ...executionReducer.requestFilters,
      sizeFilter: 10,
      pageFilter: 0,
    })
  )
}

export const setExecutionToEdit = (execution, executionUser) => async (dispatch, getState) => {
  const { executionReducer } = getState()
  dispatch(
    trigger(types.execution.setEdit, {
      executionToEdit: execution ? execution : executionReducer.toEdit.executionToEdit,
      executionUserToEdit: executionUser ? executionUser : executionReducer.toEdit.executionUserToEdit
    })
  )
}

export const updateExecutionUser = (endpoint, param) => async dispatch => {
  try {

    const response = await new Http(dispatch).put(URL.execUser + `/${endpoint}`, param)

    return dispatch(trigger(types.execution.update.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.execution.update.error, e.response.data.message.messageError))
  }
}

export const updateExecution = (executionId, param) => async dispatch => {
  try {
    const response = await new Http(dispatch).put(URL.execution + `/${executionId}`, param)
    console.log(response.data)
    return dispatch(trigger(types.execution.update.observation, response.data))
  } catch (e) {
    return dispatch(trigger(types.execution.update.observationError, e.response.data.message))
  }
}

export const clearExecutions = () => dispatch => {
  dispatch(trigger(types.execution.clear))
}
