import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import * as actionEntry from '../../actions/entry'
import * as actionActivity from '../../actions/activity'
import * as actionStatusHistory from '../../actions/statusHistory'
import { get as getUsers } from '../../actions/user'
import { get as getClients } from '../../actions/client'
import { get as getProjects } from '../../actions/project'
import { get as getPeriods } from '../../actions/period'
import { markFilterAsValidToApply } from '../../actions/entryReport'

import { globalStyles } from '../../styles/global'
import Main from '../Main'
import TablePagination from '../../utils/Table/TablePagination'
import Button from '../../utils/Form/Button'

import AddIcon from '@material-ui/icons/Add'
import {
  TableContainer,
  Paper,
  Box,
  Fab,
  Grid,
  TableCell,
  Button as MatButton,
} from '@material-ui/core'
import Dialog from '../../utils/Dialog'
import Form from './components/Form'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/Filter'

import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_RELEASES_PAGINATION_SIZE,
} from '../../../constants/pagination'
import Loading from '../../utils/Loading'
import { getObjectFromParams } from '../../utils/Params'
import { isNullOrUndefined } from '../../utils/Boolean'
import { useHistory } from 'react-router-dom'

const SORT = 'dthLancamento'

const DEFAULT_PARAM = {
  id: null,
  hrInicial: null,
  hrFinal: null,
  dthLancamento: null,
  txtDescricao: '',
  sigAtividade: '',
  indFinalizado: false,
  desUsuario: '',
  idActivity: 0,
}

const TABLE_HEADER = [
  'Cliente',
  'Projeto',
  'Atividade',
  'Sigla',
  'Data',
  'Hora Inicial',
  'Hora Final',
  'Tempo',
  'Usuário',
]

const TABLE_HEADER_INDEX_ORD = [{ index: 4, typeOrd: 0 }]

const presentOrder = [
  'descClient',
  'desProjeto',
  'desAtividade',
  'sigAtividade',
  'dthLancamento',
  'hrInicial',
  'hrFinal',
  'totalTime',
  'desUsuario',
]

const optionsSobreAviso = [
  { label: 'Não', value: false },
  { label: 'Sim', value: true },
]

const user = JSON.parse(localStorage.getItem('user'))
const { id: idUsuario } = JSON.parse(localStorage.getItem('user')) || {}

const EntryADM = ({
                    /** Actions */
                    getClients,
                    getProjects,
                    getActivities,
                    saveActivities,
                    getFilter,
                    getPeriods,
                    get,
                    remove,
                    saveStatusHistory,
                    save,
                    update,
                    markEntryReportFilterAsValidToApply,
                    getUsers,
                    /** Reducer props */
                    listClients,
                    listFilterEntry,
                    listProjects,
                    listActivities,
                    listPeriods,
                    list,
                    error,
                    success,
                    totalElementsEntry,
                    totalHours,
                    listUsers,
                  }) => {
  momentDurationFormatSetup(moment)
  const styles = globalStyles()
  const history = useHistory()

  const lastSelectedClientId = useRef('')
  const lastSelectedProjectId = useRef('')

  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_RELEASES_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState('Novo lançamento')
  const [showLoading, setShowLoading] = useState(false)

  const [showToast, setShowToast] = useState(false)
  const [formInputsDisabled, setFormInputsDisabled] = useState(false)


  const [totalColumnTimeFormatted, setTotalColumnTimeFormatted] = useState('')
  const [selectState, setSelectState] = useState({ fields: [] })
  const [entries, setEntries] = useState([])
  const [objFilter, setObjFilter] = useState(null)
  const [sortingOtions, setSortingOtions] = useState(TABLE_HEADER_INDEX_ORD)

  const hasParams = window.location.search !== ''
  const parsedParams = getObjectFromParams(window.location.search)

  const resolveUser = () => {
    const { idUser = '' } = parsedParams || {}
    if (idUser) return +idUser
    return idUsuario
  }

  const [valuesFilter, setValuesFilter] = useState({
    idUser: resolveUser(),
    dtInicial: hasParams ? '' : moment(new Date()).format('LL'),
    dtFinal: hasParams ? '' : moment(new Date()).format('LL'),
  })

  const [iniDateFilter, setIniDateFilter] = useState(new Date(2000, 1, 1))
  const [endDateFilter, setEndDateFilter] = useState(new Date(2100, 1, 1))

  const [sendingForm, setSendingForm] = useState(false)
  const [searchingActivities, setSearchingActivities] = useState(true)

  const [dragModalDisabled, setDragModalDisabled] = useState(false)

  const getDateFromParams = dateParam => {
    const resolvedDate = parsedParams[dateParam]
    const momentDate = moment(resolvedDate, 'YYYY-MM-DD')

    return momentDate.isValid()
      ? momentDate.format('YYYY-MM-DDTHH:mm:ss')
      : null
  }

  useEffect(() => {
    if (hasParams) {
      const parsedInitialDate = getDateFromParams('dtInicial')
      const parsedFinalDate = getDateFromParams('dtFinal')

      handleFilter({
        ...parsedParams,
        dtInicial: parsedInitialDate,
        dtFinal: parsedFinalDate,
      })
    }

    if (!hasParams) {
      if (Object.keys(valuesFilter).length !== 0) {
        handleFilter(valuesFilter)
      }
    }
  }, [])

  const handleBackToEntryReportPage = () => {
    markEntryReportFilterAsValidToApply()
    history.push('/entry-report')
  }

  const handleFilter = values => {
    console.log(values.idUser)
    const {
      idClient,
      idProject,
      idUser,
      sigAtividade,
      indSobreaviso,
      dtInicial,
      dtFinal,
    } = values
    const resolvedDtInicial = moment(dtInicial).isValid()
      ? moment.utc(values.dtInicial).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    const resolvedDtFinal = moment(dtFinal).isValid()
      ? moment.utc(values.dtFinal).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    setValuesFilter({
      ...values,
      dtInicial: resolvedDtInicial,
      dtFinal: resolvedDtFinal,
    })

    setIniDateFilter(values.dtInicial || undefined)
    setEndDateFilter(values.dtFinal || undefined)

    let sortingOtionsLocal = sortingOtions
    sortingOtionsLocal[0].typeOrd = 0

    setSortingOtions(sortingOtionsLocal)

    let objFilterLocal = {
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: size,
      sort: SORT,
      keySortFilter: 1,
      typeSorting: 0,
      ...(idClient && { idClient }),
      ...(!isNullOrUndefined(indSobreaviso) && { indSobreaviso }),
      ...(idProject && { idProject }),
      ...(idUser && { idUser }),
      ...(sigAtividade && { sigActivity: sigAtividade }),
      ...(dtInicial && { dtInicial: resolvedDtInicial }),
      ...(dtFinal && { dtFinal: resolvedDtFinal }),
      // ...(sigAtividade && { sigActivity: sigAtividade }),

    }
    setObjFilter(objFilterLocal)
    getFilter(objFilterLocal)

    setPage(DEFAULT_PAGINATION_PAGE)
  }

  const handleChangeFilterState = state => {
    if (
      !state.fields.idClient ||
      state.fields.idClient !== lastSelectedClientId.current
    ) {
      state.fields.idProject = null
      state.fields.sigAtividade = null
    }

    if (
      state.fields.idProject &&
      state.fields.idProject !== lastSelectedProjectId.current
    ) {
      state.fields.sigAtividade = null
    }

    setSelectState(state)
    lastSelectedClientId.current = state.fields.idClient
    lastSelectedProjectId.current = state.fields.idProject
  }

  const uniqueOptions = new Set();
  const options = listActivities
  .filter(activity => {
    if (selectState.fields.idProject) {
      return activity.projectId === selectState.fields.idProject;
    }
    if (selectState.fields.idClient) {
      const client = listClients.find(it => it.id === selectState.fields.idClient);
      return !!client && activity.descClient === client.descCliente;
    }
    return true;
  })
  .map(activity => {
    // Verifica se a atividade já existe no conjunto de valores únicos
    if (activity && !uniqueOptions.has(activity.sigAtividade.trim())) {
      uniqueOptions.add(activity.sigAtividade.trim()); // Adiciona a atividade ao conjunto
      return {
        label: activity.sigAtividade,
        value: activity.sigAtividade,
      };
    }
    return null; // Retorna null para indicar uma opção repetida
  })
  .filter(option => option !== null);

  let filterProps = {
    onSubmit: handleFilter,
    isEntryAdm: true,
    fields: [
      {
        type: 'select',
        name: 'idClient',
        label: 'Cliente',
        initial: valuesFilter.idClient,
        options: (listClients || []).map(({ id, descCliente }) => ({
          label: descCliente,
          value: id,
        })),
      },
      {
        type: 'select',
        name: 'idProject',
        label: 'Projeto',
        initial: valuesFilter.idProject,
        options: (listProjects || [])
          .filter(project => project.idClient === selectState.fields.idClient)
          .map(({ id, desProjeto }) => ({
            label: desProjeto,
            value: id,
          })),
        disabled: !selectState.fields.idClient,
      },
      {
        type: 'select',
        name: 'sigAtividade',
        label: 'Sigla Atividades',
        initial: valuesFilter.sigAtividade,
        options:options,
        disabled: !selectState.fields.idClient,
      },
      {
        type: 'select',
        name: 'idUser',
        label: 'Usuário',
        initial: valuesFilter.idUser,
        options: (listUsers || [])
          .sort((userA, userB) =>
            userA.desUsuario.localeCompare(userB.desUsuario),
          )
          .map(({ id, desUsuario }) => ({
            label: desUsuario,
            value: id,
          })),
        disabled: user.office.tipCargo === 'OPERAÇÃO',
      },
      {
        type: 'select',
        name: 'indSobreaviso',
        label: 'Sobreaviso',
        initial: valuesFilter.indSobreaviso,
        options: optionsSobreAviso,
      },
    ],

    fieldsLine2: [
      {
        type: 'date',
        name: 'dtInicial',
        label: 'Data inicial',
        initial: valuesFilter.dtInicial,
        maxDate: endDateFilter,
        onChange: e => setIniDateFilter(e || null),
      },
      {
        type: 'date',
        name: 'dtFinal',
        label: 'Data final',
        initial: valuesFilter.dtFinal,
        minDate: iniDateFilter,
        onChange: e => setEndDateFilter(e || null),
      },
    ],

    monitoreState: handleChangeFilterState,
  }

  const handleSubmit = async (values, callback) => {
    if (values.tpSub === 'deletar') {
      setSendingForm(true)
      if (idUsuario && !values.idUser) {
        values.idUser = valuesFilter.idUser
      }

      if (values.hrInicial && values.hrFinal) {
        const { hrInicial, hrFinal } = values
        let totalHoras = moment.duration(
          moment(hrFinal, 'HH:mm:ss').diff(moment(hrInicial, 'HH:mm:ss')),
        )._milliseconds
        totalHoras = moment.duration(totalHoras, 'milliseconds').format('hh', 2)
        values.totalHoras = parseFloat(totalHoras)
      }

      const response = await onSubmit(values)

      const { tipStatus: newTipStatus } = values
      const { tipStatus: oldTipStatus } = listActivities.find(
        ({ id }) => values.idActivity === id,
      )

      if (
        response.type.indexOf('SUCCESS') !== -1 &&
        newTipStatus !== oldTipStatus
      ) {
        await novoRegistroStatusHistory(values)
      }

      setSendingForm(false)

      setShowToast(true)
      getActivities()
      setShowDialog(false)
      handleFilter(valuesFilter)
    } else if (values.tpSub === 'salvar') {
      setSendingForm(true)

      if ((idUsuario !== values.idUser) && selectState.fields.idUser) {
        values.idUser = selectState.fields.idUser
      }

      if (values.hrInicial && values.hrFinal) {
        const { hrInicial, hrFinal } = values
        let totalHoras = moment.duration(
          moment(hrFinal, 'HH:mm:ss').diff(moment(hrInicial, 'HH:mm:ss')),
        )._milliseconds
        totalHoras = moment.duration(totalHoras, 'milliseconds').format('hh', 2)
        values.totalHoras = parseFloat(totalHoras)
      }

      const response = await onSubmit(values)

      const { tipStatus: newTipStatus } = values
      const { tipStatus: oldTipStatus } = listActivities.find(
        ({ id }) => values.idActivity === id,
      )

      if (
        response.type.indexOf('SUCCESS') !== -1 &&
        newTipStatus !== oldTipStatus
      ) {
        await novoRegistroStatusHistory(values)
      }

      setSendingForm(false)

      setShowToast(true)
      getActivities()
      setShowDialog(false)
      handleFilter(valuesFilter)
    } else if (values.tpSub === 'duplicando') {
      setSendingForm(true)

      if ((idUsuario !== values.idUser) && selectState.fields.idUser) {
        values.idUser = selectState.fields.idUser
      }

      if (values.hrInicial && values.hrFinal) {
        const { hrInicial, hrFinal } = values
        let totalHoras = moment.duration(
          moment(hrFinal, 'HH:mm:ss').diff(moment(hrInicial, 'HH:mm:ss')),
        )._milliseconds
        totalHoras = moment.duration(totalHoras, 'milliseconds').format('hh', 2)
        values.totalHoras = parseFloat(totalHoras)
      }

      const response = await onSubmit(values)

      const { tipStatus: newTipStatus } = values
      const { tipStatus: oldTipStatus } = listActivities.find(
        ({ id }) => values.idActivity === id,
      )

      if (
        response.type.indexOf('SUCCESS') !== -1 &&
        newTipStatus !== oldTipStatus
      ) {
        await novoRegistroStatusHistory(values)
      }

      setSendingForm(false)

      setShowToast(true)
      getActivities()
      setShowDialog(false)
      handleFilter(valuesFilter)
      startDuplicated(values)
    } else if (values.tpSub === 'limpandoDuplicando') {
      setSendingForm(true)

      if ((idUsuario !== values.idUser) && selectState.fields.idUser) {
        values.idUser = selectState.fields.idUser
      }

      if (values.hrInicial && values.hrFinal) {
        const { hrInicial, hrFinal } = values
        let totalHoras = moment.duration(
          moment(hrFinal, 'HH:mm:ss').diff(moment(hrInicial, 'HH:mm:ss')),
        )._milliseconds
        totalHoras = moment.duration(totalHoras, 'milliseconds').format('hh', 2)
        values.totalHoras = parseFloat(totalHoras)
      }

      const response = await onSubmit(values)

      const { tipStatus: newTipStatus } = values
      const { tipStatus: oldTipStatus } = listActivities.find(
        ({ id }) => values.idActivity === id,
      )

      if (
        response.type.indexOf('SUCCESS') !== -1 &&
        newTipStatus !== oldTipStatus
      ) {
        await novoRegistroStatusHistory(values)
      }

      setSendingForm(false)

      setShowToast(true)
      getActivities()
      setShowDialog(false)
      handleFilter(valuesFilter)
      startSaveClean()
      callback()
    }
  }

  const changePage = page => {
    const { idClient, idProject, idUser, sigAtividade } = valuesFilter

    const dtInicial = moment(valuesFilter.dtInicial).isValid()
      ? moment.utc(valuesFilter.dtInicial).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    const dtFinal = moment(valuesFilter.dtFinal).isValid()
      ? moment.utc(valuesFilter.dtFinal).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    setIniDateFilter(valuesFilter.dtInicial || undefined)
    setEndDateFilter(valuesFilter.dtFinal || undefined)

    setPage(page)

    let keySortFilter = objFilter.keySortFilter
    let typeSorting = objFilter.typeSorting

    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      keySortFilter,
      typeSorting,
      ...(idClient && { idClient }),
      ...(idProject && { idProject }),
      ...(idUser && { idUser }),
      ...(dtInicial && { dtInicial }),
      ...(dtFinal && { dtFinal }),
      ...(sigAtividade && { sigAtividade }),
    })
  }

  const changeSize = size => {
    const { idClient, idProject, idUser, sigAtividade } = valuesFilter

    const dtInicial = moment(valuesFilter.dtInicial).isValid()
      ? moment.utc(valuesFilter.dtInicial).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    const dtFinal = moment(valuesFilter.dtFinal).isValid()
      ? moment.utc(valuesFilter.dtFinal).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    setIniDateFilter(valuesFilter.dtInicial || undefined)
    setEndDateFilter(valuesFilter.dtFinal || undefined)
    let keySortFilter = objFilter.keySortFilter
    let typeSorting = objFilter.typeSorting

    setSize(size)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      keySortFilter,
      typeSorting,
      ...(idClient && { idClient }),
      ...(idProject && { idProject }),
      ...(idUser && { idUser }),
      ...(dtInicial && { dtInicial }),
      ...(dtFinal && { dtFinal }),
      ...(sigAtividade && { sigAtividade }),
    })
  }

  /**
   * FUNÇÃO PARA ADICIONAR UM NOVO REGISTRO NA TABELA DVL_HISTORICO_STATUS
   *
   */
  const novoRegistroStatusHistory = async values => {
    const registrotatusHistory = listFilterEntry
      .filter(entry => entry.id === values.id)
      .map(entry => ({
        userId: entry.idUser,
        entryId: entry.id,
        activityId: entry.idActivity,
        tipStatusNew: values.tipStatus,
        dthLancamento: moment().format('YYYY-MM-DDTHH:mm:ss'),
      }))
    await saveStatusHistory(registrotatusHistory[0])
  }

  const startSave = () => {
    setParam(convertRecord(DEFAULT_PARAM))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo lançamento')

    setShowDialog(true)
  }

  const startSaveClean = () => {
    setParam(convertRecordClean(DEFAULT_PARAM))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo lançamento')

    setShowDialog(true)
  }

  const convertRecord = record => {
    let hrInicial = null
    let hrFinal = null
    let dthLancamento = null
    let atividadeNaoProprietaria = false
    let colorIcon = 'inherit'

    if (user.office.tipCargo !== 'ADMINISTRADOR' && (record.idUser !== undefined && record.idUser !== user.id)) {
      atividadeNaoProprietaria = true
      colorIcon = 'disabled'
    }

    if (moment(record.dthLancamento, 'DD/MM/YYYY').isValid()) {
      dthLancamento = moment(record.dthLancamento, 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ssZ',
      )

      hrInicial = moment(record.hrInicial, 'HH:mm:ss').isValid()
        ? moment(
          record.dthLancamento + record.hrInicial,
          'DD/MM/YYYYHH:mm:ss',
        ).format('YYYY-MM-DDTHH:mm:ss')
        : record.hrInicial

      hrFinal = moment(record.hrFinal, 'HH:mm:ss').isValid()
        ? moment(
          record.dthLancamento + record.hrFinal,
          'DD/MM/YYYYHH:mm:ss',
        ).format('YYYY-MM-DDTHH:mm:ss')
        : record.hrFinal
    }

    return {
      ...record,
      hrInicial,
      hrFinal,
      dthLancamento,
      indFinalizado: record.indFinalizado === 'Sim',
      idActivity: record.idActivity || 0,
      atividadeNaoProprietaria: atividadeNaoProprietaria,
      colorIcon: colorIcon,
      activity:
        listActivities.find(activity => activity.id === record.idActivity) ||
        [],
    }
  }

  const convertRecordClean = record => {
    let hrInicial = null
    let hrFinal = null
    let dthLancamento = null
    let atividadeNaoProprietaria = false
    let colorIcon = 'inherit'

    if (user.office.tipCargo !== 'ADMINISTRADOR' && (record.idUser !== undefined && record.idUser !== user.id)) {
      atividadeNaoProprietaria = true
      colorIcon = 'disabled'
    }

    if (moment(record.dthLancamento, 'DD/MM/YYYY').isValid()) {
      dthLancamento = moment(record.dthLancamento, 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ssZ',
      )

      hrInicial = moment(record.hrInicial, 'HH:mm:ss').isValid()
        ? moment(
          record.dthLancamento + record.hrInicial,
          'DD/MM/YYYYHH:mm:ss',
        ).format('YYYY-MM-DDTHH:mm:ss')
        : record.hrInicial

      hrFinal = moment(record.hrFinal, 'HH:mm:ss').isValid()
        ? moment(
          record.dthLancamento + record.hrFinal,
          'DD/MM/YYYYHH:mm:ss',
        ).format('YYYY-MM-DDTHH:mm:ss')
        : record.hrFinal
    }

    return {
      ...record,
      hrInicial,
      hrFinal,
      dthLancamento,
      indFinalizado: record.indFinalizado === 'Sim',
      idActivity: 0,
      atividadeNaoProprietaria: atividadeNaoProprietaria,
      colorIcon: colorIcon,
      activity:
        listActivities.find(activity => activity.id === record.idActivity) ||
        [],
    }
  }

  const startDelete = record => {
    if (record.idUser === user.id || user.office.tipCargo === 'ADMINISTRADOR') {
      const openPeriods = listPeriods.filter(period => !period.indFechado)

      const checkDthLancamento = openPeriods.filter(period => {
        const dtaInicio = period.dtaInicio
          .split('/')
          .reverse()
          .join('-')
        const dtaFim = period.dtaFim
          .split('/')
          .reverse()
          .join('-')

        const dthLancamento = record.dthLancamento
          .split('/')
          .reverse()
          .join('-')

        return moment(dthLancamento).isBetween(
          moment(dtaInicio),
          moment(dtaFim),
          'days',
          '[]',
        )
      })

      if (checkDthLancamento.length > 0) {
        setParam(convertRecord(record))

        setIsDeleteAction(true)
        setPrimaryButtonLabel('Deletar')
        setPrimaryButtonColor('secondary')
        setOnSubmit(() => remove)
        setTitle(
          `Você deseja deletar o lançamento para o projeto ${record.desProjeto} no dia ${record.dthLancamento} das ${record.hrInicial} às ${record.hrFinal}?`,
        )
        setShowAlertDialog(false)
        setShowDialog(true)
      } else {
        setTitle('Não é possível excluir lançamento em período fechado')
        setShowAlertDialog(true)
      }
    }
  }

  const startEdit = record => {

    const isPeriodClosed = listPeriods
      .filter(period => period.indFechado).some(period => {
        const dtaInicio = period.dtaInicio
          .split('/')
          .reverse()
          .join('-')
        const dtaFim = period.dtaFim
          .split('/')
          .reverse()
          .join('-')
        return moment(record.dthLancamento
          .split('/')
          .reverse()
          .join('-'))
          .isBetween(
            moment(dtaInicio),
            moment(dtaFim),
            'days',
            '[]')
      })
    setFormInputsDisabled(isPeriodClosed)

    if (record.idUser === user.id || user.office.tipCargo === 'ADMINISTRADOR' || user.office.tipCargo === 'COORDENADOR') {

      setParam(convertRecord(record))

      setIsDeleteAction(false)
      setPrimaryButtonLabel('Alterar')
      setPrimaryButtonColor('primary')
      setOnSubmit(() => update)
      setTitle('Alterar lançamento')

      setShowAlertDialog(false)
      setShowDialog(true)
    }
  }

  const performSorting = (keyFilter, typeSorting) => {
    if (objFilter !== null) {
      let optionFilter = null
      if (keyFilter === presentOrder[4]) {
        optionFilter = 1
      } else if (keyFilter === presentOrder[5]) {
        optionFilter = 2
      } else if (keyFilter === presentOrder[6]) {
        optionFilter = 3
      }

      let objFilterLocal = {
        ...objFilter,
        keySortFilter: optionFilter,
        typeSorting,
      }
      getFilter(objFilterLocal)

      let sortingOtionsLocal = sortingOtions
      sortingOtionsLocal[0].typeOrd = typeSorting

      setObjFilter(objFilterLocal)
      setSortingOtions(sortingOtionsLocal)

      setPage(DEFAULT_PAGINATION_PAGE)
    }
  }

  const startDuplicated = record => {
    if (record.idUser === user.id || user.office.tipCargo === 'ADMINISTRADOR') {
      const duplicatedRecord = convertRecord({
        ...record,
        id: null,
        hrInicial: null,
        hrFinal: null,
      })
      setParam(duplicatedRecord)

      setIsDeleteAction(false)
      setPrimaryButtonLabel('Salvar')
      setPrimaryButtonColor('primary')
      setOnSubmit(() => save)
      setTitle('Duplicar lançamento')

      setShowAlertDialog(false)
      setShowDialog(true)
    }
  }

  useEffect(() => {
    setShowLoading(true)

    getClients()
    getProjects()
    getPeriods()

    getActivities().then(() => {
      setShowLoading(false)
      setSearchingActivities(false)
    })
  }, [])

  useEffect(() => {
    setTotalColumnTimeFormatted(totalHours)
  }, [entries])

  useEffect(() => {
    setEntries(
      listFilterEntry.map(entry => ({
        ...entry,
        hrFinal: entry.hrFinal
          ? moment(entry.hrFinal, 'HH:mm:ss').format('HH:mm')
          : null,
        hrInicial: entry.hrInicial
          ? moment(entry.hrInicial, 'HH:mm:ss').format('HH:mm')
          : null,
      })),
    )
  }, [listFilterEntry])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  return (
    <Main title='Lançamentos ADM'>
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <Grid item xs={12}>
            <Box
              component='div'
              className={styles.box}
              xs={12}
              display='flex'
              alignItems='flex-end'
              justifyContent={
                parsedParams.fromEntryReport ? 'space-between' : 'flex-end'
              }
            >
              {parsedParams.fromEntryReport && (
                <MatButton
                  variant='outlined'
                  onClick={handleBackToEntryReportPage}
                >
                  Voltar para Relatório de Lançamentos
                </MatButton>
              )}

              <Fab onClick={startSave} color='primary'>
                <AddIcon />
              </Fab>
            </Box>
            <Box component='div' className={styles.filter} xs={12}>
              <Filter {...filterProps} gridSizeLine2={3} />
            </Box>
            <TableContainer component={Paper}>
              <TablePagination
                isFromEntry={parsedParams.fromEntryReport ? false : true}
                titleTable='entry'
                count={totalElementsEntry}
                headers={TABLE_HEADER}
                headersIndexOrd={sortingOtions}
                onChangePage={(_, page) => changePage(page)}
                onChangeSize={event => changeSize(event.target.value)}
                page={page}
                rows={entries}
                size={size}
                startDelete={startDelete}
                startEdit={startEdit}
                startDuplicated={startDuplicated}
                performSorting={performSorting}
                order={presentOrder}
                customFooter={
                  entries.length !== 0 ? (
                    <>
                      <TableCell
                        colSpan={7}
                        style={{
                          textAlign: 'right',
                          paddingRight: '2.4%',
                        }}
                      >
                        {`Tempo total:`}
                      </TableCell>

                      <TableCell
                        colSpan={3}
                        style={{
                          textAlign: 'left',
                          paddingLeft: '2.8%',
                        }}
                      >
                        {`${totalColumnTimeFormatted}`}
                      </TableCell>
                    </>
                  ) : (
                    ''
                  )
                }
                alignTitle={[
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                ]}
                alignItens={[
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                  'center',
                ]}
              />
            </TableContainer>
          </Grid>

          <Dialog
            onClose={() => setShowDialog(false)}
            open={showDialog}
            title={title}
            isDragDisabled={dragModalDisabled}
          >
            <Form
              disabled={formInputsDisabled}
              activities={listActivities}
              periods={listPeriods}
              isDeleteAction={isDeleteAction}
              onCancel={() => setShowDialog(false)}
              onSubmit={handleSubmit}
              param={param}
              primaryButtonColor={primaryButtonColor}
              primaryButtonLabel={primaryButtonLabel}
              sendingForm={sendingForm}
              searchingActivities={searchingActivities}
              dataInicialFiltro={iniDateFilter}
              dataFinalFiltro={endDateFilter}
              onOpenDateModal={() => setDragModalDisabled(true)}
              onCloseDateModal={() => setDragModalDisabled(false)}
            />
          </Dialog>
          <Dialog
            onClose={() => setShowAlertDialog(false)}
            open={showAlertDialog}
            title={title}
          >
            <Button label='Fechar' onClick={() => setShowAlertDialog(false)} />
          </Dialog>

          <Toast
            error={error}
            onClose={() => setShowToast(false)}
            open={showToast}
            success={success}
          />
        </>
      )}
    </Main>
  )
}

const mapStateToProps = state => {
  const {
    activityReducer,
    loginReducer,
    clientReducer,
    projectReducer,
    userReducer,
    statusHistoryReducer,
    periodReducer,
    entryReducer,
  } = state

  return {
    ...activityReducer,
    ...loginReducer,
    ...clientReducer,
    ...projectReducer,
    ...userReducer,
    ...statusHistoryReducer,
    ...periodReducer,
    ...entryReducer,
    listUsers: userReducer.listUsers,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionEntry,
      getUsers,
      getActivities: actionActivity.findWithExpectedDelivery,
      saveActivities: actionActivity.update,
      saveStatusHistory: actionStatusHistory.save,
      getClients,
      getProjects,
      getPeriods,
      markEntryReportFilterAsValidToApply: markFilterAsValidToApply,
    },
    dispatch,
  )

EntryADM.propTypes = {
  /** Actions */
  getUsers: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  getActivities: PropTypes.func.isRequired,
  getPeriods: PropTypes.func.isRequired,
  saveActivities: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  saveStatusHistory: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  markEntryReportFilterAsValidToApply: PropTypes.func.isRequired,
  /** Reducer props */
  listClients: PropTypes.array.isRequired,
  listProjects: PropTypes.array.isRequired,
  listActivities: PropTypes.array.isRequired,
  listFilterEntry: PropTypes.array.isRequired,
  listPeriods: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired,
  error: PropTypes.string,
  success: PropTypes.string,
  totalElementsEntry: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryADM)
