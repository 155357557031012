import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Main from '../Main'
import Toast from '../../utils/Toast'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actionStatusHistory from '../../actions/statusHistory'
import { get as getClients } from '../../actions/client'
import * as actionActivity from '../../actions/activity'
import Filter from '../../utils/Filter/Filter'
import { globalStyles } from '../../styles/global'
import { Box } from '@material-ui/core'
import moment from 'moment'
import { TableContainer, Paper, Grid } from '@material-ui/core'
import TablePagination from '../../utils/Table/TablePagination'

import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'dthLancamento'

const StatusHistoryList = ({
  /** Actions */
  getFilter,
  get,
  getClients,
  /** Reducer props */
  listStatusHistoryFilter,
  errorStatusHistory,
  successStatusHistory,
  listClients,
  totalElementsStatusHistory,
}) => {
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)
  const [showToast, setShowToast] = useState(false)

  const [valuesFilter, setValuesFilter] = useState({ dt: [] })

  const handleFilter = values => {
    const {
      idClient,
      dt: [date1, date2],
    } = values

    const dtInicial =
      date1 instanceof Date && !isNaN(date1)
        ? moment.utc(date1).format('YYYY-MM-DDTHH:mm:ss')
        : ''

    const dtFinal =
      date2 instanceof Date && !isNaN(date2)
        ? moment.utc(date2).format('YYYY-MM-DDTHH:mm:ss')
        : ''

    setValuesFilter(values)

    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: size,
      sort: SORT,
      ...(dtInicial && { dtInicial }),
      ...(dtFinal && { dtFinal }),
      ...(idClient && { idClient }),
    })

    setPage(DEFAULT_PAGINATION_PAGE)
  }

  const filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'idClient',
        label: 'Cliente',
        options: (listClients || []).map(({ descCliente, id }) => ({
          label: descCliente,
          value: id,
        })),
      },
      {
        type: 'date_range',
        name: 'dt',
      },
    ],
  }

  const query = async () => {
    get()
    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      sort: SORT,
    })
    getClients()
  }

  useEffect(() => {
    query()
  }, [])

  const changePage = page => {
    const {
      idClient,
      dt: [date1, date2],
    } = valuesFilter

    const dtInicial =
      date1 instanceof Date && !isNaN(date1)
        ? moment.utc(date1).format('YYYY-MM-DDTHH:mm:ss')
        : ''

    const dtFinal =
      date2 instanceof Date && !isNaN(date2)
        ? moment.utc(date2).format('YYYY-MM-DDTHH:mm:ss')
        : ''

    setPage(page)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(dtInicial && { dtInicial }),
      ...(dtFinal && { dtFinal }),
      ...(idClient && { idClient }),
    })
  }

  const changeSize = size => {
    const {
      idClient,
      dt: [date1, date2],
    } = valuesFilter

    const dtInicial =
      date1 instanceof Date && !isNaN(date1)
        ? moment.utc(date1).format('YYYY-MM-DDTHH:mm:ss')
        : ''

    const dtFinal =
      date2 instanceof Date && !isNaN(date2)
        ? moment.utc(date2).format('YYYY-MM-DDTHH:mm:ss')
        : ''

    setSize(size)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(dtInicial && { dtInicial }),
      ...(dtFinal && { dtFinal }),
      ...(idClient && { idClient }),
    })
  }

  return (
    <Main title="Histórico de Atividades">
      <Grid item xs={12}>
        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            doNotShow={['userId', 'activityId']}
            count={totalElementsStatusHistory}
            headers={[
              'Sequência Histórico',
              'Usuário',
              'Sequência Atividade',
              'Sequência Lançamento',
              'Data / Hora',
              'Novo Status',
            ]}
            onChangePage={(_, page) => changePage(page)}
            onChangeSize={event => changeSize(event.target.value)}
            page={page}
            rows={listStatusHistoryFilter}
            size={size}
            hasAction={false}
            alignTitle={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
            alignItens={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
          />
        </TableContainer>
      </Grid>

      <Toast
        error={errorStatusHistory}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successStatusHistory}
      />
    </Main>
  )
}

const mapStateToProps = ({
  statusHistoryReducer,
  activityReducer,
  clientReducer,
}) => ({
  ...statusHistoryReducer,
  ...activityReducer,
  ...clientReducer,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionStatusHistory,
      getActivity: actionActivity.get,
      getClients,
    },
    dispatch
  )

StatusHistoryList.propTypes = {
  /** Actions */
  getActivity: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  /** Reducer props */
  listActivities: PropTypes.array.isRequired,
  listClients: PropTypes.array.isRequired,
  listStatusHistory: PropTypes.array.isRequired,
  listStatusHistoryFilter: PropTypes.array.isRequired,
  errorStatusHistory: PropTypes.string,
  successStatusHistory: PropTypes.string,
  totalElementsStatusHistory: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusHistoryList)
