import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    rowAction: {
      cursor: 'pointer',
      width: 'fit-content',
      '&:hover div': {
        background: theme.palette.grey['300'],
      },
    },

    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      justifyItems: 'end',
      margin: '0 auto',
      marginRight: '0px',
    },

    rowDetail: {
      marginRight: '10px',
      padding: '4px 8px',
      border: `1px solid ${theme.palette.grey['300']}`,
      transition: '0.1s',
      position: 'relative',
      height: '55px',
      width: '150px',
      cursor: 'pointer',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
      borderRadius: 4,
    },

    select: {
      display: 'flex',
      width: '200px',
      backgroundColor: '#f0f0f0',
      border: '1px solid #ccc',
      padding: '8px',
      borderRadius: '4px',
    },
    iconWarning: {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
    },
    greenIcon: {
      width: '30px',
      height: '30px',
      backgroundColor: 'green',
      display: 'flex',
      color: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      position: 'relative',
    },
    redIcon: {
      width: '30px',
      height: '30px',
      backgroundColor: 'red',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      position: 'relative',
    },
    yellowIcon: {
      width: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      position: 'relative',
    },
    tableHeader: {
      backgroundColor: '#3f51b5',
      background: '#3f51b5',
    },

    detailsButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      padding: '4px 8px',
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey['300']}`,
      transition: '0.1s',
      cursor: 'pointer',
    },
    totalTime: {
      textAlign: 'center',
      position: 'relative',
      '& span': {
        position: 'absolute',
        left: '-15px',
      },
      '@media screen and (max-width: 1000px)': {
        '& span': {
          left: '-100%',
        },
      },
    },
  }
})

export default useStyles
