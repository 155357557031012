  export const MONTH = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
];

export const ORDER = [
  'nome',
  'dataAniversario',
  'dataAdmissao',
  'tempoEmpresa',
]


export const TABLE_HEADER = [
  'Nome',
  'Data de Aniversário',
  'Data de Admissão',
  'Tempo de Empresa',
]

export const TABLE_HEADER_INDEX_ORD = [
  { index: 1, typeOrd: 0},
  { index: 2, typeOrd: 0 },
]

const monthOptions = MONTH.map(item => ({
  value: item,
  label: item,
}))




export const FILTER_FIELDS = [
  {
    type: 'select',
    name: 'dateBirthday',
    label: 'Período de Aniversário',
    options: monthOptions,
  },
  {
    type: 'select',
    name: 'dateHiring',
    label: 'Período de Admissão',
    options: monthOptions,
  },
]