import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import CheckboxComponent from '../../Form/Checkbox'

const Checkbox = ({ name, label, setValueToField, stateValues, disabled }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <CheckboxComponent
        onChange={() =>
          setValueToField(name, stateValues ? !stateValues : true)
        }
        label={label}
        checked={stateValues || false}
        disabled={disabled}
      />
    </FormControl>
  )
}

export default Checkbox
