import { types } from '../../config/action_types'

const defaultState = {
  clients: [],
  client: {},
  listClients: [],
  listClientsFilter: [],

  errorClients: null,
  successClients: null,

  totalElementsClients: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.client.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        listClientsFilter: resultList || [],
        totalElementsClients: pages.totalCount || resultList.length,
      }

    case types.client.getFilter.error:
      return {
        ...state,
        errorClients: 'Erro ao buscar Clientes',
        successClients: null,
      }

    case types.client.get.success:
      return {
        ...state,
        clients: action.payload.content || action.payload,
        client: action.payload.content || action.payload,
        listClients: action.payload.content || action.payload,
      }

    case types.client.get.error:
      return {
        ...state,
        errorClients: 'Erro ao buscar Clientes',
        successClients: null,
      }

    case types.client.save.success:
      return {
        ...state,
        successClients: 'Cliente salvo com sucesso',
        errorClients: null,
      }

    case types.client.save.error:
      return {
        ...state,
        errorClients: 'Erro ao salvar Cliente',
        successClients: null,
      }

    case types.client.delete.success:
      return {
        ...state,
        successClients: 'Cliente deletado com sucesso',
        errorClients: null,
      }

    case types.client.delete.error:
      const { messageError } = action.payload.response.data.message

      return {
        ...state,
        errorClients: messageError,
        successClients: null,
      }

    case types.client.update.success:
      return {
        ...state,
        successClients: 'Cliente atualizado com sucesso',
        errorClients: null,
      }

    case types.client.update.error:
      return {
        ...state,
        errorClients: 'Erro ao atualizar Cliente',
        successClients: null,
      }

    default:
      return state
  }
}
