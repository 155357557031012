import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useFormik } from 'formik'
import moment from 'moment'
import { Box, TextField } from '@material-ui/core/'
import { Autocomplete } from '@material-ui/lab'
import * as proposalActions from '../../../actions/proposal'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import Datepicker from '../../../utils/Form/Datepicker'
import Button from '../../../utils/Form/Button'
import { Loader } from '../../../utils/Loading'
import { validateValues } from './validations'
import Toast from '../../../utils/Toast'
import { MODELOS_PROPOSTA, STATUS_PROPOSTA } from '../../../../constants'
import { types } from '../../../../config/action_types'
import useFeedback from '../../../utils/hooks/useFeedback'
import { replaceInvalidCharsPersonName } from '../../../utils/String'

const NO_OPTIONS_TEXT = 'Sem opções'

const getDateTime = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  return date
}

const DEFAULT_FORMIK_VALUES = {
  id: '',
  client: null,
  proposalCode: '',
  proposalName: '',
  model: null,
  status: null,
  startDate: getDateTime(),
  endDate: getDateTime(),
  managerName: '',
  project: null,
  projectSAP: '',
  sri: '',
  indSubstituto: null,
}

const Form = props => {
  const {
    proposalToEdit = null,
    listClients = [],
    listProjects = [],
    listProposal = [],
    saveProposal,
    fetchProposals,
    updateProposal,
    handleCloseForm,
    onOpenDateModal,
    onCloseDateModal,
  } = props

  const isProposalEdit = !!proposalToEdit

  const { feedback, hasFeedback, setError, clearFeedback } = useFeedback()

  const [isLoading, setIsLoading] = useState(false)

  const handleResponse = async response => {
    const { type, payload: message } = response
    const hasError =
      type === types.proposal.save.error || type === types.proposal.update.error

    if (hasError) {
      setError(message)
      return
    }

    handleCloseForm(message)
  }

  const handleSubmit = async values => {
    setIsLoading(true)
    const { projectSAP, project } = values

    const proposal = {
      id: values.id,
      indSubstituto: values.indSubstituto,
      codProposta: values.proposalCode,
      codSRI: values.sri,
      desGestor: values.managerName,
      desProposta: values.proposalName,
      dtaInicio: moment(values.startDate).format('DD/MM/YYYY'),
      dtaFim: moment(values.endDate).format('DD/MM/YYYY'),
      seqCliente: values.client.id,
      tipModelo: values.model.id,
      tipStatus: values.status.id,
      ...(!!project && { seqProjeto: project.id }),
      ...(projectSAP && { desProjetoSAP: values.projectSAP }),
    }

    let response
    if (isProposalEdit) {
      response = await updateProposal(proposal)
    }

    if (!isProposalEdit) {
      response = await saveProposal(proposal)
    }

    await handleResponse(response)
    setIsLoading(false)
  }

  const resolveInitialValues = () => {
    if (isProposalEdit) {
      const proposal = listProposal.find(p => p.id === proposalToEdit)

      if (!proposal) return DEFAULT_FORMIK_VALUES

      const client = listClients.find(c => c.id === proposal.seqCliente)
      const project = listProjects.find(p => p.id === proposal.seqProjeto)
      const model = MODELOS_PROPOSTA.find(m => m.id === proposal.tipModelo)
      const status = STATUS_PROPOSTA.find(s => s.id === proposal.tipStatus)

      return {
        id: proposal.id,
        client,
        proposalCode: proposal.codProposta,
        proposalName: proposal.desProposta,
        model,
        status,
        startDate: moment(proposal.dtaInicio).toDate(),
        endDate: moment(proposal.dtaFim).toDate(),
        managerName: proposal.desGestor,
        project,
        projectSAP: proposal.desProjetoSAP,
        sri: proposal.codSRI,
        indSubstituto: proposal.indSubstituto,
      }
    }

    return DEFAULT_FORMIK_VALUES
  }

  const formik = useFormik({
    initialValues: resolveInitialValues(),
    validateOnChange: false,
    validate: validateValues,
    onSubmit: handleSubmit,
  })

  const resolveProjectOptions = () => {
    const { client } = formik.values
    if (!client) return []

    return listProjects.filter(p => p.idClient === client.id)
  }

  const resolveProjectNoOptionsText = () => {
    const { client } = formik.values
    const clientHasProjects = !!resolveProjectOptions().length
    if (client && !clientHasProjects) {
      return `O cliente não possui projetos`
    }

    if (!client) return 'Informe um cliente'

    return NO_OPTIONS_TEXT
  }

  useEffect(() => {
    return () => fetchProposals()
  }, [])

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <FlexWrapper>
            <FlexItemWrapper style={{ width: '66.6%' }}>
              <Autocomplete
                id="client"
                name="client"
                fullWidth
                noOptionsText={NO_OPTIONS_TEXT}
                options={listClients}
                getOptionLabel={(option = {}) => option.descCliente}
                value={formik.values.client}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Cliente"
                    error={!!formik.errors.clientError}
                    helperText={formik.errors.clientError}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('client', option).then(() => {
                    formik.setFieldError('clientError', '')
                  })
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <TextField
                id="proposalCode"
                name="proposalCode"
                label="Proposta"
                fullWidth
                inputProps={{
                  maxLength: 20,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('proposalCode', value).then(() => {
                    formik.setFieldError('proposalCodeError', '')
                  })
                }}
                value={formik.values.proposalCode || ''}
                error={!!formik.errors.proposalCodeError}
                helperText={formik.errors.proposalCodeError}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                id="proposalName"
                name="proposalName"
                label="Nome da proposta"
                fullWidth
                inputProps={{
                  maxLength: 255,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('proposalName', value).then(() => {
                    formik.setFieldError('proposalNameError', '')
                  })
                }}
                value={formik.values.proposalName || ''}
                error={!!formik.errors.proposalNameError}
                helperText={formik.errors.proposalNameError}
              />
            </FlexItemWrapper>
            <FlexItemWrapper middle>
              <Autocomplete
                id="model"
                name="model"
                fullWidth
                noOptionsText={NO_OPTIONS_TEXT}
                options={MODELOS_PROPOSTA}
                getOptionLabel={(option = {}) => option.description}
                value={formik.values.model}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Modelo"
                    error={!!formik.errors.modelError}
                    helperText={formik.errors.modelError}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('model', option).then(() => {
                    formik.setFieldError('modelError', '')
                  })
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Autocomplete
                id="status"
                name="status"
                fullWidth
                noOptionsText={NO_OPTIONS_TEXT}
                options={STATUS_PROPOSTA}
                getOptionLabel={(option = {}) => option.description}
                value={formik.values.status}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Status"
                    error={!!formik.errors.statusError}
                    helperText={formik.errors.statusError}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('status', option).then(() => {
                    formik.setFieldError('statusError', '')
                  })
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <Datepicker
                id="startDate"
                name="startDate"
                label="Data início"
                value={formik.values.startDate}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={time =>
                  formik.setFieldValue('startDate', time).then(() => {
                    formik.setFieldError('startDateError', '')
                  })
                }
                error={!!formik.errors.startDateError}
                helperText={formik.errors.startDateError}
              />
            </FlexItemWrapper>
            <FlexItemWrapper middle>
              <Datepicker
                id="endDate"
                name="endDate"
                label="Data final"
                value={formik.values.endDate}
                onOpen={onOpenDateModal}
                onClose={onCloseDateModal}
                onChange={time =>
                  formik.setFieldValue('endDate', time).then(() => {
                    formik.setFieldError('endDateError', '')
                  })
                }
                error={!!formik.errors.endDateError}
                helperText={formik.errors.endDateError}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <TextField
                id="managerName"
                name="managerName"
                label="Nome do gestor"
                fullWidth
                inputProps={{
                  maxLength: 255,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue(
                    'managerName',
                    replaceInvalidCharsPersonName(value)
                  )
                }}
                value={formik.values.managerName || ''}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <Autocomplete
                id="project"
                name="project"
                fullWidth
                noOptionsText={resolveProjectNoOptionsText()}
                options={resolveProjectOptions()}
                getOptionLabel={(option = {}) => option.desProjeto}
                value={formik.values.project}
                renderInput={params => (
                  <TextField {...params} label="Projeto" />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('project', option)
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper middle>
              <TextField
                id="projectSAP"
                name="projectSAP"
                label="Projeto SAP"
                fullWidth
                inputProps={{
                  maxLength: 50,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('projectSAP', value)
                }}
                value={formik.values.projectSAP || ''}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right style={{ width: '10%' }}>
              <TextField
                id="sri"
                name="sri"
                label="SRI"
                inputProps={{
                  maxLength: 50,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('sri', value)
                }}
                value={formik.values.sri || ''}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right style={{ width: '10%' }}>
              <FormControlLabel
                label="Possui Substituto"
                // style={{ paddingTop: 13 }}
                control={
                  <Checkbox
                    id="indSubstituto"
                    name="indSubstituto"
                    label="indSubstituto"
                    color="primary"
                    checked={formik.values.indSubstituto}
                    onChange={(event) => { formik.setFieldValue('indSubstituto', event.target.checked) }}
                    value={formik.values.indSubstituto}
                  />
                }
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <br />
          <FlexWrapper>
            <FlexItemWrapper>
              <Button label="Cancelar" onClick={() => handleCloseForm()} />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Button color="primary" label="Salvar" type="submit" />
            </FlexItemWrapper>
          </FlexWrapper>
          <br />
        </form>
      </Box>
      <Toast
        error={feedback.error}
        success={feedback.success}
        onClose={clearFeedback}
        open={hasFeedback}
      />
    </>
  )
}

const mapStateToProps = state => {
  const {
    clientReducer,
    projectReducer,
    periodReducer,
    proposalReducer,
  } = state

  return {
    ...clientReducer,
    ...projectReducer,
    ...periodReducer,
    ...proposalReducer,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...proposalActions,
      fetchProposals: proposalActions.fetchProposals
    },
    dispatch
  )

Form.propTypes = {
  onOpenDateModal: PropTypes.func.isRequired,
  onCloseDateModal: PropTypes.func.isRequired,
  /** Actions */
  saveProposal: PropTypes.func.isRequired,
  fetchProposals: PropTypes.func.isRequired,
  /** Reducer props */
  listClients: PropTypes.array.isRequired,
  listProjects: PropTypes.array.isRequired,
  listPeriods: PropTypes.array.isRequired,
  listProposal: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
