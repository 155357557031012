import Http from '../../config/Http'
import URL from '../../config/URL'
import { trigger, types } from '../../config/action_types'
import { DEFAULT_PAGINATION_PAGE } from '../../constants/pagination'
import moment from 'moment'

export const fetchProposals = () => async (dispatch, getState) => {
  const {
    proposalReducer: { filterParams },
  } = getState()
  try {
    const response = await new Http(dispatch).get(URL.proposal + '/filter', {
      ...filterParams,
      pageFilter: filterParams.pageFilter + 1,
      sort: 'codProposta asc',
    })
    return dispatch(trigger(types.proposal.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.proposal.getFilter.error, error))
  }
}

export const duplicateProposal = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.proposal + `/duplicate/${param.id}`, param)

    return dispatch(
      trigger(types.proposal.save.success, 'Proposta duplicada com sucesso')
    )
  } catch {
    return dispatch(
      trigger(types.proposal.save.error, 'Erro ao duplicar proposta')
    )
  }
}

export const fetchAllProposals = () => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.proposal)
    return dispatch(trigger(types.proposal.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.proposal.get.error))
  }
}

export const fetchProposalOptions = () => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.proposal)
    return dispatch(trigger(types.proposal.getOptions.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.proposal.getOptions.error))
  }
}

export const setProposalFilterParams = params => async dispatch => {
  const { seqCliente, tipModelo, tipStatus, dtaInicio, dtaFim, proposalCode, projectId } = params
  dispatch(
    trigger(types.proposal.updateFilter, {
      ...(!!seqCliente && { seqCliente }),
      ...(!!tipModelo && { tipModelo }),
      ...(!!tipStatus && { tipStatus }),
      ...(!!proposalCode && { proposalCode }),
      ...(!!projectId && { projectId }),
      ...(!!dtaInicio && { dtaInicio: moment(dtaInicio).format('DD/MM/YYYY') }),
      ...(!!dtaFim && { dtaFim: moment(dtaFim).format('DD/MM/YYYY') }),
      pageFilter: DEFAULT_PAGINATION_PAGE,
    })
  )
  dispatch(fetchProposals())
}

export const changePage = newPage => async (dispatch, getState) => {
  const { proposalReducer } = getState()
  dispatch(
    trigger(types.proposal.updateFilter, {
      ...proposalReducer.filterParams,
      pageFilter: newPage,
    })
  )
  dispatch(fetchProposals())
}

export const changePageSize = newPageSize => async (dispatch, getState) => {
  const { proposalReducer } = getState()
  dispatch(
    trigger(types.proposal.updateFilter, {
      ...proposalReducer.filterParams,
      sizeFilter: newPageSize,
      pageFilter: DEFAULT_PAGINATION_PAGE,
    })
  )
  dispatch(fetchProposals())
}

export const saveProposal = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.proposal, param)

    return dispatch(
      trigger(types.proposal.save.success, 'Proposta salva com sucesso')
    )
  } catch {
    return dispatch(
      trigger(types.proposal.save.error, 'Erro ao salvar proposta')
    )
  }
}

export const removeProposal = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.proposal, param)

    return dispatch(
      trigger(types.proposal.delete.success, 'Proposta excluída com sucesso')
    )
  } catch (error) {
    return dispatch(
      trigger(types.proposal.delete.error, 'Erro ao excluir proposta')
    )
  }
}

export const updateProposal = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.proposal, param)

    return dispatch(
      trigger(types.proposal.update.success, 'Proposta atualizada com sucesso')
    )
  } catch (error) {
    return dispatch(
      trigger(types.proposal.update.error, 'Erro ao atualizar proposta')
    )
  }
}

export const cleanToasterProposalRd = () => ({
  type: types.proposal.cleanToasterProposalRd,
  payload: null,
})
