import React from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import MaterialCheckbox from '@material-ui/core/Checkbox'

/**
 * Default checkbox
 */
const Checkbox = ({
  disabled = false,
  checked,
  color = 'primary',
  label,
  name,
  onChange,
}) => {
  return (
    <FormControlLabel
      control={
        <MaterialCheckbox
          disabled={disabled}
          checked={checked}
          color={color}
          name={name}
          onChange={event => onChange(event.target.checked)}
        />
      }
      label={label}
      style={{ margin: 0 }}
    />
  )
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.number.isRequired,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Checkbox
