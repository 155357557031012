import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import AddIcon from '@material-ui/icons/Add'
import { TableContainer, Paper, Box, Fab, Grid } from '@material-ui/core'

import * as actionPeriod from '../../actions/period'

import Form from './components/Form'
import Main from '../Main'
import { globalStyles } from '../../styles/global'
import TablePagination from '../../utils/Table/TablePagination'
import Dialog from '../../utils/Dialog'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/Filter'
import { convertDate } from '../../utils/Date'
import { convertDecimal } from '../../utils/Decimal'
import { convertInteger } from '../../utils/Integer'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'desPeriodo'

const DEFAULT_PARAM = {
  id: null,
  desPeriodo: '',
  dtaInicio: '',
  dtaFim: '',
  indFechado: false,
  qtdDiasUteis: 1,
  qtdHorasPrevClt: 1,
  qtdHorasPrevEstagio: 1,
  dtaFeriado1: null,
  dtaFeriado2: null,
  dtaFeriado3: null,
  dtaFeriado4: null,
  liberado: false
}

const doNotShowTable = [
  'id',
  'qtdDiasUteis',
  'qtdHorasPrevClt',
  'qtdHorasPrevEstagio',
  'dtaFeriado1',
  'dtaFeriado2',
  'dtaFeriado3',
  'dtaFeriado4',
]

const headersTable = ['Descrição', 'Data inicial', 'Data final', 'Fechado', 'Liberado']

const Period = ({
  /** Actions */
  getFilter,
  get,
  remove,
  save,
  update,
  /** Reducer props */
  listPeriodsFilter,
  listPeriods,
  errorPeriod,
  successPeriod,
  totalElementsPeriods,
}) => {
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState('Novo período')

  const [showToast, setShowToast] = useState(false)

  const [valuesFilter, setValuesFilter] = useState({})

  const [dragModalDisabled, setDragModalDisabled] = useState(false)

  const handleFilter = values => {
    const { desPeriodo } = values

    setValuesFilter(values)

    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: size,
      sort: SORT,
      ...(desPeriodo && { desPeriodo }),
    })

    setPage(DEFAULT_PAGINATION_PAGE)
  }

  let filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'desPeriodo',
        label: 'Descrição',
        options: listPeriods.map(period => ({
          value: period.desPeriodo,
          label: period.desPeriodo,
        })),
      },
    ],
  }

  const query = async () => {
    get()
    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      sort: SORT,
    })
  }

  const changePage = page => {
    const { desPeriodo } = valuesFilter

    setPage(page)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(desPeriodo && { desPeriodo }),
    })
  }

  const changeSize = size => {
    const { desPeriodo } = valuesFilter

    setSize(size)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(desPeriodo && { desPeriodo }),
    })
  }

  const convertRecord = record => {
    return {
      ...record,
      dtaInicio: convertDate(record.dtaInicio),
      dtaFim: convertDate(record.dtaFim),
      indFechado: record.indFechado,
      liberado: record.liberado,
      qtdDiasUteis: convertInteger(record.qtdDiasUteis),
      qtdHorasPrevClt: convertDecimal(record.qtdHorasPrevClt),
      qtdHorasPrevEstagio: convertDecimal(record.qtdHorasPrevEstagio),
      dtaFeriado1: convertDate(record.dtaFeriado1),
      dtaFeriado2: convertDate(record.dtaFeriado2),
      dtaFeriado3: convertDate(record.dtaFeriado3),
      dtaFeriado4: convertDate(record.dtaFeriado4),
    }
  }

  const handleSubmit = async values => {
    await onSubmit(values)

    setShowDialog(false)

    setShowToast(true)

    handleFilter(valuesFilter)

    query()
  }

  const startSave = () => {
    setParam(convertRecord(DEFAULT_PARAM))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo período')

    setShowDialog(true)
  }

  const startDelete = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(true)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)
    setTitle(`Você deseja deletar o período ${record.desPeriodo}?`)

    setShowDialog(true)
  }

  const startEdit = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)
    setTitle('Alterar período')

    setShowDialog(true)
  }

  useEffect(() => {
    query()
  }, [])

  return (
    <Main title="Períodos">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={startSave} color="primary">
            <AddIcon />
          </Fab>
        </Box>

        <Box component="div" className={styles.filter} xs={6}>
          <Filter {...filterProps} />
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            titleTable="period"
            count={totalElementsPeriods}
            doNotShow={doNotShowTable}
            headers={headersTable}
            onChangePage={(_, page) => changePage(page)}
            onChangeSize={event => changeSize(event.target.value)}
            page={page}
            rows={listPeriodsFilter}
            size={size}
            startDelete={startDelete}
            startEdit={startEdit}
            alignTitle={['center', 'center', 'center', 'center']}
            alignItens={['center', 'center', 'center', 'center', 'center']}
          />
        </TableContainer>
      </Grid>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
        isDragDisabled={dragModalDisabled}
      >
        <Form
          isDeleteAction={isDeleteAction}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          primaryButtonColor={primaryButtonColor}
          primaryButtonLabel={primaryButtonLabel}
          onOpenDateModal={() => setDragModalDisabled(true)}
          onCloseDateModal={() => setDragModalDisabled(false)}
        />
      </Dialog>

      <Toast
        error={errorPeriod}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successPeriod}
      />
    </Main>
  )
}

const mapStateToProps = ({ periodReducer }) => ({ ...periodReducer })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actionPeriod }, dispatch)

Period.propTypes = {
  /** Actions */
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  /** Reducer props */
  listPeriodsFilter: PropTypes.array.isRequired,
  listPeriods: PropTypes.array.isRequired,
  errorPeriod: PropTypes.string,
  successPeriod: PropTypes.string,
  totalElementsPeriods: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Period)
