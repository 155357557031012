import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AddIcon from '@material-ui/icons/Add'
import { TableContainer, Paper, Box, Fab, Grid } from '@material-ui/core'

import * as projectActions from '../../actions/project'
import * as clientActions from '../../actions/client'

import Form from './components/Form'
import Main from '../Main'
import { globalStyles } from '../../styles/global'
import TablePagination from '../../utils/Table/TablePagination'
import Dialog from '../../utils/Dialog'
import Filter from '../../utils/Filter/Filter'
import Toast from '../../utils/Toast'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'id'

const DEFAULT_PARAM = {
  id: null,
  idClient: '',
  tipFaturamento: '',
  indStatus: '',
  desProjeto: '',
  desSigla: '',
  desLinguagemFront: '',
  desLinguagemBack: '',
  desLinguagemMobile: '',
}

const doNotShowTable = ['indStatus', 'vlrPrjHor', 'tipFaturamento', 'idClient']

const headersTable = [
  'ID',
  'Cliente',
  'Projeto',
  'Sigla',
  'Ling. Front',
  'Ling. Back',
  'Ling. Mobile',
]

const Project = ({
  /** Actions */
  getClients,
  getProjectsByFilter,
  getProjects,
  remove,
  save,
  update,
  /** Reducer props */
  listProjectsFilter,
  listClients,
  listProjects,
  errorProjects,
  successProjects,
  totalElementsProjects,
  isLoading,
}) => {
  const styles = globalStyles()

  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [submitButtonColor, setSubmitButtonColor] = useState(null)
  const [submitButtonLabel, setSubmitButtonLabel] = useState(null)

  const [onSubmit, setOnSubmit] = useState(() => save)

  const [title, setTitle] = useState('')
  const [showDeleteAction, setShowDeleteAction] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const [showToast, setShowToast] = useState(false)

  const [selectState, setSelectState] = useState({ fields: [] })

  const [valuesFilter, setValuesFilter] = useState({})

  useEffect(() => {
    query()
  }, [])

  const query = () => {
    getClients()
    getProjects()
    getProjectsByFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      sort: SORT,
    })
  }

  const changePage = page => {
    const { id, idClient } = valuesFilter

    setPage(page)
    getProjectsByFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(id && { id }),
      ...(idClient && { idClient }),
    })
  }

  const changeSize = size => {
    const { id, idClient } = valuesFilter

    setSize(size)
    getProjectsByFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(id && { id }),
      ...(idClient && { idClient }),
    })
  }

  const handleFilter = (values, isDeleteAction) => {
    const { id, idClient } = values

    setValuesFilter(values)

    getProjectsByFilter({
      pageFilter: (isDeleteAction ? page : DEFAULT_PAGINATION_PAGE) + 1,
      sizeFilter: size,
      sort: SORT,
      ...(id && { id }),
      ...(idClient && { idClient }),
    })

    !isDeleteAction && setPage(DEFAULT_PAGINATION_PAGE)
  }

  let filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'idClient',
        label: 'Cliente',
        options: (listClients || []).map(({ id, descCliente }) => ({
          label: descCliente,
          value: id,
        })),
      },
      {
        type: 'select',
        name: 'id',
        label: 'Projeto',
        options: (listProjects || [])
          .filter(project => project.idClient === selectState.fields.idClient)
          .map(({ id, desProjeto }) => ({
            label: desProjeto,
            value: id,
          })),
        disabled: !selectState.fields.idClient,
      },
    ],
    monitoreState: state => setSelectState(state),
  }

  const convertRecord = record => {
    return {
      ...record,
      idClient: record.idClient,
      tipFaturamento: record.tipFaturamento,
      indStatus: record.indStatus,
      vlrPrjHor: record.vlrPrjHor,
      desProjeto: record.desProjeto,
      desSigla: record.desSigla,
      desLinguagemFront: record.desLinguagemFront,
      desLinguagemBack: record.desLinguagemBack,
      desLinguagemMobile: record.desLinguagemMobile,
    }
  }


  const handleSubmit = async values => {
    await onSubmit(values)

    setShowDialog(false)
    setShowToast(true)

    handleFilter(valuesFilter)
    query()
  }

  const showDialogSave = () => {
    setParam(convertRecord(DEFAULT_PARAM))

    setSubmitButtonLabel('Salvar')
    setSubmitButtonColor('primary')

    setOnSubmit(() => save)

    setTitle('Novo Projeto')
    setShowDeleteAction(false)
    setShowDialog(true)
  }

  const showDialogUpdate = record => {
    setParam(convertRecord(record))

    setSubmitButtonLabel('Alterar')
    setSubmitButtonColor('primary')

    setOnSubmit(() => update)

    setTitle('Alterar Projeto')
    setShowDeleteAction(false)
    setShowDialog(true)
  }

  const showDialogDelete = record => {
    setParam(convertRecord(record))

    setSubmitButtonLabel('Deletar')
    setSubmitButtonColor('secondary')

    setOnSubmit(() => remove)

    setTitle(`Você deseja deletar o projeto ${record.desProjeto}?`)
    setShowDeleteAction(true)
    setShowDialog(true)
  }

  return (
    <Main title="Projetos">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={showDialogSave} color="primary">
            <AddIcon />
          </Fab>
        </Box>

        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>

        {!isLoading && (
          <TableContainer component={Paper}>
            <TablePagination
              titleTable="project"
              count={totalElementsProjects}
              doNotShow={doNotShowTable}
              headers={headersTable}
              onChangePage={(_, page) => changePage(page)}
              onChangeSize={({ target: { value: size } }) => changeSize(size)}
              page={page}
              rows={listProjectsFilter}
              size={size}
              startDelete={showDialogDelete}
              startEdit={showDialogUpdate}
              alignTitle={[
                'right',
                'left',
                'left',
                'left',
                'left',
                'left',
                'left',
                'left',
              ]}
              alignItens={[
                'right',
                'left',
                'left',
                'left',
                'left',
                'left',
                'left',
                'left',
              ]}
            />
          </TableContainer>
        )}
      </Grid>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
      >
        <Form
          isDeleteAction={showDeleteAction}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          primaryButtonColor={submitButtonColor}
          primaryButtonLabel={submitButtonLabel}
          clients={listClients}
        />
      </Dialog>

      <Toast
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successProjects}
        error={errorProjects}
      />
    </Main>
  )
}

const mapStateToProps = ({ projectReducer, clientReducer }) => ({
  ...projectReducer,
  ...clientReducer,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProjects: projectActions.get,
      getProjectsByFilter: projectActions.getFilter,
      save: projectActions.save,
      update: projectActions.update,
      remove: projectActions.remove,
      getClients: clientActions.get,
    },
    dispatch
  )

Project.propTypes = {
  /** Actions */
  getClients: PropTypes.func.isRequired,
  getProjectsByFilter: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  /** Reducer props */
  listProjectsFilter: PropTypes.array.isRequired,
  listClients: PropTypes.array.isRequired,
  listProjects: PropTypes.array.isRequired,
  errorProjects: PropTypes.string,
  successProjects: PropTypes.string,
  totalElementsProjects: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)
