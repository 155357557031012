import { types } from '../../config/action_types'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../constants/pagination'

const defaultState = {
  listProposalUser: [],
  errorProposalUser: null,
  successProposalUser: null,
  filterParams: {
    pageFilter: DEFAULT_PAGINATION_PAGE,
    sizeFilter: DEFAULT_PAGINATION_SIZE,
  },
  totalElementsProposalUser: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.proposalUser.updateFilter:
      return {
        ...state,
        filterParams: {
          sizeFilter: state.filterParams.sizeFilter,
          ...action.payload,
        },
      }

    case types.proposalUser.clearFilter:
      return {
        ...state,
        filterParams: {
          ...defaultState.filterParams,
        },
      }

    case types.proposalUser.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        listProposalUser: resultList || [],
        totalElementsProposalUser:
          pages != null ? pages.totalCount : 0 || resultList.length,
      }

    case types.proposalUser.getFilter.error:
      return {
        ...state,
        errorProposalUser: action.payload,
        successProposalUser: null,
      }

    case types.proposalUser.get.success:
      return {
        ...state,
        listProposalUser: action.payload.content || action.payload,
      }

    case types.proposalUser.get.error:
      return {
        ...state,
        errorProposalUser: action.payload,
        successProposalUser: null,
      }

    case types.proposalUser.save.success:
      return {
        ...state,
        successProposalUser: action.payload,
        errorProposalUser: null,
      }

    case types.proposalUser.save.error:
      return {
        ...state,
        errorProposalUser: action.payload,
        successProposalUser: null,
      }

    case types.proposalUser.delete.success:
      return {
        ...state,
        successProposalUser: action.payload,
        errorProposalUser: null,
      }

    case types.proposalUser.delete.error:
      return {
        ...state,
        errorProposalUser: action.payload,
        successProposalUser: null,
      }

    case types.proposalUser.update.success:
      return {
        ...state,
        successProposalUser: action.payload,
        errorProposalUser: null,
      }

    case types.proposalUser.update.error:
      return {
        ...state,
        errorProposalUser: action.payload,
        successProposalUser: null,
      }

    default:
      return state
  }
}
