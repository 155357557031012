import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  error: null,
  userInfo: {},
  errorPassword: null,
  successPassword: null,
  resetPassword: false,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.login.error:
      return {
        ...state,
        error: action.payload,
      }
    case types.login.resetUserPassword:
      return {
        ...state,
        resetPassword: true,
        ...action.payload,
      }
    case types.login.cancelResetUserPassword:
      return {
        ...state,
        resetPassword: false,
        ...action.payload,
      }
    case types.login.periodicPasswordChangePerformed:
      return {
        ...state,
        resetPassword: false,
        redirectToHome: true,
        newPassord: action.payload.desSenha,
        ...action.payload,
      }
    case types.login.hiddenChangePassword:
      return {
        ...state,
        resetPassword: false,
        redirectToHome: false,
      }

    case types.login.dismissError:
      return {
        ...state,
        error: null,
        errorPassword: null,
        successPassword: null,
      }
    case types.login.success:
      return {
        ...state,
        error: null,
        resetPassword: false,
        userInfo: action.payload,
      }
    case types.password.error:
      return {
        ...state,
        errorPassword: action.payload,
        resetPassword: true,
        successPassword: null,
      }
    case types.password.success:
      return {
        ...state,
        successPassword: 'Senha alterada com sucesso',
        resetPassword: false,
        errorPassword: null,
      }
    case types.password.mandatoryFieldsNotCompletedError:
      return {
        ...state,
        successPassword: null,
        errorPassword: action.payload,
      }

    default:
      return state
  }
}
