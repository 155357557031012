import { types } from '../../config/action_types'

const defaultState = {
  users: [],
  devs: [],
  listUsersFilter: [],
  listUsers: [],

  errorUsers: null,
  successUsers: null,

  totalElementsUsers: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.user.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        listUsersFilter: resultList || [],
        totalElementsUsers: pages ? pages.totalCount : resultList.length,
      }
    case types.user.getFilter.error:
      return {
        ...state,
        errorUsers: 'Erro ao buscar usuários',
        successUsers: null,
      }

    case types.user.get.success:
      return {
        ...state,
        listUsers: action.payload.content || action.payload,
        users: action.payload.content || action.payload,
        devs: action.payload.content || action.payload,
      }

    case types.user.get.error:
      return {
        ...state,
        errorUsers: 'Erro ao buscar usuários',
        successUsers: null,
      }

    case types.user.save.success:
      return {
        ...state,
        successUsers: 'Usuário salvo com sucesso',
        errorUsers: null,
      }

    case types.user.save.error:
      return {
        ...state,
        errorUsers: action.payload.messageError || 'Erro ao salvar usuário'  ,
        successUsers: null,
      }

    case types.user.delete.success:
      return {
        ...state,
        successUsers: 'Usuário deletado com sucesso',
        errorUsers: null,
      }

    case types.user.delete.error:
      const { messageError } = action.payload.response.data.message

      return {
        ...state,
        errorUsers: messageError,
        successUsers: null,
      }

    case types.user.update.success:
      return {
        ...state,
        successUsers: 'Usuário atualizado com sucesso',
        errorUsers: null,
      }

    case types.user.update.error:
      return {
        ...state,
        errorUsers: action.payload.messageError || 'Erro ao atualizar usuário',
        successUsers: null,
      }

    default:
      return state
  }
}
