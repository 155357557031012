import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { get as getClients } from '../../actions/client'
import { get as getProjects } from '../../actions/project'
import * as proposalActions from '../../actions/proposal'
import { getFilter as getPeriods, getProposta } from '../../actions/period'
import * as executionActions from '../../actions/execution'

import { globalStyles } from '../../styles/global'
import Main from '../Main'

import { TableContainer, Paper, Box, Grid } from '@material-ui/core'
import Dialog from '../../utils/Dialog'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/FilterPreClosure'

import Form from './components/Form'
import Table from './components/Table'
import { Loader } from '../../utils/Loading'
import useFeedback from '../../utils/hooks/useFeedback'
import api from '../../../server'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'


const PreClosure = props => {
  const {
    listPeriodsFilter,
    handleChangeMonth,
    handleChangeMonthProposal,
    getPeriods,
    executionList,
    setExecutionToEdit,
    errorExecution,
    successExecution,
    executionToEdit,
    listProposta,
    getProposta
  } = props
  const styles = globalStyles()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const disabledValue = searchParams.get('disabled');

  const { setSuccess, clearFeedback } = useFeedback()


  const [showDialog, setShowDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [proposalToEdit, setProposalToEdit] = useState(null)
  const [showToast, setShowToast] = useState(false)
  const [showToastError, setShowToastError] = useState(false)
  const [dragModalDisabled, setDragModalDisabled] = useState(false)
  const [isProposalFilterDisabled, setIsProposalFilterDisabled] = useState(true)
  const [proposalOptions, setProposalOptions] = useState([])
  const [selectSecondOption, setSelectedOption] = useState(false)
  const [filter, setFilter] = useState({})
  const [filterCompleted, setFilterCompleted] = useState({})

  const setFilterOnCall = (peri, propos) => {
    setFilter({
      "idPeriod": peri,
      "idProposta": propos,
    });
  }

  const clearUrlParameters = () => {
    const newUrl = window.location.origin + window.location.pathname
    window.history.replaceState({}, document.title, newUrl)
  }

  const listaProposal = useCallback(

    async period => {
      console.log(await getProposta(period))
      const { data } = await api.get(`/execution/filter?periodoId=${period}`)
      setProposalOptions(data.resultList)
    },
    [proposalOptions]
  )

  useEffect(() => {
    clearUrlParameters()


    if (successExecution) {
      setShowToast(true)
    } else {
      setShowToast(false)
    }

    if (errorExecution) {
      setShowToastError(true)
    } else setShowToastError(false)
  }, [errorExecution, successExecution, selectSecondOption])

  const handleFilterSubmit = async value => {
    const selectedPeriod = listPeriodsFilter.find(
      item => item.id === value.idPeriod
    )
    setFilterCompleted({isProposalFilterDisabled:false})
    setFilter(value)
    setIsLoading(true)
    if (selectedPeriod)
      if (value.idPeriod && value.idProposta) {
        await handleChangeMonthProposal(
          {
            label: selectedPeriod.desPeriodo,
            value: selectedPeriod.id,
          },
          { value: value.idProposta }
        )
      } else {
        await handleChangeMonth({
          label: selectedPeriod.desPeriodo,
          value: selectedPeriod.id,
        })
      }

    setIsLoading(false)
  }

  useEffect(() => {
    getPeriods({ indFechado: 1, sort: 'dtaInicio desc' })
  }, [proposalOptions, selectSecondOption])

  const handleUserFilterChange = async (fieldName, value) => {
    if (fieldName === 'idPeriod') {
      if(value === null){
        setIsProposalFilterDisabled(true)      }

      await listaProposal(value)
      setIsProposalFilterDisabled(false)
    } else if (fieldName === 'idProposta') {
      setSelectedOption(true)
    }
  }

  const handleChangeStatus = useCallback(async()=>{
    setIsProposalFilterDisabled(false)

  },[isProposalFilterDisabled])

  const filterProps = useMemo(
    () => ({
      onSubmit: handleFilterSubmit,
      onValueChange: handleUserFilterChange,
      onCallBackScreen: setFilterOnCall,
      fields: [
        {
          type: 'select',
          name: 'idPeriod',
          label: 'Período',
          options: (listPeriodsFilter || []).map(({ id, desPeriodo }) => ({
            label: desPeriodo,
            value: id,
          })),
        },
        {
          type: 'select',
          name: 'idProposta',
          label: 'Proposta',
        //  initial: decodedValue !== 'null'
        //   ?[{ label: decodedValue, value: Number(idProposta) }] : null,
          options:
          (listProposta || []).map(item => ({
            label: item.proposal.codProposta,
            value:  item.proposal.id,
          })).sort((a, b) => {
            const propostaA = a.label;
            const propostaB = b.label;
            const alphaComparison = propostaA.localeCompare(propostaB, undefined, { numeric: true });
            return alphaComparison !== 0 ? alphaComparison : (parseInt(propostaA) - parseInt(propostaB)); }),
          disabled: disabledValue !== null ? false: isProposalFilterDisabled,
        },
      ],
    }),
    [listPeriodsFilter, proposalOptions, isProposalFilterDisabled, listProposta]
  )

  const handleCloseForm = successMessage => {
    setShowDialog(false)
    setProposalToEdit(null)

    if (successMessage) {
      clearFeedback()
      setSuccess(successMessage)
    }
  }

  const renderForm = () => {
    return (
      <Dialog
        onClose={() => handleCloseForm()}
        open={showDialog || !!proposalToEdit}
        title={'Alterar status'}
        isDragDisabled={dragModalDisabled}
      >
        <Form
          proposalToEdit={proposalToEdit}
          executionUserToEdit={''}
          handleCloseForm={handleCloseForm}
          onOpenDateModal={() => setDragModalDisabled(true)}
          onCloseDateModal={() => setDragModalDisabled(false)}
          filter={filter}
        />
      </Dialog>
    )
  }

  const handleOnEdit = exec => {
    const execution = executionList.find(item => item.id === exec.id)

    if (executionToEdit && executionToEdit.id === execution.id) {
      setProposalToEdit(execution.id)
    } else {
      setExecutionToEdit(execution, null)
      setProposalToEdit(exec.id)
    }
  }

  const renderTable = () => {
    return (
      <>
        <Loader isLoading={isLoading} />
        <Table filter={filterCompleted} onEdit={p => handleOnEdit(p)} />
      </>
    )
  }

  useEffect(() => {
    return () => {
      setShowToast(false)
      if(disabledValue !== null || disabledValue !== 'null'){
        handleChangeStatus(false)
      }
    }
  }, [isProposalFilterDisabled, filterCompleted, listProposta])

  return (
    <Main title="Pré-Fechamento">
      <Toast
        success={'Status atualizado com sucesso'}
        open={showToast}
        onClose={() => setShowToast(false)}
        autoHideDuration={2000}
      />
      <Toast
        error={'Houve um erro na solicitação'}
        open={showToastError}
        onClose={() => setShowToastError(false)}
        autoHideDuration={2000}
      />
      <Grid item xs={12}>
        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>
        <TableContainer component={Paper}>{renderTable()}</TableContainer>
      </Grid>
      {renderForm()}
    </Main>
  )
}

const mapStateToProps = state => {
  const {
    clientReducer,
    projectReducer,
    proposalReducer,
    periodReducer,
    executionReducer,
  } = state

  return {
    ...clientReducer,
    ...proposalReducer,
    ...projectReducer,
    listPeriodsFilter: periodReducer.listPeriodsFilter,
    listProposta: periodReducer.listProposta,
    executionList: executionReducer.list,
    errorExecution: executionReducer.errorExecution,
    successExecution: executionReducer.successExecution,
    periodId: executionReducer.requestFilters.periodId,
    executionToEdit: executionReducer.toEdit.executionToEdit,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getClients,
      getProjects,
      ...proposalActions,
      handleChangeMonth: executionActions.handleChangeMonth,
      handleChangeMonthProposal: executionActions.handleChangeMonthProposal,
      getExecutionByMonth: executionActions.getExecutionByMonth,
      getExecutionByMonthProposal: executionActions.getExecutionByMonthProposal,
      getPeriods,
      getProposta,
      setExecutionToEdit: executionActions.setExecutionToEdit,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PreClosure)
