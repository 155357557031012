import React from 'react'
import PropTypes from 'prop-types'
import MaterialTablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { globalStyles } from '../../styles/global'
import { tableIdentification } from './tableUtils'

const TablePagination = ({
  /** Pagination */
  count,
  onPageChange,
  onChangeSize,
  page,
  size,
  order,
  /** Table content */
  titleTable,
  headers,
  columnKeyName,
  rows,
  customFooter,
  alignTitle = [],
  alignItens = [],
  maskFunction = {},
}) => {
  const styles = globalStyles()

  const renderHeader = (header, index) => {
    return (
      <TableCell
        style={{
          textAlign: alignTitle[index] ? alignTitle[index] : 'center',
          color: '#ffff',
        }}
        className={styles.th}
        key={`header-${index}`}
      >
        {header}
      </TableCell>
    )
  }

  const renderRow = row => {
    return (
      <TableRow key={columnKeyName ? row[columnKeyName] : row.id}>
        {order
          ? order.map((key, index) =>
            renderColumn([key, row[key], index], index)
          )
          : Object.entries(row).map(renderColumn)}
      </TableRow>
    )
  }

  const renderColumn = (column, index) => {
    const key = column[0]
    const value = column[1]
    const parse = maskFunction[key] ? maskFunction[key](value) : value

    return (
      <TableCell
        style={{ textAlign: alignItens[index] ? alignItens[index] : 'center' }}
        key={`column-${key}-${value}`}
      >
        {tableIdentification(titleTable, key, parse)}
      </TableCell>
    )
  }

  return (
    <Table className={styles.table}>
      {headers && (
        <TableHead className={styles.thead}>
          <TableRow>
            {headers.map((item, index) => renderHeader(item, index))}
          </TableRow>
        </TableHead>
      )}

      <TableBody>
        {rows && rows.length !== 0 ? (
          rows
          .map(renderRow)
          .slice(page * size, page * size + size)
        ) : (
          <TableRow>
            <TableCell align="center" colSpan={headers.length}>
              <strong>
                Nenhum lançamento encontrado!
              </strong>
            </TableCell>
          </TableRow>
        )}
      </TableBody>

      <TableFooter>
        <TableRow>{customFooter}</TableRow>
        <TableRow>
          <MaterialTablePagination
            rowsPerPageOptions={[10, 20, 50, { label: 'Todos', value: -1 }]}
            count={count}
            labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
            }
            labelRowsPerPage="Registros por página"
            onPageChange={onPageChange}
            onRowsPerPageChange={onChangeSize}
            rowsPerPage={size}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

TablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  headers: PropTypes.array.isRequired,
  columnKeyName: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onChangeSize: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rows: PropTypes.array,
  size: PropTypes.number.isRequired,
  alignTitle: PropTypes.array,
  alignItens: PropTypes.array,
  maskFunction: PropTypes.object,
}

export default TablePagination
