import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AddIcon from '@material-ui/icons/Add'
import { Box, Fab, Grid, Paper, TableContainer } from '@material-ui/core'
import * as officeActions from '../../actions/office'
import * as employeeActions from '../../actions/employee'

import Form from './components/Form'
import Main from '../Main'
import { globalStyles } from '../../styles/global'
import Dialog from '../../utils/Dialog'
import TablePagination from '../../utils/Table/TablePagination'
import Toast from '../../utils/Toast'

import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'
import moment from 'moment'
import FilterDefault from '../../utils/Filter/FilterEmployeeSearch'

const SORT = 'id'

const DEFAULT_PARAM = {
  id: null,
  name: '',
  cpf: '',
  rg: '',
  birthdayDate: '',
  seniority: '',
  educationLevel: '',
  specialty: '',
  phone: '',
  phone2: '',
  phone3: '',
  phoneEmergency: '',
  email: '',
  email2: '',
  address: '',
  district: '',
  cep: '',
  city: '',
  startDateJob: '',
  endDateJob: '',
  typeContract: '',
  idOffice: '',
  stateEnum: '',
}

const doNotShowTable = [
  'id',
  'cep',
  'rg',
  'email',
  'email2',
  'district',
  'educationLevel',
  'phone2',
  'phone3',
  'phoneEmergency',
  'birthdayDate',
  'address',
  'cpf',
  'city',
  'phone',
  'idOffice',
  'stateEnum',
]

const headersTable = [
  'Nome',
  'Senioridade',
  'Especialidade',
  'Data Admissão',
  'Data Demissão',
  'Tipo Contrato',
]

const listTipContratacao = [
  { key: 'CLT', value: 'CLT' },
  { key: 'TERCEIRO', value: 'TERCEIRO' },
  { key: 'ESTAGIO', value: 'ESTAGIO' },
  { key: 'NAO_CADASTRADO', value: '-' },
]

const listTipSenioridade = [
  { key: 'ESTAGIARIO', value: 'ESTAGIARIO' },
  { key: 'TRAINEE', value: 'TRAINEE' },
  { key: 'JUNIOR', value: 'JUNIOR' },
  { key: 'PLENO', value: 'PLENO' },
  { key: 'SENIOR', value: 'SENIOR' },
  { key: 'NAO_CADASTRADO', value: '-' },
]

const listTipEscolaridade = [
  { key: 'FUNDAMENTAL_INCOMPLETO', value: 'FUNDAMENTAL INCOMPLETO' },
  { key: 'FUNDAMENTAL_COMPLETO', value: 'FUNDAMENTAL COMPLETO' },
  { key: 'MEDIO_INCOMPLETO', value: 'MÉDIO INCOMPLETO' },
  { key: 'MEDIO_COMPLETO', value: 'MÉDIO COMPLETO' },
  { key: 'SUPERIOR_INCOMPLETO', value: 'SUPERIOR INCOMPLETO' },
  { key: 'SUPERIOR_COMPLETO', value: 'SUPERIOR COMPLETO' },
  { key: 'ESPECIALIZACAO', value: 'ESPECIALIZAÇÃO' },
]

const listTipState = [
  { key: 'AMAZONAS', value: 'AMAZONAS' },
  { key: 'ALAGOAS', value: 'ALAGOAS' },
  { key: 'ACRE', value: 'ACRE' },
  { key: 'AMAPA', value: 'AMAPA' },
  { key: 'BAHIA', value: 'BAHIA' },
  { key: 'PARA', value: 'PARA' },
  { key: 'MATO_GROSSO', value: 'MATO GROSSO' },
  { key: 'MINAS_GERAIS', value: 'MINAS GERAIS' },
  { key: 'MATO_GROSSO_DO_SUL', value: 'MATO GROSSO DO SUL' },
  { key: 'GOIAS', value: 'GOIAS' },
  { key: 'MARANHAO', value: 'MARANHAO' },
  { key: 'RIO_GRANDE_DO_SUL', value: 'RIO GRANDE DO SUL' },
  { key: 'TOCANTINS', value: 'TOCANTINS' },
  { key: 'PIAUI', value: 'PIAUI' },
  { key: 'SAO_PAULO', value: 'SAO PAULO' },
  { key: 'RONDONIA', value: 'RONDONIA' },
  { key: 'RORAIMA', value: 'RORAIMA' },
  { key: 'PARANA', value: 'PARANA' },
  { key: 'CEARA', value: 'CEARA' },
  { key: 'PERNAMBUCO', value: 'PERNAMBUCO' },
  { key: 'SANTA_CATARINA', value: 'SANTA CATARINA' },
  { key: 'PARAIBA', value: 'PARAIBA' },
  { key: 'RIO_GRANDE_DO_NORTE', value: 'RIO GRANDE DO NORTE' },
  { key: 'ESPIRITO_SANTO', value: 'ESPIRITO SANTO' },
  { key: 'RIO_DE_JANEIRO', value: 'RIO DE JANEIRO' },
  { key: 'SERGIPE', value: 'SERGIPE' },
  { key: 'DISTRITO_FEDERAL', value: 'DISTRITO FEDERAL' },
]

const Employee = ({
  /** Actions */
  getFilter,
  getOffices,
  clearMessages,

  remove,
  save,
  update,
  /** Reducer props */
  listEmployee,
  listOffices,
  listEmployeeFilter,
  error,
  success,
  totalElementsEmployee,
}) => {
  const styles = globalStyles()

  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)
  const [showDialog, setShowDialog] = useState(false)
  const [totalElementGetFilter, setTotalElementGetFilter] = useState(0)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [isUpdateAction, setIsUpdateAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState('Novo Colaborador')
  const [forceUpdate, setForceUpdate] = useState(false)

  const [showToast, setShowToast] = useState(false)

  const [valuesFilter, setValuesFilter] = useState({})

  const [dragModalDisabled, setDragModalDisabled] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [line, setLine] = useState()
  const employeeOptions = useRef([])
  const [filterOptions, setFilterOptions] = useState([])
  const [idUser, setIdUser] = useState()
  const [disabledRows, setDisabledRows] = useState(false)
  const [isFilterErase, setIsFilterErase] = useState(false)

  const filterDefaultRef = useRef()

  const handleProps = async props => {
    setDisabledRows(false)
    if(disabledRows === true){
      setLine(0)
    }
    return
  }

  const handleFilter = async values => {
    const { id } = values

    setIsFilterApplied(true)
    setValuesFilter(values)
    const totalCountFilter = await getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      sort: SORT,
      ...(id && { id }),
    })

    setTotalElementGetFilter(totalCountFilter.payload.resultList.length)

    if (id === null) {
      setPage(DEFAULT_PAGINATION_PAGE)
      setSize(DEFAULT_PAGINATION_SIZE)

      await query()
    }

    if (id !== null) {
      setIsFilterErase(true)
      setIdUser(id)
      setPage(DEFAULT_PAGINATION_PAGE)
      setSize(DEFAULT_PAGINATION_SIZE)
    }
  }

  const filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'id',
        label: 'Nome',
        initial: valuesFilter.id,
        options: filterOptions.map(employee => ({
          value: employee.id,
          label: employee.name,
        })),
      },
    ],
  }

  const query = async (lastSubmit = false) => {
    await getOffices()
    const totalCount = await getFilter()
    setTotalElementGetFilter(totalCount.payload.resultList.length)
    await getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
    })
    const user = JSON.parse(localStorage.getItem('user'))

    if (lastSubmit && lastSubmit.id && lastSubmit.id === user.id) {
      const changed = listEmployee.find(item => item.id === user.id)
      if (changed) {
        user.desUsuario = lastSubmit.desUsuario
        localStorage.setItem('user', JSON.stringify(user))
        setForceUpdate(!forceUpdate)
      }
    }
  }

  const convertRecord = record => {
    return {
      ...record,
      startDateJob: moment(record.startDateJob, 'DD/MM/YYYY').toDate(),
      endDateJob: record.endDateJob
        ? moment(record.endDateJob, 'DD/MM/YYYY').toDate()
        : null,
      birthdayDate: moment(record.birthdayDate, 'YYYY-MM-DD').toDate(),
      idOffice: record.idOffice,
    }
  }

  const handleSubmit = async values => {
    clearMessages()

    function uppercaseName(name) {
      return name.charAt(0).toUpperCase() + name.slice(1)
    }

    if (!values.idOffice) {
      values.idOffice = null
    }

    await onSubmit({
      ...values,
      name: uppercaseName(values.name),
      birthdayDate: moment(values.birthdayDate).format('YYYY-MM-DD'),
      seniority: values.seniority || null,
      educationLevel: values.educationLevel || null,
    })

    if (isUpdateAction && isFilterApplied === false) {
      setShowDialog(false)
      setShowToast(true)
      return await handleProps()
    }

    if (isDeleteAction) {
      if (idUser === undefined || idUser === null) {
        query()
        handleFilter(valuesFilter)
        employeeOptions.current = employeeOptions.current.filter(employee => {
          return employee.id !== values.id
        })
        setShowDialog(false)
        setShowToast(true)
        handleFilter(valuesFilter)
        return
      }

      if (isFilterErase === true) {
        setIsFilterErase(false)
        filterDefaultRef.current.clearAllFields()
        setIsFilterErase(false)
      }

      employeeOptions.current = employeeOptions.current.filter(employee => {
        return employee.id !== values.id
      })
    }

    if (isUpdateAction) {
      employeeOptions.current = employeeOptions.current.filter(employee => {
        setPage(page)
        setSize(size)
        return employee.id !== values.id
      })
    }

    if (isUpdateAction && idUser !== null) {
      await handleProps()
    }

    setShowDialog(false)
    setShowToast(true)
    handleFilter(valuesFilter)
  }

  const startSave = () => {
    setParam(DEFAULT_PARAM)
    setIsDeleteAction(false)
    setIsUpdateAction(true)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo Colaborador')
    setShowDialog(true)
  }

  const startDelete = record => {
    setParam(convertRecord(record))
    setIsDeleteAction(true)
    setIsUpdateAction(false)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)
    setTitle(`Você deseja deletar o colaborador ${record.name}?`)
    setShowDialog(true)
  }

  const startEdit = record => {
    setParam(convertRecord(record))
    setIsDeleteAction(false)
    setIsUpdateAction(true)
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)
    setTitle('Alterar Colaborador')

    setShowDialog(true)
  }

  useEffect(() => {
    if (disabledRows === true) {
      handleProps()
    }
    if (disabledRows === false) {
      handleFilter(valuesFilter)
      query()
    }
  }, [])

  useEffect(() => {
    const newEmployeeOptionsList = [
      ...employeeOptions.current,
      ...listEmployeeFilter,
    ].reduce((acc, current) => {
      const itemExists = acc.find(it => it.id === current.id)
      if (!itemExists) {
        acc.push(current)
      }
      return acc
    }, [])
    employeeOptions.current = newEmployeeOptionsList.sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    setFilterOptions(newEmployeeOptionsList)
  }, [listEmployeeFilter, size, page, isFilterErase])

  const handlePage = async (event, page) => {
    setPage(page)

    const newPage = page
    await getFilter({
      pageFilter: newPage + 1,
      sizeFilter: size,
    })
  }

  const handleSize = async event => {
    setSize(event.target.value)
    const newSize = event.target.value
    await getFilter({
      pageFilter: page + 1,
      sizeFilter: newSize,
    })
  }

  return (
    <Main title="Colaboradores">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={startSave} color="primary">
            <AddIcon />
          </Fab>
        </Box>

        <Box component="div" className={styles.filter} xs={6}>
          <FilterDefault
            {...filterProps}
            ref={filterDefaultRef}
            onClear={handleProps}
          />
        </Box>
        <TableContainer component={Paper}>
          <TablePagination
            count={totalElementGetFilter}
            doNotShow={doNotShowTable}
            headers={headersTable}
            onChangePage={handlePage}
            onChangeSize={handleSize}
            page={page}
            maskFunction={{
              seniority: value => {
                const item = listTipSenioridade.find(item => {
                  return item.key === value
                })

                return item ? item.value : ''
              },

              typeContract: value => {
                const type = listTipContratacao.find(item => {
                  return item.key === value
                })

                return type ? type.value : ''
              },
            }}
            rows={isFilterApplied === false ? line : listEmployeeFilter}
            size={size}
            startDelete={startDelete}
            startEdit={startEdit}
            alignTitle={['center', 'center', 'center', 'center', 'center']}
            alignItens={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
          />
        </TableContainer>
      </Grid>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
        isDragDisabled={dragModalDisabled}
      >
        <Form
          isDeleteAction={isDeleteAction}
          isUpdateAction={isUpdateAction}
          listOffices={listOffices}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          listTipContratacao={listTipContratacao.slice(0, -1)}
          listTipSenioridade={listTipSenioridade.slice(0, -1)}
          listTipEscolaridade={listTipEscolaridade}
          listTipState={listTipState}
          primaryButtonColor={primaryButtonColor}
          primaryButtonLabel={primaryButtonLabel}
          onOpenDateModal={() => setDragModalDisabled(true)}
          onCloseDateModal={() => setDragModalDisabled(false)}
        />
      </Dialog>

      <Toast
        onClose={() => {
          setShowToast(false)
          clearMessages()
        }}
        open={showToast}
        success={success}
      />
    </Main>
  )
}

const mapStateToProps = state => {
  const { officeReducer, employeeReducer } = state

  return {
    ...employeeReducer,
    ...officeReducer,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...employeeActions,
      getOffices: officeActions.get,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Employee)

Employee.propTypes = {
  /** Actions */
  getOffices: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,

  /** Reducer props */
  listOffices: PropTypes.array.isRequired,
  listEmployeeFilter: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  success: PropTypes.string.isRequired,
  totalElementsUsers: PropTypes.number.isRequired,
}
