import { types } from '../../config/action_types'

const defaultState = {
  listOffices: [],
  listOfficesFilter: [],

  errorOffices: null,
  successOffices: null,

  totalElementsOffices: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.office.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        listOfficesFilter: resultList || [],
        totalElementsOffices: pages.totalCount || resultList.length,
      }
    case types.office.getFilter.error:
      return {
        ...state,
        errorOffices: 'Erro ao buscar cargos',
        successOffices: null,
      }

    case types.office.get.success:
      return {
        ...state,
        listOffices: action.payload.content || action.payload,
      }

    case types.office.get.error:
      return {
        ...state,
        errorOffices: 'Erro ao buscar cargos',
        successOffices: null,
      }

    case types.office.save.success:
      return {
        ...state,
        successOffices: 'Cargo salvo com sucesso',
        errorOffices: null,
      }

    case types.office.save.error:
      return {
        ...state,
        errorOffices: 'Erro ao salvar cargo',
        successOffices: null,
      }

    case types.office.delete.success:
      return {
        ...state,
        successOffices: 'Cargo deletado com sucesso',
        errorOffices: null,
      }

    case types.office.delete.error:
      const { messageError } = action.payload.response.data.message

      return {
        ...state,
        errorOffices: messageError,
        successOffices: null,
      }

    case types.office.update.success:
      return {
        ...state,
        successOffices: 'Cargo atualizado com sucesso',
        errorOffices: null,
      }

    case types.office.update.error:
      return {
        ...state,
        errorOffices: 'Erro ao atualizar cargo',
        successOffices: null,
      }

    default:
      return state
  }
}
