import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import * as loginActions from '../../actions/login'

const TOKEN_TIME = 'token'

const Secure = ComposedComponent => {
  const Authentication = ({ history: { push = () => {} }, ...props }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const token = sessionStorage.getItem(TOKEN_TIME)

    const checkToken = () => {
      console.log('check token')
      if (!user || !token || moment(token) < moment())
        window.location.href = window.location.href.includes(
          'develcode.com.br/atividades/'
        )
          ? '/atividades/'
          : '/'
    }

    const updateTokenTimeListener = () => {
      sessionStorage.setItem(
        TOKEN_TIME,
        moment()
          .add(30, 'minutes')
          .toString()
      )
    }

    useEffect(() => {
      const debouncedUpdateTokenTime = _.debounce(updateTokenTimeListener, 300)

      window.addEventListener('mousemove', debouncedUpdateTokenTime)
      const interval = setInterval(checkToken, 5000)

      return () => {
        clearInterval(interval)
        window.removeEventListener('mousemove', debouncedUpdateTokenTime)
      }
    }, [])

    return user && <ComposedComponent {...props} />
  }

  Authentication.propTypes = {
    history: PropTypes.object,
  }

  const mapStateToProps = ({ loginReducer }) => ({ ...loginReducer })

  const mapDispatchToProps = dispatch =>
    bindActionCreators({ ...loginActions }, dispatch)

  return connect(mapStateToProps, mapDispatchToProps)(Authentication)
}

export default Secure
