import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import ReorderIcon from '@material-ui/icons/Reorder'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Date from './components/Date'
import TextArea from './components/TextArea'
import moment from 'moment'
import Select from './components/Select'
import InputNumber2 from './components/InputNumber2'
import Input from './components/Input'

class Filter extends Component {
  state = {
    fields: {},
    wasClear: false,
  }

  componentDidMount() {
    const { fields, fieldsLine2 = {}, fieldsLine3 = {} } = this.props
    const fieldsCompleted = {}

    const totalFields = fields.concat(fieldsLine2).concat(fieldsLine3)

    totalFields.forEach(
      field =>
        (fieldsCompleted[field.name] =
          field.initial || this.createInitialValue(field.type))
    )

    this.setState({
      fields: fieldsCompleted,
    })
  }


  componentDidUpdate() {
    if (this.props.monitoreState) this.props.monitoreState(this.state)
    const { executeClearAllFields = () => {} } = this.props

    if (this.props.limparFields !== null) {
      executeClearAllFields()
      this.clearAllFieldsExceptDiasUteis()
    }
  }

  clearAllFieldsExceptDiasUteis = () => {
    const { fields, fieldsLine2 = {}, fieldsLine3 = {} } = this.props
    const fieldsCompleted = {}

    const totalFields = fields
      .concat(fieldsLine2)
      .concat(fieldsLine3)
      .filter(field => field.name !== 'diasUteis')

    totalFields.forEach(
      field =>
        (fieldsCompleted[field.name] =
          field.disabled && field.initial
            ? field.initial
            : this.createInitialValue(field.type))
    )

    this.setState((state) => ({
      fields: { ...state.fields, ...fieldsCompleted },
      wasClear: true,
    }))
  }

  enviarReport(detailedReport) {
    const { onSubmit = () => {} } = this.props
    onSubmit(this.state.fields, detailedReport, true)
    this.clearAllFieldsExceptDiasUteis()
  }

  setValueToField = (field, value) => {
    const { changeFieldFilter = () => {} } = this.props

    this.setState({
      fields: {
        ...this.state.fields,
        [field]: value,
      },
    })
    
    changeFieldFilter(field, value)

    if (field === 'idUser') {
      this.setState({
        fields: {
          ...this.state.fields,
          idUser: value,
          idProposta: null,
        },
      })
    }

    if (field === 'userIdPeriod' && value === null) {
      const newState = { 
        ...this.state.fields,
        userIdPeriod: null,
        dtInicial: null,
        dtFinal: null,
        userExecution: null,
      }

      if (this.state.fields.userExecution) {
        newState.idUser = null
        newState.idProposta = null
        newState.txtDescricao = null
        newState.statusExecution = null
      }
     
      this.setState({
        fields: newState,
      })
    }

    if (field === 'userIdPeriod' && value !== null) {
      this.setState({
        fields: { 
        ...this.state.fields,
        userIdPeriod: null,
        dtInicial: null,
        dtFinal: null,
        userExecution: null,
        idUser: null
      }}) 
    }

    if (field === 'userExecution' && value !== null) {
      const execution = this.props.executionList.find(item => item.id === value)
      if (execution) {
        this.setState({
          fields: {
            ...this.state.fields,
            userExecution: value,
            idProposta: execution.proposal.id,
            idUser: execution.userExecution ? execution.userExecution.id : null,
            txtDescricao: execution.reportObservations,
          }
        })
      }
    }

    if (field === 'userExecution' && value === null) {
      this.setState({
        fields: {
          ...this.state.fields,
          userExecution: null,
          idProposta: null,
          idUser: null,
          statusExecution: null,
          txtDescricao: null,
        },
      })
    }
  }

  formataDataToState(campo) {
    let campos = campo.split('/')
    let data = campos[1] + '/' + campos[0] + '/' + campos[2]
    return moment(data).format('LL')
  }

  setValueToFieldConditionalPeriod = (field, value) => {
    if (value && value.dtaInicio && value.dtaFim) {
      this.setState({
        fields: {
          [field]: value.value,
          diasUteis: this.state.fields.diasUteis,
          dtInicial: this.formataDataToState(value.dtaInicio),
          dtFinal: this.formataDataToState(value.dtaFim),
        },
      })
    }
  }

  createInitialValue = type => {
    switch (type) {
      case 'select_multiple':
        return []
      case 'checkbox':
        return false
      case 'date_range':
        return [null, null]
      case 'input':
        return ''
      default:
        return null
    }
  }

  buildField(props) {
    switch (props.type) {
      case 'select':
        return (
          <Select
            wasClear={this.state.wasClear}
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'select-data':
        return (
          <Select
            wasClear={this.state.wasClear}
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            setValueToFieldConditional={this.setValueToFieldConditionalPeriod}
            {...props}
          />
        )
      case 'date':
        return (
          <Date
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'inputNumber':
        return (
          <InputNumber2
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )

      case 'textarea':
        return (
          <TextArea
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            cols={160}
            rows={10}
            {...props}
            inputProps={{ maxLength: props.maxLength || null }}
          />
        )

        case 'input':
          return (
            <Input
              stateValues={this.state.fields[props.name]}
              setValueToField={this.setValueToField}
              {...props}
            />
          )

      default:
        return
    }
  }

  render() {
    const { fields, fieldsLine2, fieldsLine3 } = this.props

    return (
      <>
        <Grid
          container
          className="common-filter"
          direction="row"
          alignItems="center"
          spacing={4}
          rowSpacing={1}
        >
          {(fields || []).map((field, i) => (
            <Grid xs={3} item key={i} className="filter-item" rowSpacing={1}>
              {this.buildField(field)}
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          className="common-filter"
          direction="row"
          alignItems="center"
          spacing={4}
        >
          {(fieldsLine2 || []).map((field, i) => (
            <Grid xs={3} item key={i} className="filter-item">
              {this.buildField(field)}
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          className="common-filter"
          direction="row"
          alignItems="center"
          spacing={4}
          rowSpacing={1}
        >
          {(fieldsLine3 || []).map((field, i) => (
            <Grid xs={9} item key={i} className="filter-item" style={{maxWidth: '100%', flex: 1}}>
              {this.buildField(field)}
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          className="common-filter"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={3}>
            <ButtonGroup  variant="contained" size="small">
              <Button
                style={{ paddingLeft: 25, paddingRight: 25 }}
                startIcon={<FilterListIcon />}
                color="primary"
                onClick={() => {this.enviarReport(false)}}
                className="submit-button-fechamento"
              >
                Gerar Resumo 
              </Button>
              <Button
                style={{ paddingLeft: 25, paddingRight: 25 }}
                startIcon={<FilterListIcon />}
                color="primary"
                onClick={() => {this.enviarReport(true)}}
                className="submit-button-fechamento"
              >
                Gerar Completo
              </Button>
              <Button
                style={{ paddingLeft: 25, paddingRight: 25 }}
                startIcon={<ReorderIcon />}
                color="secondary"
                onClick={() => this.clearAllFieldsExceptDiasUteis()}
                className="submit-button-fechamento"
              >
                Limpar
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </>
    )
  }
}

Filter.propTypes = {
  fields: PropTypes.array.isRequired,
  executionList: PropTypes.array,
  fieldsLine2: PropTypes.array.isRequired,
  fieldsLine3: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  changeFieldFilter: PropTypes.func.isRequired,
  executeClearAllFields: PropTypes.func.isRequired,
}

export default Filter
