const isValidDate = d => {
  return d instanceof Date && !isNaN(d)
}

export const validateValues = values => {
  const { activity, user, dthInitial, dthFinal, description } = values
  let errors = {}
  if (!activity.id) {
    errors = { ...errors, activityError: 'Informe a atividade' }
  }

  if (!user.id) {
    errors = { ...errors, userError: 'Informe o usuário' }
  }

  if (!description) {
    errors = { ...errors, descriptionError: 'Informe a descrição' }
  }

  if (!dthInitial) {
    errors = { ...errors, dthInitialError: 'Informe a data inicial' }
  }

  if (dthInitial !== null && !isValidDate(dthInitial)) {
    errors = { ...errors, dthInitialError: 'Data inicial inválida' }
  }

  if (!dthFinal) {
    errors = { ...errors, dthFinalError: 'Informe a data final' }
  }

  if (dthFinal !== null && !isValidDate(dthFinal)) {
    errors = { ...errors, dthFinalError: 'Data final inválida' }
  }

  if (dthFinal && dthInitial) {
    const lessThanInitial = dthFinal.getTime() < dthInitial.getTime()
    if (lessThanInitial) {
      errors = {
        ...errors,
        dthFinalError: 'Data final deve ser maior que a data inicial',
      }
    }
  }
  return errors
}
