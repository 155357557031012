import React from 'react'
import 'date-fns'
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'
import { DD_MM_YYYY_DISPLAY } from '../../../constants/date'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

export default function Datepicker({
  disabled,
  id,
  label,
  onChange,
  value,
  error,
  helperText,
  inputRef,
  onOpen = () => {},
  onClose = () => {},
}) {
  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDatePicker
        disabled={!!disabled}
        inputRef={inputRef}
        fullWidth
        id={id}
        label={label}
        format={DD_MM_YYYY_DISPLAY}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        invalidDateMessage="Data inválida!"
        okLabel="OK"
        cancelLabel="Cancelar"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        autoOk
        onOpen={onOpen}
        onClose={onClose}
      />
      
    </MuiPickersUtilsProvider>
  )
}
