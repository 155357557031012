import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { useFormik } from 'formik'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'

import { makeStyles } from '@material-ui/core/styles'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      decimalSeparator={','}
      thousandSeparator={'.'}
      isNumericString={true}
      maxLength={30}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const validationSchema = yup.object({
  userId: yup.number().required('Por favor, escolha o usuário!'),
  periodId: yup.number().required('Por favor, escolha o período!'),
  projectId: yup.number().required('Por favor, escolha o projeto!'),
  qtdHoras: yup.number().required('Por favor, informe a qtd. horas!'),
})

/**
 * User Period form
 */
const Form = ({
  projects = [],
  periods = [],
  devs = [],
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const formik = useFormik({
    initialValues: {
      id: param.id,
      userId: user.office.tipCargo === 'OPERAÇÃO' ? user.id : param.userId,
      periodId: param.periodId,
      projectId: param.projectId,
      qtdHoras: param.qtdHoras,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSubmit(values)
    },
  })

  const classes = makeStyles({
    container: {
      marginTop: 50,
    },
  })()

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.container,
                    variant: 'menu',
                  },
                }}
                id="userId"
                name="userId"
                label="Usuário"
                value={formik.values.userId}
                onChange={formik.handleChange}
                error={formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={formik.touched.userId && formik.errors.userId}
                disabled={user.office.tipCargo === 'OPERAÇÃO'}
              >
                {devs.map(dev => (
                  <MenuItem key={dev.id} value={dev.id}>
                    {dev.desUsuario}
                  </MenuItem>
                ))}
              </TextField>
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.container,
                    variant: 'menu',
                  },
                }}
                id="periodId"
                name="periodId"
                label="Período"
                value={formik.values.periodId}
                onChange={formik.handleChange}
                error={
                  formik.touched.periodId && Boolean(formik.errors.periodId)
                }
                helperText={formik.touched.periodId && formik.errors.periodId}
              >
                {periods.map(period => (
                  <MenuItem key={period.id} value={period.id}>
                    {period.desPeriodo}
                  </MenuItem>
                ))}
              </TextField>
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.container,
                    variant: 'menu',
                  },
                }}
                id="projectId"
                name="projectId"
                label="Projeto"
                value={formik.values.projectId}
                onChange={formik.handleChange}
                error={
                  formik.touched.projectId && Boolean(formik.errors.projectId)
                }
                helperText={formik.touched.projectId && formik.errors.projectId}
              >
                {projects.map(project => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.desProjeto}
                  </MenuItem>
                ))}
              </TextField>
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                id="qtdHoras"
                name="qtdHoras"
                label="Qtd. Horas"
                value={formik.values.qtdHoras}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdHoras && Boolean(formik.errors.qtdHoras)
                }
                helperText={formik.touched.qtdHoras && formik.errors.qtdHoras}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <Button
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  projects: PropTypes.array,
  periods: PropTypes.array,
  devs: PropTypes.array,
  isDeleteAction: PropTypes.bool,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
  userPeriods: PropTypes.object,
}

export default Form
