import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  successMonthlyClose: null,
  errorMonthlyClose: null,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.monthlyClose.generateMonthlyClose.success:
      return {
        ...state,
        successMonthlyClose: 'Fechamento mensal gerado com sucesso!',
      }
    case types.monthlyClose.generateMonthlyClose.error:
      return {
        ...state,
        errorMonthlyClose: 'Falha ao gera fechamento mensal!',
      }

    default:
      return state
  }
}
