import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import * as actionEntryReport from '../../actions/entry'
import { getFilter as getPeriods } from '../../actions/period'
import * as proposalActions from '../../actions/proposal'
import * as proposalUserActions from '../../actions/proposalUser'
import * as executionActions from '../../actions/execution'
import { get as getUsers } from '../../actions/user'
import { getReportFechamentoRD } from '../../actions/entry'
import { globalStyles } from '../../styles/global'
import Main from '../Main'
import { Box, Grid } from '@material-ui/core'
import Filter from '../../utils/Filter/FilterFechamentoRD'

import Loading from '../../utils/Loading'
import Toast from '../../utils/Toast'

const EntryReportFechamentoRD = ({
  /** Actions */
  getReportFechamentoRD,
  getPeriods,
  getExecutions,
  fetchProposals,
  fetchProposalByUsers,
  cleanListPropostalRd,
  fetchProposalByUsersProposalReducer,
  getUsers,
  listUsers,
  changeProposalFilterPageSize = () => {},
  /** Reducer props */
  listPeriodsFilter,
  listProposal,
  executionList,
  errorReportRD = null,
  warningReportRD = null,
  successRD = null,
  mandatoryFieldsNotCompletedError = null,
  cleanToasterRd = null,
  cleanFiltersRd = null,
  cleanToasterProposalRd = null,
}) => {
  momentDurationFormatSetup(moment)
  const { id: idUsuario } = JSON.parse(localStorage.getItem('user'))
  const styles = globalStyles()

  const [showLoading, setShowLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [selectState, setSelectState] = useState({})
  const [exec, setExec] = useState(null)

  const [valuesFilter, setValuesFilter] = useState({
    idUser: idUsuario,
    dtInicial: moment(new Date()).format('LL'),
    dtFinal: moment(new Date()).format('LL'),
  })

  useEffect(() => {
    if (errorReportRD || warningReportRD) {
      setShowToast(true)
    } else {
      setShowToast(false)
    }
  }, [errorReportRD, warningReportRD])

  useEffect(() => {
    if (successRD) {
      filterProps.limparFields = true
    }
  }, [successRD])

  const validarCamposObrigatorios = values => {
    const {
      userIdPeriod,
      dtInicial,
      dtFinal,
      diasUteis,
      idProposta,
    } = values

    let qtdCampos = 0
    let camposNaoPreenchidos = ''

    if (!userIdPeriod) {
      camposNaoPreenchidos = ' Período'
      qtdCampos++
    }
    if (!idProposta) {
      camposNaoPreenchidos += (qtdCampos !== 0 ? ', ' : '') + ' Proposta'
      qtdCampos++
    }
    if (!dtInicial) {
      camposNaoPreenchidos += (qtdCampos !== 0 ? ', ' : '') + ' Data Inicial'
      qtdCampos++
    }
    if (!dtFinal) {
      camposNaoPreenchidos += (qtdCampos !== 0 ? ', ' : '') + ' Data Final'
      qtdCampos++
    }
    if (!diasUteis) {
      camposNaoPreenchidos +=
        (qtdCampos !== 0 ? ', ' : '') + ' Dias úteis do mês'
      qtdCampos++
    }
    let msgDias = ''
    if (diasUteis && diasUteis < 1) {
      msgDias = ' Dias úteis do mês deve ser maior do que 0.'
    }
    if (qtdCampos !== 0 || msgDias) {
      let msgCampos = ''
      if (qtdCampos !== 0) {
        if (qtdCampos === 1) {
          msgCampos =
            'O campo ' + camposNaoPreenchidos + ' deve ser informado. '
        } else {
          msgCampos =
            'Os campos ' + camposNaoPreenchidos + ' devem ser informados. '
        }
      }
      mandatoryFieldsNotCompletedError(msgCampos + msgDias)
      return false
    }
    return true
  }

  const closeTaster = () => {
    cleanToasterRd()
    cleanToasterProposalRd()
    setShowToast(false)
  }

  const cleanFilterReportRd = () => {
    cleanFiltersRd()
  }

  const changeFieldFilter = (name, value) => {
    if (name === 'idUser') {
      if (value !== null) {
        let userSelec = listUsers.filter(user => user.id === value)[0]
        fetchProposalByUsersProposalReducer({
          idUser: value,
          desUsuario: userSelec.desUsuario,
        })
      }
      else {
        fetchProposals()
      }
    }
     if (name === 'userExecution' && value === null) {
      fetchProposals()
    }
  }

  const handleFilter = async (values,detailedReport, enviar) => {
    const { userIdPeriod, diasUteis, txtDescricao, userExecution } = values
    if (enviar) {
      if (validarCamposObrigatorios(values)) {
        const dtInicial = moment(values.dtInicial).isValid()
          ? moment.utc(values.dtInicial).format('YYYY-MM-DDTHH:mm:ss')
          : ''

        const dtFinal = moment(values.dtFinal).isValid()
          ? moment.utc(values.dtFinal).format('YYYY-MM-DDTHH:mm:ss')
          : ''

        setValuesFilter({
          ...values,
          dtInicial: dtInicial,
          dtFinal: dtFinal,
        })

        await fetchProposals()
        await getReportFechamentoRD({
          idProposal: values.idProposta,
          idUser: values.idUser,
          workingDays: diasUteis ? Number(diasUteis) : null,
          ...(userIdPeriod && { idPeriod: userIdPeriod }),
          ...(txtDescricao && { txtDescription: txtDescricao }),
          ...(userExecution && {idExecution : userExecution}),
          detailedReport: detailedReport
        })
        await getExecutions()
      }
    }
  }

  let filterProps = {
      onSubmit: handleFilter,
      changeFieldFilter: changeFieldFilter,
      executeClearAllFields: cleanFilterReportRd,
      enviar: false,
      limparFields: successRD,
      monitoreState: (state) => {
        setSelectState(state.fields)
      },
      fields: [
        {
          type: 'select-data',
          name: 'userIdPeriod',
          label: 'Período',
          options: (listPeriodsFilter || []).map(
            ({ id, desPeriodo, dtaInicio, dtaFim }) => ({
              label: desPeriodo,
              value: id,
              dtaInicio,
              dtaFim,
            })
          ),
        },
        {
          type: 'date',
          name: 'dtInicial',
          label: 'Data inicial',
          disabled: true,
        },
        {
          type: 'date',
          name: 'dtFinal',
          label: 'Data final',
          disabled: true,
        },
        {
          type: 'select',
          name: 'userExecution',
          label: 'Execução',
          options: (executionList || [])
          .filter(item => selectState.idUser ? item.userExecution.id === selectState.idUser : true)
          .filter(item => item.period.id === selectState.userIdPeriod)
          .map((execution) => {
            return {
              label: `${execution.proposal.codProposta}`,
              value: execution.id
            }
          }),
          disabled: !selectState.userIdPeriod,
        },
      ],

      fieldsLine2: [
        {
          type: 'input',
          name: 'statusExecution',
          label: 'Status Execução',
          stateValues: exec ? exec.executionStatusType : '',
          disabled: true,
        },
        {
          type: 'select',
          name: 'idProposta',
          label: 'Proposta',
          options: (listProposal || [])
            .filter(item => item.desCliente === 'Raia Drogasil')
            .filter(period => {
              const dtaInicio = period.dtaInicio
                .split('/')
                .reverse()
                .join('-')
              const dtaFim = period.dtaFim
                .split('/')
                .reverse()
                .join('-')


              const dtaInicioFormatada =  moment(dtaInicio).format("YYYY-MM")
              const dtaFimFormatada = moment(dtaFim).format("YYYY-MM")

              if (selectState.useridPeriod === null) return false

              const selectedPeriod = moment(selectState.dtInicial).format('YYYY-MM-DD')
              const dataFormatada = moment(selectedPeriod).format("YYYY-MM")

              const isBetween = moment(dataFormatada).isBetween(
                moment(dtaInicioFormatada),
                moment(dtaFimFormatada),
                'month',
                '[]')

              return isBetween
            })
            .filter(item => !item.codProposta.startsWith('Cópia'))
            .sort((propostaA, propostaB) =>
              propostaA.codProposta.localeCompare(propostaB.codProposta)
            )
            .map(({id, codProposta}) => ({
              label: codProposta,
              value: id,
            })),
          disabled: exec && exec.proposal
        },
        {
          type: 'inputNumber',
          name: 'diasUteis',
          label: 'Dias úteis do mês',
          disabled: exec && exec.executionStatusType !== 'Aberto',
          initial: 21,
        },
      ],
      fieldsLine3: [
        {
          type: 'textarea',
          name: 'txtDescricao',
          label: 'Descrição',
           maxLength: 294
        }
      ],
    }

  useEffect(() => {
    setShowLoading(true)
    if (listPeriodsFilter == null || listPeriodsFilter.length === 0) {
      getPeriods({ indFechado: 1, sort: 'dtaInicio desc' })
    }

    changeProposalFilterPageSize(9999)
    fetchProposals()
    setShowLoading(false)
  }, [])

  useEffect(() => {
    if (Object.keys(valuesFilter).length !== 0) {
      handleFilter(valuesFilter)
    }
  }, [])

  useEffect(() => {
    getExecutions()
  }, [])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  useEffect(() => {
    const execution = executionList.find(item => item.id === selectState.userExecution)
    setExec(execution)
  }, [selectState.userExecution])

  return (
    <Main title="RD Fechamento e Relatório">
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <Grid item xs={12}>
            <Box component="div" className={styles.box} xs={12}></Box>
            <Box component="div" className={styles.filter} xs={12}>
              <Filter {...filterProps} executionList={executionList || []} />
            </Box>
          </Grid>
        </>
      )}

      <Toast
        autoHideDuration={3000}
        onClose={() => closeTaster()}
        open={showToast}
        error={errorReportRD}
        success={warningReportRD}
      />
    </Main>
  )
}

const mapStateToProps = state => {
  const { userReducer, periodReducer, entryReducer, proposalReducer, executionReducer } = state
  return {
    ...userReducer,
    ...periodReducer,
    ...proposalReducer,
    ...entryReducer,
    executionList: executionReducer.list,
    listUsers: userReducer.listUsers,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionEntryReport,
      ...proposalActions,
      ...proposalUserActions,
      getPeriods,
      getExecutions: executionActions.get,
      changeProposalFilterPageSize: proposalActions.changePageSize,
      getUsers,
      getReportFechamentoRD
    },
    dispatch
  )

  EntryReportFechamentoRD.propTypes = {
  /** Actions */
  getUsers: PropTypes.func.isRequired,
  getPeriods: PropTypes.func.isRequired,
  getReportFechamentoRD: PropTypes.func.isRequired,
  fetchProposalByUsersProposalReducer: PropTypes.func.isRequired,
  getExecutions: PropTypes.func.isRequired,
  /** Reducer props */
  listPeriodsFilter: PropTypes.array.isRequired,
  listProposal: PropTypes.array.isRequired,
  mandatoryFieldsNotCompletedError: PropTypes.func,
  cleanToasterRd: PropTypes.func,
  cleanFiltersRd: PropTypes.func,
  successRD: PropTypes.string,
  errorReportRD: PropTypes.object,
  warningReportRD: PropTypes.object,
  executionList: PropTypes.array,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryReportFechamentoRD)
