import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SelectAutocompleteField = ({
  label,
  value,
  options,
  onChange,
  error,
  helperText,
  defaultValue,
  menuProps,
}) => {
  return (
    <Autocomplete
      id="select-autocomplete-field"
      options={options}
      value={value}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={error}
          helperText={helperText}
          defaultValue={defaultValue}
        />
      )}
      {...menuProps}
    />
  );
};

SelectAutocompleteField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  defaultValue: PropTypes.any,
  menuProps: PropTypes.object,
};

export default SelectAutocompleteField;
