import React from 'react'
import PropTypes from 'prop-types'
import { Visibility } from '@material-ui/icons'
import { Link } from "react-router-dom"
import { useStyles } from '../useStyles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as executionActions from '../../../actions/execution'


const CustomActions = props => {
  
  const {filter, rowData, changeExecutionId, setExecutionToEdit, executionList} = props

  const classes = useStyles()

  return (
  <>
      <span>
      <button
        type="button"
        onClick={() => {
          const execution = executionList.find(item => item.id === rowData.id)
          changeExecutionId(execution.id, execution.proposal.id)
          setExecutionToEdit(execution, null)
        }}
        className={classes.unstyledLink}
        title="Ir para execução"
      >
      <Link
        to={`/pre-closure-execution-user?disabled=${filter.isProposalFilterDisabled}`}
        className={classes.link}
        title='Ir para execução de usuários'
      >
        <Visibility />
      </Link>
     </button>
    </span>
  </>
  )
}

CustomActions.propTypes = {
  rowData: PropTypes.object.isRequired,
  onDuplicate: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const { executionReducer } = state

  return {
    executionList: executionReducer.list,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...executionActions,
      changeExecutionId: executionActions.changeExecutionId,
      setExecutionToEdit: executionActions.setExecutionToEdit,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CustomActions)
