import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  period: {
    label: '',
    value: null
  },
  proposal: {
    label:'',
    value: null
  },
  user: {
    label:'',
    value: null
  },
  list: [],
  feedback: {
    error: null,
    success: null,
    message: '',
  },
  listProposal: [],
  listPeriods: [],
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.entryAutomatic.setPeriod:
      return {
        ...state,
        period: {
          label: action.payload.label,
          value: action.payload.value
        },
      }

    case types.entryAutomatic.setProposal:
      return {
        ...state,
        proposal: {
          label: action.payload.label,
          value: action.payload.value
        }
      }

    case types.entryAutomatic.setUser:
      return {
        ...state,
        user: {
          label: action.payload.label,
          value: action.payload.value
        },
      }

    case types.entryAutomatic.getList.success:
      return {
        ...state,
        feedback: {
          success: true,
          error: false,
          message: 'Lançamentos encontrados!',
        },
        list: [...action.payload],
      }

    case types.entryAutomatic.getList.error:
      return {
        ...state,
        feedback: {
          success: false,
          error: true,
          message: action.payload,
        },
        list: [],
      }

    case types.entryAutomatic.clearList:
      return {
        ...state,
        list: [],
      }

    case types.entryAutomatic.clearFilters:
      return {
        ...state,
        period: {
          label: '',
          value: null
        },
        proposal: {
          label:'',
          value: null
        },
        user: {
          label:'',
          value: null
        },
      }

    case types.entryAutomatic.generateEntry.success:
      return {
        ...state,
        feedback: {
          success: true,
          error: false,
          message: 'Lançamentos gerados com sucesso!',
        },
      }

    case types.entryAutomatic.generateEntry.error:
      return {
        ...state,
        feedback: {
          success: false,
          error: true,
          message: action.payload.message.messageError,
        }
      }

    case types.entryAutomatic.getProposal.success:
      return {
        ...state,
        listProposal: action.payload
      }

    case types.entryAutomatic.getProposal.error:
      return {
        ...state,
        feedback: {
          error: true,
          success: false,
          message: 'Erro ao buscar as propostas!',
        }
      }

    case types.entryAutomatic.getPeriod.success:
      const { resultList } = action.payload
      return {
        ...state,
        listPeriods: resultList || [],
      }

    case types.entryAutomatic.getPeriod.error:
      return {
        ...state,
        feedback: {
          error: true,
          success: false,
          message: 'Erro ao buscar períodos',
        }
      }

    default:
      return {
        ...state
      }
  }
}
