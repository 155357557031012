import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AddIcon from '@material-ui/icons/Add'
import { TableContainer, Paper, Box, Fab } from '@material-ui/core'

import * as action_client from '../../actions/client'

import Form from './components/Form'
import Main from '../Main'
import { globalStyles } from '../../styles/global'
import Dialog from '../../utils/Dialog'
import TablePagination from '../../utils/Table/TablePagination'
import Filter from '../../utils/Filter/Filter'
import Toast from '../../utils/Toast'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'id'

const DEFAULT_PARAM = {
  id: null,
  descCliente: '',
  indInativo: '',
}

const doNotShowTable = ['indInativo']

const headersTable = ['ID', 'Nome']

const Client = ({
  /** Actions */
  get,
  getFilter,
  remove,
  save,
  update,
  /** Reducer props */
  listClients,
  listClientsFilter,
  errorClients,
  successClients,
  totalElementsClients,
}) => {
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState('Novo cliente')

  const [showToast, setShowToast] = useState(false)

  const [valuesFilter, setValuesFilter] = useState({})

  const handleFilter = async values => {
    const { id, indInativo } = values

    await getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(id && { id }),
      ...(typeof indInativo === 'boolean' && { indInativo }),
    })
  }

  let filterProps = {
    onSubmit: values => {
      setValuesFilter(values)
    },
    fields: [
      {
        type: 'select',
        name: 'id',
        label: 'Nome',
        options: listClients.map(client => ({
          value: client.id,
          label: client.descCliente,
        })),
      },
      {
        type: 'select',
        name: 'indInativo',
        label: 'Status',
        initial: true,
        options: [
          { value: true, label: 'ATIVO' },
          { value: false, label: 'INATIVO' },
        ],
      },
    ],
  }

  const query = async () => {
    await get()
  }

  const convertRecord = record => {
    return {
      ...record,
      descCliente: record.descCliente || '',
      indInativo: record.indInativo
        ? 'ATIVO'
        : record.indInativo === false
        ? 'INATIVO'
        : record.indInativo,
    }
  }

  const handleSubmit = async values => {
    setShowDialog(false)

    await onSubmit(values)

    setShowToast(true)

    handleFilter(valuesFilter)

    return null
  }

  const startSave = () => {
    setParam(convertRecord(DEFAULT_PARAM))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo cliente')

    setShowDialog(true)
  }

  const startDelete = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(true)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)
    setTitle(`Você deseja deletar o cliente ${record.descCliente}?`)

    setShowDialog(true)
  }

  const startEdit = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)
    setTitle('Alterar registro')

    setShowDialog(true)
  }

  useEffect(() => {
    if (page === DEFAULT_PAGINATION_PAGE) {
      handleFilter(valuesFilter)
    } else {
      setPage(DEFAULT_PAGINATION_PAGE)
    }
  }, [valuesFilter])

  useEffect(() => {
    Object.keys(valuesFilter).length !== 0
      ? handleFilter(valuesFilter)
      : handleFilter({ indInativo: true })
  }, [page, size])

  useEffect(() => {
    query()
  }, [])

  return (
    <Main title="Clientes">
      <Box component="div" className={styles.box} xs={12}>
        <Fab color="primary" onClick={startSave}>
          <AddIcon />
        </Fab>
      </Box>
      <Box component="div" className={styles.filter} xs={6}>
        <Filter {...filterProps} />
      </Box>
      <TableContainer component={Paper}>
        <TablePagination
          titleTable="client"
          count={totalElementsClients}
          doNotShow={doNotShowTable}
          headers={headersTable}
          onChangePage={(event, page) => setPage(page)}
          onChangeSize={event => setSize(event.target.value)}
          page={page}
          rows={listClientsFilter}
          size={size}
          startDelete={startDelete}
          startEdit={startEdit}
          alignTitle={['right', 'left']}
          alignItens={['right', 'left']}
        />
      </TableContainer>
      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
      >
        <Form
          isDeleteAction={isDeleteAction}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          primaryButtonColor={primaryButtonColor}
          primaryButtonLabel={primaryButtonLabel}
        />
      </Dialog>
      <Toast
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successClients}
        error={errorClients}
      />
    </Main>
  )
}

const mapStateToProps = ({ clientReducer }) => ({ ...clientReducer })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...action_client }, dispatch)

Client.propTypes = {
  /** Actions */
  get: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  /** Reducer props */
  listClients: PropTypes.array.isRequired,
  listClientsFilter: PropTypes.array.isRequired,
  errorClients: PropTypes.string.isRequired,
  successClients: PropTypes.string.isRequired,
  totalElementsClients: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Client)
