import { types, trigger } from '../../config/action_types'
import Http from '../../config/Http'
import URL from '../../config/URL'

export const getFilter = param => async dispatch => {
  try {
    dispatch(trigger(types.project.getFilter.request))

    const response = await new Http(dispatch).get(
      URL.project + '/filter',
      param
    )

    return dispatch(trigger(types.project.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.project.getFilter.error, error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.project, param)

    return dispatch(trigger(types.project.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.project.get.error))
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.project, param)

    return dispatch(trigger(types.project.delete.success))
  } catch (error) {
    return dispatch(trigger(types.project.delete.error, error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.project, param)

    return dispatch(trigger(types.project.save.success))
  } catch (e) {
    return dispatch(trigger(types.project.save.error))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.project, param)

    return dispatch(trigger(types.project.update.success))
  } catch (e) {
    return dispatch(trigger(types.project.update.error))
  }
}
