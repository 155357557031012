import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { isEmailValid } from '../../utils/masks'
import useStyles from './useStyles'
import Button from '@material-ui/core/Button'
import Loading from '../../utils/Loading'
import Logo from '../../../assets/image/logo.png'
import TextField from '@material-ui/core/TextField'
import Toast from '../../utils/Toast'
import Dialog from '../../utils/Dialog'
import Form from './components/Password'

import * as loginActions from '../../actions/login'

const Login = ({
  error = null,
  resetPassword = false,
  redirectToHome = false,
  newPassord = null,
  history: { push = () => {} },
  dismissError = null,
  isLoad = false,
  login = () => {},
  cancelarTrocaSenha = () => {},
}) => {
  const [desEmail, setDesEmail] = useState('')
  const [desSenha, setDesPassword] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [showPasswordResetDialog, setShowPasswordResetDialog] = useState(false)

  const closeError = () => {
    dismissError()
    setShowToast(false)
  }

  /**
   * Show toast if error have returned
   */
  useEffect(() => {
    if (!showToast && error) {
      setShowToast(true)
    }
  })

  useEffect(() => {
    if (redirectToHome) {
      handleSubmit()
    } else {
      if (resetPassword) {
        setShowPasswordResetDialog(true)
      } else {
        setShowPasswordResetDialog(false)
      }
    }
  }, [resetPassword, redirectToHome])

  const classes = useStyles()

  const cancelar = () => {
    cancelarTrocaSenha({ desEmail, desSenha })
  }

  const renderLogin = () => (
    <div maxWidth="" className={classes.container}>
      <img src={Logo} alt="Logo Develcode" className={classes.image} />
      <form autoComplete="off" noValidate className={classes.form}>
        <TextField
          label="Email"
          onChange={e => setDesEmail(e.target.value)}
          value={desEmail}
        />

        <TextField
          label="Senha"
          onChange={e => setDesPassword(e.target.value)}
          type="password"
          value={desSenha}
          onKeyDown={e =>
            e.keyCode === 13 && isEmailValid(desEmail) && handleSubmit()
          }
        />

        <div className={classes.forgotPasswordLinkContainer}>
          <Link to='forgot-password' className={classes.forgotPasswordLink}>
            Esqueci minha senha
          </Link>
        </div>

        <Button
          className={classes.button}
          disabled={!isEmailValid(desEmail) || desSenha.length < 1}
          color="primary"
          onClick={handleSubmit}
          type="submit"
          variant="outlined"
        >
          Entrar
        </Button>
      </form>

      <Dialog
        onClose={() => cancelar()}
        open={showPasswordResetDialog}
        desSenha={desSenha}
        push={push}
        title={'Alterar senha'}
      >
        <Form onCancel={() => cancelar()}></Form>
      </Dialog>

      <Toast
        error={error}
        onClose={closeError}
        open={showToast}
        success={null}
      />
    </div>
  )

  const handleSubmit = () => {
    if (newPassord !== null && newPassord !== '') {
      login({ desEmail, desSenha: newPassord }, push)
    } else {
      login({ desEmail, desSenha }, push)
    }
  }

  return isLoad ? Loading() : renderLogin()
}

const mapStateToProps = ({ loadReducer, loginReducer }) => ({
  ...loadReducer,
  ...loginReducer,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...loginActions }, dispatch)

Login.propTypes = {
  error: PropTypes.string,
  newPassord: PropTypes.string,
  resetPassword: PropTypes.bool,
  redirectToHome: PropTypes.bool,
  isLoad: PropTypes.bool,
  login: PropTypes.func,
  cancelarTrocaSenha: PropTypes.func,
  dismissError: PropTypes.func,
  push: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
