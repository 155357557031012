import React from 'react'
import { ptBR } from 'date-fns/locale'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const Date = ({
  name,
  label,
  setValueToField,
  stateValues,
  disabled,
  onChange = () => {},
  minDate,
  maxDate,
  style = {},
}) => {
  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDatePicker
        format="dd/MM/yyyy"
        margin="none"
        id="date-picker-inline"
        maxDateMessage="Data inicial não pode ser maior que a data final"
        minDateMessage="Data final não pode ser menor que a data inicial"
        invalidDateMessage="Data inválida!"
        label={label}
        inputVariant="outlined"
        okLabel="OK"
        cancelLabel="Cancelar"
        onChange={newDate => {
          setValueToField(name, newDate)
          onChange(newDate)
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputLabelProps={{
          shrink: true,
        }}
        value={stateValues}
        autoOk
        clearable
        clearLabel="Limpar"
        fullWidth
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        style={style}
      />
    </MuiPickersUtilsProvider>
  )
}

export default Date
