import React from 'react'

import AccessTimeIcon from '@material-ui/icons/AccessTime'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import BallotIcon from '@material-ui/icons/Ballot'
import Snooze from '@material-ui/icons/Snooze'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import MailIcon from '@material-ui/icons/Mail'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import LockIcon from '@material-ui/icons/Lock'
import PersonAddAlt1 from '@material-ui/icons/PeopleSharp'
import PostAdd from '@material-ui/icons/PostAdd'

const addIfNotInProd = (menuItem) => {
  const { href } = window.location
  const prodHostname = "develcode-app-prd.s3-website-us-east-1.amazonaws.com"
  if (href.includes(prodHostname)) return null
  return menuItem
}

const menus = [
  {
    text: 'Home',
    icon: <DashboardIcon />,
    link: '/home',
    target: false,
  },
  {
    text: 'Email',
    icon: <MailIcon />,
    link:
      'https://accounts.google.com/signin/v2/identifier?service=mail&passive=true&rm=false&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&ss=1&scc=1&ltmpl=default&ltmplcache=2&emr=1&osid=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin',
    externalLink: true,
  },
  {
    text: 'Alterar Senha',
    icon: <LockIcon />,
    link: '/change-password',
  },
]

const sair = [
  {
    text: 'Sair',
    icon: <ExitToAppIcon />,
    link: '/login',
    logout: () => {
      localStorage.clear()
    },
  },
]

const menu_operation = [
  { text: 'Cadastros', icon: <AccountBalanceIcon />,
    list: [

      {
        text: 'Consulta Colaborador',
        icon: <PersonAddAlt1 />,
        link: '/consulta-colaborador',
      },
    ]
  },
  {
    text: 'Lançamentos', icon: <BallotIcon />,
    list: [
      {
        text: 'Consolidado',
        icon: <BallotIcon />,
        link: '/entry-report',
      },
      { text: 'Lançamentos', icon: <BallotIcon />, link: '/entries' },
      {
        text: 'Férias/Atestados/Folgas',
        icon: <Snooze />,
        link: '/entry-vacation',
      },
      addIfNotInProd({
        text: 'Banco de Horas - Consulta',
        icon: <AccountBalanceIcon />,
        link: '/hour-bank-consult',
      }),
    ].filter(Boolean)
  }
]

const menu_coordinator = [
  { text: 'Cadastros', icon: <AccountBalanceIcon />,
    list: [

      {
        text: 'Consulta Colaborador',
        icon: <PersonAddAlt1 />,
        link: '/consulta-colaborador',
      },
    ]
  },
  { text: 'Atividades', icon: <AssignmentIcon />,
  list: [  {
    text: 'Atividades',
    icon: <AssignmentIcon />,
    link: '/tasks',
  },]
  },
  { text: 'Lançamentos', icon: <BallotIcon />,
  list: [
    {
      text: 'Consolidado',
      icon: <BallotIcon />,
      link: '/entry-report',
    },
    { text: 'Lançamentos', icon: <BallotIcon />, link: '/entries' },
    {
      text: 'Férias/Atestados/Folgas',
      icon: <Snooze />,
      link: '/entry-vacation',
    },
  ]},
  { text: 'Fechamento Usuários', icon: <BallotIcon />,
    list: [
      addIfNotInProd({
        text: 'Banco de Horas - Consulta',
        icon: <AccountBalanceIcon />,
        link: '/hour-bank-consult',
      }),
      {
        text: 'Usuários por Período',
        icon: <HowToRegIcon />,
        link: '/user-period',
      },
    ].filter(Boolean)
  },
  { text: 'Fechamento Clientes', icon: <FormatIndentIncreaseIcon />,
    list: [
      {
        text: 'RD Fechamento e Relatório',
        icon: <BallotIcon />,
        link: '/entry-report-fechamento-alocacaoRD',
      }
    ]
  }
]

const menu_administrator = [
  { text: 'Cadastros', icon: <AccountBalanceIcon />,
    list: [
      { text: 'Cargos', icon: <BusinessCenterIcon />, link: '/offices' },
      { text: 'Usuários', icon: <AssignmentIndIcon />, link: '/users' },
      { text: 'Clientes', icon: <TrackChangesIcon />, link: '/clients' },
      { text: 'Projetos', icon: <AccountTreeIcon />, link: '/projects' },
      { text: 'Períodos', icon: <AccessTimeIcon />, link: '/periods' },
      {
        text: 'Colaboradores',
        icon: <PersonAddAlt1 />,
        link: '/colaborador',
      },
      {
        text: 'Consulta Colaborador',
        icon: <PersonAddAlt1 />,
        link: '/consulta-colaborador',
      },
    ]
  },
  { text: 'Atividades', icon: <AssignmentIcon />,
    list: [{
      text: 'Atividades',
      icon: <AssignmentIcon />,
      link: '/tasks',
    }]
  },
  { text: 'Lançamentos', icon: <BallotIcon />,
    list: [
      {
        text: 'Consolidado',
        icon: <BallotIcon />,
        link: '/entry-report',
      },
      { text: 'Lançamentos', icon: <BallotIcon />, link: '/entries' },
      { text: 'Lançamentos ADM', icon: <BallotIcon />, link: '/entriesADM' },
      { text: 'Lançamentos Automáticos', icon: <BallotIcon />, link: '/EntryAutomatic' },
      {
        text: 'Férias/Atestados/Folgas',
        icon: <Snooze />,
        link: '/entry-vacation',
      },
    ]
  },
  { text: 'Fechamento Usuários', icon: <BallotIcon />,
    list: [
      {
        text: 'Conferência de Horas',
        icon: <FormatIndentIncreaseIcon />,
        link: '/conferencia-de-horas',
      },
      {
        text: 'Fechamento Período',
        icon: <FormatIndentIncreaseIcon />,
        link: '/monthly-close',
      },
      {
        text: 'Banco de Horas',
        icon: <AccountBalanceIcon />,
        link: '/hour-bank',
      },
      addIfNotInProd({
        text: 'Banco de Horas - Consulta',
        icon: <AccountBalanceIcon />,
        link: '/hour-bank-consult',
      }),
      {
        text: 'Clientes por Período',
        icon: <RecentActorsIcon />,
        link: '/client-period',
      },
      {
        text: 'Usuários por Período',
        icon: <HowToRegIcon />,
        link: '/user-period',
      },
    ].filter(Boolean)
  },
  { text: 'Fechamento Clientes', icon: <FormatIndentIncreaseIcon />,
    list: [
      {
        text: 'Fechamento Clientes',
        icon: <BallotIcon />,
        link: '/closureClients',
      },
      { text: 'Propostas', icon: <PostAdd />, link: '/proposal' },
      { text: 'Pré-Fechamento', icon: <PostAdd />, link: '/pre-closure' },
      // {
      //   text: 'RD Fechamento e Relatório',
      //   icon: <BallotIcon />,
      //   link: '/entry-report-fechamento-alocacaoRD',
      // },
      {
        text: 'RD Ponto',
        icon: <BallotIcon />,
        link: '/relatorio-ponto-rd',
      },
      // {
      //   text: 'Lerroy Squad (Em dev)',
      //   icon: <BallotIcon />,
      //   link: '/', //link será gerado
      // },
      // {
      //   text: 'Lerroy Body Shop (Em dev)',
      //   icon: <BallotIcon />,
      //   link: '/' //link será gerado
      // }
    ]
  },
]

export {
  sair, menus, menu_operation, menu_coordinator, menu_administrator
}
