const YES = 'Sim'

/**
 * Convert string to boolean (if {@link YES}, return true, otherwise, return false)
 *
 * @param {string} boolean - String
 * @param {boolean} - Boolean
 */
export const convertBoolean = boolean => {
  return YES === boolean
}

export const isNullOrUndefined = value => {
  return value === null || value === undefined
}
