import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 460,
  },
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    width: '330px',
    marginTop: '50px',
  },
}))

export default useStyles
