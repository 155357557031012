import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Button, ButtonGroup } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import ReorderIcon from '@material-ui/icons/Reorder'
import Checkbox from './components/Checkbox'
import Date from './components/Date'
import DateRange from './components/DateRange'
import Input from './components/Input'
import SelectMultiple from './components/SelectMultiple'
import Select from './components/Select'

class Filter extends Component {
  state = {
    fields: {},
    wasClear: false,
  }

  componentDidMount() {
    const { fields, fieldsLine2 = {} } = this.props
    const fieldsCompleted = {}

    const totalFields = fields.concat(fieldsLine2)

    totalFields.forEach(
      field =>
        (fieldsCompleted[field.name] =
          field.initial || this.createInitialValue(field.type))
    )

    this.setState({
      fields: fieldsCompleted,
    })
  }

  componentDidUpdate() {
    if (this.props.monitoreState) this.props.monitoreState(this.state)
  }

  clearAllFields = () => {
    const { fields, fieldsLine2 = {},  isEntryAdm } = this.props
    const fieldsCompleted = {}

    const totalFields = fields.concat(fieldsLine2)
    const { id: idUsuario } = JSON.parse(localStorage.getItem('user')) || {}
    totalFields.forEach(
      field =>
        (fieldsCompleted[field.name] =
          isEntryAdm=== true ?
          field.name === 'idUser'
            ? idUsuario
            : this.createInitialValue(field.type):
          field.disabled && field.initial
            ? field.initial
            : this.createInitialValue(field.type))
    )

    this.setState({
      fields: fieldsCompleted,
      wasClear: true,
    })

  }

  setValueToField = (field, value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: value,
      },
    })

  }

  createInitialValue = type => {
    switch (type) {
      case 'select_multiple':
        return []
      case 'checkbox':
        return false
      case 'date_range':
        return [null, null]
      case 'input':
        return ''
      default:
        return null
    }
  }

  buildField(props) {

    switch (props.type) {      
      case 'select':
        return (
          <Select
            wasClear={this.state.wasClear}
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'select_multiple':
        return (
          <SelectMultiple
            wasClear={this.state.wasClear}
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'checkbox':
        return (
          <Checkbox
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'date':
        return (
          <Date
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'date_range':
        return (
          <DateRange
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'input':
        return (
          <Input
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )

      default:
        return
    }
  }

  render() {
    const {
      fields,
      fieldsLine2,
      onSubmit = () => {},
      gridSizeLine1,
      gridSizeLine2,
      gridSizeButtons,
    } = this.props

    return (
      <Grid
        container
        className="common-filter"
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        {(fields || []).map((field, i) => (
          <Grid xs={gridSizeLine1} item key={i} className="filter-item">
            {this.buildField(field)}
          </Grid>
        ))}

        {(fieldsLine2 || []).map((field, i) => (
          <Grid xs={gridSizeLine2} item key={i} className="filter-item">
            {this.buildField(field)}
          </Grid>
        ))}

        <Grid item xs={gridSizeButtons}  className="submit-button-container">
          <ButtonGroup fullWidth disableElevation variant="contained" size="small">
            <Button
              style={{ paddingLeft: 25, paddingRight: 25 }}
              startIcon={<FilterListIcon />}
              color="primary"
              onClick={() => onSubmit(this.state.fields)}
              className="submit-button"
            >
              Filtrar
            </Button>
            <Button
              style={{ paddingLeft: 25, paddingRight: 25 }}
              startIcon={<ReorderIcon />}
              color="secondary"
              onClick={() => this.clearAllFields()}
              className="submit-button"
            >
              Limpar
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    )
  }
}

Filter.defaultProps = {
  gridSizeLine1: 3,
  gridSizeLine2: 2,
  gridSizeButtons: 1.5,
}

Filter.propTypes = {
  fields: PropTypes.array.isRequired,
  fieldsLine2: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  gridSizeLine1: PropTypes.number,
  gridSizeLine2: PropTypes.number,
  gridSizeButtons: PropTypes.number,
}

export default Filter
