import { differenceInMonths, differenceInYears, parse } from 'date-fns';


export function calculateDiffDatas(dataAdmissao, dataReferencia, notHiringTime, monthHiringTime) {


  const dataInicio = parse(dataAdmissao, 'yyyy-MM-dd', new Date());
  const dataFim = parse(dataReferencia, 'MMMM d, yyyy', new Date());
  dataInicio.setDate(0)
  dataInicio.setMonth(dataInicio.getMonth());

  const anos = differenceInYears(dataFim, dataInicio);
  const meses = (differenceInMonths(dataFim, dataInicio) % 12) ;

  console.log("Data inicio ", dataInicio)



  if(monthHiringTime !== null && notHiringTime === true && monthHiringTime !== ''){  

    const ano = calcularTempoEmpresa(dataAdmissao, nameMonthToNumberMonth(monthHiringTime))
    return `${ano} ${ano === 1 ? 'ano' : 'anos'}`;
  }

  if (anos === 0 && meses === 0) {
    return 'Menos de 1 mês';
  } 
  else if(anos === 0 && meses === 12){
    return '1 ano';
   }  
   else if(anos === 1 && meses === 12){
    const anosFinal = anos + 1
    return `${anosFinal} ${anosFinal === 1 ? 'ano' : 'anos' 
    }`;
   } 
  else if (anos === 0) {
    return `${meses} ${meses === 1 ? 'mês' : 'meses'}`;
  } else if (meses === 0) {
    return `${anos} ${anos === 1 ? 'ano' : 'anos'}`;
  } else {
    return `${anos} ${anos === 1 ? 'ano' : 'anos'} e ${meses} ${
      meses === 1 ? 'mês' : 'meses'
    }`;
  }
}

export function nameMonthToNumberMonth(nameMonth) {
  const month = {
    Janeiro: 1,
    Fevereiro: 2,
    Março: 3,
    Abril: 4,
    Maio: 5,
    Junho: 6,
    Julho: 7,
    Agosto: 8,
    Setembro: 9,
    Outubro: 10,
    Novembro: 11,
    Dezembro: 12,
  };
  
  if (month[nameMonth]) {
    return month[nameMonth];
  } else {
    return null; 
  }
}

function calcularTempoEmpresa(dataAdmissao, mesFiltro) {

  const dataAdmissaoObj = new Date(dataAdmissao);
  const anoAdmissao = dataAdmissaoObj.getFullYear();
  const anoAtual = new Date().getFullYear();

  let tempoEmpresa = anoAtual - anoAdmissao;


  return tempoEmpresa;
}
