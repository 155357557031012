import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

const InputNumber2 = ({
  name,
  label,
  setValueToField,
  stateValues,
  disabled,
  onBlur,
  controlled = false
}) => {
  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        allowNegative={false}
        decimalScale={0}
        fixedDecimalScale={true}
        isNumericString={true}
        maxLength={2}
      />
    )
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <TextField
        value={isNaN(Number(stateValues)) ? '' : stateValues}
        label={label}
        id={name}
        name={name}
        variant="outlined"
        onBlur={({ target: { value } }) => {
          controlled ? onBlur(value) : setValueToField(name, value)
        }}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        disabled={disabled}
      />
    </FormControl>
  )
}

export default InputNumber2