import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.office + '/filter', param)

    return dispatch(trigger(types.office.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.office.getFilter.error, error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.office, param)

    return dispatch(trigger(types.office.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.office.get.error))
  }
}

export const remove = param => async dispatch => {
  try {
    const responseActions = await new Http(dispatch).delete(URL.office, param)

    return dispatch(trigger(types.office.delete.success, responseActions.data))
  } catch (e) {
    return dispatch(trigger(types.office.delete.error, e))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.office, param)

    return dispatch(trigger(types.office.save.success))
  } catch (e) {
    return dispatch(trigger(types.office.save.error))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.office, param)

    return dispatch(trigger(types.office.update.success))
  } catch (e) {
    return dispatch(trigger(types.office.update.error))
  }
}
