import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useFormik } from 'formik'

import { Box, TextField } from '@material-ui/core/'
import { Autocomplete } from '@material-ui/lab'
import NumberFormat from 'react-number-format'

import * as proposalUserActions from '../../../actions/proposalUser'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import Button from '../../../utils/Form/Button'
import { Loader } from '../../../utils/Loading'
import { validateValues } from './validations'
import Toast from '../../../utils/Toast'
import { types } from '../../../../config/action_types'
import useFeedback from '../../../utils/hooks/useFeedback'

const NO_OPTIONS_TEXT = 'Sem opções'

const DEFAULT_FORMIK_VALUES = {
  id: '',
  funcao: null,
  user: null,
  perAlocacao: '',
}

const NumberFormatCustom = props => {
  const { formik, name, inputRef, errorAttribute } = props

  return (
    <NumberFormat
      {...props}
      name={name}
      getInputRef={inputRef}
      onValueChange={values => {
        formik.setFieldValue(name, values.floatValue).then(() => {
          formik.setFieldError(errorAttribute, '')
        })
      }}
      suffix="%"
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={0}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString={true}


    />
  )
}

const Form = props => {
  const {
    proposalUserToEdit = null,
    listProposalFunction = [],
    listProposalUser = [],
    updateProposalUser,
    saveProposalUser,
    handleCloseForm,
    fetchProposalUsers,
    listUsers,
  } = props

  const isProposalUserEdit = !!proposalUserToEdit

  const { feedback, hasFeedback, setError, clearFeedback } = useFeedback()

  const [isLoading, setIsLoading] = useState(false)

  const handleResponse = async response => {
    const { type, payload: message } = response
    const hasError =
      type === types.proposalUser.save.error ||
      type === types.proposalUser.update.error

    if (hasError) {
      setError(message)
      return
    }

    await fetchProposalUsers()
    handleCloseForm(message)
  }

  const handleSubmit = async values => {
    setIsLoading(true)
    const { funcao, user, ...other } = values

    const proposalFunction = {
      ...other,
      funcao: funcao.id,
      user: user.id,
    }

    let response
    if (isProposalUserEdit) {
      response = await updateProposalUser(proposalFunction)
    }

    if (!isProposalUserEdit) {
      response = await saveProposalUser(proposalFunction)
    }

    await handleResponse(response)
    setIsLoading(false)
  }

  const resolveInitialValues = () => {
    if (isProposalUserEdit) {
      const proposalUser = listProposalUser.find(
        proposalUser => proposalUser.id === proposalUserToEdit
      )

      if (!proposalUser) return DEFAULT_FORMIK_VALUES

      const proposalFunction = listProposalFunction.find(
        p => p.id === proposalUser.funcao
      )

      const user = listUsers.find(p => p.id === proposalUser.user)

      return {
        id: proposalUser.id,
        perAlocacao: proposalUser.perAlocacao,
        funcao: proposalFunction,
        user,
      }
    }

    return DEFAULT_FORMIK_VALUES
  }

  const formik = useFormik({
    initialValues: resolveInitialValues(),
    validateOnChange: false,
    validate: validateValues,
    onSubmit: handleSubmit,
  })

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <FlexWrapper>
            <FlexItemWrapper style={{ width: '51%' }}>
              <Autocomplete
                id="funcao"
                name="funcao"
                fullWidth
                noOptionsText={NO_OPTIONS_TEXT}
                options={listProposalFunction}
                getOptionLabel={(option = {}) => `${option.codProposta} - ${option.desFuncao}`}
                value={formik.values.funcao || null}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Função"
                    error={!!formik.errors.erroFuncao}
                    helperText={formik.errors.erroFuncao}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('funcao', option).then(() => {
                    formik.setFieldError('erroFuncao', '')
                  })
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper middle style={{ width: '25%' }}>
              <TextField
                label="Senioridade"
                fullWidth
                value={(formik.values.funcao || {}).desSenioridade || ''}
                disabled
              />
            </FlexItemWrapper>
            <FlexItemWrapper right style={{ width: '25%' }}>
              <TextField
                label="Cargo"
                fullWidth
                value={(formik.values.funcao || {}).desCargo || ''}
                disabled
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                label="Proposta"
                value={(formik.values.funcao || {}).codProposta || ''}
                fullWidth
                disabled
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <TextField
                label="Cliente"
                fullWidth
                value={(formik.values.funcao || {}).desCliente || ''}
                disabled
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <Autocomplete
                id="user"
                name="user"
                fullWidth
                noOptionsText={NO_OPTIONS_TEXT}
                options={listUsers}
                getOptionLabel={(option = {}) => option.desUsuario}
                value={formik.values.user || null}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Usuário"
                    error={!!formik.errors.erroUsuario}
                    helperText={formik.errors.erroUsuario}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('user', option).then(() => {
                    formik.setFieldError('erroUsuario', '')
                  })
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <TextField
                id="perAlocacao"
                name="perAlocacao"
                label="% Alocação"
                style={{ width: '50%' }}
                value={formik.values.perAlocacao}
                InputProps={{
                  inputComponent: NumberFormatCustom
                }}
                // eslint-disable-next-line
                inputProps={{
                  errorAttribute: 'erroPerAlocacao',
                  formik,
                 maxLength: 4
                }}

                error={!!formik.errors.erroPerAlocacao}
                helperText={formik.errors.erroPerAlocacao}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <br />
          <FlexWrapper>
            <FlexItemWrapper>
              <Button label="Cancelar" onClick={() => handleCloseForm()} />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Button
                color="primary"
                label={isProposalUserEdit ? 'Alterar' : 'Salvar'}
                type="submit"
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <br />
        </form>
      </Box>
      <Toast
        error={feedback.error}
        success={feedback.success}
        onClose={clearFeedback}
        open={hasFeedback}
      />
    </>
  )
}

const mapStateToProps = state => {
  const {
    proposalFunctionReducer: { listProposalFunction },
    proposalUserReducer: { listProposalUser },
    userReducer: { listUsers },
  } = state

  return {
    listProposalFunction,
    listProposalUser,
    listUsers,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...proposalUserActions,
    },
    dispatch
  )

Form.propTypes = {
  /** Actions */
  updateProposalUser: PropTypes.func.isRequired,
  saveProposalUser: PropTypes.func.isRequired,
  fetchProposalUsers: PropTypes.func.isRequired,
  /** Reducer props */
  listProposalFunction: PropTypes.array.isRequired,
  listProposalUser: PropTypes.array.isRequired,
  listUsers: PropTypes.array.isRequired,
  /** Other props */
  handleCloseForm: PropTypes.func.isRequired,
  proposalFunctionToEdit: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
