import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.statusHistory, param)

    return dispatch(trigger(types.statusHistory.get.success, response.data))
  } catch (error) {
    return dispatch(trigger(types.statusHistory.get.error, error))
  }
}

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.statusHistory + '/filter',
      param
    )

    return dispatch(trigger(types.statusHistory.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.statusHistory.getFilter.error, error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.statusHistory, param)

    return dispatch(trigger(types.statusHistory.save.success))
  } catch (error) {
    return dispatch(trigger(types.statusHistory.save.error, error))
  }
}
