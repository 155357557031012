import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'
import FileSaver from 'file-saver'
import { base64toBlob } from './entry'

export const getPunchClockReportRD = param => async dispatch => {
  dispatch(trigger(types.entry.getReportRD.waitingReportGeneration))
  try {
    const response = await new Http(dispatch).get(
      URL.punchClockReportRD,
      param
    )
    fetchPunchClockSuccess(response, dispatch)
  } catch (e) {
    return dispatch(trigger(types.entry.getReport.error, "Houve um erro ao gerar o relatório"))
  }
}

export const fetchPunchClockSuccess = async (response, dispatch) => {
  if (response.data === null) {
    return dispatch(trigger(types.punchClockReportRD.getReport.error, "Houve um erro ao gerar o relatório"))
  } else {
    var datax = new Blob([base64toBlob(response.data, 'application/pdf')], {
      type: 'application/pdf',
    })
    FileSaver.saveAs(datax, 'relatorio_ponto_RD.pdf')
    return dispatch => {  
      dispatch(trigger(types.punchClockReportRD.getReport.success, response)) 
    }
  }
}