import React, { useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as executionActions from '../../../actions/execution'
import TablePagination from '../../../utils/Table/TablePaginationPreClosure'

const TABLE_HEADER = [
  'Usuário',
  'Cargo',
]

const TABLE_VALUES_ORDER = [
  'desUsuario',
  'cargo',
]

const doNotShowTable = ['id', 'seqUsuario']

const Table = props => {
  const {
    executionUserList,
    resetPagination,
    requestFilters,
    execUserPagination,
    execUserPaginationSize,
    onEdit,
    totalCountExecUser,
  } = props

  const handleChangePage = async (_, newPage) => {
    await execUserPagination(newPage)
  }

  const handleChangePageSize = async event => {
    const newPageSize = event.target.value
    await execUserPaginationSize(newPageSize)
  }

  useEffect(() => {
    resetPagination()
  }, [])

  const ListExecution = useMemo(() => {
    return executionUserList
    .map(item => {

      const {
        id,
        user,
      } = item

        return {
          id,
          user: user.desUsuario,
          cargo: user.office.desCargo
        }
      }
    )
  }, [executionUserList])

  return (
    <TablePagination
      titleTable="executions"
      count={totalCountExecUser}
      headers={TABLE_HEADER}
      headersIndexOrder={TABLE_VALUES_ORDER}
      doNotShow={doNotShowTable}
      onChangePage={handleChangePage}
      onChangeSize={handleChangePageSize}
      page={requestFilters.pageFilter}
      rows={ListExecution}
      proposal={true}
      size={requestFilters.sizeFilter}
      startEdit={onEdit}
    />
  )
}

const mapStateToProps = state => {
  const { executionReducer } = state
  
  return {
    ...executionReducer,
    executionUserList: executionReducer.executionUserList,
    requestFilters: executionReducer.requestFilters
  }
}

const mapDispatchToProps = dispatch =>
bindActionCreators(
  {
    ...executionActions,
  },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Table)
