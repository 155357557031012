import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useFormik } from 'formik'
import { Box, TextField } from '@material-ui/core/'
import { Autocomplete } from '@material-ui/lab'

import * as proposalActions from '../../../actions/proposal'
import * as proposalUserActions from '../../../actions/proposalUser'

import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import Button from '../../../utils/Form/Button'
import { Loader } from '../../../utils/Loading'
import FormControl from '@material-ui/core/FormControl'
import * as executionActions from '../../../actions/execution'
import NumberFormat from 'react-number-format'

const NO_OPTIONS_TEXT = 'Sem opções'

const DEFAULT_FORMIK_VALUES = {
  id: '',
  user: '',
  codProposal: '',
  executionId: '',
  status_exec: '',
  qtd_dias_trabalhados: 0,
  qtd_horas_trabalhadas: 0,
  qtd_horas_total: 0,
  qtd_horas_total_mes: 0,
  qtd_horas_50: 0,
  qtd_horas_75: 0,
  qtd_horas_100: 0,
  qtd_horas_adn_50: 0,
  qtd_horas_adn_75: 0,
  qtd_horas_adn_100: 0,
  qtd_horas_sobreaviso: 0,
  observacao_relatorio: '',
  observacao_pre_fechamento: '',
  totalValue: 0,
}

const Form = props => {
  const {
    proposalToEdit = null,
    listClients = [],
    handleCloseForm,
    executionUserToEdit,
    executionToEdit,
    updateExecutionUser,
    getExecutionUser,
  } = props

  const isProposalEdit = !!proposalToEdit

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async values => {
    setIsLoading(true)

    const execution = {
      daysWorked: Number(values.qtd_dias_trabalhados) || 0,
      executionId: Number(values.executionId),
      hours100: Number(values.qtd_horas_100 || 0),
      hours50: Number(values.qtd_horas_50 || 0),
      totalValue: Number(values.totalValue || 0),
      hours75: Number(values.qtd_horas_75 || 0),
      hoursOnNotice: Number(values.qtd_horas_sobreaviso || 0),
      hoursWorked: Number(values.qtd_horas_trabalhadas) || 0,
      nightHours100: Number(values.qtd_horas_adn_100 || 0),
      nightHours50: Number(values.qtd_horas_adn_50 || 0),
      nightHours75: Number(values.qtd_horas_adn_75 || 0),
      obsPreFechamento: String(values.observacao_pre_fechamento || ''),
      obsUser: String(values.observacao_relatorio || ''),
      totalHours: Number(values.qtd_horas_total) || 0,
      userId: Number(executionUserToEdit.user.id),
    }

    if (isProposalEdit) {
      await updateExecutionUser(executionUserToEdit.id, execution).then(() => getExecutionUser())
      handleCloseForm()
    }

    setIsLoading(false)
  }

  const resolveInitialValues = () => {
    if (isProposalEdit) {
      const horasMes = executionUserToEdit.daysWorked * 8 * (executionUserToEdit.allocationPercentual / 100)

      return {
        id: executionUserToEdit.id,
        user: executionUserToEdit.user.desUsuario,
        codProposal: executionToEdit.proposal.codProposta,
        executionId: executionToEdit.id,
        status_exec: executionToEdit.executionStatusType,
        qtd_dias_trabalhados: executionUserToEdit.daysWorked,
        qtd_horas_trabalhadas: executionUserToEdit.hoursWorked,
        qtd_horas_total: executionUserToEdit.totalHours,
        totalValue: executionUserToEdit.totalValue,  // added total value
        qtd_horas_total_mes: horasMes,
        qtd_horas_50: executionUserToEdit.hours50,
        qtd_horas_75: executionUserToEdit.hours75,
        qtd_horas_100: executionUserToEdit.hours100,
        qtd_horas_adn_50: executionUserToEdit.nightHours50,
        qtd_horas_adn_75: executionUserToEdit.nightHours75,
        qtd_horas_adn_100: executionUserToEdit.nightHours100,
        qtd_horas_sobreaviso: executionUserToEdit.hoursOnNotice,
        observacao_relatorio: executionUserToEdit.obsUser,
        observacao_pre_fechamento: executionUserToEdit.obsPreFechamento,
      }
    }

    return DEFAULT_FORMIK_VALUES
  }

  const validateValues = values => {
    const {
      qtd_dias_trabalhados
    } = values

    let errors = {}

    if (qtd_dias_trabalhados < 0 || qtd_dias_trabalhados.length > 4) {
      errors = { ...errors, qtd_dias_trabalhados_error: 'Máximo de 4 caracteres' }
    }

    if (String(qtd_dias_trabalhados).includes(',') || String(qtd_dias_trabalhados).includes('.')) {
      errors = { ...errors, qtd_dias_trabalhados_error: 'Insira um número inteiro' }
    }

    return errors
  }


  const formik = useFormik({
    initialValues: resolveInitialValues(),
    validateOnChange: false,
    validate: validateValues,
    onSubmit: handleSubmit,
  })


  const { qtd_horas_100,
    qtd_horas_50,
    qtd_horas_75,
    qtd_horas_adn_100,
    qtd_horas_adn_50,
    qtd_horas_adn_75,
    qtd_horas_sobreaviso,
  } = formik.values

  const sum = [qtd_horas_100,
    qtd_horas_50,
    qtd_horas_75,
    qtd_horas_adn_100,
    qtd_horas_adn_50,
    qtd_horas_adn_75,
    qtd_horas_sobreaviso,
    executionUserToEdit.daysWorked * 8 * (executionUserToEdit.allocationPercentual / 100)]
  .filter(item => item !== undefined).reduce((partialSum, a) => partialSum + a, 0);

  useEffect(() => {
    formik.setFieldValue('qtd_horas_total', sum)
  }, [sum])

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                id="user"
                name="user"
                fullWidth
                disabled
                noOptionsText={NO_OPTIONS_TEXT}
                options={listClients}
                getOptionLabel={(option = {}) => option.descCliente}
                value={formik.values.user}
                label="Usuário"
                onChange={(_, option) => {
                  formik.setFieldValue('user', option).then(() => {
                    formik.setFieldError('userError', '')
                  })
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <TextField
                id="codProposal"
                name="codProposal"
                label="Proposta"
                fullWidth
                disabled
                inputProps={{
                  maxLength: 20,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('codProposal', value).then(() => {
                    formik.setFieldError('proposalCodeError', '')
                  })
                }}
                value={formik.values.codProposal || ''}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                id="executionId"
                name="executionId"
                type="number"
                disabled
                label="Execução"
                fullWidth
                inputProps={{
                  maxLength: 255,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('executionId', value).then(() => {
                    formik.setFieldError('executionError', '')
                  })
                }}
                value={formik.values.executionId || ''}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Autocomplete
                id="status_exec"
                name="status_exec"
                disabled
                fullWidth
                noOptionsText={NO_OPTIONS_TEXT}
                options={['Aberto', 'Em análise']}
                value={formik.values.status_exec}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Status execução"
                    value={formik.values.status_exec}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('status_exec', option).then(() => {
                    formik.setFieldError('statusExecutionError', '')
                  })
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 0.45, paddingRight: 0.5 }}>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_total_mes"}
                id="qtd_horas_total_mes"
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                disabled
                label="Total horas mês"
                value={formik.values.qtd_horas_total_mes}
                />
              </FlexWrapper>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_50"}
                id="qtd_horas_50"
                onValueChange={values => {
                  formik.setFieldValue("qtd_horas_50", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                label="Total horas extras 50%"
                value={formik.values.qtd_horas_50}
                onBlur={e => {
                  if (e.target.value === '') formik.setFieldValue("qtd_horas_50", 0)
                }}
                />
              </FlexWrapper>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_75"}
                id="qtd_horas_75"
                onValueChange={values => {
                  formik.setFieldValue("qtd_horas_75", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                label="Total horas extras 75%"
                value={formik.values.qtd_horas_75}
                onBlur={e => {
                  if (e.target.value === '') formik.setFieldValue("qtd_horas_75", 0)
                }}
                />
              </FlexWrapper>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_100"}
                id="qtd_horas_100"
                onValueChange={values => {
                  formik.setFieldValue("qtd_horas_100", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                label="Total horas extras 100%"
                value={formik.values.qtd_horas_100}
                onBlur={e => {
                  if (e.target.value === '') formik.setFieldValue("qtd_horas_100", 0)
                }}
                />
              </FlexWrapper>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_adn_50"}
                id="qtd_horas_adn_50"
                onValueChange={values => {
                  formik.setFieldValue("qtd_horas_adn_50", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                label="Total horas ADN 50%"
                value={formik.values.qtd_horas_adn_50}
                onBlur={e => {
                  if (e.target.value === '') formik.setFieldValue("qtd_horas_adn_50", 0)
                }}
                />
              </FlexWrapper>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_adn_75"}
                id="qtd_horas_adn_75"
                onValueChange={values => {
                  formik.setFieldValue("qtd_horas_adn_75", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                label="Total horas ADN 75%"
                value={formik.values.qtd_horas_adn_75}
                onBlur={e => {
                  if (e.target.value === '') formik.setFieldValue("qtd_horas_adn_100", 0)
                }}
                />
              </FlexWrapper>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_adn_100"}
                id="qtd_horas_adn_100"
                onValueChange={values => {
                  formik.setFieldValue("qtd_horas_adn_100", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                label="Total horas ADN 100%"
                value={formik.values.qtd_horas_adn_100}
                onBlur={e => {
                  if (e.target.value === '') formik.setFieldValue("qtd_horas_adn_100", 0)
                }}
                />
              </FlexWrapper>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_sobreaviso"}
                id="qtd_horas_sobreaviso"
                onValueChange={values => {
                  formik.setFieldValue("qtd_horas_sobreaviso", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                label="Total horas sobreaviso"
                value={formik.values.qtd_horas_sobreaviso}
                onBlur={e => {
                  if (e.target.value === '') formik.setFieldValue("qtd_horas_sobreaviso", 0)
                }}
                />
              </FlexWrapper>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_total"}
                id="qtd_horas_total"
                onValueChange={values => {
                  formik.setFieldValue("qtd_horas_total", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                disabled
                label="Total de horas"
                value={formik.values.qtd_horas_total}
                />
              </FlexWrapper>
              <FlexWrapper>
                <NumberFormat
                customInput={TextField}
                name={"totalValue"}
                id="totalValue"
                onValueChange={values => {
                  formik.setFieldValue("totalValue", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                fullWidth
                disabled
                label="Valor Contrato"
                value={formik.values.totalValue || 0.0}
                prefix='R$'
                />
              </FlexWrapper>
            </div>
            <div style={{ flex: 0.55, flexDirection: 'row', paddingLeft: 20 }}>
              <div style={{ marginBottom: 20, marginTop: 16 }}>
                <TextField
                  style={{ width: '48%', marginRight: 20, marginBottom: 16 }}
                  id="qtd_dias_trabalhados"
                  name="qtd_dias_trabalhados"
                  error={!!formik.errors.qtd_dias_trabalhados_error}
                  helperText={formik.errors.qtd_dias_trabalhados_error}
                  type="number"
                  onBlur={e => {
                    const value = String(e.target.value)
                    if(value.startsWith('0')) formik.setFieldValue('qtd_dias_trabalhados', parseInt(value, 10))
                  }}
                  label="Dias úteis do mês"
                  onChange={({ target: { value } }) => {
                    formik.setFieldValue('qtd_dias_trabalhados', value)
                    formik.setFieldValue('qtd_horas_total_mes', value * 8 * (executionUserToEdit.allocationPercentual / 100))
                    formik.setFieldError('qtd_dias_trabalhados', '')
                  }}
                  value={formik.values.qtd_dias_trabalhados}
                />
                <NumberFormat
                customInput={TextField}
                name={"qtd_horas_trabalhadas"}
                id="qtd_horas_trabalhadas"
                onValueChange={values => {
                  formik.setFieldValue("qtd_horas_trabalhadas", values.floatValue)
                }}
                allowNegative={false}
                fixedDecimalScale={true}
                decimalScale={2}
                maxLength={7}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString={true}
                style={{ width: '48%' }}
                disabled
                label="Total de horas trabalhadas"
                value={formik.values.qtd_horas_trabalhadas}
                />
              </div>

              <div style={{ flex: 1, marginBottom: 20 }}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Observação relatório individual:"
                    id="observacao_relatorio"
                    name="observacao_relatorio"
                    type="string"
                    value={formik.values.observacao_relatorio}
                    rows={10}
                    variant="outlined"
                    onChange={({ target: { value } }) => {
                      formik.setFieldValue('observacao_relatorio', value)
                    }}
                    multiline={10}
                    inputProps={{
                      maxLength: 255,
                    }}
                  />
                </FormControl>

              </div>
              <div style={{ flex: 1 }}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Observação interna pré-fechamento:"
                    id="observacao_pre_fechamento"
                    name="observacao_pre_fechamento"
                    type="string"
                    value={formik.values.observacao_pre_fechamento}
                    rows={10}
                    variant="outlined"
                    onChange={({ target: { value } }) => {
                      formik.setFieldValue('observacao_pre_fechamento', value)
                    }}
                    multiline={10}
                    inputProps={{
                      maxLength: 255,
                    }}
                  />
                </FormControl>

              </div>
            </div>
          </div>
          <br />
          <FlexWrapper>
            <FlexItemWrapper>
              <Button label="Cancelar" onClick={() => handleCloseForm()} />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Button color="primary" label="Alterar" type="submit" disabled={executionToEdit.executionStatusType === 'Fechado'} />
            </FlexItemWrapper>
          </FlexWrapper>
          <br />
        </form>
      </Box>
    </>
  )
}
const mapStateToProps = state => {
  const {
    clientReducer,
    projectReducer,
    periodReducer,
    proposalReducer,
    proposalUserReducer: { listProposalUser },
    executionReducer,
  } = state

  return {
    ...clientReducer,
    ...projectReducer,
    ...periodReducer,
    ...proposalReducer,
    listProposalUser,
    executionList: executionReducer.list,
    executionUserList: executionReducer.executionUserList,
    executionUserToEdit: executionReducer.toEdit.executionUserToEdit,
    executionToEdit: executionReducer.toEdit.executionToEdit,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...proposalActions,
      ...executionActions,
      fetchProposalUsers: proposalUserActions.fetchProposalUsers,
      getExecutionUser: executionActions.getExecutionUser
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Form)
