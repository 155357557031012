import React from 'react'
import PropTypes from 'prop-types'
import { Visibility } from '@material-ui/icons'
import { Link } from "react-router-dom"
import { useStyles } from '../useStyles'

const CustomActions = ({ rowData }) => {
  const classes = useStyles()

  const queryString = new URLSearchParams({
    cliente: rowData.seqCliente,
    proposta: rowData.seqProposta,
    funcao: rowData.id,
  }).toString()

  return (
    <span>
      <Link
        to={`/proposal-user?${queryString}`}
        className={classes.link}
        title='Ir para usuário da proposta'
      >
        <Visibility />
      </Link>
    </span>
  )
}

CustomActions.propTypes = {
  rowData: PropTypes.object.isRequired,
}

export default CustomActions
