import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as proposalActions from '../../actions/proposal'
import { getFilter as getPeriods } from '../../actions/period'
import Loading from '../../utils/Loading'

import Main from '../Main'

import { TableContainer, Paper, Grid,  Button as MatButton  } from '@material-ui/core'
import * as executionActions from '../../actions/execution'
import Toast from '../../utils/Toast'
import Dialog from '../../utils/Dialog'

import Form from './components/Form'
import Table from './components/Table'

import useFeedback from '../../utils/hooks/useFeedback'

import { useHistory } from 'react-router-dom'
import {
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
const PreClosureExecutionUser = props => {
  const {
    executionId,
    getExecutionUser,
    executionUserList,
    setExecutionToEdit,
    executionUserToEdit,
    error,
    success
  } = props
  const {
    setSuccess,
    clearFeedback,
  } = useFeedback()
  const history = useHistory()

  const [showDialog, setShowDialog] = useState(false)
  const [proposalToEdit, setProposalToEdit] = useState(null)
  const [showToast, setShowToast] = useState(false)
  const [showToastError, setShowToastError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dragModalDisabled, setDragModalDisabled] = useState(false)

  useEffect(() => {
    if (!!success) {
      if (showToast === true) {
        setShowToast(false)
        setShowToast(true)
      }
      if (showToast === false) {
        setShowToast(true)
      }
    } else {
      setShowToast(false)
    }
  }, [success])

  useEffect(() => {
    if (!!error) {
      if (showToastError === true) {
        setShowToastError(false)
        setShowToastError(true)
      }
      if (showToastError === false) {
        setShowToastError(true)
      }
    } else {
      setShowToastError(false)
    }
  }, [error])

  useEffect(() => {
    setIsLoading(true)
    getExecutionUser().then(() => setIsLoading(false))
  }, [executionId])

  const handleCloseForm = successMessage => {
    setShowDialog(false)
    setProposalToEdit(null)

    if (successMessage) {
      clearFeedback()
      setSuccess(successMessage)
    }
  }

  const renderForm = () => {
    return (
      <Dialog
        onClose={() => handleCloseForm()}
        open={showDialog || !!proposalToEdit}
        title={'Alterar pré-fechamento'}
        isDragDisabled={dragModalDisabled}
      >
        <Form
          proposalToEdit={executionUserToEdit}
          handleCloseForm={handleCloseForm}
          onOpenDateModal={() => setDragModalDisabled(true)}
          onCloseDateModal={() => setDragModalDisabled(false)}
        />
      </Dialog>
    )
  }
  const location = useLocation()


  const searchParams = new URLSearchParams(location.search)

  const renderTable = () => {
    return (
      <>
        <Table
          onEdit={exec => {
            const execution = executionUserList.find(item => item.id === exec.id)
              setProposalToEdit(execution.id)
              setExecutionToEdit(null, execution)
          }}
        />
      </>
    )
  }

  return (
    <Main title="Pré-Fechamento">
      {isLoading ? <Loading /> :
        <>
      <Grid item xs={12}>
        <div style={{marginTop: 25, marginBottom: 25}}>
          <MatButton
            variant="outlined"
            onClick={() => history.push(`/pre-closure?disabled=${searchParams.get('disabled')}`)}
          >
            Voltar para Pré-fechamento
          </MatButton>
        </div>
        <TableContainer component={Paper}>{renderTable()}</TableContainer>
      </Grid>
      <Toast
        success={"Pré-fechamento atualizado com sucesso"}
        open={showToast}
        onClose={() => setShowToast(false)}
        autoHideDuration={2000}
      />
      <Toast
        error={"Ocorreu um erro na sua solicitação"}
        open={showToastError}
        onClose={() => setShowToastError(false)}
        autoHideDuration={2000}
      />
      {renderForm()}
      </>
      }
    </Main>
  )
}

const mapStateToProps = state => {
  const { clientReducer, projectReducer, proposalReducer, executionReducer } = state

  return {
    ...clientReducer,
    ...proposalReducer,
    ...projectReducer,
    ...executionReducer,
    executionList: executionReducer.list,
    executionUserList: executionReducer.executionUserList,
    executionId: executionReducer.requestFilters.executionId,
    executionUserToEdit: executionReducer.toEdit.executionUserToEdit,
    error: executionReducer.error,
    success:executionReducer.success
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...proposalActions,
      ...executionActions,
      getExecutions: executionActions.get,
      getPeriods,
      handleChangeMonth: executionActions.handleChangeMonth,
      setExecutionToEdit: executionActions.setExecutionToEdit,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PreClosureExecutionUser)
