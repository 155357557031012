import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { useFormik } from 'formik'

import TextField from '@material-ui/core/TextField'

import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import Select from '../../../utils/Form/Select'
import createSyntheticEvent from '../../../utils/createSyntheticEvent'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={','}
      decimalScale={6}
      fixedDecimalScale={true}
      suffix={' hr(s)'}
      isNumericString={true}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const validationSchema = yup.object({
  periodId: yup.number().required('Por favor, escolha o período!'),
  userId: yup.number().required('Por favor, escolha o usuário!'),
  qtdSaldoIni: yup.number().required('Por favor, informe qtd. saldo inicial!'),
  qtdHorasPositivas: yup
    .number()
    .required('Por favor, informe qtd. horas positivas!'),
  qtdHorasNegativas: yup
    .number()
    .required('Por favor, informe qtd. horas negativas!'),
  qtdAjuste: yup.number().required('Por favor, informe qtd. ajuste!'),
  qtdSaldoFinal: yup.number().required('Por favor, informe qtd. saldo final!'),
  qtdHorasPrevista: yup
    .number()
    .required('Por favor, informe qtd. horas previstas!'),
})

/**
 * Period form
 */
const Form = ({
  users = [],
  periods = [],
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [listPeriods, setListPeriods] = useState([])
  const [listUsers, setListUsers] = useState([])
  const user = JSON.parse(localStorage.getItem('user'))

  const formik = useFormik({
    initialValues: {
      id: param.id,
      periodId: param.periodId,
      userId: user.office.tipCargo === 'OPERAÇÃO' ? user.id : param.userId,
      qtdSaldoIni: param.qtdSaldoIni,
      qtdHorasPositivas: param.qtdHorasPositivas,
      qtdHorasNegativas: param.qtdHorasNegativas,
      qtdHorasPos50: param.qtdHorasPos50,
      qtdHorasPos75: param.qtdHorasPos75,
      qtdHorasPos100: param.qtdHorasPos100,
      qtdHorasTrabalhadas: param.qtdHorasTrabalhadas,
      qtdAjuste: param.qtdAjuste,
      qtdSaldoFinal: param.qtdSaldoFinal,
      desJustificaAjuste: param.desJustificaAjuste,
      desObs: param.desObs,
      qtdHorasPrevista: param.qtdHorasPrevista,
      qtdAdicional: param.qtdAdicional,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    setListPeriods(
      periods.map(period => ({ key: period.id, value: period.desPeriodo }))
    )
    setListUsers(users.map(user => ({ key: user.id, value: user.desUsuario })))
  }, [periods, users])

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                autoFocus
                id="desJustificaAjuste"
                name="desJustificaAjuste"
                label="Descrição Justificativa Ajuste"
                value={formik.values.desJustificaAjuste}
                onChange={formik.handleChange}
                error={
                  formik.touched.desJustificaAjuste &&
                  Boolean(formik.errors.desJustificaAjuste)
                }
                helperText={
                  formik.touched.desJustificaAjuste &&
                  formik.errors.desJustificaAjuste
                }
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                id="desObs"
                name="desObs"
                label="Descrição Observação"
                value={formik.values.desObs}
                onChange={formik.handleChange}
                error={formik.touched.desObs && Boolean(formik.errors.desObs)}
                helperText={formik.touched.desObs && formik.errors.desObs}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                id="qtdSaldoIni"
                name="qtdSaldoIni"
                label="Qtd. saldo inicial"
                value={formik.values.qtdSaldoIni}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdSaldoIni &&
                  Boolean(formik.errors.qtdSaldoIni)
                }
                helperText={
                  formik.touched.qtdSaldoIni && formik.errors.qtdSaldoIni
                }
                inputProps={{
                  maxLength: 43,
                }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <TextField
                fullWidth
                id="qtdHorasPrevista"
                name="qtdHorasPrevista"
                label="Qtd. horas previstas"
                value={formik.values.qtdHorasPrevista}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdHorasPrevista &&
                  Boolean(formik.errors.qtdHorasPrevista)
                }
                helperText={
                  formik.touched.qtdHorasPrevista &&
                  formik.errors.qtdHorasPrevista
                }
                inputProps={{
                  maxLength: 43,
                }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <TextField
                fullWidth
                id="qtdAjuste"
                name="qtdAjuste"
                label="Qtd. ajuste"
                value={formik.values.qtdAjuste}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdAjuste && Boolean(formik.errors.qtdAjuste)
                }
                helperText={formik.touched.qtdAjuste && formik.errors.qtdAjuste}
                inputProps={{
                  maxLength: 43,
                }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                id="qtdSaldoFinal"
                name="qtdSaldoFinal"
                label="Qtd. saldo final"
                value={formik.values.qtdSaldoFinal}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdSaldoFinal &&
                  Boolean(formik.errors.qtdSaldoFinal)
                }
                helperText={
                  formik.touched.qtdSaldoFinal && formik.errors.qtdSaldoFinal
                }
                inputProps={{
                  maxLength: 43,
                }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <TextField
                fullWidth
                id="qtdHorasPositivas"
                name="qtdHorasPositivas"
                label="Qtd. horas positivas"
                value={formik.values.qtdHorasPositivas}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdHorasPositivas &&
                  Boolean(formik.errors.qtdHorasPositivas)
                }
                helperText={
                  formik.touched.qtdHorasPositivas &&
                  formik.errors.qtdHorasPositivas
                }
                inputProps={{
                  maxLength: 43,
                }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <TextField
                fullWidth
                id="qtdHorasNegativas"
                name="qtdHorasNegativas"
                label="Qtd. horas negativas"
                value={formik.values.qtdHorasNegativas}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdHorasNegativas &&
                  Boolean(formik.errors.qtdHorasNegativas)
                }
                helperText={
                  formik.touched.qtdHorasNegativas &&
                  formik.errors.qtdHorasNegativas
                }
                inputProps={{
                  maxLength: 43,
                }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <TextField
                fullWidth
                id="qtdAdicional"
                name="qtdAdicional"
                label="Qtd. adicional"
                value={formik.values.qtdAdicional}
                onChange={formik.handleChange}
                inputProps={{
                  maxLength: 43,
                }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <Select
                fullWidth
                select
                id="periodId"
                name="periodId"
                label="Período"
                options={listPeriods}
                value={{ value: formik.values.periodId }}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent('periodId', val.target.value)
                  )
                }
                error={
                  formik.touched.periodId && Boolean(formik.errors.periodId)
                }
                helperText={formik.touched.periodId && formik.errors.periodId}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <Select
                fullWidth
                select
                id="userId"
                name="userId"
                label="Usuário"
                options={listUsers}
                value={{ value: formik.values.userId }}
                onChange={val =>
                  formik.handleChange(
                    createSyntheticEvent('userId', val.target.value)
                  )
                }
                error={formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={formik.touched.userId && formik.errors.userId}
                disabled={user.office.tipCargo === 'OPERAÇÃO'}
              />
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <Button
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  isDeleteAction: PropTypes.bool,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
}

export default Form
