import { makeStyles } from '@material-ui/core/styles'

export const globalStyles = makeStyles({
  table: {
    minWidth: 450,
  },

  thead: {
    backgroundColor: '#3f51b5',
  },

  th: {
    color: '#ffff',
  },

  box: {
    textAlign: 'right',
    padding: 15,
  },

  filter: {
    padding: 15,
  },

  buttonImport: {
    marginRight: 15,
  },
})
