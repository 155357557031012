import { types } from '../../config/action_types'
import {
  DEFAULT_PAGINATION_PAGE,
} from '../../constants/pagination'

const defaultState = {
  listProposal: [],
  errorProposal: null,
  successProposal: null,
  filterParams: {
    pageFilter: DEFAULT_PAGINATION_PAGE,
    sizeFilter: 50,
  },
  totalElementsProposal: 0,
  allProposals: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.proposal.updateFilter:
      return {
        ...state,
        filterParams: {
          sizeFilter: state.filterParams.sizeFilter,
          ...action.payload,
        },
      }

    case types.proposal.clearFilter:
      return {
        ...state,
        filterParams: {
          ...defaultState.filterParams,
        },
      }

    case types.proposal.getFilter.success: {
      const { resultList, pages } = action.payload

      return {
        ...state,
        listProposal: resultList || [],
        totalElementsProposal:
          pages != null ? pages.totalCount : 0 || resultList.length,
      }
    }

    case 'USUARIO_SEM_PROPOSTA':
      return {
        ...state,
        listProposal: [],
        successProposal: null,
        errorProposal: null
      }

    case types.proposal.getFilter.error:
      return {
        ...state,
        errorProposal: 'Erro ao buscar Clientes',
        successProposal: null,
      }

    case types.proposal.get.success:
      return {
        ...state,
        listProposal: action.payload.content || action.payload,
      }

    case types.proposal.get.error:
      return {
        ...state,
        errorProposal: 'Erro ao buscar Proposta',
        successProposal: null,
      }

    case types.proposal.getOptions.success:
      return {
        ...state,
        allProposals: action.payload || []
      }

    case types.proposal.getOptions.error:
      return {
        ...state,
        errorProposal: 'Erro ao buscar todas as Propostas',
        successProposal: null,
      }

    case types.proposal.save.success:
      return {
        ...state,
        successProposal: 'Proposta salva com sucesso',
        errorProposal: null,
      }

    case types.proposal.save.error:
      return {
        ...state,
        errorProposal: 'Erro ao salvar proposta',
        successProposal: null,
      }

    case types.proposal.delete.success:
      return {
        ...state,
        successProposal: 'Proposta excluída com sucesso',
        errorProposal: null,
      }

    case types.proposal.delete.error:
      return {
        ...state,
        errorProposal: action.payload || 'Erro ao excluir proposta',
        successProposal: null,
      }

    case types.proposal.update.success:
      return {
        ...state,
        successProposal: 'Proposta atualizada com sucesso',
        errorProposal: null,
      }

    case types.proposal.update.error:
      return {
        ...state,
        errorProposal: 'Erro ao atualizar proposta',
        successProposal: null,
      }

    case types.proposal.cleanToasterProposalRd:
      return {
        ...state,
        errorReportRD: null,
      }

    default:
      return state
  }
}
