import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  leftFlexItemWrapper: {
    width: '33%',
    marginRight: '.5rem',
  },
  middleFlexItemWrapper: {
    width: '33%',
    marginLeft: '.5rem',
    marginRight: '.5rem',
  },
  rightFlexItemWrapper: {
    width: '33%',
    marginLeft: '.5rem',
  },
})

/**
 * Default flex wrapper
 */
const FlexItemWrapper = ({ middle, right, children, ...otherProps }) => {
  let className

  if (right) {
    className = useStyles().rightFlexItemWrapper
  } else if (middle) {
    className = useStyles().middleFlexItemWrapper
  } else {
    className = useStyles().leftFlexItemWrapper
  }

  return (
    <Box className={className} flexGrow={1} {...otherProps}>
      {children}
    </Box>
  )
}

FlexItemWrapper.propTypes = {
  middle: PropTypes.bool,
  right: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default FlexItemWrapper
