import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  errorPoint: null,
  records: [],
  points: [],
  pointsFilter: [],
  successPoint: null,
  totalElements: 0,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.point.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        pointsFilter: resultList || [],
        totalElements: pages.totalCount || resultList.length,
      }

    case types.point.getFilter.error:
      return {
        ...state,
        errorPoint: 'Erro ao buscar pontos',
        successPoint: null,
      }

    case types.point.get.success:
      return {
        ...state,
        records: action.payload.content || action.payload,
        points: action.payload.content || action.payload,
      }

    case types.point.get.error:
      return {
        ...state,
        errorPoint: 'Erro ao buscar pontos',
      }

    case types.point.save.success:
      return {
        ...state,
        successPoint: 'Ponto salvo com sucesso',
        errorPoint: null,
      }

    case types.point.save.error:
      return {
        ...state,
        errorPoint: 'Erro ao salvar ponto',
      }

    case types.point.update.success:
      return {
        ...state,
        successPoint: 'Ponto alterado com sucesso',
        errorPoint: null,
      }

    case types.point.update.error:
      return {
        ...state,
        errorPoint: 'Erro ao editar ponto',
      }

    case types.point.remove.success:
      return {
        ...state,
        successPoint: 'Ponto removido com sucesso',
        errorPoint: null,
      }

    case types.point.remove.error:
      const { messageError } = action.payload.response.data.message
      return {
        ...state,
        errorPoint: messageError,
      }

    default:
      return state
  }
}
