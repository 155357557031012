import { types } from '../../config/action_types'

const defaultState = {
  listProjects: [],
  projects: [],
  listProjectsFilter: [],
  errorProjects: null,
  successProjects: null,
  totalElementsProjects: 0,
  isLoading: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.project.getFilter.success:
      const { resultList, pages } = action.payload

      return {
        ...state,
        listProjectsFilter: resultList || [],
        totalElementsProjects: pages.totalCount || resultList.length,
        isLoading: false,
      }
    case types.project.getFilter.request:
      return {
        ...state,
        listProjectsFilter: [],
        totalElementsProjects: 0,
        isLoading: true,
      }
    case types.project.getFilter.error:
      return {
        ...state,
        errorProjects: 'Erro ao buscar projetos',
        successProjects: null,
        isLoading: false,
      }

    case types.project.get.success:
      return {
        ...state,
        listProjects: action.payload.content || action.payload,
        projects: action.payload.content || action.payload,
      }
    case types.project.get.error:
      return {
        ...state,
        errorProjects: 'Erro ao buscar projetos',
        successProjects: null,
      }

    case types.project.save.success:
      return {
        ...state,
        successProjects: 'Projeto salvo com sucesso',
        errorProjects: null,
      }
    case types.project.save.error:
      return {
        ...state,
        errorProjects: 'Erro ao salvar projeto',
        successProjects: null,
      }

    case types.project.delete.success:
      return {
        ...state,
        successProjects: 'Projeto deletado com sucesso',
        errorProjects: null,
      }
    case types.project.delete.error:
      const { messageError } = action.payload.response.data.message
      return {
        ...state,
        errorProjects: messageError,
        successProjects: null,
      }

    case types.project.update.success:
      return {
        ...state,
        successProjects: 'Projeto alterado com sucesso',
        errorProjects: null,
      }
    case types.project.update.error:
      return {
        ...state,
        errorProjects: 'Erro ao alterar projeto',
        successProjects: null,
      }

    default:
      return state
  }
}
