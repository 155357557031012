import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useFormik } from 'formik'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import { makeStyles } from '@material-ui/core/styles'

const tipCargo = [
  { key: 'ADMINISTRADOR', value: 'ADMINISTRADOR' },
  { key: 'COORDENADOR', value: 'COORDENADOR' },
  { key: 'OPERAÇÃO', value: 'OPERAÇÃO' },
]

const indInativo = [
  { key: true, value: 'ATIVO' },
  { key: false, value: 'INATIVO' },
]

const validationSchema = yup.object({
  desCargo: yup
    .string()
    .max(50, 'A descrição deve conter no máximo 50 caracteres!')
    .required('Por favor, informe a descrição!'),
  tipCargo: yup.string().required('Por favor, escolha o perfil do cargo'),
  indInativo: yup.boolean().required('Por favor, escolha o status'),
})

/**
 * Period form
 */
const Form = ({
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [listTipCargos, setListTipCargos] = useState([])
  const [listTipStatus, setListTipStatus] = useState([])

  const formik = useFormik({
    initialValues: {
      id: param.id,
      desCargo: param.desCargo,
      tipCargo: param.tipCargo,
      indInativo: param.indInativo,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    setListTipCargos(
      tipCargo.map(status => ({ key: status.key, value: status.value }))
    )
  }, [tipCargo])

  useEffect(() => {
    setListTipStatus(
      indInativo.map(status => ({ key: status.key, value: status.value }))
    )
  }, [indInativo])

  const classes = makeStyles({
    container: {
      marginTop: 50,
    },
  })()

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                autoFocus
                id="desCargo"
                name="desCargo"
                label="Descrição"
                value={formik.values.desCargo}
                onChange={formik.handleChange}
                error={
                  formik.touched.desCargo && Boolean(formik.errors.desCargo)
                }
                helperText={formik.touched.desCargo && formik.errors.desCargo}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.container,
                    variant: 'menu',
                  },
                }}
                id="tipCargo"
                name="tipCargo"
                label="Perfil do Cargo"
                value={formik.values.tipCargo}
                onChange={formik.handleChange}
                error={
                  formik.touched.tipCargo && Boolean(formik.errors.tipCargo)
                }
                helperText={formik.touched.tipCargo && formik.errors.tipCargo}
              >
                {listTipCargos.map(cargo => (
                  <MenuItem key={cargo.key} value={cargo.key}>
                    {cargo.value}
                  </MenuItem>
                ))}
              </TextField>
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                select
                SelectProps={{
                  MenuProps: {
                    className: classes.container,
                    variant: 'menu',
                  },
                }}
                id="indInativo"
                name="indInativo"
                label="Status"
                value={formik.values.indInativo}
                onChange={formik.handleChange}
                error={
                  formik.touched.indInativo && Boolean(formik.errors.indInativo)
                }
                helperText={
                  formik.touched.indInativo && formik.errors.indInativo
                }
              >
                {listTipStatus.map(status => (
                  <MenuItem key={status.key} value={status.key}>
                    {status.value}
                  </MenuItem>
                ))}
              </TextField>
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <Button
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
            variant="contained"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  isDeleteAction: PropTypes.bool,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
}

export default Form
