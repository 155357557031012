import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as action_task from '../../actions/activity'

import Main from '../Main'
import TablePagination from '../../utils/Table/TablePagination'
import Checkbox from '../../utils/Form/Checkbox'
import { convertDate } from '../../utils/Date'
import { convertActivityStatus } from '../../utils/Status'

import Alert from '@material-ui/lab/Alert'
import { TableContainer, Paper } from '@material-ui/core'

import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'
import { YYYY_MM_DD_PARSE, DD_MM_YYYY_PARSE } from '../../../constants/date'

const CHARGE_ADMIN = 1

const MOCK_USER = {
  userId: 1,
  desc: 'Bruno',
  salary: 1000.0,
  typeContract: 'Terceirizado',
  officeType: {
    code: 2,
    desc: 'admin',
    fullView: true,
  },
}

const SORT = 'id'

const USER_ACTIVITY_HEADER = [
  'Projeto',
  'Pontuação',
  'Descrição',
  'Tipo',
  'Data de criação',
  'Previsão de entrega',
  'Status',
]
const TEAM_ACTIVITY_HEADER = [
  'Desenvolvedor',
  'Projeto',
  'Pontuação',
  'Descrição',
  'Tipo',
  'Data de criação',
  'Previsão de entrega',
  'Status',
]

const UserTask = ({
  /** Actions */
  getUserCurrentTask,
  /** Action feedback */
  error,
  userActivities,
  projectActivities,
}) => {
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [displayTeamActivities, setDisplayTeamActivities] = useState(false)

  /**
   * Get user task activities when page or size change
   */
  useEffect(() => {
    query()
  }, [page, size])

  const query = () => {
    const pagination = { page, size, sort: SORT }

    getUserCurrentTask(MOCK_USER.userId, pagination)
  }

  /**
   * Get table activity header
   */
  const getActivityHeader = () => {
    return (
      (displayTeamActivities && TEAM_ACTIVITY_HEADER) || USER_ACTIVITY_HEADER
    )
  }

  /**
   * Convert User Activities received
   */
  const convertUserActivities = () => {
    const activities = []

    if (displayTeamActivities) {
      projectActivities.map(activity =>
        // eslint-disable-next-line
        activity.developerActivities.map((devData, devIndex) => {
          // eslint-disable-next-line
          devData.activities.map((devActivity, index) => {
            activities.push({
              id: `${devIndex}${index}`,
              developerName: devData.user.desc,
              projectName: activity.project.desc,
              activityPoint: devActivity.point.number,
              activityDesc: devActivity.desc,
              activityType: devActivity.typeActivity,
              activityCreatedDate: convertDate(
                devActivity.initialDate,
                YYYY_MM_DD_PARSE,
                DD_MM_YYYY_PARSE
              ),
              activityForecastDate: convertDate(
                devActivity.forecastDate,
                YYYY_MM_DD_PARSE,
                DD_MM_YYYY_PARSE
              ),
              activityStatus: convertActivityStatus(devActivity.status),
            })
          })
        })
      )
    } else {
      userActivities.map((activity, index) =>
        activities.push({
          id: index,
          projectName: activity.project.desc,
          activityPoint: activity.point.number,
          activityDesc: activity.desc,
          activityType: activity.typeActivity,
          activityCreatedDate: convertDate(
            activity.initialDate,
            YYYY_MM_DD_PARSE,
            DD_MM_YYYY_PARSE
          ),
          activityForecastDate: convertDate(
            activity.forecastDate,
            YYYY_MM_DD_PARSE,
            DD_MM_YYYY_PARSE
          ),
          activityStatus: convertActivityStatus(activity.status),
        })
      )
    }

    return activities
  }

  /**
   * Render error
   */
  const renderError = () => {
    return (
      error && (
        <Alert severity="error">Ocorreu um erro ao processar a ação.</Alert>
      )
    )
  }

  return (
    <Main title="Pontos">
      <Checkbox
        disabled={MOCK_USER.officeType.code !== CHARGE_ADMIN}
        checked={displayTeamActivities}
        label="Visualizar atividades do time"
        name="displayTeamActivities"
        onChange={displayTeamActivities =>
          setDisplayTeamActivities(displayTeamActivities)
        }
      />

      {!error && (
        <TableContainer component={Paper}>
          <TablePagination
            count={userActivities.length}
            headers={getActivityHeader()}
            onChangePage={(event, page) => setPage(page)}
            onChangeSize={event => setSize(event.target.value)}
            page={page}
            rows={convertUserActivities()}
            size={size}
            hasAction={false}
            startDelete={() => {}}
            startEdit={() => {}}
            alignTitle={['left', 'left', 'center']}
            alignItens={['left', 'left']}
          />
        </TableContainer>
      )}

      {renderError()}
    </Main>
  )
}

const mapStateToProps = ({ activityReducer }) => ({ ...activityReducer })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...action_task }, dispatch)

UserTask.propTypes = {
  /** Actions */
  getUserCurrentTask: PropTypes.func.isRequired,
  /** Action feedback */
  error: PropTypes.object,
  userActivities: PropTypes.array.isRequired,
  projectActivities: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTask)
