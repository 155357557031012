import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.user + '/filter', param)

    return dispatch(trigger(types.user.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.user.getFilter.error, error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.user, param)

    return dispatch(trigger(types.user.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.user.get.error))
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.user, param)

    return dispatch(trigger(types.user.delete.success))
  } catch (error) {
    return dispatch(trigger(types.user.delete.error, error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.user, param)

    return dispatch(trigger(types.user.save.success))
  } catch (e) {
    return dispatch(trigger(types.user.save.error, e.response.data.message))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.user, param)

    return dispatch(trigger(types.user.update.success))
  } catch (e) {
    return dispatch(trigger(types.user.update.error, e.response.data.message))
  }
}
