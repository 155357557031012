import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  flexWrapper: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
})

/**
 * Default flex wrapper
 */
const FlexWrapper = ({ children }) => {
  /** Classes */
  const flexWrapper = useStyles().flexWrapper

  return (
    <Box className={flexWrapper} display="flex">
      {children}
    </Box>
  )
}

FlexWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FlexWrapper
