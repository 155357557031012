import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useFormik } from 'formik'

import { Box, TextField } from '@material-ui/core/'
import { Autocomplete } from '@material-ui/lab'

import * as proposalFunctionActions from '../../../actions/proposalFunction'
import { get as fetchEmployeePositions } from '../../../actions/office'

import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import Button from '../../../utils/Form/Button'
import { Loader } from '../../../utils/Loading'
import { validateValues } from './validations'
import Toast from '../../../utils/Toast'
import { types } from '../../../../config/action_types'
import useFeedback from '../../../utils/hooks/useFeedback'
import NumberFormat from 'react-number-format'
import { WrapperWithLabel } from '../../../utils/WrapperWithLabel'

const NO_OPTIONS_TEXT = 'Sem opções'

const NUMERIC_FIELDS_LINE_1 = [
  { name: 'alocacao', label: '% Alocação', prefix: '', error: 'erroAlocacao' },
]
const NUMERIC_FIELDS_LINE_3 = [
  { name: 'vlrHora', label: 'Valor Hora', prefix: 'R$ ', error: 'erroVlrHora' },
  { name: 'vlrTotal', label: 'Valor Contrato', prefix: 'R$ ', error: 'erroVlrTotal' },
]

const NUMERIC_FIELDS_LINE_2 = [
  {
    name: 'vlrHora50',
    label: 'Valor hora extra 50%',
    prefix: 'R$ ',
    error: 'erroVlrHora50',
  },
  {
    name: 'vlrHora75',
    label: 'Valor hora extra 75%',
    prefix: 'R$ ',
    error: 'erroVlrHora75',
  },
  {
    name: 'vlrHora100',
    label: 'Valor hora extra 100%',
    prefix: 'R$ ',
    error: 'erroVlrHora100',
  },
  {
    name: 'vlrHoraAdn50',
    label: 'Valor hora ADN 50%',
    prefix: 'R$ ',
    error: 'erroVlrHoraAdn50',
  },
  {
    name: 'vlrHoraAdn75',
    label: 'Valor hora ADN 75%',
    prefix: 'R$ ',
    error: 'erroVlrHoraAdn75',
  },
  {
    name: 'vlrHoraAdn100',
    label: 'Valor hora ADN 100%',
    prefix: 'R$ ',
    error: 'erroVlrHoraAdn100',
  },
  {
    name: 'vlrHoraSobreAviso',
    label: 'Valor Hora sobreaviso',
    prefix: 'R$ ',
    error: 'erroVlrHoraSobreAviso',
  },
]

const DEFAULT_FORMIK_VALUES = {
  id: '',
  desFuncao: '',
  desSenioridade: '',
  cargo: null,
  alocacao: '',
  vlrHora: 0,
  vlrHora50: 0,
  vlrHora75: 0,
  vlrHora100: 0,
  vlrHoraAdn50: 0,
  vlrHoraAdn75: 0,
  vlrHoraAdn100: 0,
  vlrHoraSobreAviso: 0,
  vlrTotal: 0,
  proposta: null,
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, formik, errorAttribute, name, ...other } = props

  return (
    <NumberFormat
      {...other}
      name={name}
      getInputRef={inputRef}
      onValueChange={values => {
        formik.setFieldValue(name, values.floatValue).then(() => {
          formik.setFieldError(errorAttribute, '')
        })
      }}
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString={true}
    />
  )
}

const NumberFormatCustomPercentil = props => {
  const { inputRef, onChange, formik, errorAttribute, name, ...other } = props
  return (
    <NumberFormat
    {...other}
    name={name}
    getInputRef={inputRef}
    onValueChange={values => {
      formik.setFieldValue(name, values.floatValue).then(() => {
        formik.setFieldError(errorAttribute, '')
      })
    }}
      suffix="%"
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={0}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString={true}


    />
  )
}

const Form = props => {
  const {
    proposalFunctionToEdit = null,
    listProposal = [],
    listOffices = [],
    listProposalFunction = [],
    updateProposalFunction,
    saveProposalFunction,
    handleCloseForm,
    fetchProposalFunctions,
  } = props

  const isProposalFunctionEdit = !!proposalFunctionToEdit

  const { feedback, hasFeedback, setError, clearFeedback } = useFeedback()

  const [isLoading, setIsLoading] = useState(false)

  const handleResponse = async response => {
    const { type, payload: message } = response
    const hasError =
      type === types.proposal.save.error || type === types.proposal.update.error

    if (hasError) {
      setError(message)
      return
    }

    await fetchProposalFunctions()
    handleCloseForm(message)
  }

  const handleSubmit = async values => {
    setIsLoading(true)
    const { cargo, proposta } = values

    const proposalFunction = {
      ...values,
      proposta: proposta.id,
      ...(cargo && { cargo: cargo.id }),
    }

    let response
    if (isProposalFunctionEdit) {
      response = await updateProposalFunction(proposalFunction)
    }

    if (!isProposalFunctionEdit) {
      response = await saveProposalFunction(proposalFunction)
    }

    await handleResponse(response)
    setIsLoading(false)
  }

  const resolveInitialValues = () => {
    if (isProposalFunctionEdit) {
      const proposalFunction = listProposalFunction.find(
        p => p.id === proposalFunctionToEdit
      )

      if (!proposalFunction) return DEFAULT_FORMIK_VALUES

      const proposal = listProposal.find(
        p => p.id === proposalFunction.proposta
      )
      const employeePosition = listOffices.find(
        p => p.id === proposalFunction.cargo
      )

      return {
        ...proposalFunction,
        proposta: proposal,
        cargo: employeePosition,
      }
    }

    return DEFAULT_FORMIK_VALUES
  }

  const formik = useFormik({
    initialValues: resolveInitialValues(),
    validateOnChange: false,
    validate: validateValues,
    onSubmit: handleSubmit,
  })

  const buildProposalLabel = proposal => {
    const { codProposta, desProposta = '' } = proposal || {}

    if (!codProposta || !desProposta) return ''

    return `${codProposta} - ${desProposta}`
  }

  const renderNumericFields = fields => {
    return fields.map((numericField, index) => (
      <div
        key={numericField.name}
      >
        <TextField
          fullWidth
          id={numericField.name}
          name={numericField.name}
          label={numericField.label}
          value={formik.values[numericField.name] || 0.0}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          // eslint-disable-next-line
          inputProps={{
            errorAttribute: numericField.error,
            prefix: numericField.prefix,
            maxLength: 40,
            formik,
          }}
          error={!!formik.errors[numericField.error]}
          helperText={formik.errors[numericField.error]}
        />
      </div>
    ))
  }

  const renderNumericFieldsPercentil = fields => {
    return fields.map((numericField, index) => (
      <div
        key={numericField.name}
      >
        <TextField
          fullWidth
          id={numericField.name}
          name={numericField.name}
          label={numericField.label}
          value={formik.values[numericField.name]}
          InputProps={{
            inputComponent: NumberFormatCustomPercentil,
          }}
          // eslint-disable-next-line
          inputProps={{
            errorAttribute: numericField.error,
            prefix: numericField.prefix,
            formik,
            maxLength: 4,

          }}
          error={!!formik.errors.erroPerAlocacao}
          helperText={formik.errors.erroPerAlocacao}
        />
      </div>
    ))
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <FlexWrapper>
            <FlexItemWrapper>
              <Autocomplete
                id="proposta"
                name="proposta"
                fullWidth
                noOptionsText={NO_OPTIONS_TEXT}
                options={listProposal}
                getOptionLabel={buildProposalLabel}
                value={formik.values.proposta || null}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Proposta"
                    error={!!formik.errors.erroProposta}
                    helperText={formik.errors.erroProposta}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('proposta', option).then(() => {
                    formik.setFieldError('erroProposta', '')
                  })
                }}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <TextField
                id="nomeProposta"
                name="nomeProposta"
                label="Nome"
                value={(formik.values.proposta || {}).desProposta || ''}
                fullWidth
                disabled
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                id="desFuncao"
                name="desFuncao"
                label="Função"
                fullWidth
                inputProps={{
                  maxLength: 255,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('desFuncao', value).then(() => {
                    formik.setFieldError('erroDesFuncao', '')
                  })
                }}
                value={formik.values.desFuncao}
                error={!!formik.errors.erroDesFuncao}
                helperText={formik.errors.erroDesFuncao}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <TextField
                id="cliente"
                name="cliente"
                label="Cliente"
                fullWidth
                value={(formik.values.proposta || {}).desCliente || ''}
                disabled
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                id="desSenioridade"
                name="desSenioridade"
                label="Senioridade"
                fullWidth
                inputProps={{
                  maxLength: 255,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('desSenioridade', value).then(() => {
                    formik.setFieldError('erroDesSenioridade', '')
                  })
                }}
                value={formik.values.desSenioridade}
                error={!!formik.errors.erroDesSenioridade}
                helperText={formik.errors.erroDesSenioridade}
              />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Autocomplete
                id="cargo"
                name="cargo"
                fullWidth
                noOptionsText={NO_OPTIONS_TEXT}
                options={listOffices}
                getOptionLabel={(option = {}) => option.desCargo}
                value={formik.values.cargo || null}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Cargo"
                    error={!!formik.errors.erroCargo}
                    helperText={formik.errors.erroCargo}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('cargo', option).then(() => {
                    formik.setFieldError('erroCargo', '')
                  })
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <div style={{flexDirection: 'row', display: 'flex', gap: '20%'}}>
          <div style={{display: 'flex', flexDirection: 'column', flex: '1', gap: '10px'}}>
            {renderNumericFieldsPercentil(NUMERIC_FIELDS_LINE_1)}
            {renderNumericFields(NUMERIC_FIELDS_LINE_3)}

          </div>

          <WrapperWithLabel>
            {renderNumericFields(NUMERIC_FIELDS_LINE_2)}
          </WrapperWithLabel>
          </div>
          <br />
          <FlexWrapper>
            <FlexItemWrapper>
              <Button label="Cancelar" onClick={() => handleCloseForm()} />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Button
                color="primary"
                label={isProposalFunctionEdit ? 'Alterar' : 'Salvar'}
                type="submit"
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <br />
        </form>
      </Box>
      <Toast
        error={feedback.error}
        success={feedback.success}
        onClose={clearFeedback}
        open={hasFeedback}
      />
    </>
  )
}

const mapStateToProps = state => {
  const {
    clientReducer,
    proposalReducer,
    officeReducer,
    proposalFunctionReducer,
  } = state

  return {
    ...clientReducer,
    ...proposalReducer,
    ...officeReducer,
    ...proposalFunctionReducer,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...proposalFunctionActions,
      fetchEmployeePositions,
    },
    dispatch
  )

Form.propTypes = {
  /** Actions */
  updateProposalFunction: PropTypes.func.isRequired,
  saveProposalFunction: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  fetchProposalFunctions: PropTypes.func.isRequired,
  /** Reducer props */
  listProposalFunction: PropTypes.array.isRequired,
  listProposal: PropTypes.array.isRequired,
  listOffices: PropTypes.array.isRequired,
  /** Other props */
  proposalFunctionToEdit: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
