import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  listClientPeriod: [],
  listClientPeriodFilter: [],

  errorClientPeriod: null,
  successClientPeriod: null,

  totalElementsClientPeriod: 0,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.clientPeriod.getFilter.success:
      const { resultList, pages } = action.payload
      return {
        ...state,
        listClientPeriodFilter: resultList || [],
        totalElementsClientPeriod: pages.totalCount || resultList.length,
      }
    case types.clientPeriod.getFilter.error:
      return {
        ...state,
        errorClientPeriod: 'Erro ao filtrar cliente por período',
      }

    case types.clientPeriod.get.success:
      return {
        ...state,
        listClientPeriod: action.payload.content || action.payload,
      }

    case types.clientPeriod.get.error:
      return {
        ...state,
        errorClientPeriod: 'Erro ao buscar cliente por período',
      }

    case types.clientPeriod.save.success:
      return {
        ...state,
        successClientPeriod: 'Cliente Período salvo com sucesso',
      }

    case types.clientPeriod.save.error:
      return {
        ...state,
        errorClientPeriod: 'Erro ao salvar Cliente Período',
      }

    case types.clientPeriod.delete.success:
      return {
        ...state,
        successClientPeriod: 'Cliente Período deletado com sucesso',
      }

    case types.clientPeriod.delete.error:
      return {
        ...state,
        errorClientPeriod: 'Erro ao deletar Cliente Período',
      }

    case types.clientPeriod.update.success:
      return {
        ...state,
        successClientPeriod: 'Cliente Período atualizado com sucesso',
      }

    case types.clientPeriod.update.error:
      return {
        ...state,
        errorClientPeriod: 'Erro ao atualizar Cliente Período',
      }

    default:
      return state
  }
}
