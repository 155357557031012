import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  list: [],
  listFilterEntry: [],
  error: null,
  success: null,
  totalElementsEntry: 0,
  totalHours: 0,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.entry.getFilter.success:
      const { resultList, pages, totalHours } = action.payload

      return {
        ...state,
        listFilterEntry: resultList || [],
        totalElementsEntry: pages.totalCount || resultList.length,
        totalHours: totalHours,
      }
    case types.entry.getFilter.error:
      return {
        ...state,
        success: null,
        error: 'Erro ao filtrar lançamentos',
      }
    case types.entry.getReport.success:
      return {
        ...state,
        error: null,
        listFilterEntry:
          action.payload.resultList || action.payload.resultList.length,
        totalHours: action.payload.totalHours,
      }

      case types.entry.getReportRD.error:
        return {
          ...state,
          errorReportRD: action.payload.mensagemErro,
          warningReportRD: null,
          successRD: null,
        }
  
      case types.entry.getReportRD.errorPeriod:
      return {
        ...state,
        errorReportRD:  action.type, 
        warningReportRD: null,
        successRD: null,
      }

    case types.proposal.getFilter.successReportRD: {
      const { resultList } = action.payload

      return {
        ...state,
        listProposal: resultList || [],
        errorReportRD: null,
        warningReportRD: null,
        successRD: null,
      }
    }

    case types.entry.getReportRD.successUserPropostaRD:
      return {
        ...state,
        errorReportRD: null,
        warningReportRD: null,
        successRD: null,
      }

    case types.entry.getReportRD.success:
      return {
        ...state,
        errorReportRD: null,
        warningReportRD: null,
        successRD: true,
      }

    case types.entry.getReportRD.waitingReportGeneration:
      return {
        ...state,
        warningReportRD: 'Aguardando a geração do relatório.',
        errorReportRD: null,
        successRD: null,
      }

    case types.entry.getReportRD.noRegisterUserPropostaRD:
      return {
        ...state,
        listProposal: null,
        warningReportRD: null,
        successRD: null,
        errorReportRD:
          'Não existe a proposta para o usuário ' + action.payload + '.',
      }

    case types.entry.getReportRD.mandatoryFieldsNotCompletedError:
      return {
        ...state,
        errorReportRD: action.payload,
        successRD: null,
      }

    case types.entry.getReportRD.cleanToasterRd:
      return {
        ...state,
        errorReportRD: null,
        warningReportRD: null,
        successRD: null,
      }

    case types.entry.getReportRD.cleanFiltersRd:
      return {
        ...state,
        errorReportRD: null,
        warningReportRD: null,
        successRD: null,
      }

    case types.entry.getReportRD.cleanListPropostalRd:
      return {
        ...state,
        errorReportRD: null,
        warningReportRD: null,
        listProposal: null,
        successRD: null,
      }

      case types.entry.getReportSquadRD.error:
        return {
          ...state,
          errorReportSquadRD: action.payload.mensagemErro,
          warningReportSquadRD: null,
          successSquadRD: null,
        }

      case types.proposal.getFilter.successReportSquadRD: {
        const { resultList } = action.payload

        return {
          ...state,
          listProposal: resultList || [],
          errorReportSquadRD: null,
          warningReportSquadRD: null,
          successSquadRD: null,
        }
      }

      case types.entry.getReportSquadRD.successUserPropostaSquadRD:
        return {
          ...state,
          errorReportSquadRD: null,
          warningReportSquadRD: null,
          successSquadRD: null,
        }

      case types.entry.getReportSquadRD.success:
        return {
          ...state,
          errorReportSquadRD: null,
          warningReportSquadRD: null,
          successSquadRD: true,
        }

      case types.entry.getReportSquadRD.waitingReportGeneration:
        return {
          ...state,
          warningReportSquadRD: 'Aguardando a geração do relatório.',
          errorReportSquadRD: null,
          successSquadRD: null,
        }

      case types.entry.getReportSquadRD.noRegisterUserPropostaSquadRD:
        return {
          ...state,
          listProposal: null,
          warningReportSquadRD: null,
          successSquadRD: null,
          errorReportSquadRD:
            'Não existe a proposta para o usuário ' + action.payload + '.',
        }

      case types.entry.getReportSquadRD.mandatoryFieldsNotCompletedErrorSquadRD:
        return {
          ...state,
          errorReportSquadRD: action.payload,
          successSquadRD: null,
        }
        
      case types.entry.getReportSquadRD.cleanToasterSquadRd:
        return {
          ...state,
          errorReportSquadRD: null,
          warningReportSquadRD: null,
          successSquadRD: null,
        }

      case types.entry.getReportSquadRD.cleanFiltersSquadRd:
        return {
          ...state,
          errorReportSquadRD: null,
          warningReportSquadRD: null,
          successSquadRD: null,
        }

      case types.entry.getReportSquadRD.cleanListPropostalSquadRd:
        return {
          ...state,
          errorReportSquadRD: null,
          warningReportSquadRD: null,
          listProposal: null,
          successSquadRD: null,
        }

    case types.entry.get.success:
      return {
        ...state,
        error: null,
        list: action.payload.content || action.payload,
      }

    case types.entry.get.error:
      return {
        ...state,
        success: null,
        error: 'Erro ao buscar lançamentos',
      }

    case types.entry.save.success:
      return {
        ...state,
        error: null,
        success: 'Lançamento salvo com sucesso',
      }

    case types.entry.save.error:
      return {
        ...state,
        success: null,
        error: action.payload.messageError || 'Erro ao salvar lançamento',
      }

    case types.entry.delete.success:
      return {
        ...state,
        error: null,
        success: 'Lançamento deletado com sucesso',
      }

    case types.entry.delete.error:
      return {
        ...state,
        success: null,
        error: 'Erro ao deletar lançamento',
      }

    case types.entry.update.success:
      return {
        ...state,
        error: null,
        success: 'Lançamento atualizado com sucesso',
      }

    case types.entry.update.error:
      return {
        ...state,
        success: null,
        error: action.payload.messageError || 'Erro ao atualizar lançamento',
      }

    default:
      return state
  }
}
