import React, { useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as proposalActions from '../../../actions/proposal'
import * as executionActions from '../../../actions/execution'

import TablePagination from '../../../utils/Table/TablePaginationPreClosure'
import CustomProposalActions from './CustomActions'

const TABLE_HEADER = [
  'Proposta',
  'Nome do Projeto',
  'Tipo Proposta',
  'Modelo',
  'Status',
]

const TABLE_VALUES_ORDER = [
  'codProposta',
  'desProjeto',
  'desProposta',
  'modelo',
  'tipStatus',
]

const doNotShowTable = ['id', 'seqCliente']

const Table = props => {
  const {
    fetchProposals,
    requestFilters,
    handlePagination,
    totalCount,
    onEdit,
    handlePaginationSize,
    executionList,
    filter
  } = props

  const handleChangePage = async (_, newPage) => {
    await handlePagination(newPage)
  } 
 console.log(requestFilters)
  const handleChangePageSize = async event => {
    const newPageSize = event.target.value
    await handlePaginationSize(newPageSize)
  }

  useEffect(() => {
    fetchProposals()
  }, [fetchProposals])

  const ListExecution = useMemo(() => {
    return executionList
      .map(item => {
        const {
          id,
          proposal,
          executionStatusType,
          desProjeto
        } = item
        return {
          id,
          codProposta: proposal ? proposal.codProposta : '',
          desProjeto: desProjeto,
          desProposta: proposal ? proposal.desProposta : '',
          modelo: proposal.tipModelo,
          tipStatus: executionStatusType,
        }
      }
      ).sort((a, b) => {
        const alphaComparison = a.codProposta.localeCompare(b.codProposta, undefined, { numeric: true });  
        return alphaComparison !== 0 ? alphaComparison : (parseInt(a.codProposta) - parseInt(b.codProposta));
      });

  }, [executionList])

  return (
    <TablePagination
      titleTable="entry"
      count={ListExecution.length !== 0 ? totalCount : 0}
      headers={TABLE_HEADER}
      headersIndexOrder={TABLE_VALUES_ORDER}
      doNotShow={doNotShowTable}
      onChangePage={handleChangePage}
      onChangeSize={handleChangePageSize}
      page={requestFilters.pageFilter}
      rows={ListExecution}
      proposal={true}
      size={requestFilters.sizeFilter}
      startDelete={()=>{}}
      startEdit={onEdit}
      renderCustomActions={(rowData) =>
        <CustomProposalActions filter={filter} rowData={rowData} />
      }
    />
  )
}

const mapStateToProps = state => {
  const { projectReducer, proposalReducer, executionReducer } = state

  return {
    ...proposalReducer,
    ...projectReducer,
    executionList: executionReducer.list,
    sizeFilter: executionReducer.sizeFilter,
    totalCount: executionReducer.totalCount,
    currentPage: executionReducer.currentPage,
    requestFilters: executionReducer.requestFilters
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...proposalActions,
      ...executionActions,
      changeExecutionId: executionActions.changeExecutionId
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Table)
