import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useFormik } from 'formik'
import FormControl from '@material-ui/core/FormControl'

import { Box, TextField } from '@material-ui/core/'
import { Autocomplete } from '@material-ui/lab'

import * as proposalActions from '../../../actions/proposal'
import * as executionActions from '../../../actions/execution'

import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import Button from '../../../utils/Form/Button'
import { Loader } from '../../../utils/Loading'
import Toast from '../../../utils/Toast'
import useFeedback from '../../../utils/hooks/useFeedback'

const NO_OPTIONS_TEXT = 'Sem opções'

const getDateTime = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  return date
}

const DEFAULT_FORMIK_VALUES = {
  id: '',
  client: null,
  proposalCode: '',
  proposalName: '',
  model: null,
  status: null,
  startDate: getDateTime(),
  endDate: getDateTime(),
  managerName: '',
  observacao_relatorio: ''
}

const Form = props => {
  const {
    proposalToEdit = null,
    executionList,
    updateExecution,
    handleCloseForm,
    executionToEdit,
    handleChangeMonthProposal,
    filter
  } = props

  const isProposalEdit = !!proposalToEdit

  const { feedback, hasFeedback, clearFeedback } = useFeedback()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async values => {
    console.log(filter)
    setIsLoading(true)
    const execution = executionList.find(p => p.id === proposalToEdit)

    const proposal = {
      executionStatusType: values.status,
      periodId: execution.period.id,
      processingDate: execution.processingDate,
      proposalId: execution.proposal.id,
      reportObservations: values.observacao_relatorio,
      userProcessing: execution.userProcessing,
    }

    console.log("perde", filter)
    if (isProposalEdit) {
      await updateExecution(execution.id, proposal)
      console.log(proposal)
      handleCloseForm()
      await handleChangeMonthProposal(
        {
          label: filter.desPeriodo,
          value: filter.idPeriod,
        },
        { value: filter.idProposta })
      // executionActions.getExecutionByMonthProposal()
    }

    setIsLoading(false)
  }

  const resolveInitialValues = () => {
    if (isProposalEdit) {
      const execution = executionList.find(p => p.id === proposalToEdit)

      if (!execution) return DEFAULT_FORMIK_VALUES

      return {
        id: executionToEdit.id,
        proposalCode: executionToEdit.proposal.codProposta,
        proposalName: executionToEdit.proposal.desProposta,
        model: executionToEdit.proposal.tipModelo,
        status: executionToEdit.executionStatusType,
        observacao_relatorio: executionToEdit.reportObservations
      }
    }
    return DEFAULT_FORMIK_VALUES
  }

  const formik = useFormik({
    initialValues: resolveInitialValues(),
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                id="proposalCode"
                name="proposalCode"
                label="Proposta"
                fullWidth
                disabled
                inputProps={{
                  maxLength: 20,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('proposalCode', value).then(() => {
                    formik.setFieldError('proposalCodeError', '')
                  })
                }}
                value={formik.values.proposalCode || ''}
                error={!!formik.errors.proposalCodeError}
                helperText={formik.errors.proposalCodeError}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <TextField
                id="proposalName"
                name="proposalName"
                label="Nome da proposta"
                fullWidth
                disabled
                inputProps={{
                  maxLength: 255,
                }}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue('proposalName', value).then(() => {
                    formik.setFieldError('proposalNameError', '')
                  })
                }}
                value={formik.values.proposalName || ''}
                error={!!formik.errors.proposalNameError}
                helperText={formik.errors.proposalNameError}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                id="model"
                name="model"
                fullWidth
                disabled
                label="Modelo"
                value={formik.values.model}
                error={!!formik.errors.modelError}
                helperText={formik.errors.modelError}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <Autocomplete
                id="status"
                name="status"
                fullWidth
                noOptionsText={NO_OPTIONS_TEXT}
                options={["Aberto", "Em análise", "Fechado"]}
                value={formik.values.status}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Status"
                    error={!!formik.errors.statusError}
                    helperText={formik.errors.statusError}
                  />
                )}
                onChange={(_, option) => {
                  formik.setFieldValue('status', option).then(() => {
                    formik.setFieldError('statusError', '')
                  })
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Observação relatório:"
              id="observacao_relatorio"
              name="observacao_relatorio"
              type="string"
              value={formik.values.observacao_relatorio}
              rows={10}
              variant="outlined"
              onChange={({ target: { value } }) => {
                formik.setFieldValue('observacao_relatorio', value)
              }}
              multiline={10}
              inputProps={{
                maxLength: 255,
              }}
            />
          </FormControl>
          <br />
          <FlexWrapper>
            <FlexItemWrapper>
              <Button label="Cancelar" onClick={() => handleCloseForm()} />
            </FlexItemWrapper>
            <FlexItemWrapper right>
              <Button color="primary" label="Alterar" type="submit" />
            </FlexItemWrapper>
          </FlexWrapper>
          <br />
        </form>
      </Box>
      <Toast
        error={feedback.error}
        success={feedback.success}
        onClose={clearFeedback}
        open={hasFeedback}
      />
    </>
  )
}

const mapStateToProps = state => {
  const {
    clientReducer,
    projectReducer,
    periodReducer,
    proposalReducer,
    executionReducer,
  } = state

  return {
    ...clientReducer,
    ...projectReducer,
    ...periodReducer,
    ...proposalReducer,
    executionList: executionReducer.list,
    executionToEdit: executionReducer.toEdit.executionToEdit,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...proposalActions,
      ...executionActions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Form)
