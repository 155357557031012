import React from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as proposalActions from '../../../actions/proposal'

import TablePagination from '../../../utils/Table/TablePagination'
import CustomProposalActions from './CustomActions'

const TABLE_HEADER = [
  'Cliente',
  'Proposta',
  'Nome',
  'Modelo',
  'Status',
  'Data Inicial',
  'Data Final',
  'Projeto',
  'Projeto SAP',
]

const TABLE_VALUES_ORDER = [
  'desCliente',
  'codProposta',
  'desProposta',
  'tipModelo',
  'tipStatus',
  'dtaInicio',
  'dtaFim',
  'desProjeto',
  'desProjetoSAP',
]

const doNotShowTable = ['id', 'seqCliente']

const Table = props => {
  const {
    filterParams,
    changePage,
    changePageSize,
    totalElementsProposal,
    onEdit,
    onDelete,
    onDuplicate = () => null,
    proposalToTable
  } = props
  const handleChangePage = async (_, newPage) => {
    await changePage(newPage)
  }

  const handleChangePageSize = async event => {
    const newPageSize = event.target.value
    await changePageSize(newPageSize)
  }

  return (
    <TablePagination
      titleTable="entry"
      count={totalElementsProposal}
      headers={TABLE_HEADER}
      headersIndexOrder={TABLE_VALUES_ORDER}
      doNotShow={doNotShowTable}
      onChangePage={handleChangePage}
      onChangeSize={handleChangePageSize}
      page={filterParams.pageFilter}
      rows={proposalToTable}
      proposal={true}
      size={filterParams.sizeFilter}
      startDelete={onDelete}
      startEdit={onEdit}
      renderCustomActions={(rowData) =>
        <CustomProposalActions rowData={rowData} onDuplicate={onDuplicate} />
      }
    />
  )
}

const mapStateToProps = state => {
  const { projectReducer, proposalReducer } = state

  return {
    ...proposalReducer,
    ...projectReducer,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...proposalActions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Table)
