const activityStatus = ['Não iniciado', 'Iniciado', 'Finalizado']

/**
 * Convert date to string
 *
 * @param {string} date - Date to convert
 */
export const convertActivityStatus = status => {
  return activityStatus[status]
}
