import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as closureClientsActions from '../../actions/closureClients'
import { get as getUsers } from '../../actions/user'
import { globalStyles } from '../../styles/global'
import Main from '../Main'
import { Box, Grid } from '@material-ui/core'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/FilterClosureClients'

const closureClients = props => {
  const {
    listPeriods,
    getPeriod,
    listProposal,
    getProposal,
    setPeriod,
    setProposal,
    feedback,
    getUsers,
    generateClosure,
    clearFilters,
  } = props
  const styles = globalStyles()
  const [showToast, setShowToast] = useState(false)
  const [showToastError, setShowToastError] = useState(false)
  const [isPeriod, setIsPeriod] = useState(false)
  const [isProposal, setIsProposal] = useState(false)
  // const [filter, setFilter] = useState({})

  const listaProposal = useCallback(
    async period => {
      const selectedPeriod = listPeriods.find(
        item => item.id === period
      )
      await getProposal(selectedPeriod)
    },
    [listPeriods]
  )

  useEffect(() => {
    if (feedback.success) {
      setShowToast(true)
    } else {
      setShowToast(false)
    }

    if (feedback.error) {
      setShowToastError(true)
    } else {
      setShowToastError(false)
    }
  }, [feedback])

  const handleFilterSubmit = async value => {
    const selectedPeriod = listPeriods.find(
      item => item.id === value.idPeriod
    )
    if (selectedPeriod) await generateClosure(selectedPeriod);
  }

  useEffect(() => {
    getPeriod({ indFechado: 1, sort: 'dtaInicio desc' })
  }, [listProposal, isProposal])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  const handleUserFilterChange = async (fieldName, value) => {
    if (fieldName === 'idPeriod') {
      await setPeriod({
        label: fieldName,
        value: value
      })
      if(value === null){
        setIsPeriod(false)
      } else {
        await listaProposal(value)
        setIsPeriod(true)
      }
    } else if (fieldName === 'idProposal') {
      await setProposal({
        label: fieldName,
        value: value
      })
      if(value === null){
        setIsProposal(false)
      }
    } else {
      setIsPeriod(false)
      setIsProposal(false)
    }
  }

  const handleClearTable = async () => {
    await clearFilters();
  }

  const filterProps = useMemo(() => ({
    onSubmit: handleFilterSubmit,
    onValueChange: handleUserFilterChange,
    onClear: handleClearTable,
    fields: [
      {
        type: 'select',
        name: 'idPeriod',
        label: 'Período',
        options: (listPeriods || []).map(({ id, desPeriodo }) => ({
          label: desPeriodo,
          value: id,
        })),
      },
      {
        type: 'select',
        name: 'idProposal',
        label: 'Proposta',
        options:
        (listProposal || [])
        .filter((item) => item.tipStatus === 'ANDAMENTO')
        .map(item => ({
          label: item.codProposta,
          value:  item.id,
        }))
        .sort((a, b) => {
          const propostaA = a.label;
          const propostaB = b.label;
          const alphaComparison = propostaA.localeCompare(propostaB, undefined, { numeric: true });
          return alphaComparison !== 0 ? alphaComparison : (parseInt(propostaA) - parseInt(propostaB)); }),
        disabled: !isPeriod,
        },
    ],
  }), [listPeriods, isPeriod, listProposal])

  return (
    <Main title="Fechamento Clientes">
      <Toast
        success={feedback.message}
        open={showToast}
        onClose={() => setShowToast(false)}
        autoHideDuration={2000}
      />
      <Toast
        error={feedback.message}
        open={showToastError}
        onClose={() => setShowToastError(false)}
        autoHideDuration={2000}
      />
      <Grid item xs={12}>
        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>
      </Grid>
    </Main>
  )
}

const mapStateToProps = state => {
  const {
    closureClientsReducer,
  } = state

  return {
    ...closureClientsReducer,
    listPeriods: closureClientsReducer.listPeriods,
    listProposal: closureClientsReducer.listProposal,
    feedback: closureClientsReducer.feedback,
    period: closureClientsReducer.period,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPeriod: closureClientsActions.getPeriod,
      getProposal: closureClientsActions.getProposal,
      setPeriod: closureClientsActions.setPeriod,
      setProposal: closureClientsActions.setProposal,
      generateClosure: closureClientsActions.generateClosure,
      clearFilters: closureClientsActions.clearFilters,
      getUsers,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(closureClients)
