import Http from '../../config/Http'
import URL from '../../config/URL'
import { trigger, types } from '../../config/action_types'
import { DEFAULT_PAGINATION_PAGE } from '../../constants/pagination'

export const fetchProposalUsers = () => async (dispatch, getState) => {
  const {
    proposalUserReducer: { filterParams },
  } = getState()
  try {
    const response = await new Http(dispatch).get(
      URL.proposalUser + '/filter',
      {
        ...filterParams,
        pageFilter: filterParams.pageFilter + 1,
        sort: 'id desc',
      }
    )
    return dispatch(trigger(types.proposalUser.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.proposalUser.getFilter.error, error))
  }
}

export const fetchProposalByUsersProposalReducer = filterParams => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.proposalUser + '/filtersProposalByUser',
      {
        ...filterParams,
        sort: 'id desc',
      }
    )
    if (response.resultList.length !== 0) {
      return dispatch(
        trigger(types.proposal.getFilter.success, response)
      )
    } else if (response.resultList.length === 0) {
      return dispatch(trigger('USUARIO_SEM_PROPOSTA', null))
      
    } else {
      return dispatch(
        trigger(
          types.proposal.getFilter.error,
          filterParams.desUsuario
        )
      )
    }
  } catch (error) {
    return dispatch(trigger(types.proposal.getFilter.error))
  }
}

export const fetchProposalByUsers = filterParams => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.proposalUser + '/filtersProposalByUser',
      {
        ...filterParams,
        sort: 'id desc',
      }
    )
    if (response.resultList.length !== 0) {
      return dispatch(
        trigger(types.proposal.getFilter.successReportRD, response)
      )
    } else {
      return dispatch(
        trigger(
          types.entry.getReportRD.noRegisterUserPropostaRD,
          filterParams.desUsuario
        )
      )
    }
  } catch (error) {
    return dispatch(trigger(types.proposal.getFilter.error))
  }
}

export const setProposalUserFilterParams = params => async dispatch => {
  const { cliente, proposta, funcao } = params
  dispatch(
    trigger(types.proposalUser.updateFilter, {
      ...(!!cliente && { cliente }),
      ...(!!proposta && { proposta }),
      ...(!!funcao && { funcao }),
      pageFilter: DEFAULT_PAGINATION_PAGE,
    })
  )
  dispatch(fetchProposalUsers())
}

export const changePage = newPage => async (dispatch, getState) => {
  const { proposalUserReducer } = getState()
  dispatch(
    trigger(types.proposalUser.updateFilter, {
      ...proposalUserReducer.filterParams,
      pageFilter: newPage,
    })
  )
  dispatch(fetchProposalUsers())
}

export const changePageSize = newPageSize => async (dispatch, getState) => {
  const { proposalUserReducer } = getState()
  dispatch(
    trigger(types.proposalUser.updateFilter, {
      ...proposalUserReducer.filterParams,
      sizeFilter: newPageSize,
      pageFilter: DEFAULT_PAGINATION_PAGE,
    })
  )
  dispatch(fetchProposalUsers())
}

export const saveProposalUser = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.proposalUser, param)

    return dispatch(
      trigger(
        types.proposalUser.save.success,
        'Usuário da proposta salvo com sucesso'
      )
    )
  } catch {
    return dispatch(
      trigger(
        types.proposalUser.save.error,
        'Erro ao salvar usuário da proposta'
      )
    )
  }
}

export const removeProposalUser = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.proposalUser, param)

    return dispatch(
      trigger(
        types.proposalUser.delete.success,
        'Usuário da proposta excluído com sucesso'
      )
    )
  } catch (error) {
    return dispatch(
      trigger(
        types.proposalUser.delete.error,
        'Erro ao excluir usuário da proposta'
      )
    )
  }
}

export const updateProposalUser = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.proposalUser, param)

    return dispatch(
      trigger(
        types.proposalUser.update.success,
        'Usuário da proposta atualizado com sucesso'
      )
    )
  } catch (error) {
    return dispatch(
      trigger(
        types.proposalUser.update.error,
        'Erro ao atualizar usuário da proposta'
      )
    )
  }
}
