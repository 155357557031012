import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useFormik } from 'formik'

import TextField from '@material-ui/core/TextField'

import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import Select from '../../../utils/Form/Select'
import createSyntheticEvent from '../../../utils/createSyntheticEvent'

const indInativo = [
  { key: 'ATIVO', value: 'ATIVO' },
  { key: 'INATIVO', value: 'INATIVO' },
]

const validationSchema = yup.object({
  descCliente: yup
    .string()
    .max(50, 'O nome deve conter no máximo 50 caracteres!')
    .required('Por favor, informe o nome!'),
  indInativo: yup
    .string()
    .required('Por favor, escolha o status!')
    .nullable(),
})

/**
 * Activity form
 */
const Form = ({
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [listTipStatus, setListTipStatus] = useState([])

  const formik = useFormik({
    initialValues: {
      id: param.id,
      descCliente: param.descCliente,
      indInativo: param.indInativo,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      values.indInativo = values.indInativo === 'ATIVO' ? true : false
      onSubmit(values)
    },
  })

  useEffect(() => {
    setListTipStatus(
      indInativo.map(status => ({ key: status.key, value: status.value }))
    )
  }, [indInativo])

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {!isDeleteAction && (
          <>
            <FlexWrapper>
              <FlexItemWrapper>
                <TextField
                  fullWidth
                  id="descCliente"
                  label="Nome"
                  value={formik.values.descCliente}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.descCliente &&
                    Boolean(formik.errors.descCliente)
                  }
                  helperText={
                    formik.touched.descCliente && formik.errors.descCliente
                  }
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </FlexItemWrapper>

              <FlexItemWrapper>
                <Select
                  fullWidth
                  select
                  id="indInativo"
                  name="indInativo"
                  label="Status"
                  options={listTipStatus}
                  value={{ value: formik.values.indInativo }}
                  onChange={val =>
                    formik.handleChange(
                      createSyntheticEvent('indInativo', val.target.value)
                    )
                  }
                  error={
                    formik.touched.indInativo &&
                    Boolean(formik.errors.indInativo)
                  }
                  helperText={
                    formik.touched.indInativo && formik.errors.indInativo
                  }
                />
              </FlexItemWrapper>
            </FlexWrapper>
          </>
        )}

        <FlexWrapper>
          <FlexItemWrapper>
            <Button label="Cancelar" onClick={onCancel} />
          </FlexItemWrapper>

          <FlexItemWrapper right>
            <Button
              color={primaryButtonColor}
              label={primaryButtonLabel}
              type="submit"
              variant="contained"
            />
          </FlexItemWrapper>
        </FlexWrapper>
      </form>
    </div>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  isDeleteAction: PropTypes.bool,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
}

export default Form
