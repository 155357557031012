import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  error: null,
  userInfo: {},
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.password.error:
      return {
        ...state,
        error: action.payload,
      }
    case types.password.success:
      return {
        ...state,
        error: null,
        userInfo: action.payload,
      }
    default:
      return state
  }
}
