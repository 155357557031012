import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  filter: {},
  shouldApplyFilter: false,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.entryReport.setFilter:
      return {
        filter: action.payload,
        shouldApplyFilter: false,
      }

    case types.entryReport.markFilterAsValidToApply:
      const isFilterNotEmpty =  JSON.stringify(state.filter) !== '{}'
      return {
        ...state,
        shouldApplyFilter: isFilterNotEmpty,
      }

    default:
      return state
  }
}
