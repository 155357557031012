const isValidDate = d => {
  return d instanceof Date && !isNaN(d)
}
export const validateValues = values => {
  const {
    client,
    proposalCode,
    proposalName,
    model,
    status,
    startDate,
    endDate,
  } = values
  let errors = {}

  if (!client) {
    errors = { ...errors, clientError: 'Informe o cliente' }
  }

  if (!proposalCode) {
    errors = { ...errors, proposalCodeError: 'Informe o código da proposta' }
  }

  if (!proposalName) {
    errors = { ...errors, proposalNameError: 'Informe o nome da proposta' }
  }

  if (!model) {
    errors = { ...errors, modelError: 'Informe o modelo' }
  }

  if (!status) {
    errors = { ...errors, statusError: 'Informe o status' }
  }

  /*DATE VALIDATIONS */
  if (!startDate) {
    errors = { ...errors, startDateError: 'Informe a data inicial' }
  }

  if (startDate !== null && !isValidDate(startDate)) {
    errors = { ...errors, startDateError: 'Data inicial inválida' }
  }

  if (!endDate) {
    errors = { ...errors, endDateError: 'Informe a data final' }
  }

  if (endDate !== null && !isValidDate(endDate)) {
    errors = { ...errors, endDateError: 'Data final inválida' }
  }

  if (startDate && endDate) {
    const lessThanInitial = endDate.getTime() < startDate.getTime()
    if (lessThanInitial) {
      errors = {
        ...errors,
        endDateError: 'Data final deve ser maior ou igual a data inicial',
      }
    }
  }
  return errors
}
