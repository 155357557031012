export const columnMapping = {
    codigo: 'Código',
    usuario: 'Usuário',
    totalPositivas: 'Total Positivas',
    total50: 'Total 50',
    total75: 'Total 75',
    total100: 'Total 100',
    adn50: 'Adn 50',
    adn75: 'Adn 75',
    adn100: 'Adn 100',
    totalNegativas: 'Total Negativas',
    saldoInicial: 'Saldo inicial',
    saldoFinal: 'Saldo final',
    previstas: 'Previstas',
    trabalhadas: 'Trabalhadas',
  }
  
  export function convertJsonToCsv(jsonData, columnMapping) {
    if (jsonData.length === 0) {
      return ''
    }
  
    const columns = Object.values(columnMapping)
  
    let csv = columns.join(',') + '\n'
  
    jsonData.forEach(obj => {
      const values = columns.map(col => {
        const key = Object.keys(columnMapping).find(k => columnMapping[k] === col)
        const value = obj[key]
        return typeof value === 'string' ? `"${value}"` : value
      })
      csv += values.join(',') + '\n'
    })
  
    return csv
  }
  