export const  findIndex = (list, obj) => {
    return list.findIndex((current) =>
      Object.keys(current).every((key) => obj[key] === current[key])
    );
  }
  

 export  function extractNameFromURLParameters(userInitFilter, userFinalFilter) {
    const decodedUserInitFilter = decodeURIComponent(userInitFilter);
    const decodedUserFinalFilter = decodeURIComponent(userFinalFilter);
  
    const nameFromUserInitFilter = decodedUserInitFilter.replace(/\+/g, ' ');
    const nameFromUserFinalFilter = decodedUserFinalFilter.replace(/\+/g, ' ');
  
    return {
      userInitFilter: nameFromUserInitFilter,
      userFinalFilter: nameFromUserFinalFilter,
    };
  }
  