import { makeStyles } from '@material-ui/core/styles'

const buttonsStyles = makeStyles({
  box: {
    padding: 15,
  },
  boxButton: {
    display: 'flex',
  },
  button: {
    width: '50%',
    margin: 5,
  },
})

const col = 12

export { buttonsStyles, col }
