import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  errorUserPeriod: null,
  successUserPeriod: null,

  listUserPeriod: [],
  listUserPeriodFilter: [],

  totalElementsUserPeriod: 0,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.userPeriod.getFilter.success:
      const { resultList, pages } = action.payload
      return {
        ...state,
        listUserPeriodFilter: resultList || [],
        totalElementsUserPeriod: pages.totalCount || resultList.length,
      }

    case types.userPeriod.getFilter.error:
      return {
        ...state,
        errorUserPeriod: 'Erro ao buscar Usuários por Período',
        successUserPeriod: null,
      }

    case types.userPeriod.get.success:
      return {
        ...state,
        listUserPeriod: action.payload.content || action.payload,
      }

    case types.userPeriod.get.error:
      return {
        ...state,
        errorUserPeriod: 'Erro ao buscar Usuários por Período',
        successUserPeriod: null,
      }

    case types.userPeriod.save.success:
      return {
        ...state,
        successUserPeriod: 'Usuários por Período salvo com sucesso',
        errorUserPeriod: null,
      }

    case types.userPeriod.save.error:
      return {
        ...state,
        errorUserPeriod: 'Erro ao salvar Usuários por Período',
        successUserPeriod: null,
      }

    case types.userPeriod.delete.success:
      return {
        ...state,
        successUserPeriod: 'Usuários por Período deletado com sucesso',
        errorUserPeriod: null,
      }

    case types.userPeriod.delete.error:
      const { messageError } = action.payload.response.data.message

      return {
        ...state,
        errorUserPeriod: messageError,
        successUserPeriod: null,
      }

    case types.userPeriod.update.success:
      return {
        ...state,
        successUserPeriod: 'Usuários por Período atualizado com sucesso',
        errorUserPeriod: null,
      }

    case types.userPeriod.update.error:
      return {
        ...state,
        errorUserPeriod: 'Erro ao atualizar Usuários por Período',
        successUserPeriod: null,
      }

    default:
      return state
  }
}
