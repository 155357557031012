import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MaterialDialog from '@material-ui/core/Dialog'
import { Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import Draggable from 'react-draggable'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitleStyled = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

/**
 * Default dialog
 */
const Dialog = ({
  children,
  id = 'dialog-title',
  onClose = () => {},
  open = false,
  title = '',
  maxWidth = 'lg',
  isDragDisabled,
}) => {
  let [activeDrags, setActiveDrags] = useState(0)

  const onStart = e => {
    if (
      e.target.nodeName === 'H6' ||
      e.target.nodeName === 'FORM' ||
      (e.target.nodeName === 'DIV' &&
        (e.target.id.length === 0 || e.target.id === 'dialog-title'))
    ) {
      setActiveDrags(++activeDrags)
      return true
    } else {
      return false
    }
  }

  const onStop = e => {
    setActiveDrags(--activeDrags)
    return true
  }

  return (
    <Draggable onStart={onStart} onStop={onStop} defaultClassName="{box}" disabled={isDragDisabled}>
      <MaterialDialog
        BackdropProps={{ invisible: true }}
        fullWidth
        maxWidth={maxWidth}
        onClose={() => true}
        open={open}
        scale={1}
      >
        <DialogTitleStyled id={id} onClose={onClose}>
          {title}
        </DialogTitleStyled>

        <DialogContent>{children}</DialogContent>
      </MaterialDialog>
    </Draggable>
  )
}

Dialog.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  isDragDisabled: PropTypes.bool,
}

export default Dialog
