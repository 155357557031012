import React from 'react'
import PropTypes from 'prop-types'

import MaterialSelect from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core'
import { MenuItem } from '@material-ui/core'

/**
 * Default select
 */
const Select = ({
  noOptionsText = 'Sem registro, realize um cadastro para selecioná-lo',
  label = 'select-label-id',
  onChange = () => {},
  options = [],
  variant,
  id = 'select-id',
  error,
  helperText,
  value = '',
  disabled = false,
}) => {
  const classes = makeStyles({
    container: {
      marginTop: 30,
    },
  })()

  const renderOption = option => {
    return (
      <MenuItem
        key={option.key}
        value={option.key}
        selected={value === option.key && 'true'}
      >
        {option.value}
      </MenuItem>
    )
  }

  return (
    <>
      <MaterialSelect
        fullWidth
        variant={variant}
        select
        id={id}
        name={id}
        label={label}
        value={value.value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        disabled={disabled}
        SelectProps={{
          MenuProps: {
            className: classes.container,
            variant: 'menu',
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        {options.length !== 0 ? (
          options.map(renderOption)
        ) : (
          <MenuItem disabled={true} value="">
            {noOptionsText}
          </MenuItem>
        )}
      </MaterialSelect>
    </>
  )
}

Select.propTypes = {
  labelContent: PropTypes.string,
  labelId: PropTypes.string,
  labelStyle: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  selectId: PropTypes.string,
  selectStyle: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Select
