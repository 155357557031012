import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'

const SelectMultiple = ({
  name,
  options,
  label,
  setValueToField,
  initial = [],
  stateValues,
  wasClear,
  disabled,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        value={
          stateValues
            ? options.filter(option =>
                stateValues.some(stateValue => stateValue === option.value)
              )
            : !wasClear
            ? options.filter(option =>
                initial.some(initialValue => initialValue === option.value)
              )
            : []
        }
        options={options}
        getOptionLabel={option => option.label}
        multiple
        filterSelectedOptions
        fullWidth
        onChange={(_, newArray) =>
          setValueToField(
            name,
            newArray.map(option => option.value)
          )
        }
        disableCloseOnSelect
        renderInput={params => (
          <TextField {...params} label={label} variant="outlined" />
        )}
        disabled={disabled}
      />
    </FormControl>
  )
}

export default SelectMultiple
