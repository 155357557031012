import React from 'react'
import PropTypes from 'prop-types'

import MaterialButton from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  defaultButton: {
    width: '100%',
  },
})

/**
 * Default button
 */
const Button = ({
  className = useStyles().defaultButton,
  color,
  disabled = false,
  label = 'Salvar',
  onClick,
  type = 'button',
  ...otherProps
}) => {
  return (
    <MaterialButton
      className={className}
      color={color}
      disabled={disabled}
      onClick={onClick}
      type={type}
      variant="contained"
      {...otherProps}
    >
      {label}
    </MaterialButton>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
}

export default Button
