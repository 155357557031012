import { useState } from 'react'

const DEFAULT_FEEDBACK = {
  error: '',
  success: '',
}

const useFeedback = () => {
  const [feedback, setFeedback] = useState(DEFAULT_FEEDBACK)

  const hasFeedback = !!feedback.error || !!feedback.success

  const setError = error => setFeedback({ error, success: '' })

  const setSuccess = success => setFeedback({ error: '', success })

  const clearFeedback = () => setFeedback(DEFAULT_FEEDBACK)

  return { feedback, hasFeedback, setError, setSuccess, clearFeedback }
}

export default useFeedback
