import React from 'react'
import PropTypes from 'prop-types'
import { FileCopy, Visibility } from '@material-ui/icons'
import { Link } from "react-router-dom"
import { useStyles } from '../useStyles'

const CustomActions = ({ rowData, onDuplicate }) => {
  const classes = useStyles()

  const queryString = new URLSearchParams({
    cliente: rowData.seqCliente,
    proposta: rowData.id,
  }).toString()

  return (
  <>
    <span>
      <Link
        to={`/proposal-function?${queryString}`}
        className={classes.link}
        title='Ir para função de proposta'
      >
        <Visibility />
      </Link>
      <span>
      <button
        type="button"
        onClick={() => onDuplicate(rowData)}
        className={classes.unstyledLink}
        title="Duplicar proposta"
      >
        <FileCopy />
      </button>
    </span>
    </span>
    </>
  )
}

CustomActions.propTypes = {
  rowData: PropTypes.object.isRequired,
  onDuplicate: PropTypes.func.isRequired,
}

export default CustomActions
