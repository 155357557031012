import { isValid, format, parse, subDays } from "date-fns"
import { utcToZonedTime } from 'date-fns-tz';


export default function getDayOfWeekName(dateStr) {
    const daysOfWeek = [
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
      'Domingo',
    ]
    const date = new Date(dateStr)
    const dayOfWeekNumber = date.getDay()
    const dayOfWeekName = daysOfWeek[dayOfWeekNumber]
    return dayOfWeekName
  }


 export const formatDate = dateString => {

 

  // Adicione um dia à data original
  const originalDate = new Date(dateString);

  if (!isValid(originalDate)) {
    return null; 
  }
  const modifiedDate = new Date(originalDate);
  modifiedDate.setDate(originalDate.getDate() + 1);


  const formattedDate = format(modifiedDate, 'dd/MM/yyyy');
  return formattedDate;
};


  export function formatTimeToHHMM(time) {
    const date = new Date(`1970-01-01T${time}`);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  export const formatDateToString = (dateString) => {
    const parsedDate = parse(dateString, 'EEE MMM dd yyyy HH:mm:ss xx', new Date());
    const formattedDate = format(parsedDate, 'yyyy-MM-dd');
    return formattedDate;
  };

  export const formatDateToBrazilTimeZone = (dateString, daysToAdd = 0) => {
    if (!dateString || dateString === 'null') {
      return null;
  }
  
    const zonedDate = utcToZonedTime(new Date(dateString), 'America/Sao_Paulo');
    const adjustedDate = new Date(zonedDate);
    adjustedDate.setDate(adjustedDate.getDate() + daysToAdd);
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
    return formattedDate;
  };

  export const formatDateFromTextToNumber = (inputDate) => {
    const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
  
    const parts = inputDate.split('/');
    const monthIndex = months.findIndex(month => month === parts[0]);
    const formattedMonth = (monthIndex + 1).toString().padStart(2, '0');
    const formattedYear = parts[1];
  
    return `${formattedMonth}/${formattedYear}`;
  }
  
  export const formatDateToMonthYear = (inputDate) => {
    const [month, year] = inputDate.split('/');
    const monthNames = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    const formattedMonth = monthNames[parseInt(month, 10) - 1]; // Subtrai 1 pois o array começa do índice 0
    return `${formattedMonth}/${year}`;
  }

  export const  subtractOneDay = (dateString) => {
    const date = new Date(dateString);
    const previousDay = subDays(date, 1);
    return format(previousDay, 'yyyy-MM-dd');
  }

  export const formatDateToYYYYMMDD = (dateString) =>{
    const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
  }