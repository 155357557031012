import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware, browserHistory } from 'react-router-redux'
import { Provider } from 'react-redux'
import reducer from './scripts/reducers/rootReducers'
import thunk from 'redux-thunk'
import Routes from './config/Routes'

import './assets/css/main.scss'

const middlewares = [thunk, routerMiddleware(browserHistory)]

const store = createStore(reducer, applyMiddleware(...middlewares))

const target = document.querySelector('.root')

render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  target
)
