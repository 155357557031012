import React, { useState } from 'react'
import DatePicker from './Date'

const DateRange = ({ name, setValueToField, stateValues, disabled }) => {
  const [dateIni, setDateIni] = useState(undefined)
  const [dateEnd, setDateEnd] = useState(undefined)

  return (
    <div className="calendar-control">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <DatePicker
          onChange={newDate => {
            setDateIni(new Date(newDate) || undefined)
            setValueToField(name, [newDate, stateValues[1]])
          }}
          name="dateIni"
          setValueToField={() => {}}
          label="De"
          stateValues={stateValues ? stateValues[0] : null}
          disabled={disabled}
          maxDate={dateEnd}
        />
        <DatePicker
          onChange={newDate => {
            setDateEnd(new Date(newDate) || undefined)
            setValueToField(name, [stateValues[0], newDate])
          }}
          name="dateEnd"
          setValueToField={() => {}}
          label="Até"
          stateValues={stateValues ? stateValues[1] : null}
          disabled={disabled}
          minDate={dateIni}
          style={{ marginLeft: 8 }}
        />
      </div>
    </div>
  )
}

export default DateRange
