import React from 'react'

import Main from '../Main'
import Filter from '../../utils/Filter/Filter'

class FilterTest extends React.Component {
  render() {
    let filterProps = {
      onSubmit: values => {
        console.log({ values })
      },
      fields: [
        {
          type: 'input',
          name: 'input',
          label: 'Input',
        },
        {
          type: 'select',
          name: 'select',
          label: 'Seleção única',
          options: [
            { value: '1', label: 'Opção 1' },
            { value: '2', label: 'Opção 2' },
            { value: '3', label: 'Opção 3' },
            { value: '4', label: 'Opção 4' },
          ],
        },
        {
          type: 'select_multiple',
          name: 'select_multiple',
          label: 'Seleção múltipla',
          options: [
            { value: '1', label: 'Opção 1' },
            { value: '2', label: 'Opção 2' },
            { value: '3', label: 'Opção 3' },
            { value: '4', label: 'Opção 4' },
          ],
        },
        {
          type: 'checkbox',
          name: 'checkbox',
          label: 'Checkbox',
        },
        {
          type: 'date',
          name: 'date',
          label: 'Data',
        },
        {
          type: 'date_range',
          name: 'period',
          label: 'Período',
        },
      ],
    }
    let filterPropsDisabled = {
      onSubmit: values => {
        console.log({ values })
      },
      fields: [
        {
          type: 'input',
          name: 'input',
          label: 'Input',
          disabled: true,
        },
        {
          type: 'select',
          name: 'select',
          label: 'Seleção única',
          options: [
            { value: '1', label: 'Opção 1' },
            { value: '2', label: 'Opção 2' },
            { value: '3', label: 'Opção 3' },
            { value: '4', label: 'Opção 4' },
          ],
          disabled: true,
        },
        {
          type: 'select_multiple',
          name: 'select_multiple',
          label: 'Seleção múltipla',
          options: [
            { value: '1', label: 'Opção 1' },
            { value: '2', label: 'Opção 2' },
            { value: '3', label: 'Opção 3' },
            { value: '4', label: 'Opção 4' },
          ],
          disabled: true,
        },
        {
          type: 'checkbox',
          name: 'checkbox',
          label: 'Checkbox',
          disabled: true,
        },
        {
          type: 'date',
          name: 'date',
          label: 'Data',
          disabled: true,
        },
        {
          type: 'date_range',
          name: 'period',
          label: 'Período',
          disabled: true,
        },
      ],
    }
    let filterPropsInitial = {
      onSubmit: valuesInitial => {
        console.log({ valuesInitial })
      },
      fields: [
        {
          type: 'input',
          name: 'input',
          label: 'Input',
          initial: 'Teste',
        },
        {
          type: 'select',
          name: 'select',
          label: 'Seleção única',
          options: [
            { value: '1', label: 'Opção 1' },
            { value: '2', label: 'Opção 2' },
            { value: '3', label: 'Opção 3' },
            { value: '4', label: 'Opção 4' },
          ],
          initial: '1',
        },
        {
          type: 'select_multiple',
          name: 'select_multiple',
          label: 'Seleção múltipla',
          options: [
            { value: '1', label: 'Opção 1' },
            { value: '2', label: 'Opção 2' },
            { value: '3', label: 'Opção 3' },
            { value: '4', label: 'Opção 4' },
          ],
          initial: ['1'],
        },
        {
          type: 'checkbox',
          name: 'checkbox',
          label: 'Checkbox',
          initial: true,
        },
        {
          type: 'date',
          name: 'date',
          label: 'Data',
          initial: new Date('10/20/1995'),
        },
        {
          type: 'date_range',
          name: 'period',
          label: 'Período',
          initial: [new Date(), new Date()],
        },
      ],
    }

    return (
      <Main title="Teste de filtro">
        <Filter {...filterProps} />
        <Filter {...filterPropsInitial} />
        <Filter {...filterPropsDisabled} />
      </Main>
    )
  }
}

export default FilterTest
