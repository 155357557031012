import React, { useEffect, useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { TableContainer, Paper, Box, Grid, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Filter from '../../utils/Filter/Filter'

import Form from './components/Form'
import Main from '../Main'
import * as actionHourBank from '../../actions/hourBank'
import * as actionPeriod from '../../actions/period'
import { get as getUsers } from '../../actions/user'
import TablePagination from '../../utils/Table/TablePagination'
import Dialog from '../../utils/Dialog'
import Toast from '../../utils/Toast'
import { globalStyles } from '../../styles/global'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'id'

const DEFAULT_PARAM = {
  id: null,
  periodId: '',
  userId: '',
  qtdSaldoIni: '',
  qtdHorasPositivas: '',
  qtdHorasNegativas: '',
  qtdAjuste: '',
  qtdSaldoFinal: '',
  desJustificaAjuste: '',
  desObs: '',
  qtdHorasPrevista: '',
  qtdAdicional: '',
}

const DO_NOT_SHOW_TABLE = ['userId', 'periodId', 'id']

const TABLE_HEADER = [
  'Usuário',
  'Período',
  'Saldo Inicial',
  'Horas Positivas',
  'Horas Negativas',
  'Ajuste',
  'Saldo Final',
]

const ORDER_TABLE = [
  'desUsuario',
  'desPeriodo',
  'qtdSaldoIni',
  'qtdHorasPositivas',
  'qtdHorasNegativas',
  'qtdAjuste',
  'qtdSaldoFinal',
]

const HourBank = ({
  /** Actions */
  getPeriods,
  getUsers,
  getFilter,
  get,
  remove,
  save,
  update,
  listUsers,
  /** Reducer props */
  listPeriods,
  listHourBankFilter,
  errorHourBank,
  successHourBank,
  totalElementsHourBank,
}) => {
  const { id: idUsuario } = JSON.parse(localStorage.getItem('user'))
  const user = JSON.parse(localStorage.getItem('user'))
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState('Novo registro Banco de Horas')

  const [showToast, setShowToast] = useState(false)

  const [valuesFilter, setValuesFilter] = useState({})

  const handleFilter = values => {
    const { userId, periodId } = values

    setValuesFilter(values)

    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idUsuario &&
        user.office.tipCargo === 'OPERAÇÃO' && { userId: idUsuario }),
      ...(userId && { userId }),
      ...(periodId && { periodId }),
    })

    setPage(DEFAULT_PAGINATION_PAGE)
  }

  const filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'userId',
        label: 'Usuário',
        options: listUsers.map(({ id, desUsuario }) => ({
          label: desUsuario,
          value: id,
        })),
        initial: user.id,
        disabled: user.office.tipCargo === 'OPERAÇÃO',
      },
      {
        type: 'select',
        name: 'periodId',
        label: 'Período',
        options: listPeriods.map(({ id, desPeriodo }) => ({
          label: desPeriodo,
          value: id,
        })),
      },
    ],
  }

  const convertRecord = record => {
    return {
      ...record,
      qtdSaldoIni: record.qtdSaldoIni,
      qtdHorasPositivas: record.qtdHorasPositivas,
      qtdHorasNegativas: record.qtdHorasNegativas,
      periodId: record.periodId,
      userId: record.userId,
      qtdAjuste: record.qtdAjuste,
      qtdSaldoFinal: record.qtdSaldoFinal,
      desJustificaAjuste: record.desJustificaAjuste,
      desObs: record.desObs,
      qtdHorasPrevista: record.qtdHorasPrevista,
      qtdAdicional: record.qtdAdicional,
    }
  }

  const changePage = page => {
    const { userId, periodId } = valuesFilter

    setPage(page)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idUsuario &&
        user.office.tipCargo === 'OPERAÇÃO' && { userId: idUsuario }),
      ...(userId && { userId }),
      ...(periodId && { periodId }),
    })
  }

  const changeSize = size => {
    const { userId, periodId } = valuesFilter

    setSize(size)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idUsuario &&
        user.office.tipCargo === 'OPERAÇÃO' && { userId: idUsuario }),
      ...(userId && { userId }),
      ...(periodId && { periodId }),
    })
  }

  const query = () => {
    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      sort: SORT,
      ...(idUsuario && { userId: idUsuario }),
    })
    get()
    getPeriods()
    getUsers()
  }

  const handleSubmit = async values => {
    await onSubmit(values)

    setShowToast(true)

    setShowDialog(false)

    handleFilter(valuesFilter)

    query()
  }

  const startSave = () => {
    setParam(convertRecord(DEFAULT_PARAM))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo registro Banco de Horas')

    setShowDialog(true)
  }

  const startDelete = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(true)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)
    setTitle(
      `Você deseja deletar o banco de horas do ${record.desPeriodo} do usuário ${record.desUsuario}?`
    )

    setShowDialog(true)
  }

  const startEdit = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)
    setTitle('Alterar registro')

    setShowDialog(true)
  }

  useEffect(() => {
    query()
  }, [])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  return (
    <Main title="Cadastro Banco de Horas">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={startSave} color="primary">
            <AddIcon />
          </Fab>
        </Box>

        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            count={totalElementsHourBank}
            doNotShow={DO_NOT_SHOW_TABLE}
            headers={TABLE_HEADER}
            onChangePage={(_, page) => changePage(page)}
            onChangeSize={event => changeSize(event.target.value)}
            page={page}
            rows={listHourBankFilter}
            size={size}
            startDelete={startDelete}
            startEdit={startEdit}
            order={ORDER_TABLE}
            alignTitle={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
            alignItens={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
          />
        </TableContainer>
      </Grid>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
      >
        <Form
          isDeleteAction={isDeleteAction}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          primaryButtonColor={primaryButtonColor}
          primaryButtonLabel={primaryButtonLabel}
          users={listUsers}
          periods={listPeriods}
        />
      </Dialog>

      <Toast
        error={errorHourBank}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successHourBank}
      />
    </Main>
  )
}

const mapStateToProps = ({ hourBankReducer, periodReducer, userReducer }) => ({
  ...hourBankReducer,
  ...periodReducer,
  ...userReducer,
  listUsers: userReducer.listUsers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionHourBank,
      getPeriods: actionPeriod.get,
      getUsers,
    },
    dispatch
  )

HourBank.propTypes = {
  /** Actions */
  getPeriods: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  /** Reducer props */
  listPeriods: PropTypes.array.isRequired,
  listHourBankFilter: PropTypes.array.isRequired,
  listHourBank: PropTypes.array.isRequired,
  errorHourBank: PropTypes.string,
  successHourBank: PropTypes.string,
  totalElementsHourBank: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(HourBank)
