import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as proposalUserActions from '../../../actions/proposalUser'

import TablePagination from '../../../utils/Table/TablePagination'

const TABLE_HEADER = ['Cliente', 'Proposta', 'Função', 'Senioridade', 'Usuário', '%Alocação']

const TABLE_VALUES_ORDER = [
  'desCliente',
  'desProposta',
  'desFuncao',
  'desSenioridade',
  'desUsuario',
  'perAlocacao',
]

const Table = props => {
  const {
    filterParams,
    changePage,
    changePageSize,
    totalElementsProposalUser = 0,
    onEdit,
    onDelete,
    proposalUsers,
  } = props

  const handleChangePage = async (_, newPage) => {
    await changePage(newPage)
  }

  const handleChangePageSize = async event => {
    const newPageSize = event.target.value
    await changePageSize(newPageSize)
  }

  return (
    <TablePagination
      titleTable="entry"
      count={totalElementsProposalUser}
      headers={TABLE_HEADER}
      headersIndexOrder={TABLE_VALUES_ORDER}
      onChangePage={handleChangePage}
      onChangeSize={handleChangePageSize}
      page={filterParams.pageFilter}
      rows={proposalUsers}
      size={filterParams.sizeFilter}
      startDelete={onDelete}
      startEdit={onEdit}
    />
  )
}

const mapStateToProps = state => {
  const { proposalUserReducer } = state
  return { ...proposalUserReducer }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...proposalUserActions }, dispatch)

Table.propTypes = {
  /** Actions */
  changePage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  /** Reducer props */
  filterParams: PropTypes.object.isRequired,
  totalElementsProposalUser: PropTypes.number.isRequired,
  /** Other props */
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
