import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as proposalFunctionActions from '../../../actions/proposalFunction'

import TablePagination from '../../../utils/Table/TablePagination'
import CustomActions from './CustomActions'

const TABLE_HEADER = [
  'Cliente',
  'Proposta',
  'Função',
  'Senioridade',
  'Cargo',
  '%Alocação',
]

const TABLE_VALUES_ORDER = [
  'desCliente',
  'desProposta',
  'desFuncao',
  'desSenioridade',
  'desCargo',
  'desAlocacao',
]

const doNotShowTable = ['id', 'seqCliente', 'seqProposta']

const Table = props => {
  const {
    filterParams,
    changePage,
    changePageSize,
    totalElementsProposalFunction = 0,
    onEdit,
    onDelete,
    proposalFunctions,
  } = props

  const handleChangePage = async (_, newPage) => {
    await changePage(newPage)
  }

  const handleChangePageSize = async event => {
    const newPageSize = event.target.value
    await changePageSize(newPageSize)
  }

  return (
    <TablePagination
      titleTable="entry"
      count={totalElementsProposalFunction}
      headers={TABLE_HEADER}
      headersIndexOrder={TABLE_VALUES_ORDER}
      doNotShow={doNotShowTable}
      onChangePage={handleChangePage}
      onChangeSize={handleChangePageSize}
      page={filterParams.pageFilter}
      rows={proposalFunctions}
      proposalFunction={true}
      size={filterParams.sizeFilter}
      startDelete={onDelete}
      startEdit={onEdit}
      renderCustomActions={(rowData) => <CustomActions rowData={rowData} />}
    />
  )
}

const mapStateToProps = state => {
  const { projectReducer, proposalReducer, proposalFunctionReducer } = state

  return {
    ...proposalReducer,
    ...projectReducer,
    ...proposalFunctionReducer,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...proposalFunctionActions }, dispatch)

Table.propTypes = {
  /** Actions */
  changePage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  /** Reducer props */
  filterParams: PropTypes.object.isRequired,
  totalElementsProposalFunction: PropTypes.number.isRequired,
  /** Other props */
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  proposalFunctions: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
