import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { get as fetchAllClients } from '../../actions/client'
import * as proposalActions from '../../actions/proposal'
import * as proposalFunctionActions from '../../actions/proposalFunction'
import { get as fetchAllEmployeePositions } from '../../actions/office'

import { globalStyles } from '../../styles/global'
import Main from '../Main'
import ButtonsCancelAndSubmit from '../../utils/ButtonsCancelAndSubmit'

import AddIcon from '@material-ui/icons/Add'
import { TableContainer, Paper, Box, Fab, Grid, Typography, Button as MatButton } from '@material-ui/core'
import Dialog from '../../utils/Dialog'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/Filter'

import { useStyles } from './useStyles'

import Form from './components/Form'
import Table from './components/Table'
import { Loader } from '../../utils/Loading'
import { types } from '../../../config/action_types'
import useFeedback from '../../utils/hooks/useFeedback'
import { getObjectFromParams } from '../../utils/Params'
import { truncateString } from '../../utils/String'
import { useHistory, useLocation } from 'react-router-dom'

const ProposalFunction = props => {
  const {
    fetchAllClients,
    fetchAllProposals,
    fetchProposalFunctions,
    removeProposalFunction,
    setProposalFunctionFilterParams,
    fetchAllEmployeePositions,
    emptyProposalFunctions,
    listClients = [],
    listProposal = [],
    listProposalFunction = [],
    isLoad,
    filterParams: filterParamsFromProposalFunctionReducer,
  } = props
  const styles = globalStyles()
  const {
    feedback,
    hasFeedback,
    setError,
    setSuccess,
    clearFeedback,
  } = useFeedback()
  const location = useLocation()
  const history = useHistory()
  const className = useStyles()

  const [showDialog, setShowDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [proposalFunctionToDelete, setProposalFunctionToDelete] = useState(null)
  const [proposalFunctionToEdit, setProposalFunctionToEdit] = useState(null)
  const [selectedClientId, setSelectedClientId] = useState(null)

  const filterInitialValues = useMemo(() => {
    if (location.search) return getObjectFromParams(location.search)
    return filterParamsFromProposalFunctionReducer
  }, [location.search])

  const proposalFunctions = useMemo(() => {
    return listProposalFunction.map(proposalFunction => {
      const {
        id,
        proposta,
        desCliente,
        desFuncao,
        desSenioridade,
        desCargo,
        alocacao,
        seqCliente,
      } = proposalFunction

      const { codProposta, desProposta } =
        listProposal.find(p => p.id === proposta) || {}

      return {
        id,
        desCliente,
        desProposta: `${codProposta || ''} - ${desProposta}`,
        desFuncao,
        desSenioridade,
        desCargo,
        alocacao: `${alocacao}%`,
        seqCliente,
        seqProposta: proposta,
      }
    })
  }, [listProposalFunction, listProposal])

  const handleFilterSubmit = async filterParams => {
    // quando é dado clear no filtro de cliente também é dado clear no fitro de proposta
    // porém o valor da proposta no componente do Filter continua existindo
    const isProposalFromRightClient = listProposal
      .filter(p => p.seqCliente === filterParams.cliente)
      .some(p => p.id === filterParams.proposta) || !listProposal.length

    const resolvedParams = {
      ...filterParams,
      proposta: isProposalFromRightClient ? filterParams.proposta : null,
    }
    await setProposalFunctionFilterParams(resolvedParams)
  }

  const resolveProposalOptions = () => {
    if (!selectedClientId) return []

    return listProposal.filter(p => p.seqCliente === selectedClientId)
  }

  const resolveProposalNoOptionsText = () => {
    const clientHasProposal = !!resolveProposalOptions().length
    if (selectedClientId && !clientHasProposal) {
      return `O cliente não possui propostas`
    }

    if (!selectedClientId) return 'Informe um cliente'

    return 'Sem opções'
  }

  const filterProps = useMemo(
    () => ({
      onSubmit: handleFilterSubmit,
      monitoreState: ({ fields }) => {
        setSelectedClientId(fields.cliente)
      },
      fields: [
        {
          type: 'select',
          name: 'cliente',
          label: 'Cliente',
          initial: filterInitialValues.cliente,
          options: listClients
          .filter((item) => item.descCliente !== null)
          .map(({ id: value, descCliente: label }) => ({
            label: truncateString({ value: label }),
            value,
          })),
        },
        {
          type: 'select',
          name: 'proposta',
          label: 'Proposta',
          initial: filterInitialValues.proposta,
          options: resolveProposalOptions()
          .filter((item) => item.codProposta !== null)
          .map(
            ({ id: value, codProposta: label }) => ({
              label: truncateString({ value: label }),
              value,
            })
          ),
          noOptionsText: resolveProposalNoOptionsText(),
        },
      ],
    }),
    [listClients, listProposal, selectedClientId]
  )

  const handleCloseForm = successMessage => {
    setShowDialog(false)
    setProposalFunctionToEdit(null)

    if (successMessage) {
      clearFeedback()
      setSuccess(successMessage)
    }
  }

  const resolveFormTitle = () => {
    return proposalFunctionToEdit
      ? 'Alteração função da proposta'
      : 'Nova função da proposta'
  }

  const renderForm = () => {
    return (
      <Dialog
        onClose={() => handleCloseForm()}
        open={showDialog || !!proposalFunctionToEdit}
        title={resolveFormTitle()}
      >
        <Form
          proposalFunctionToEdit={proposalFunctionToEdit}
          handleCloseForm={handleCloseForm}
        />
      </Dialog>
    )
  }

  const handleDeleteProposalFunction = async () => {
    setProposalFunctionToDelete(null)
    setIsLoading(true)
    const response = await removeProposalFunction(proposalFunctionToDelete)
    const { type, payload } = response
    const hasError = type === types.proposal.delete.error

    if (hasError) {
      setError(payload)
      setIsLoading(false)
      return
    }

    await fetchProposalFunctions()
    setIsLoading(false)
    setSuccess(payload)
  }

  const renderDeleteDialog = () => {
    if (!proposalFunctionToDelete) return null

    const { desProposta } = proposalFunctionToDelete

    return (
      <Dialog
        title={`Você deseja deletar a função da proposta ${desProposta}?`}
        open
        onClose={() => setProposalFunctionToDelete(null)}
        maxWidth="md"
      >
      <Typography
        variant='subtitle1'
        className={className.deleteWarning}
      >
        *Caso existam, os usuários de proposta vinculados a esse registro também serão excluidos.
      </Typography>
        <ButtonsCancelAndSubmit
          submitLabel="Deletar"
          submitColor="secondary"
          onCancel={() => setProposalFunctionToDelete(null)}
          onSubmit={handleDeleteProposalFunction}
        />
      </Dialog>
    )
  }

  const renderTable = () => {
    return (
      <>
      {isLoading || isLoad ?(<Loader isLoading={isLoading || isLoad} />):
        <Table
          onEdit={p => setProposalFunctionToEdit(p.id)}
          onDelete={setProposalFunctionToDelete}
          proposalFunctions={proposalFunctions}
        />
      }
      </>
    )
  }

  const renderFeedback = () => {
    if (!hasFeedback) return null

    return (
      <Toast
        error={feedback.error}
        success={feedback.success}
        open
        onClose={clearFeedback}
      />
    )
  }

  useEffect(() => {
    setIsLoading(true)
    emptyProposalFunctions();
    async function initialFetch() {
      await Promise.all([
        fetchAllClients(),
        fetchAllProposals(),
        fetchAllEmployeePositions(),
        handleFilterSubmit(filterInitialValues)
      ])
      .then(() => setIsLoading(false))
    }
    initialFetch()
  }, [fetchAllClients, fetchAllProposals, filterInitialValues])

  return (
    <Main title="Função da proposta">
      <Grid item xs={12}>
        <Box
          component="div"
          className={styles.box}
          xs={12}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <MatButton
            variant="outlined"
            onClick={() => history.push('/proposal')}
          >
            Voltar para Propostas
          </MatButton>
          <Fab onClick={() => setShowDialog(true)} color="primary">
            <AddIcon />
          </Fab>
        </Box>
        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>
        <TableContainer component={Paper}>{renderTable()}</TableContainer>
      </Grid>
      {renderForm()}
      {renderDeleteDialog()}
      {renderFeedback()}
    </Main>
  )
}

const mapStateToProps = state => {
  const {
    clientReducer,
    projectReducer,
    proposalReducer,
    proposalFunctionReducer,
    loadReducer,
  } = state

  return {
    ...clientReducer,
    ...proposalReducer,
    ...projectReducer,
    ...proposalFunctionReducer,
    isLoad: loadReducer.isLoad
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAllClients,
      fetchAllEmployeePositions,
      ...proposalActions,
      ...proposalFunctionActions,
    },
    dispatch
  )

ProposalFunction.propTypes = {
  /** Actions */
  fetchAllProposals: PropTypes.func.isRequired,
  fetchProposalFunctions: PropTypes.func.isRequired,
  removeProposalFunction: PropTypes.func.isRequired,
  setProposalFunctionFilterParams: PropTypes.func.isRequired,
  fetchAllEmployeePositions: PropTypes.func.isRequired,
  fetchAllClients: PropTypes.func.isRequired,
  /** Reducer props */
  listClients: PropTypes.array.isRequired,
  listProposal: PropTypes.array.isRequired,
  listProposalFunction: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalFunction)
