/**
 * Default date format to be displayed
 */
export const DD_MM_YYYY_DISPLAY = 'dd/MM/yyyy'

/**
 * Default date format to be parsed
 */
export const DD_MM_YYYY_PARSE = 'DD/MM/YYYY'

/**
 * Date format to be parsed
 */
export const YYYY_MM_DD_PARSE = 'yyyy-MM-dd'

/**
 * Default date format to be parsed
 */
export const HH_MM_SS_PARSE = 'HH:mm:ss'
