import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  confirmDelete: {
    textAlign: 'center',
    fontSize: '16px',
    marginBottom: '30px',
  },
  link: {
    color: 'black',
  },
  deleteWarning: {
    marginBottom: '10px',
    marginLeft: '10px'
  }
})

export { useStyles }
