import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { get as getClients } from '../../actions/client'
import { get as getProjects } from '../../actions/project'
import * as proposalActions from '../../actions/proposal'

import { globalStyles } from '../../styles/global'
import Main from '../Main'
import ButtonsCancelAndSubmit from '../../utils/ButtonsCancelAndSubmit'

import AddIcon from '@material-ui/icons/Add'
import { TableContainer, Paper, Box, Fab, Grid, Typography } from '@material-ui/core'
import Dialog from '../../utils/Dialog'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/Filter'

import { useStyles } from './useStyles'

import Form from './components/Form'
import Table from './components/Table'
import { MODELOS_PROPOSTA } from '../../../constants'
import { STATUS_PROPOSTA } from '../../../constants'
import { Loader } from '../../utils/Loading'
import { types } from '../../../config/action_types'
import useFeedback from '../../utils/hooks/useFeedback'

const formatDateFromReducer = (date, defaultValue = null) => date ? 
  moment(date, 'DD-MM-YYYY').toDate() : 
  defaultValue

const Proposal = props => {
  const {
    getClients,
    getProjects,
    fetchProposals,
    fetchProposalOptions,
    allProposals,
    listProposal,
    listProjects = [],
    removeProposal,
    setProposalFilterParams,
    listClients = [],
    duplicateProposal,
    isLoad,
    filterParams: filterParamsFromProposalReducer,
  } = props
  const styles = globalStyles()
  const {
    feedback,
    hasFeedback,
    setError,
    setSuccess,
    clearFeedback,
  } = useFeedback()

  const className = useStyles();

  const [showDialog, setShowDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [proposalToDelete, setProposalToDelete] = useState(null)
  const [proposalToDuplicate, setProposalToDuplicate] = useState(null)
  const [proposalToEdit, setProposalToEdit] = useState(null)
  const [valuesFilter, setValuesFilter] = useState({
    ...filterParamsFromProposalReducer,
    dtaInicio: formatDateFromReducer(filterParamsFromProposalReducer.dtaInicio),
    dtaFim: formatDateFromReducer(filterParamsFromProposalReducer.dtaFim),
  })

  const [dragModalDisabled, setDragModalDisabled] = useState(false)

  const olderDate = listProposal.reduce((acc, proposal) => {
    const { dtaInicio } = proposal
    const beginDate = dtaInicio.split('/')
    .reverse()
      .join('-')
    const date = moment(beginDate, 'YYYY-MM-DD').toDate()

    if (!acc) return date

    return date < acc ? date : acc
  }, null)

  async function initialFetch() {
    setIsLoading(true)
    await Promise.all([
      getProjects(),
      fetchProposalOptions(),
      fetchProposals(),
      getClients()
    ]).then(()=>setIsLoading(false))
  }

  useEffect(() => {
    initialFetch()
  },[])

  const handleFilterSubmit = async filterParams => {
    const { dtaFim, dtaInicio } = filterParams
    const isInvalidDates =
      dtaFim && dtaInicio && dtaFim.getTime() < dtaInicio.getTime()

    if (isInvalidDates) return

    setIsLoading(true)
    await setProposalFilterParams(filterParams)
    setIsLoading(false)
  }

  const generalFilter =  allProposals
  .filter(proposal => valuesFilter.seqCliente ? proposal.seqCliente === valuesFilter.seqCliente : true)
  .filter(proposal => valuesFilter.tipModelo ? proposal.tipModelo === valuesFilter.tipModelo : true)
  .filter(proposal => valuesFilter.tipStatus ? proposal.tipStatus === valuesFilter.tipStatus : true)
  .filter(proposal => valuesFilter.proposalCode ? proposal.codProposta === valuesFilter.proposalCode : true) 
  .filter(proposal => valuesFilter.projectId ? proposal.seqProjeto === valuesFilter.projectId : true)

  const filterProps = useMemo(
    () => ({
      onSubmit: handleFilterSubmit,
      monitoreState: (state) => {
        setValuesFilter(state.fields)
      },
      fields: [
        {
          type: 'select',
          name: 'seqCliente',
          label: 'Cliente',
          initial: valuesFilter.seqCliente,
          options: listClients
          .filter(client => generalFilter.map( item => item.seqCliente).includes(client.id))
          .map(({ id: value, descCliente: label }) => ({
            label,
            value,
          })),
        },
        {
          type: 'select',
          name: 'tipModelo',
          label: 'Modelo',
          initial: valuesFilter.tipModelo,
          options: MODELOS_PROPOSTA
          .filter(model => generalFilter.map( item => item.tipModelo).includes(model.id))
          .map(({ id: value, description: label }) => ({
              label,
              value,
            })
          ),
        },
        {
          type: 'select',
          name: 'tipStatus',
          label: 'Status',
          initial: valuesFilter.tipStatus,
          options: STATUS_PROPOSTA
          .filter(status => generalFilter.map( item => item.tipStatus).includes(status.id))
          .map(({ id: value, description: label }) => ({
            label,
            value,
          })),
        },
        {
          type: 'select',
          name: 'proposalCode',
          label: 'Proposta',
          initial: valuesFilter.proposalCode,
          options: generalFilter
          .sort((a, b) => a.codProposta.localeCompare(b.codProposta))
            .map(({ codProposta: value, codProposta: label }) => ({
            label,
            value,
          })),
        },
        {
          type: 'select',
          name: 'projectId',
          label: 'Projeto',
          initial: valuesFilter.projectId,
          options: listProjects
            .filter(project => generalFilter.map( item => item.seqProjeto).includes(project.id))
            .map(({ id, desProjeto }) => ({
            label: desProjeto,
            value: id,
          })),
        },
        {
          type: 'date',
          name: 'dtaInicio',
          label: 'Data inicial',
          initial: valuesFilter.dtaInicio,
          maxDate: valuesFilter.dtaFim || new Date(),
          minDate: olderDate,
        },
        {
          type: 'date',
          name: 'dtaFim',
          label: 'Data final',
          initial: valuesFilter.dtaFim,
          minDate: valuesFilter.dtaInicio,
          maxDate: new Date(),
        },
      ],
    }),
    [listClients, valuesFilter, listProjects, listProposal, allProposals]
  )

  const handleCloseForm = successMessage => {
    setShowDialog(false)
    setProposalToEdit(null)

    if (successMessage) {
      clearFeedback()
      setSuccess(successMessage)
      initialFetch()
    }
  }

  const resolveFormTitle = () => {
    return proposalToEdit ? 'Editar Proposta' : 'Nova Proposta'
  }

  const renderForm = () => {
    return (
      <Dialog
        onClose={() => handleCloseForm()}
        open={showDialog || !!proposalToEdit}
        title={resolveFormTitle()}
        isDragDisabled={dragModalDisabled}
      >
        <Form
          proposalToEdit={proposalToEdit}
          handleCloseForm={handleCloseForm}
          onOpenDateModal={() => setDragModalDisabled(true)}
          onCloseDateModal={() => setDragModalDisabled(false)}
        />
      </Dialog>
    )
  }

  const handleDeleteProposal = async () => {
    setProposalToDelete(null)
    setIsLoading(true)
    const response = await removeProposal(proposalToDelete)
    const { type, payload } = response
    const hasError = type === types.proposal.delete.error

    if (hasError) {
      setError(payload)
      setIsLoading(false)
      return
    }
    if(generalFilter.length === 1) { clearAllFields(valuesFilter) };
    await fetchProposals()
    await fetchProposalOptions()
    setIsLoading(false)
    setSuccess(payload)
  }

  const handleDuplicateProposal = async (data) => {
    setProposalToDuplicate(null)
    setIsLoading(true)
    const {type, payload} = await duplicateProposal(data)
    const hasError = type === types.proposal.save.error

    if (hasError) {
      setError(payload)
      setIsLoading(false)
      return
    }
    await fetchProposals()
    await fetchProposalOptions()
    setIsLoading(false)
    setSuccess(payload)
    
  }
  
  function clearAllFields(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = null;
      }
    }
  }

  const renderDeleteDialog = () => {
    if (!proposalToDelete) return null

    const { desProposta } = proposalToDelete

    return (
        <Dialog
          title={`Você deseja excluir a proposta ${desProposta}?`}
          open
          onClose={() => setProposalToDelete(null)}
          maxWidth="md"
        >
          <Typography
            variant='subtitle1'
            className={className.deleteWarning}
          >
            *Caso existam, as funções e usuários de proposta vinculados a esse registro também serão excluídos.
          </Typography>
          <ButtonsCancelAndSubmit
            submitLabel="Deletar"
            submitColor="secondary"
            onCancel={() => setProposalToDelete(null)}
            onSubmit={handleDeleteProposal} />
        </Dialog>
    )
  }

  const renderDuplicateDialog = () => {
    if (!proposalToDuplicate) return null
    
    const { desProposta } = proposalToDuplicate

    return (
        <Dialog
          title={`Você deseja duplicar a proposta ${desProposta}?`}
          open
          onClose={() => setProposalToDuplicate(null)}
          maxWidth="md"
        >
          <ButtonsCancelAndSubmit
            submitLabel="Duplicar"
            submitColor="secondary"
            onCancel={() => setProposalToDuplicate(null)}
            onSubmit={()=> handleDuplicateProposal(proposalToDuplicate)}  
             />
        </Dialog>
    )
  }

  const findProposalModel = modelId => {
    if (!modelId) return ''

    const model = MODELOS_PROPOSTA.find(item => item.id === modelId)

    return model ? model.description : ''
  }

  const findProposalStatus = statusId => {
    if (!statusId) return ''

    const status = STATUS_PROPOSTA.find(item => item.id === statusId)

    return status ? status.description : ''
  }

  const findProposalProject = projectId => {
    if (!projectId) return ''

    const project = listProjects.find(item => item.id === projectId)

    return project ? project.desProjeto : ''
  }

  const proposalToTable = useMemo(() => {
    return listProposal.map(proposal => {
      const {
        id,
        desCliente,
        codProposta,
        desProjetoSAP,
        desProposta,
        dtaFim,
        dtaInicio,
        tipModelo,
        tipStatus,
        seqProjeto,
        seqCliente,
      } = proposal

      return {
        id,
        desCliente,
        codProposta,
        desProposta,
        tipModelo: findProposalModel(tipModelo),
        tipStatus: findProposalStatus(tipStatus),
        dtaInicio: moment(dtaInicio).format('DD/MM/YYYY'),
        dtaFim: moment(dtaFim).format('DD/MM/YYYY'),
        desProjeto: findProposalProject(seqProjeto),
        desProjetoSAP,
        seqCliente,
      }
    })
  }, [listProposal, listProjects])

  const renderTable = () => {
    return (
      <>{isLoading || isLoad ?(<Loader isLoading={isLoading || isLoad} />):        
        <Table
          onEdit={p => setProposalToEdit(p.id)}
          onDelete={setProposalToDelete}
          onDuplicate={setProposalToDuplicate}
          proposalToTable={proposalToTable}
        />
      }
      </>
    )
  }

  const renderFeedback = () => {
    if (!hasFeedback) return null

    return (
      <Toast
        error={feedback.error}
        success={feedback.success}
        open
        onClose={clearFeedback}
      />
    )
  }

  return (
    <Main title="Propostas">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={() => setShowDialog(true)} color="primary">
            <AddIcon />
          </Fab>
        </Box>
        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>
        <TableContainer component={Paper}>{renderTable()}</TableContainer>
      </Grid>
      {renderForm()}
      {renderDeleteDialog()}
      {renderDuplicateDialog()}
      {renderFeedback()}
    </Main>
  )
}

const mapStateToProps = state => {
  const { clientReducer, projectReducer, proposalReducer, loadReducer } = state

  return {
    ...clientReducer,
    ...proposalReducer,
    ...projectReducer,
    listProjects: projectReducer.listProjects,
    listProposal: proposalReducer.listProposal,
    isLoad: loadReducer.isLoad
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getClients,
      getProjects,
      ...proposalActions,
    },
    dispatch
  )

Proposal.propTypes = {
  /** Actions */
  fetchProposalOptions: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  getPeriods: PropTypes.func.isRequired,
  getProposal: PropTypes.func.isRequired,
  /** Reducer props */
}

export default connect(mapStateToProps, mapDispatchToProps)(Proposal)
