import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import TablePagination from '../../../utils/Table/TablePaginationEntryA'

const TABLE_HEADER = [
  'Usuário',
  'Projeto',
  'Atividade',
]

const Table = props => {
  const {
    list,
  } = props
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const List = useMemo(() => {
    return list
      .map(item => {
        const {
          nomeUsuario,
          siglaProjeto,
          desProjeto,
          sigAtividade,
          desAtividade
        } = item
        return {
          nomeUsuario,
          desProjeto: `${siglaProjeto} - ${desProjeto}`,
          desAtividade: `${sigAtividade} - ${desAtividade}`
        }
      })
      .sort((a, b) => {
        const alphaComparison = a.nomeUsuario.localeCompare(b.nomeUsuario, undefined, { numeric: true });
        return alphaComparison !== 0 ? alphaComparison : (parseInt(a.nomeUsuario) - parseInt(b.nomeUsuario));
      });

  }, [list])

  return (
    <TablePagination
      titleTable="entryAutomatic"
      count={list.length}
      headers={TABLE_HEADER}
      onPageChange={handleChangePage}
      onChangeSize={handleChangeRowsPerPage}
      page={page}
      rows={List}
      size={rowsPerPage}
      hasAction={false}
    />
  )
}

const mapStateToProps = state => {
  const { entryAutomaticReducer } = state

  return {
    ...entryAutomaticReducer,
    list: entryAutomaticReducer.list,
  }
}

export default connect(mapStateToProps)(Table)
