import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import Select from '../../../utils/Form/Select'

import useForm from '../../../utils/Form'

/**
 * Period form
 */
const Form = ({
  periods = [],
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  selectNoOptionsText,
  values = {},
  /** Functions */
  beforeSubmit = () => {},
  setter = () => {},
}) => {
  return (
    <form onSubmit={beforeSubmit}>
      <FlexWrapper>
        <FlexItemWrapper>
          <Select
            variant="outlined"
            labelContent="Período"
            noOptionsText={selectNoOptionsText}
            labelId="period-select-label"
            label="Selecione o período para gerar o fechamento"
            onChange={setter('idPeriod')}
            options={periods.map(period => ({
              key: period.id,
              value: period.desPeriodo,
            }))}
            selectId="period-select"
            value={values.idPeriod}
          />
        </FlexItemWrapper>
      </FlexWrapper>

      <FlexWrapper>
        <FlexItemWrapper>
          <Button
            color={primaryButtonColor}
            disabled={!values.idPeriod}
            label={primaryButtonLabel}
            type="submit"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  periods: PropTypes.array,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  selectNoOptionsText: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
}

export default useForm(Form)
