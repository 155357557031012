import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    rowAction: {
      cursor: 'pointer',
      width: 'fit-content',
      '&:hover div': {
        background: theme.palette.grey['300'],
      },
    },
    rowDetail: {
      cursor: 'pointer',
      width: 'fit-content',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      gap: 8,
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey['300']}`,
      transition: '0.1s',
    },
    totalTime: {
      textAlign: 'center',
      position: 'relative',

      '& span': {
        position: 'absolute',
        left: '-15px',
      },

      '@media screen and (max-width: 1000px)': {
        '& span': {
          left: '-100%',
        },
      },
    },
  }
})

export default useStyles
