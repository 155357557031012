import Http from '../../config/Http'
import URL from '../../config/URL'
import { trigger, types } from '../../config/action_types'
import { DEFAULT_PAGINATION_PAGE } from '../../constants/pagination'

export const fetchProposalFunctions = () => async (dispatch, getState) => {
  const {
    proposalFunctionReducer: { filterParams },
  } = getState()
  try {
    const response = await new Http(dispatch).get(
      URL.proposalFunction + '/filter',
      {
        ...filterParams,
        pageFilter: filterParams.pageFilter + 1,
        sort: 'id desc',
      }
    )
    return dispatch(trigger(types.proposalFunction.getFilter.success, response))
  } catch (error) {
    return dispatch(trigger(types.proposalFunction.getFilter.error, error))
  }
}

export const fetchAllProposalFunctions = () => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.proposalFunction, {
      sort: 'id desc',
    })

    return dispatch(trigger(types.proposalFunction.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.proposalFunction.get.error))
  }
}

export const emptyProposalFunctions = () => async dispatch => {
  dispatch(trigger(types.proposalFunction.emptyList, null))
}

export const setProposalFunctionFilterParams = params => async dispatch => {
  const { cliente, proposta } = params
  dispatch(
    trigger(types.proposalFunction.updateFilter, {
      ...(!!cliente && { cliente }),
      ...(!!proposta && { proposta }),
      pageFilter: DEFAULT_PAGINATION_PAGE,
    })
  )
  dispatch(fetchProposalFunctions())
}

export const changePage = newPage => async (dispatch, getState) => {
  const { proposalFunctionReducer } = getState()
  dispatch(
    trigger(types.proposalFunction.updateFilter, {
      ...proposalFunctionReducer.filterParams,
      pageFilter: newPage,
    })
  )
  dispatch(fetchProposalFunctions())
}

export const changePageSize = newPageSize => async (dispatch, getState) => {
  const { proposalFunctionReducer } = getState()
  dispatch(
    trigger(types.proposalFunction.updateFilter, {
      ...proposalFunctionReducer.filterParams,
      sizeFilter: newPageSize,
      pageFilter: DEFAULT_PAGINATION_PAGE,
    })
  )
  dispatch(fetchProposalFunctions())
}

export const saveProposalFunction = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.proposalFunction, param)

    return dispatch(
      trigger(
        types.proposalFunction.save.success,
        'Função da proposta salva com sucesso'
      )
    )
  } catch {
    return dispatch(
      trigger(
        types.proposalFunction.save.error,
        'Erro ao salvar função da proposta'
      )
    )
  }
}

export const removeProposalFunction = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.proposalFunction, param)

    return dispatch(
      trigger(
        types.proposalFunction.delete.success,
        'Função da proposta excluída com sucesso'
      )
    )
  } catch (error) {
    return dispatch(
      trigger(
        types.proposalFunction.delete.error,
        'Erro ao excluir função da proposta'
      )
    )
  }
}

export const updateProposalFunction = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.proposalFunction, param)

    return dispatch(
      trigger(
        types.proposalFunction.update.success,
        'Função da proposta atualizada com sucesso'
      )
    )
  } catch (error) {
    return dispatch(
      trigger(
        types.proposalFunction.update.error,
        'Erro ao atualizar função da proposta'
      )
    )
  }
}
