import { combineReducers } from 'redux'
import activityReducer from './activity'
import clientReducer from './client'
import password from './password'
import clientPeriodReducer from './clientPeriod'
import entryReducer from './entry'
import hourBankReducer from './hourBank'
import loadReducer from './load'
import loginReducer from './login'
import officeReducer from './office'
import periodReducer from './period'
import pointReducer from './point'
import projectReducer from './project'
import toasterReducer from './toaster'
import userReducer from './user'
import devReducer from './user'
import statusHistoryReducer from './statusHistory'
import userPeriodReducer from './userPeriod'
import monthlyCloseReducer from './monthlyClose'
import employeeReducer from './employee'
import proposalReducer from './proposal'
import proposalFunctionReducer from './proposalFunction'
import proposalUserReducer from './proposalUser'
import entryReportReducer from './entryReport'
import executionReducer from './execution'
import entryAutomaticReducer from './entryAutomatic'
import closureClientsReducer from './closureClients'

const reducers = {
  activityReducer,
  clientReducer,
  clientPeriodReducer,
  entryReducer,
  hourBankReducer,
  loadReducer,
  loginReducer,
  officeReducer,
  periodReducer,
  pointReducer,
  projectReducer,
  toasterReducer,
  userReducer,
  devReducer,
  statusHistoryReducer,
  userPeriodReducer,
  monthlyCloseReducer,
  employeeReducer,
  password,
  proposalReducer,
  proposalFunctionReducer,
  proposalUserReducer,
  entryReportReducer,
  executionReducer,
  entryAutomaticReducer,
  closureClientsReducer,
}

export default combineReducers(reducers)
