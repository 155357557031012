import React from 'react';
import useStyles from '../../useStyles'


const CustomWarningIcon = () => {

  const styles= useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="30"
      height="30"
      className={styles.yellowIcon}
    >
      {/* Triangle */}
      <path
        fill="yellow"
        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      />
      {/* Exclamation mark */}
      <path
        fill="black"
        d="M11 16h2v2h-2zm0-8h2v6h-2z"
      />
    </svg>
  );
};

export default CustomWarningIcon;
