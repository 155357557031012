import { isNumber } from '../../../utils/Number'

export const validateValues = values => {
  const { funcao, user, perAlocacao } = values
  let errors = {}

  if (!funcao) {
    errors = { ...errors, erroFuncao: 'Informe a função' }
  }

  if (!user) {
    errors = { ...errors, erroUsuario: 'Informe o usuário' }
  }

  if (!isNumber(perAlocacao)) {
    errors = { ...errors, erroPerAlocacao: 'Informe o valor da alocação' }
  }

  if (perAlocacao < 1) {
    errors = { ...errors, erroPerAlocacao: 'O percentual não deve ser menor do que 1%' }
  }


  if (perAlocacao > 100) {
    errors = { ...errors, erroPerAlocacao: 'O percentual não deve ser maior do que 100%' }
  }
  return errors
}
