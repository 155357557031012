import 'date-fns'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers'

const Hourpicker = ({
  disabled,
  id,
  label,
  onChange,
  value,
  helperText,
  error,
  invalidHourMessage = 'Horário inválido',
  autoOk,
  onOpen = () => {},
  onClose = () => {},
}) => {
  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <Grid fullWidth>
        <KeyboardTimePicker
          disabled={disabled}
          fullWidth
          id={id}
          label={label}
          autoOk={autoOk}
          ampm={false}
          invalidDateMessage={invalidHourMessage}
          value={value}
          onChange={onChange}
          error={error}
          helperText={helperText}
          format="HH:mm"
          InputLabelProps={{
            shrink: true,
          }}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default Hourpicker
