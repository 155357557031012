const types = {
  login: {
    success: 'LOGIN_SUCCESS',
    error: 'LOGIN_ERROR',
    dismissError: 'DISMISS_ERROR',
    resetUserPassword: 'RESET_USER_PASSWORD',
    cancelResetUserPassword: 'CANCEL_RESET_USER_PASSWORD',
    periodicPasswordChangePerformed: 'PERIODIC_PASSWORD_CHANGE_PERFORMED',
    hiddenChangePassword: 'HIDDEN_CHANGE_PASSWORD',
  },
  load: {
    start: 'START_LOAD',
    stop: 'STOP_LOAD',
  },
  menu: {
    open: 'MENU_OPEN',
    close: 'MENU_CLOSE',
  },
  password: {
    success: 'CHANGE_PASSWORD_SUCCESS',
    error: 'CHANGE_PASSWORD_ERROR',
    mandatoryFieldsNotCompletedError: 'MANDATORY_FIELDS_NOT_COMPLETED_ERROR',
  },
  client: {
    getFilter: {
      success: 'CLIENT_GET_FILTER_SUCCESS',
      error: 'CLIENT_GET_FILTER_ERROR',
    },
    get: {
      success: 'CLIENT_GET_SUCCESS',
      error: 'CLIENT_GET_ERROR',
    },
    save: {
      success: 'CLIENT_SAVE_SUCESS',
      error: 'CLIENT_SAVE_ERROR',
    },
    delete: {
      success: 'CLIENT_DELETE_SUCESS',
      error: 'CLIENT_DELETE_ERROR',
    },
    update: {
      success: 'CLIENT_UPDATE_SUCESS',
      error: 'CLIENT_UPDATE_ERROR',
    },
  },
  param: { error: 'PARAM_ERROR' },
  office: {
    getFilter: {
      success: 'PROJECT_GET_FILTER_SUCCESS',
      error: 'PROJECT_GET_FILTER_ERROR',
    },
    delete: { success: 'OFFICE_DELETE_SUCCESS', error: 'OFFICE_DELETE_ERROR' },
    get: { success: 'OFFICE_GET_SUCCESS', error: 'OFFICE_GET_ERROR' },
    save: { success: 'OFFICE_SAVE_SUCCESS', error: 'OFFICE_SAVE_ERROR' },
    update: { success: 'OFFICE_UPDATE_SUCCESS', error: 'OFFICE_UPDATE_ERROR' },
  },
  user: {
    getFilter: {
      success: 'USER_GET_FILTER_SUCCESS',
      error: 'USER_GET_FILTER_ERROR',
    },
    save: { success: 'USER_SAVE_SUCCESS', error: 'USER_SAVE_ERROR' },
    get: { success: 'USER_GET_SUCCESS', error: 'USER_GET_ERROR' },
    delete: { success: 'USER_DELETE_SUCCESS', error: 'USER_DELETE_ERROR' },
    update: { success: 'USER_UPDATE_SUCCESS', error: 'USER_UPDATE_ERROR' },
    select: 'USER_SELECTED',
  },
  project: {
    getFilter: {
      success: 'PROJECT_GET_FILTER_SUCCESS',
      error: 'PROJECT_GET_FILTER_ERROR',
      request: 'PROJECT_GET_FILTER_REQUEST',
    },
    get: {
      success: 'PROJECT_GET_SUCCESS',
      error: 'PROJECT_GET_ERROR',
    },
    delete: {
      success: 'PROJECT_DELETE_SUCCESS',
      error: 'PROJECT_DELETE_ERROR',
    },
    save: {
      success: 'PROJECT_SAVE_SUCCESS',
      error: 'PROJECT_SAVE_ERROR',
    },
    update: {
      success: 'PROJECT_UPDATE_SUCCESS',
      error: 'PROJECT_UPDATE_ERROR',
    },
  },
  toaster: {
    error: 'TOASTER_ERROR',
    success: 'TOASTER_SUCCESS',
    info: 'TOASTER_INFO',
    clear: 'TOASTER_CLEAR',
  },
  statusHistory: {
    get: {
      success: 'STATUS_GET_SUCCESS',
      error: 'STATUS_GET_ERROR',
    },
    getFilter: {
      success: 'STATUS_GET_FILTER_SUCCESS',
      error: 'STATUS_GET_FILTER_ERROR',
    },
    save: {
      success: 'STATUS_SAVE_SUCESS',
      error: 'STATUS_SAVE_ERROR',
    },
  },
  activity: {
    getFilter: {
      success: 'ACTIVITY_GET_FILTER_SUCCESS',
      error: 'ACTIVITY_GET_FILTER_ERROR',
    },
    get: {
      success: 'ACTIVITY_GET_SUCCESS',
      error: 'ACTIVITY_GET_ERROR',
    },
    filter: {
      success: 'ACTIVITY_GET_SUCCESS',
      error: 'ACTIVITY_GET_ERROR',
    },
    save: {
      success: 'ACTIVITY_SAVE_SUCESS',
      error: 'ACTIVITY_SAVE_ERROR',
    },
    delete: {
      success: 'ACTIVITY_DELETE_SUCESS',
      error: 'ACTIVITY_DELETE_ERROR',
    },
    update: {
      success: 'ACTIVITY_UPDATE_SUCESS',
      error: 'ACTIVITY_UPDATE_ERROR',
    },
    getHistory: {
      success: 'ACTIVITY_GET_HISTORY_SUCCESS',
      failure: 'ACTIVITY_GET_HISTORY_FAILURE',
    },
    clearMessages: 'ACTIVITY_CLEAR_MESSAGES',
  },
  period: {
    getFilter: {
      success: 'PERIOD_GET_FILTER_SUCCESS',
      error: 'PERIOD_GET_FILTER_ERROR',
    },
    getProposta: {
      success: 'PERIOD_GET_PROPOSTA_SUCCESS',
      error: 'PERIOD_GET_FILTER_ERROR',
    },
    save: {
      success: 'PERIOD_SAVE_SUCCESS',
      error: 'PERIOD_SAVE_ERROR',
    },
    get: {
      success: 'PERIOD_GET_SUCCESS',
      error: 'PERIOD_GET_ERROR',
    },
    delete: {
      success: 'PERIOD_DELETE_SUCCESS',
      error: 'PERIOD_DELETE_ERROR',
    },
    update: {
      success: 'PERIOD_UPDATE_SUCCESS',
      error: 'PERIOD_UPDATE_ERROR',
    },
  },
  hourBank: {
    getFilter: {
      success: 'HOUR_BANK_GET_FILTER_SUCCESS',
      error: 'HOUR_BANK_GET_FILTER_ERROR',
    },
    save: {
      success: 'HOUR_BANK_SAVE_SUCCESS',
      error: 'HOUR_BANK_SAVE_ERROR',
    },
    get: {
      success: 'HOUR_BANK_GET_SUCCESS',
      error: 'HOUR_BANK_GET_ERROR',
    },
    delete: {
      success: 'HOUR_BANK_DELETE_SUCCESS',
      error: 'HOUR_BANK_DELETE_ERROR',
    },
    update: {
      success: 'HOUR_BANK_UPDATE_SUCCESS',
      error: 'HOUR_BANK_UPDATE_ERROR',
    },
  },
  userPeriod: {
    save: {
      success: 'USER_PERIOD_SAVE_SUCCESS',
      error: 'USER_PERIOD_SAVE_ERROR',
    },
    get: {
      success: 'USER_PERIOD_GET_SUCCESS',
      error: 'USER_PERIOD_GET_ERROR',
    },
    getFilter: {
      success: 'USER_PERIOD_GET_FILTER_SUCCESS',
      error: 'USER_PERIOD_GET_FILTER_ERROR',
    },
    delete: {
      success: 'USER_PERIOD_DELETE_SUCCESS',
      error: 'USER_PERIOD_DELETE_ERROR',
    },
    update: {
      success: 'USER_PERIOD_UPDATE_SUCCESS',
      error: 'USER_PERIOD_UPDATE_ERROR',
    },
  },
  clientPeriod: {
    getFilter: {
      success: 'CLIENT_PERIOD_GET_FILTER_SUCCESS',
      error: 'CLIENT_PERIOD_GET_FILTER_ERROR',
    },
    save: {
      success: 'CLIENT_PERIOD_SAVE_SUCCESS',
      error: 'CLIENT_PERIOD_SAVE_ERROR',
    },
    get: {
      success: 'CLIENT_PERIOD_GET_SUCCESS',
      error: 'CLIENT_PERIOD_GET_ERROR',
    },
    delete: {
      success: 'CLIENT_PERIOD_DELETE_SUCCESS',
      error: 'CLIENT_PERIOD_DELETE_ERROR',
    },
    update: {
      success: 'CLIENT_PERIOD_UPDATE_SUCCESS',
      error: 'CLIENT_PERIOD_UPDATE_ERROR',
    },
  },
  entryReport: {
    setFilter: 'ENTRY_REPORT_SET_FILTER',
    markFilterAsValidToApply: 'ENTRY_REPORT_MARK_FILTER_AS_VALID_TO_APPLY',
  },
  entry: {
    getReport: {
      success: 'ENTRY_GET_FILTER_REPORT_SUCCESS',
      error: 'ENTRY_GET_FILTER_REPORT_ERROR',
    },
    getReportRD: {
      success: 'ENTRY_GET_FILTER_REPORT_RD_SUCCESS',
      successUserPropostaRD: 'SUCESS_REPORT_RD',
      waitingReportGeneration: 'WAITING_REPORT_GENERATION',
      noRegisterUserPropostaRD: 'NO_REGISTER_USER_PROPOSTA_REPORT_RD',
      error: 'ENTRY_GET_FILTER_REPORT_RD_ERROR',
      mandatoryFieldsNotCompletedError: 'MANDATORY_FIELDS_NOT_COMPLETED_ERROR',
      cleanToasterRd: 'CLEAN_TOASTER_RD',
      cleanFiltersRd: 'CLEAN_FILTERS_RD',
      cleanListPropostalRd: 'CLEAN_LIST_PROPOSAL_RD',
    },
    getReportSquadRD: {
      success: 'ENTRY_GET_FILTER_REPORT_SQUAD_RD_SUCCESS',
      successUserPropostaSquadRD: 'SUCESS_REPORT_SQUAD_RD',
      waitingReportGeneration: 'WAITING_REPORT_GENERATION_SQUAD_RD',
      noRegisterUserPropostaSquadRD:
        'NO_REGISTER_USER_PROPOSTA_REPORT_SQUAD_RD',
      error: 'ENTRY_GET_FILTER_REPORT_SQUAD_RD_ERROR',
      mandatoryFieldsNotCompletedErrorSquadRD:
        'MANDATORY_FIELDS_NOT_COMPLETED_ERROR_SQUAD_RD',
      cleanToasterSquadRd: 'CLEAN_TOASTER_SQUAD_RD',
      cleanFiltersSquadRd: 'CLEAN_FILTERS_SQUAD_RD',
      cleanListPropostalSquadRd: 'CLEAN_LIST_PROPOSAL_SQUAD_RD',
    },
    getFilter: {
      success: 'ENTRY_GET_FILTER_SUCCESS',
      error: 'ENTRY_GET_FILTER_ERROR',
    },
    save: {
      success: 'ENTRY_SAVE_SUCCESS',
      error: 'ENTRY_SAVE_ERROR',
    },
    get: {
      success: 'ENTRY_GET_SUCCESS',
      error: 'ENTRY_GET_ERROR',
    },
    delete: {
      success: 'ENTRY_DELETE_SUCCESS',
      error: 'ENTRY_DELETE_ERROR',
    },
    update: {
      success: 'ENTRY_UPDATE_SUCCESS',
      error: 'ENTRY_UPDATE_ERROR',
    },
  },
  employee: {
    getFilter: {
      success: 'EMPLOYEE_GET_FILTER_SUCCESS',
      error: 'EMPLOYEE_GET_FILTER_ERROR',
    },
    save: {
      success: 'EMPLOYEE_SAVE_SUCCESS',
      error: 'EMPLOYEE_SAVE_ERROR',
    },
    get: {
      success: 'EMPLOYEE_GET_SUCCESS',
      error: 'EMPLOYEE_GET_ERROR',
    },
    delete: {
      success: 'EMPLOYEE_DELETE_SUCCESS',
      error: 'EMPLOYEE_DELETE_ERROR',
    },
    update: {
      success: 'EMPLOYEE_UPDATE_SUCCESS',
      error: 'EMPLOYEE_UPDATE_ERROR',
    },
    clearMessages: 'EMPLOYEE_CLEAR_MESSAGES',
  },
  point: {
    getFilter: {
      success: 'POINT_GET_FILTER_SUCCESS',
      error: 'POINT_GET_FILTER_ERROR',
    },
    get: {
      success: 'POINT_GET_SUCCESS',
      error: 'POINT_GET_ERROR',
    },
    save: {
      success: 'POINT_SAVE_SUCCESS',
      error: 'POINT_SAVE_ERROR',
    },
    update: {
      success: 'POINT_UPDATE_SUCCESS',
      error: 'POINT_UPDATE_ERROR',
    },
    remove: {
      success: 'POINT_REMOVE_SUCCESS',
      error: 'POINT_REMOVE_ERROR',
    },
  },
  monthlyClose: {
    generateMonthlyClose: {
      success: 'MONTHLY_CLOSE_GENERATE_SUCCESS',
      error: 'MONTHLY_CLOSE_GENERATE_ERROR',
    },
  },
  proposal: {
    updateFilter: 'PROPOSAL_UPDATE_FILTER',
    clearFilter: 'PROPOSAL_CLEAR_FILTER',
    cleanToasterProposalRd: 'CLEAN_TOASTER_PROPOSAL',
    getFilter: {
      success: 'PROPOSAL_GET_FILTER_SUCCESS',
      successByUser: 'PROPOSAL_GET_FILTER_SUCCESS',
      successReportRD: 'PROPOSAL_SUCESS_REPORT_RD',
      noRegisterReportRD: 'NO_REGISTER_REPORT_RD',
      error: 'PROPOSAL_GET_FILTER_ERROR',
    },
    get: {
      success: 'PROPOSAL_GET_SUCCESS',
      error: 'PROPOSAL_GET_ERROR',
    },
    getOptions: {
      success: 'PROPOSAL_OPTIONS_SUCCESS',
      error: 'PROPOSAL_OPTIONS_ERROR',
    },
    save: {
      success: 'PROPOSAL_SAVE_SUCCESS',
      error: 'PROPOSAL_SAVE_ERROR',
    },
    delete: {
      success: 'PROPOSAL_DELETE_SUCCESS',
      error: 'PROPOSAL_DELETE_ERROR',
    },
    update: {
      success: 'PROPOSAL_UPDATE_SUCCESS',
      error: 'PROPOSAL_UPDATE_ERROR',
    },
  },
  proposalFunction: {
    updateFilter: 'PROPOSAL_FUNCTION_UPDATE_FILTER',
    clearFilter: 'PROPOSAL_FUNCTION_CLEAR_FILTER',
    emptyList: 'PROPOSAL_FUNCTION_EMPTY_LIST',
    getFilter: {
      success: 'PROPOSAL_FUNCTION_GET_FILTER_SUCCESS',
      error: 'PROPOSAL_FUNCTION_GET_FILTER_ERROR',
    },
    get: {
      success: 'PROPOSAL_FUNCTION_GET_SUCCESS',
      error: 'PROPOSAL_FUNCTION_GET_ERROR',
    },
    save: {
      success: 'PROPOSAL_FUNCTION_SAVE_SUCCESS',
      error: 'PROPOSAL_FUNCTION_SAVE_ERROR',
    },
    delete: {
      success: 'PROPOSAL_FUNCTION_DELETE_SUCCESS',
      error: 'PROPOSAL_FUNCTION_DELETE_ERROR',
    },
    update: {
      success: 'PROPOSAL_FUNCTION_UPDATE_SUCCESS',
      error: 'PROPOSAL_FUNCTION_UPDATE_ERROR',
    },
  },
  proposalUser: {
    updateFilter: 'PROPOSAL_USER_UPDATE_FILTER',
    clearFilter: 'PROPOSAL_USER_CLEAR_FILTER',
    getFilter: {
      success: 'PROPOSAL_USER_GET_FILTER_SUCCESS',
      error: 'PROPOSAL_USER_GET_FILTER_ERROR',
    },
    get: {
      success: 'PROPOSAL_USER_GET_SUCCESS',
      error: 'PROPOSAL_USER_GET_ERROR',
    },
    save: {
      success: 'PROPOSAL_USER_SAVE_SUCCESS',
      error: 'PROPOSAL_USER_SAVE_ERROR',
    },
    delete: {
      success: 'PROPOSAL_USER_DELETE_SUCCESS',
      error: 'PROPOSAL_USER_DELETE_ERROR',
    },
    update: {
      success: 'PROPOSAL_USER_UPDATE_SUCCESS',
      error: 'PROPOSAL_USER_UPDATE_ERROR',
    },
  },
  execution: {
    get: {
      success: 'EXECUTION_GET_SUCCESS',
      error: 'EXECUTION_GET_ERROR',
    },
    update: {
      success: 'EXECUTION_UPDATE_SUCCESS',
      error: 'EXECUTION_UPDATE_ERROR',
      observation: 'EXEUTION_OBSERVATION',
      observationError: 'EXECUTION_OBSERVATION_ERROR',
    },
    user: {
      success: 'EXECUTION_USER_SUCCESS',
    },
    getByMonth: {
      success: 'EXECUTION_GETBYMONTH_SUCCESS',
      error: 'EXECUTION_GETBYMONTH_ERROR',
    },
    getByMonthProposal: {
      success: 'EXECUTION_GETBYMONTH_SUCCESS',
      error: 'EXECUTION_GETBYMONTH_ERROR',
    },
    updateFilter: 'UPDATE_EXECUTION_FILTER',
    updatePeriod: 'UPDATE_PERIOD',
    updateSizeFilter: 'UPDATE_SIZE_FILTER',
    updatePageFilter: 'UPDATE_PAGE_FILTER',
    setEdit: 'SET_EXECUTION_TO_EDIT',
    refreshUserToEdit: 'REFRESH_USER',
    clear: 'EXECUTION_CLEAR',
    punchClockReportRD: {
      getReport: {
        error: 'GET_PUNCH_CLOCK_REPORT_ERROR',
        success: 'GET_PUNCH_CLOCK_REPORT_SUCCESS',
      },
    },
  },
  entryAutomatic: {
    setPeriod: 'SET_PERIOD',
    setProposal: 'SET_PROPOSAL',
    setUser: 'SET_USER',
    getList: {
      success: 'GETLIST_SUCCESS',
      error: 'GETLIST_ERROR',
    },
    clearList: 'CLEAR_LIST',
    clearFilters: 'CLEAR_FILTERS',
    generateEntry: {
      generate: 'GENERATE_ENTRY',
      success: 'GENERATE_SUCCESS',
      error: 'GENERATE_ERROR',
    },
    getProposal: {
      success: 'GETPROPOSAL_SUCCESS',
      error: 'GETPROPOSAL_ERROR',
    },
    getPeriod: {
      success: 'GETPERIOD_SUCCESS',
      error: 'GETPERIOD_ERROR',
    },
  },
  closureClients: {
    setPeriod: 'SET_PERIOD',
    setProposal: 'SET_PROPOSAL',
    clearFilters: 'CLEAR_FILTERS',
    generateClosure: {
      generate: 'GENERATE_CLOSURE',
      success: 'GENERATE_CLOSURE_SUCCESS',
      error: 'GENERATE_CLOSURE_ERROR',
    },
    getProposal: {
      success: 'GETPROPOSAL_SUCCESS',
      error: 'GETPROPOSAL_ERROR',
    },
    getPeriod: {
      success: 'GETPERIOD_SUCCESS',
      error: 'GETPERIOD_ERROR',
    },
  },
}

const trigger = (type, payload = null) => ({ type, payload })

export { types, trigger }
