import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  listPeriods: [],
  listPeriodsFilter: [],
  periods: [],
  listProposta:[],

  errorPeriod: null,
  successPeriod: null,

  totalElementsPeriods: 0,
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.period.getFilter.success:
      const { resultList, pages } = action.payload
      return {
        ...state,
        listPeriodsFilter: resultList || [],
        totalElementsPeriods:
          pages != null ? pages.totalCount : 0 || resultList.length,
      }
    case types.period.getFilter.error:
      return {
        ...state,
        errorPeriod: 'Erro ao buscar períodos',
        successPeriod: null,
      }

    case types.period.getProposta.success:
       return {...state,
        listProposta: action.payload.resultList || action.payload
      }  
    case types.period.getProposta.error:
        return {
          ...state,
          errorPeriod: 'Erro ao buscar períodos',
          successPeriod: null,
        }

    case types.period.get.success:
      return {
        ...state,
        listPeriods: action.payload.content || action.payload,
        periods: action.payload.content || action.payload,
      }

    case types.period.get.error:
      return {
        ...state,
        errorPeriod: 'Erro ao buscar períodos',
        successPeriod: null,
      }

    case types.period.save.success:
      return {
        ...state,
        successPeriod: 'Período salvo com sucesso',
        errorPeriod: null,
      }

    case types.period.save.error:
      return {
        ...state,
        errorPeriod: action.payload.messageError || 'Erro ao salvar período',
        successPeriod: null,
      }

    case types.period.delete.success:
      return {
        ...state,
        successPeriod: 'Período deletado com sucesso',
        errorPeriod: null,
      }

    case types.period.delete.error:
      const { messageError } = action.payload.response.data.message

      return {
        ...state,
        errorPeriod: messageError,
        successPeriod: null,
      }

    case types.period.update.success:
      return {
        ...state,
        successPeriod: 'Período atualizado com sucesso',
        errorPeriod: null,
      }

    case types.period.update.error:
      return {
        ...state,
        errorPeriod: action.payload.messageError || 'Erro ao atualizar período',
        successPeriod: null,
      }

    default:
      return state
  }
}
