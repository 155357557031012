import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

/**
 * Default toast
 */
const Toast = ({
  autoHideDuration = 6000,
  error,
  onClose = () => {},
  open,
  success,
}) => {
  const [severity, setSeverity] = useState(null)

  useEffect(() => {
    error ? setSeverity('warning') : setSeverity('success')
  }, [error])

  const shouldShowToast = (success || error) && !!severity

  if (!shouldShowToast) return null

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      open={open}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <Alert
        variant="filled"
        onClose={onClose}
        severity={severity}
        style={{ fontSize: '17px', alignItems: 'center' }}
      >
        {error || success}
      </Alert>
    </Snackbar>
  )
}

Toast.propTypes = {
  autoHideDuration: PropTypes.number,
  error: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  success: PropTypes.string,
}
export default Toast
