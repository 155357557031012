export const MODELOS_PROPOSTA = [
  { id: 'SQUAD', description: 'SQUAD' },
  { id: 'ALOCACAO_MASSA', description: 'ALOCAÇÃO EM MASSA' },
]

export const STATUS_PROPOSTA = [
  { id: 'INCLUIDA', description: 'INCLUÍDA' },
  { id: 'ANDAMENTO', description: 'EM ANDAMENTO' },
  { id: 'FINALIZADA', description: 'FINALIZADA' },
]

export const STATUS_EXECUCAO = [
    { id: 'Aberto', description: 'ABERTO' },
    { id: 'Em analise', description: 'EM ANÁLISE' },
  ]