import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'

import { globalStyles } from '../../styles/global'

import { tableIdentification } from './tableUtils'

const TablePagination = ({
  /** Pagination */
  order,
  /** Table content */
  titleTable,
  headers,
  columnKeyName,
  rows,
  doNotShow = ['id'],
  customFooter,
  /** Actions */
  hasAction = false,
  startDelete,
  startEdit,
  startDuplicated,
  alignTitle = [],
  alignItens = [],
  maskFunction = {},
  rowAction = null,
  rowActionClassName = '',
  onClickRowAction = () => {},
}) => {
  const styles = globalStyles()
  const renderHeader = (header, index) => {
    return (
      <TableCell
        style={{
          textAlign: alignTitle[index] ? alignTitle[index] : 'center',
          color: '#ffff',
        }}
        className={styles.th}
        key={header}
      >
        {header}
      </TableCell>
    )
  }

  const renderRow = row => {
    return (
      <TableRow key={nanoid()}>
        {order
          ? order.map((key, index) =>
              renderColumn([key, row[key], index], index)
            )
          : Object.entries(row).map(renderColumn)}
        {renderRowAction(row)}
      </TableRow>
    )
  }

  const renderColumn = (column, index) => {
    const key = column[0]
    const value = column[1]

    if (
      doNotShow.some(item => item === key) ||
      (typeof value === 'object' && value !== null)
    )
      return
    const parse = maskFunction[key] ? maskFunction[key](value) : value
    return (
      <TableCell
        style={{ textAlign: alignItens[index] ? alignItens[index] : 'center' }}
        key={`${key}-${value}`}
      >
        {tableIdentification(titleTable, key, parse)}
      </TableCell>
    )
  }

  const handleRowActionClick = (event, row) => {
    event.preventDefault()
    onClickRowAction(row)
  }

  const renderRowAction = row => {
    const hasRowAction = rowAction !== null

    if (!hasRowAction) return null

    return (
      <TableCell
        align="center"
        key={nanoid()}
        className={rowActionClassName}
        onClick={event => handleRowActionClick(event, row)}
      >
        {rowAction}
      </TableCell>
    )
  }

  const renderTableRows = () => {
    const hasRows = rows && rows.length > 0

    if (hasRows) {
      return <>{rows.map(renderRow)}</>
    }

    return (
      <TableRow>
        <TableCell align="center" colSpan={headers.length}>
          <strong>Não existem registros, por favor faça um cadastro!</strong>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Table className={styles.table}>
      {headers && (
        <TableHead className={styles.thead}>
          <TableRow>
            {headers.map((item, index) => renderHeader(item, index))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>{renderTableRows()}</TableBody>
      <TableFooter>
        <TableRow>{customFooter}</TableRow>
      </TableFooter>
    </Table>
  )
}

TablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  doNotShow: PropTypes.array,
  headers: PropTypes.array.isRequired,
  columnKeyName: PropTypes.string,
  onChangePage: PropTypes.func.isRequired,
  onChangeSize: PropTypes.func.isRequired,
  rows: PropTypes.array,
  startDelete: PropTypes.func.isRequired,
  startEdit: PropTypes.func.isRequired,
  startDuplicated: PropTypes.func.isRequired,
  alignTitle: PropTypes.array.isRequired,
  alignItens: PropTypes.array.isRequired,
  maskFunction: PropTypes.object,
  rowActionClassName: PropTypes.string,
}

export default TablePagination
