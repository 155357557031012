import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import * as actionEntryReport from '../../actions/entry'
import { getFilter as getPeriods } from '../../actions/period'
import * as proposalActions from '../../actions/proposal'
import * as punchClockReportActions from '../../actions/punchClockReportRD'
import * as proposalUserActions from '../../actions/proposalUser'
import * as executionActions from '../../actions/execution'
import { get as getUsers } from '../../actions/user'
import { getReportFechamentoRD } from '../../actions/entry'
import { globalStyles } from '../../styles/global'
import Main from '../Main'
import { Box, Grid } from '@material-ui/core'
import Filter from '../../utils/Filter/FilterPontoRD'

import Loading from '../../utils/Loading'
import Toast from '../../utils/Toast'

const PunchClockReportRD = ({
  /** Actions */
  getPunchClockReportRD,
  getPeriods,
  fetchProposals,
  errorPunchClockRD,
  changeProposalFilterPageSize = () => {},
  /** Reducer props */
  listUsers,
  getUsers,
  listPeriodsFilter,
  errorReportRD = null,
  warningReportRD = null,
  successRD = null,
  mandatoryFieldsNotCompletedError = null,
  cleanToasterRd = null,
  cleanFiltersRd = null,
  cleanToasterProposalRd = null,
}) => {
  momentDurationFormatSetup(moment)
  const styles = globalStyles()

  const [showLoading, setShowLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showToastPunchClock, setShowToastPunchClock] = useState(false)
  const [selectState, setSelectState] = useState({})

  useEffect(() => {
    if (errorReportRD || warningReportRD) {
      setShowToast(true)
    } else {
      setShowToast(false)
    }
    if (errorPunchClockRD) {
      setShowToastPunchClock(true)
    } else {
      setShowToastPunchClock(false)
    }
  }, [errorReportRD, warningReportRD, errorPunchClockRD])

  useEffect(() => {
    if (successRD) {
      filterProps.limparFields = true
    }
  }, [successRD])

  const validarCamposObrigatorios = values => {
    const { userIdPeriod, idUser } = values

    let qtdCampos = 0
    let camposNaoPreenchidos = ''

    if (!userIdPeriod) {
      camposNaoPreenchidos = ' Período'
      qtdCampos++
    }
    if (!idUser) {
      camposNaoPreenchidos += (qtdCampos !== 0 ? ' e ' : '') + ' Recurso'
      qtdCampos++
    }
    if (qtdCampos !== 0) {
      let msgCampos = ''
      if (qtdCampos !== 0) {
        if (qtdCampos === 1) {
          msgCampos =
            'O campo ' + camposNaoPreenchidos + ' deve ser informado. '
        } else {
          msgCampos =
            'Os campos ' + camposNaoPreenchidos + ' devem ser informados. '
        }
      }
      mandatoryFieldsNotCompletedError(msgCampos)
      return false
    }
    return true
  }

  const closeTaster = () => {
    cleanToasterRd()
    cleanToasterProposalRd()
    setShowToast(false)
  }

  const cleanFilterReportRd = () => {
    cleanFiltersRd()
  }

  const handleFilter = async (values, enviar) => {
    if (enviar) {
      if (validarCamposObrigatorios(values)) {
        getPunchClockReportRD({
          periodId: values.userIdPeriod,
          userId: values.idUser,
        })
      }
    }
  }

  let filterProps = {
    onSubmit: handleFilter,
    executeClearAllFields: cleanFilterReportRd,
    enviar: false,
    limparFields: successRD,
    monitoreState: state => {
      setSelectState(state.fields)
    },
    fields: [
      {
        type: 'select-data',
        name: 'userIdPeriod',
        label: 'Período',
        options: (listPeriodsFilter || []).map(
          ({ id, desPeriodo, dtaInicio, dtaFim }) => ({
            label: desPeriodo,
            value: id,
            dtaInicio,
            dtaFim,
          })
        ),
      },
      {
        type: 'select',
        name: 'idUser',
        label: 'Usuário',
        options: (listUsers || [])
          .sort((userA, userB) =>
            userA.desUsuario.localeCompare(userB.desUsuario)
          )
          .map(({ id, desUsuario }) => ({
            label: desUsuario,
            value: id,
          })),
        disabled: selectState.userExecution,
      },
    ],
  }

  useEffect(() => {
    setShowLoading(true)

    if (listPeriodsFilter == null || listPeriodsFilter.length === 0) {
      getPeriods({ indFechado: 1, sort: 'dtaInicio desc' })
    }

    changeProposalFilterPageSize(9999)
    fetchProposals()
    setShowLoading(false)
  }, [])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  return (
    <Main title="RD Ponto">
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <Grid item xs={12}>
            <Box component="div" className={styles.box} xs={12}></Box>
            <Box component="div" className={styles.filter} xs={12}>
              <Filter {...filterProps} />
            </Box>
          </Grid>
        </>
      )}

      <Toast
        autoHideDuration={3000}
        onClose={() => closeTaster()}
        open={showToast}
        error={errorReportRD}
        success={warningReportRD}
      />
      <Toast
        autoHideDuration={3000}
        onClose={() => closeTaster()}
        open={showToastPunchClock}
        error={errorPunchClockRD}
        success={null}
      />
    </Main>
  )
}

const mapStateToProps = state => {
  const {
    userReducer,
    periodReducer,
    entryReducer,
    proposalReducer,
    executionReducer,
    punchClockReportRDReducer,
  } = state
  return {
    ...userReducer,
    ...periodReducer,
    ...proposalReducer,
    ...entryReducer,
    ...punchClockReportRDReducer,
    executionList: executionReducer.list,
    listUsers: userReducer.listUsers,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionEntryReport,
      ...proposalActions,
      ...proposalUserActions,
      ...punchClockReportActions,
      getPeriods,
      getExecutions: executionActions.get,
      changeProposalFilterPageSize: proposalActions.changePageSize,
      getUsers,
      getReportFechamentoRD,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PunchClockReportRD)
