import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { useFormik } from 'formik'

import TextField from '@material-ui/core/TextField'

import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import Select from '../../../utils/Form/Select'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      decimalSeparator={'.'}
      decimalScale={6}
      fixedDecimalScale={true}
      suffix={' hr(s)'}
      isNumericString={true}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

function NumberFormatCustomMoeda(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      fixedDecimalScale={true}
      decimalScale={6}
      decimalSeparator=","
      prefix="R$ "
      thousandSeparator="."
      isNumericString={true}
    />
  )
}

NumberFormatCustomMoeda.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const validationSchema = yup.object({
  periodId: yup.number().required('Por favor, escolha o período!'),
  projectId: yup.number().required('Por favor, escolha o projeto!'),
  qtdHoras: yup.string().required('Por favor, informe a qtd. horas'),
})

/**
 * Period form
 */
const Form = ({
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  param = {},
  projects,
  periods,
  listProjects,
  /** Functions */
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [clients, setClients] = useState('')

  const formik = useFormik({
    initialValues: {
      id: param.id,
      clientId: param.clientId,
      periodId: param.periodId,
      projectId: param.projectId,
      qtdHoras: param.qtdHoras,
      vlrHora: param.vlrHora,
      vlrTotal: param.vlrTotal,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const { vlrHora, vlrTotal } = values
      values.vlrHora = vlrHora * 1
      values.vlrTotal = vlrTotal * 1
      onSubmit(values)
    },
  })

  useEffect(() => {
    if (formik.values.projectId) {
      setClients(
        listProjects
          .filter(
            project => project.id === parseInt(formik.values.projectId, 10)
          )
          .map(project => project.descClient)
      )
    }
  }, [formik.values.projectId])

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                disabled
                id="idClient"
                name="idClient"
                label="Cliente"
                value={clients}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <Select
                fullWidth
                select
                id="projectId"
                name="projectId"
                label="Projeto"
                options={projects}
                value={{ value: formik.values.projectId }}
                onChange={formik.handleChange}
                error={
                  formik.touched.projectId && Boolean(formik.errors.projectId)
                }
                helperText={formik.touched.projectId && formik.errors.projectId}
              />
            </FlexItemWrapper>
            <FlexItemWrapper>
              <Select
                fullWidth
                select
                id="periodId"
                name="periodId"
                label="Periodo"
                options={periods}
                value={{ value: formik.values.periodId }}
                onChange={formik.handleChange}
                error={
                  formik.touched.periodId && Boolean(formik.errors.periodId)
                }
                helperText={formik.touched.periodId && formik.errors.periodId}
              />
            </FlexItemWrapper>
          </FlexWrapper>

          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                id="qtdHoras"
                name="qtdHoras"
                label="Qtd. Horas"
                value={formik.values.qtdHoras}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdHoras && Boolean(formik.errors.qtdHoras)
                }
                helperText={formik.touched.qtdHoras && formik.errors.qtdHoras}
                inputProps={{ maxLength: 47 }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper middle>
              <TextField
                fullWidth
                id="vlrHora"
                name="vlrHora"
                label="Valor Hora"
                value={formik.values.vlrHora}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 47 }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustomMoeda,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper right>
              <TextField
                fullWidth
                id="vlrTotal"
                name="vlrTotal"
                label="Valor Total"
                value={formik.values.vlrTotal}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 47 }}
                // eslint-disable-next-line
                InputProps={{
                  inputComponent: NumberFormatCustomMoeda,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <Button
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  beforeSubmit: PropTypes.func,
  isDeleteAction: PropTypes.bool,
  onCancel: PropTypes.func,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  setter: PropTypes.func,
  values: PropTypes.object,
  projects: PropTypes.array,
  clients: PropTypes.array,
  periods: PropTypes.array,
}

export default Form
