const resolveValue = value => {
  if (value === 'true') return true

  if (value === 'false') return false

  if (value === 'null') return null

  if (value === 'undefined') return undefined

  if (value === 'NaN') return 0

  if (+value) return +value

  return value
}

export const getObjectFromParams = params => {
  return (params || '')
    .substring(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, pair) => {
      const [key, value] = pair.map(decodeURIComponent)
      obj[key] = resolveValue(value)
      return obj
    }, {})
}

export const getParamsFromObject = obj => {
  return (Object || {})
    .keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')
}
