import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Button, ButtonGroup } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import ReorderIcon from '@material-ui/icons/Reorder'
import Checkbox from './components/Checkbox'
import Date from './components/Date'
import DateRange from './components/DateRange'
import Input from './components/Input'
import SelectMultiple from './components/SelectMultiple'
import Select from './components/Select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as entryAutomaticActions from '../../actions/entryAutomatic'

class Filter extends Component {
  state = {
    fields: {},
    wasClear: false,
    isEmpty: true,
    previousIdPeriodValue: null,
    isClear: false,
  }

  componentDidMount() {
    const { fields, fieldsLine2 = {}, period, proposal, user } = this.props
    const fieldsCompleted = {}

    const totalFields = fields.concat(fieldsLine2)

    totalFields.forEach(
      field =>
        (fieldsCompleted[field.name] =
          field.initial || this.createInitialValue(field.type))
    )

    this.setState({
      fields: {
        idPeriod: period.value,
        idProposal: proposal.value,
        idUser: user.value
      },
      isEmpty:true,
    })

    if (period.value) {
      this.setState({
        isEmpty: false
      })
    }
  }

  componentDidUpdate(previousProps) {
    if (this.props.monitoreState) this.props.monitoreState(this.state)

    if (this.props.period.value !== previousProps.period.value) {
      if (this.props.period.value === null) {
        this.clearAllFields()
      }
    }
  }

  clearAllFields = async () => {
    await this.props.clearFilters()
    const { fields, fieldsLine2 = {}} = this.props
    const fieldsCompleted = {}

    const totalFields = fields.concat(fieldsLine2)

    totalFields.forEach(
      field =>
        (fieldsCompleted[field.name] =
          field.disabled && field.initial
            ? field.initial
            : this.createInitialValue(field.type)),
    )

    this.props.fields[1].disabled = true
    this.props.fields[2].disabled = true

    this.setState({
      fields: fieldsCompleted,
      wasClear: true,
      isEmpty: true,
    })
    this.props.onValueChange(fields);
    this.props.onClear();
  }

  setValueToField = async (field, value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: value,
      },
    })

    if (this.props.onValueChange) {
      this.props.onValueChange(field, value);
    }

    if (field === "idPeriod" && value === null) {
      this.clearAllFields()
      this.setState({
        isEmpty: true
      })
    }

    if (field === "idPeriod" && !value) {
      this.clearAllFields()
      this.setState({
        isEmpty: true
      })
    }

    if (field === "idProposal" && !value) {
      this.setState({
        isEmpty: true
      })
    }

    if (field === "idUser" && value === null) {
      this.setState({
        isEmpty: true
      })
    }

    else if (value) {
      this.setState({
        isEmpty: false,
      })

      if (field === "idPeriod" && value !== this.state.previousIdPeriodValue) {
        await this.props.setProposal({ label:'', value: null })
        await this.props.setUser({ label:'', value: null })
        this.setState({ previousIdPeriodValue: value });
        this.setState({
          fields: {
            ...this.state.fields,
            [field]: value,
            idProposal: null, // Limpe o valor de idProposta
            idUser: null, // Limpe o valor de idUser
          },
          isEmpty: true,
        });
        this.props.onValueChange();
      }
    }
  }

  createInitialValue = type => {
    switch (type) {
      case 'select_multiple':
        return []
      case 'checkbox':
        return false
      case 'date_range':
        return [null, null]
      case 'input':
        return ''
      default:
        return null
    }
  }

  buildField(props) {
    switch (props.type) {
      case 'select':
        return (
          <Select
            isFromPreClosure
            wasClear={this.state.wasClear}
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'select_multiple':
        return (
          <SelectMultiple
            wasClear={this.state.wasClear}
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'checkbox':
        return (
          <Checkbox
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'date':
        return (
          <Date
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'date_range':
        return (
          <DateRange
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )
      case 'input':
        return (
          <Input
            stateValues={this.state.fields[props.name]}
            setValueToField={this.setValueToField}
            {...props}
          />
        )

      default:
        return
    }
  }

  render() {
    const {
      fields,
      fieldsLine2,
      onSubmit = () => {},
      gridSizeLine1,
      gridSizeLine2,
      gridSizeButtons,
    } = this.props

    return (
      <Grid
        container
        className="common-filter"
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        {(fields || []).map((field, i) => (
          <Grid xs={gridSizeLine1} item key={`grid-1-${i}`} className="filter-item">
            {this.buildField(field)}
          </Grid>
        ))}

        {(fieldsLine2 || []).map((field, i) => (
          <Grid xs={gridSizeLine2} item key={`grid-2-${i}`} className="filter-item">
            {this.buildField(field)}
          </Grid>
        ))}

        <Grid item xs={gridSizeButtons}  className="submit-button-container">
          <ButtonGroup fullWidth disableElevation variant="contained" size="small">
            <Button
              style={{ paddingLeft: 25, paddingRight: 25 }}
              startIcon={<FilterListIcon />}
              color="primary"
              onClick={() => onSubmit(this.state.fields)}
              className="submit-button"
              disabled={this.state.isEmpty}
            >
              Filtrar
            </Button>
            <Button
              style={{ paddingLeft: 25, paddingRight: 25 }}
              startIcon={<ReorderIcon />}
              color="secondary"
              onClick={() => this.clearAllFields()}
              className="submit-button"
            >
              Limpar
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => {
  const { entryAutomaticReducer } = state
  return {
    period: entryAutomaticReducer.period,
    proposal: entryAutomaticReducer.proposal,
    user: entryAutomaticReducer.user,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearFilters: entryAutomaticActions.clearFilters,
      setProposal: entryAutomaticActions.setProposal,
      setUser: entryAutomaticActions.setUser,
    },
    dispatch
  )

Filter.defaultProps = {
  gridSizeLine1: 3,
  gridSizeLine2: 2,
  gridSizeButtons: 2,
}

Filter.propTypes = {
  fields: PropTypes.array.isRequired,
  fieldsLine2: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  gridSizeLine1: PropTypes.number,
  gridSizeLine2: PropTypes.number,
  gridSizeButtons: PropTypes.number,
  isClear: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
