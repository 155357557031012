import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'
import FileSaver from 'file-saver'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.entry + '/filter', param)
    return dispatch(trigger(types.entry.getFilter.success, response))
  } catch (e) {
    return dispatch(trigger(types.entry.getFilter.error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.entry, param)

    return dispatch(trigger(types.entry.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.entry.get.error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.entry, param)

    return dispatch(trigger(types.entry.save.success))
  } catch (e) {
    return dispatch(trigger(types.entry.save.error, e.response.data.message))
  }
}

export const saveVacation = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.entryVacation, param)
  } catch (e) {
    return e.response.data
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.entry, param)

    return dispatch(trigger(types.entry.delete.success))
  } catch (e) {
    return dispatch(trigger(types.entry.delete.error))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.entry, param)

    return dispatch(trigger(types.entry.update.success))
  } catch (e) {
    return dispatch(trigger(types.entry.update.error, e.response.data.message))
  }
}

export const getReport = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.entry + '/entry-report',
      param
    )
    return dispatch(trigger(types.entry.getReport.success, response))
  } catch (e) {
    return dispatch(trigger(types.entry.getReport.error))
  }
}

export const getReportFechamentoRD = param => async dispatch => {
  const reportType = param.detailedReport ? 'detailed' : 'resumed';
  const { detailedReport, ...requestParams } = param;
  dispatch(trigger(types.entry.getReportRD.waitingReportGeneration))
  try{
    const response = await new Http(dispatch).post(
      URL.allocation + `/${reportType}`,
      requestParams
    )
    fetchReportsSuccess(response, dispatch)
  } catch (e) {
    await sleep(1000)
    return dispatch(trigger(types.entry.getReportRD.error, e.response.data))}
}

export const getReportFechamentoSquadRD = param => async dispatch => {
  dispatch(trigger(types.entry.getReportSquadRD.waitingReportGeneration))
  try {
    const response = await new Http(dispatch).get(
      URL.entryReportFechamentoAlocacaoSquadRD,
      param,
      false
    )
    fetchReportsSuccessSquadRd(response, dispatch)
  } catch (e) {
    return dispatch(
      trigger(types.entry.getReportSquadRD.error, {
        mensagemErro: 'Nenhum dado encontrado.',
      })
    )
  }
}

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fetchReportsSuccess = async (response, dispatch) => {
  if (response.data === null) {
    await sleep(1000);
    return dispatch(trigger(types.entry.getReportRD.error, response))
  } else {
    var datax = new Blob([base64toBlob(response.data, 'application/pdf')], {
      type: 'application/pdf',
    })
    FileSaver.saveAs(datax, 'relatorio_alocacao_RD.pdf')
    return dispatch(trigger(types.entry.getReportRD.success, response)) 
  }
}

export const fetchReportsSuccessSquadRd = (response, dispatch) => {
  if (response.data === null) {
    return dispatch(trigger(types.entry.getReportSquadRD.error, response))
  } else {
    var datax = new Blob([base64toBlob(response.data, 'application/pdf')], {
      type: 'application/pdf',
    })
    FileSaver.saveAs(datax, 'relatorio_alocacao_Squad_RD.pdf')
    return dispatch(trigger(types.entry.getReportSquadRD.success, response))
  }
}

export function base64toBlob(base64, contentType = '', sliceSize = 512) {
  try {
    const byteCharacters = atob(decodeURIComponent(base64))
    const byteArrays = []
    const len = byteCharacters.length

    for (let offset = 0; offset < len; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      byteArrays.push(new Uint8Array(byteNumbers))
    }

    return new Blob(byteArrays, { type: contentType })
  } catch (e) {
    console.log(e)
  }
}

export const mandatoryFieldsNotCompletedError = msg => ({
  type: types.entry.getReportRD.mandatoryFieldsNotCompletedError,
  payload: msg,
})

export const cleanToasterRd = () => ({
  type: types.entry.getReportRD.cleanToasterRd,
  payload: null,
})

export const cleanListPropostalRd = () => ({
  type: types.entry.getReportRD.cleanListPropostalRd,
  payload: null,
})

export const cleanFiltersRd = () => ({
  type: types.entry.getReportRD.cleanFiltersRd,
  payload: null,
})

export const mandatoryFieldsNotCompletedErrorSquadRD = msg => ({
  type: types.entry.getReportSquadRD.mandatoryFieldsNotCompletedErrorSquadRD,
  payload: msg,
})

export const cleanToasterSquadRd = () => ({
  type: types.entry.getReportSquadRD.cleanToasterSquadRd,
  payload: null,
})

export const cleanListPropostalSquadRd = () => ({
  type: types.entry.getReportSquadRD.cleanListPropostalSquadRd,
  payload: null,
})

export const cleanFiltersSquadRd = () => ({
  type: types.entry.getReportSquadRD.cleanFiltersSquadRd,
  payload: null,
})
