import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'
import { convertDate } from '../utils/Date'

export const setPeriod = (period) => async (dispatch) => {
  dispatch(
    trigger(types.entryAutomatic.setPeriod, period)
  )
}

export const setProposal = (proposal) => async (dispatch) => {
  dispatch(
    trigger(types.entryAutomatic.setProposal, proposal)
  )
}

export const setUser = (user) => async (dispatch) => {
  dispatch(
    trigger(types.entryAutomatic.setUser, user)
  )
}

export const getList = (period) => async (dispatch, getState) => {
  const {
    entryAutomaticReducer: { proposal, user },
  } = getState()
  try {
    const dthFinal = period ? period.dtaFim : "";
    const dthInitial = period ? period.dtaInicio : "";
    const idProposta = proposal.value ? proposal.value : "";
    const idUsuario = user.value ? user.value : "";

    const response = await new Http(dispatch).get(
      URL.entryAutomaticList +
      `?dthFinal=${convertDate(dthFinal, 'DD/MM/YYYY', 'YYYY-MM-DD')}&dthInitial=${convertDate(dthInitial, 'DD/MM/YYYY', 'YYYY-MM-DD')}&idProposta=${idProposta}&idUsuario=${idUsuario}`,
    )
    if(response && response.length) {
      return dispatch(
        trigger(types.entryAutomatic.getList.success, response)
      )
    } else {
      return dispatch(
        trigger(types.entryAutomatic.getList.error, 'Nenhum lançamento encontrado!')
      )
    }
  } catch (e) {
    return dispatch(trigger(types.entryAutomatic.getList.error, e.response.data))
  }
}

export const generateEntry = (period) => async (dispatch, getState) => {
  const {
    entryAutomaticReducer: { proposal, user },
  } = getState()
  try {
    const dthFinal = period ? convertDate(period.dtaFim, 'DD/MM/YYYY', 'YYYY-MM-DD') : "";
    const dthInitial = period ? convertDate(period.dtaInicio, 'DD/MM/YYYY', 'YYYY-MM-DD') : "";
    const idProposta = proposal.value ? proposal.value : "";
    const idUsuario = user.value ? user.value : "";

    const param = {
      dthFinal: dthFinal,
      dthInitial: dthInitial,
      idProposta: idProposta,
      idUsuario: idUsuario
    }

    const response = await new Http(dispatch).post(URL.entryAutomaticExec, param)

    return dispatch(
      trigger(types.entryAutomatic.generateEntry.success, response)
    )
  } catch (e) {
    return dispatch(trigger(types.entryAutomatic.generateEntry.error, e.response.data))
  }
}

export const clearList = () => async (dispatch) => {
  dispatch(
    trigger(types.entryAutomatic.clearList)
  )
}

export const clearFilters = () => async (dispatch) => {
  dispatch(
    trigger(types.entryAutomatic.clearFilters)
  )
}

export const getProposal = () => async dispatch => {
  try {
    const response = await new Http(dispatch).get(`${URL.proposal}`);

    return dispatch(trigger(types.entryAutomatic.getProposal.success, response.data));
  } catch (error) {
    return dispatch(trigger(types.entryAutomatic.getProposal.error, error));
  }
}

export const getPeriod = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.period + '/filter', param)

    return dispatch(trigger(types.entryAutomatic.getPeriod.success, response))
  } catch (error) {
    return dispatch(trigger(types.entryAutomatic.getPeriod.error, error))
  }
}
