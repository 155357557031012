import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { matchSorter } from 'match-sorter';

const CustomSelect = ({
  isFromPreClosure = false,
  name,
  options,
  label,
  setValueToField,
  initial,
  stateValues,
  wasClear,
  disabled,
  setValueToFieldConditional = null,
  noOptionsText = 'Nenhuma opção encontrada',
  width, 
  height, 
}) => {
  const [wasChange, setWasChange] = useState(false);

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ['label'],
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    });

  return (
    <FormControl variant="outlined" fullWidth style={{ width, height }}>
      <Autocomplete
        value={
          stateValues || stateValues === false
            ? options.find(option => option.value === stateValues)
            : !wasClear && !wasChange
            ? options.find(option => option.value === initial) || null
            : null
        }
        filterOptions={filterOptions}
        options={options}
        getOptionLabel={option => option.label}
        fullWidth
        onChange={(_, option) => {
          setWasChange(true);
          setValueToField(option.value, option ? option.value : null);
          if (setValueToFieldConditional) {
            setValueToFieldConditional(name, option ? option : null);
          }
        }}
        renderInput={params => <TextField {...params} label={label} variant="outlined" />}
        disabled={disabled}
        noOptionsText={noOptionsText}
      />
    </FormControl>
  );
};

export default CustomSelect;
