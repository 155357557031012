import React, { useCallback, useEffect, useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { TableContainer, Paper, Box, Grid } from '@material-ui/core'
import Filter from '../../utils/Filter/Filter'

import Form from './components/Form'
import Main from '../Main'
import * as actionHourBank from '../../actions/hourBank'
import * as actionPeriod from '../../actions/period'
import { get as getUsers } from '../../actions/user'
import TablePagination from '../../utils/Table/TablePagination'
import Dialog from '../../utils/Dialog'
import Toast from '../../utils/Toast'
import { globalStyles } from '../../styles/global'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

import api from "../../../server"

const SORT = 'user.desUsuario asc , model.period.id desc'

const DEFAULT_PARAM = {
  id: null,
  periodId: '',
  userId: '',
  qtdSaldoIni: '',
  qtdHorasPositivas: '',
  qtdHorasNegativas: '',
  qtdAjuste: '',
  qtdSaldoFinal: '',
  desJustificaAjuste: '',
  desObs: '',
  qtdHorasPrevista: '',
  qtdAdicional: '',
  saldoFinal: ''
}

const DO_NOT_SHOW_TABLE = ['userId', 'periodId', 'id',  'qtdSaldoIni',
  'qtdHorasPositivas', 'qtdHorasNegativas', 'qtdAjuste', 'qtdSaldoFinal']

const TABLE_HEADER = [
  'Usuário',
  'Período',
  'Saldo Final',
]

const ORDER_TABLE = [
  'desUsuario',
  'desPeriodo',
  'saldoFinal',
]

const HourBankConsult = ({
  /** Actions */
  getPeriods,
  getFilter,
  get,
  remove,
  save,
  update,
  getUsers,
  /** Reducer props */
  listUsers,
  listPeriods,
  listHourBankFilter,
  errorHourBank,
  successHourBank,
  totalElementsHourBank,
}) => {

  const filteredPeriods = listPeriods
  .filter(item => item.indFechado && item.liberado)
  .map(({ id, desPeriodo }) => ({
    label: desPeriodo,
    value: id,
  }));

  const { id: idUsuario } = JSON.parse(localStorage.getItem('user'))
  const user = JSON.parse(localStorage.getItem('user'))
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)

  const [showToast, setShowToast] = useState(false)

  const [filterDefaultPeriod, setFilterDefaultPeriod] = useState()
  const [valuesFilter, setValuesFilter] = useState({})



  const handleFilter = values => {
    const { userId, periodId } = values

    setValuesFilter(values)

    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      indFechado: true,
      sizeFilter: size,
      liberado: true,
      sort: SORT,
      ...(idUsuario &&
        user.office.tipCargo === 'OPERAÇÃO' && { userId: idUsuario }),
      ...(userId && { userId }),
      ...(periodId && { periodId }),
    })

    setPage(DEFAULT_PAGINATION_PAGE)
  }



  const changePage = page => {
    const { userId, periodId } = valuesFilter

    setPage(page)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idUsuario &&
        user.office.tipCargo === 'OPERAÇÃO' && { userId: idUsuario }),
      ...(userId && { userId }),
      ...(periodId && { periodId }),
    })
  }

  const changeSize = size => {
    const { userId, periodId } = valuesFilter

    setSize(size)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idUsuario &&
        user.office.tipCargo === 'OPERAÇÃO' && { userId: idUsuario }),
      ...(userId && { userId }),
      ...(periodId && { periodId }),
    })
  }

  const query = (periodId) => {

    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      liberado: true,
      sort: SORT,
      ...(idUsuario && { userId: idUsuario }),
      ...(periodId && {periodId})
    })
    get()
    getPeriods({
      liberado: true
    })
  }

  const filterProps = useCallback((filterDefaultPeriod1) => {
    if(filterDefaultPeriod !== null){
    return {
      onSubmit: handleFilter,
      fields: [
        {
          type: 'select',
          name: 'userId',
          label: 'Usuário',
          options: listUsers.map(({ id, desUsuario }) => ({
            label: desUsuario,
            value: id,
          })),
          initial: user.id,
          disabled: user.office.tipCargo === 'OPERAÇÃO',
        },
        {
          type: 'select',
          name: 'periodId',
          label: 'Período',
          options: filteredPeriods ,
          initial: filterDefaultPeriod1,
        },
      ],
    };
  }}, [handleFilter, listUsers, user.id, user.office.tipCargo, filteredPeriods, filterDefaultPeriod]);

const loadData = useCallback(async () => {
    // const bank = await api.get(`/hour-bank/filter?userId=${user.id}&&indFechado=true&&liberado=true`);
    const periodo = await api.get(`/period/filter?usuarioId=${user.id}&&indFechado=true&&liberado=true`);

    // console.log("Dados do período ", bank.data.resultList[bank.data.resultList.length - 1].desPeriodo)
    setFilterDefaultPeriod(periodo.data.resultList[0].id);
    filterProps(periodo.data.resultList[0].id)
    query(periodo.data.resultList[0].id);
  }, [filterDefaultPeriod, query, user.id]);


  useEffect(() =>{
    loadData();
  },[filterDefaultPeriod])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  if (filterDefaultPeriod === undefined){
    loadData()
  }

  return (
    <Main title="Consulta Banco de Horas">
      <Grid item xs={12}>
      <Box component="div" className={styles.filter} xs={12}>
  {filterDefaultPeriod !== undefined && (
    <>
      <Filter {...filterProps(filterDefaultPeriod)} />
    </>
  )}
</Box>


        <TableContainer component={Paper}>
          <TablePagination
            count={totalElementsHourBank}
            doNotShow={DO_NOT_SHOW_TABLE}
            headers={TABLE_HEADER}
            onChangePage={(_, page) => changePage(page)}
            onChangeSize={event => changeSize(event.target.value)}
            page={page}
            rows={listHourBankFilter}
            size={size}
            order={ORDER_TABLE}
            hasAction={false}
            alignTitle={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
            alignItens={[
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
              'center',
            ]}
          />
        </TableContainer>
      </Grid>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title="Novo registro Banco de Horas"
      >
        <Form
          onCancel={() => setShowDialog(false)}
          param={DEFAULT_PARAM}
          users={listUsers}
          periods={listPeriods}
        />
      </Dialog>

      <Toast
        error={errorHourBank}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successHourBank}
      />
    </Main>
  )
}

const mapStateToProps = ({ hourBankReducer, periodReducer, userReducer }) => ({
  ...hourBankReducer,
  ...userReducer,
  listPeriods: periodReducer.listPeriodsFilter,
  listUsers: userReducer.listUsers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionHourBank,
      getPeriods: actionPeriod.getFilter,
      getUsers,
    },
    dispatch
  )

HourBankConsult.propTypes = {
  /** Actions */
  getUsers: PropTypes.func.isRequired,
  getPeriods: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  /** Reducer props */
  listPeriods: PropTypes.array.isRequired,
  listHourBankFilter: PropTypes.array.isRequired,
  listHourBank: PropTypes.array.isRequired,
  errorHourBank: PropTypes.string,
  successHourBank: PropTypes.string,
  totalElementsHourBank: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(HourBankConsult)
