import React, { useState } from 'react'
import { Button, Container, TextField, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { isEmailValid } from '../../utils/masks'
import Toast from '../../utils/Toast'
import { apiWithoutToken } from '../../../server'
import useStyles from './useStyles'

import Logo from '../../../assets/image/logo.png'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('E-mail inválido!')
    .required('Campo obrigatório!')
    .test({
      message: 'O e-mail deve ser do domínio Develcode!',
      test: value => value.endsWith('@develcode.com.br'),
    }),
})

function ForgotPassword() {
  const styles = useStyles()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [toast, setToast] = useState({ error: '', success: '' })
  const [isLoading, setIsLoading] = useState(false)

  const resetToast = () => setToast({ error: '', success: '' })

  const showSuccessMessage = () =>
    setToast({ error: '', success: 'E-mail enviado!' })

  const showErrorMessage = (error) => {
    let msg = 'Erro ao enviar e-mail'
    if (error.response.status === 404) {
      msg = 'E-mail não encontrado'
    }
    setToast({ error: msg, success: '' })
  }

  const vaildateForm = async data => {
    try {
      await validationSchema.validate(data)
      setEmailError('')
      return true
    } catch (err) {
      setEmailError(err.message)
      return false
    }
  }

  const onSuccessSubmit = () => {
    showSuccessMessage()
    const afterSuccessToastTimeout = 1500
    setTimeout(() => {
      history.push('/')
    }, afterSuccessToastTimeout)
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    const data = { email }
    const isValid = await vaildateForm(data)
    if (!isValid) return

    setIsLoading(true)
      try {
      await apiWithoutToken.get(`/user/reset-pass-email/${email}`)
      onSuccessSubmit()
    } catch (error) {
      console.error('Error loading users:', error)
      showErrorMessage()
    } finally {
      setIsLoading(false)
    }
  }

  const isToastVisible = !!toast.error || !!toast.success

  return (
    <Container className={styles.container}>
      <img src={Logo} alt="Logo Develcode" className={styles.image} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <Typography color="textSecondary">
          Preencha o seu e-mail abaixo para enviarmos o link de recuperação do seu acesso:
        </Typography>

        <TextField
          label="E-mail"
          value={email}
          onChange={ev => setEmail(ev.target.value)}
          error={!!emailError}
          helperText={emailError}
        />

        <Button
          variant="outlined"
          color="primary"
          type="submit"
          className={styles.submitButton}
          disabled={!isEmailValid(email) || email.length < 1 || isLoading}
        >
          {isLoading ? 'Carregando...' : 'Recuperar senha'}
        </Button>
      </form>

      <Toast
        success={toast.success}
        error={toast.error}
        open={isToastVisible}
        onClose={resetToast}
      />
    </Container>
  )
}

export default ForgotPassword
