import Http from '../../config/Http'
import URL from '../../config/URL'
import { trigger, types } from '../../config/action_types'

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.monthlyClose, param)

    return dispatch(
      trigger(types.monthlyClose.generateMonthlyClose.success, response.data)
    )
  } catch (e) {
    return dispatch(trigger(types.monthlyClose.generateMonthlyClose.error))
  }
}



