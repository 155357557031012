import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import * as yup from 'yup'
import { useFormik } from 'formik'

import TextField from '@material-ui/core/TextField'

import Button from '../../../utils/Form/Button'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      fixedDecimalScale={true}
      isNumericString={true}
      decimalSeparator={','}
      decimalScale={2}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const validationSchema = yup.object({
  numPontuacao: yup.number().required('Por favor, informe a pontuação!'),
  qtdHorasInicial: yup.number().required('Por favor, informe a hora inicial!'),
  qtdHorasFinal: yup.number().required('Por favor, informe a hora final!'),
})

/**
 * Period form
 */
const Form = ({
  param,
  isDeleteAction = false,
  primaryButtonColor = 'primary',
  primaryButtonLabel = 'Salvar',
  /** Functions */
  onCancel,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      id: param.id,
      numPontuacao: param.numPontuacao,
      qtdHorasInicial: param.qtdHorasInicial,
      qtdHorasFinal: param.qtdHorasFinal,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSubmit(values)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isDeleteAction && (
        <>
          <FlexWrapper>
            <FlexItemWrapper>
              <TextField
                fullWidth
                autoFocus
                id="numPontuacao"
                name="numPontuacao"
                label="Pontuação"
                value={formik.values.numPontuacao}
                onChange={formik.handleChange}
                error={
                  formik.touched.numPontuacao &&
                  Boolean(formik.errors.numPontuacao)
                }
                helperText={
                  formik.touched.numPontuacao && formik.errors.numPontuacao
                }
                inputProps={{
                  maxLength: 2,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                id="qtdHorasInicial"
                name="qtdHorasInicial"
                label="Qtd. horas iniciais"
                value={formik.values.qtdHorasInicial}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdHorasInicial &&
                  Boolean(formik.errors.qtdHorasInicial)
                }
                helperText={
                  formik.touched.qtdHorasInicial &&
                  formik.errors.qtdHorasInicial
                }
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>

            <FlexItemWrapper>
              <TextField
                fullWidth
                id="qtdHorasFinal"
                name="qtdHorasFinal"
                label="Qtd horas finais"
                value={formik.values.qtdHorasFinal}
                onChange={formik.handleChange}
                error={
                  formik.touched.qtdHorasFinal &&
                  Boolean(formik.errors.qtdHorasFinal)
                }
                helperText={
                  formik.touched.qtdHorasFinal && formik.errors.qtdHorasFinal
                }
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FlexItemWrapper>
          </FlexWrapper>
        </>
      )}

      <FlexWrapper>
        <FlexItemWrapper>
          <Button
            style={{ margin: 10 }}
            onClick={onCancel}
            label="Cancelar"
            variant="contained"
          />
        </FlexItemWrapper>

        <FlexItemWrapper>
          <Button
            style={{ margin: 10 }}
            color={primaryButtonColor}
            label={primaryButtonLabel}
            type="submit"
            variant="contained"
          />
        </FlexItemWrapper>
      </FlexWrapper>
    </form>
  )
}

Form.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  param: PropTypes.object.isRequired,
  isDeleteAction: PropTypes.bool,
  primaryButtonColor: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
}

export default Form
