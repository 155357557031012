import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'

export const getFilter = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(
      URL.clientPeriod + '/filter',
      param
    )

    return dispatch(trigger(types.clientPeriod.getFilter.success, response))
  } catch (e) {
    return dispatch(trigger(types.clientPeriod.getFilter.error))
  }
}

export const get = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.clientPeriod, param)

    return dispatch(trigger(types.clientPeriod.get.success, response.data))
  } catch (e) {
    return dispatch(trigger(types.clientPeriod.get.error))
  }
}

export const save = param => async dispatch => {
  try {
    await new Http(dispatch).post(URL.clientPeriod, param)

    return dispatch(trigger(types.clientPeriod.save.success))
  } catch (e) {
    return dispatch(trigger(types.clientPeriod.save.error))
  }
}

export const remove = param => async dispatch => {
  try {
    await new Http(dispatch).delete(URL.clientPeriod, param)

    return dispatch(trigger(types.clientPeriod.delete.success))
  } catch (e) {
    return dispatch(trigger(types.clientPeriod.delete.error))
  }
}

export const update = param => async dispatch => {
  try {
    await new Http(dispatch).put(URL.clientPeriod, param)

    return dispatch(trigger(types.clientPeriod.update.success))
  } catch (e) {
    return dispatch(trigger(types.clientPeriod.update.error))
  }
}
