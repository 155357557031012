import React, { useState, useEffect } from 'react'
import {
  periodicChangePassword,
  dismissError,
  mandatoryFieldsNotCompletedError,
} from '../../../actions/login'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FlexItemWrapper from '../../../utils/Wrapper/FlexItemWrapper'
import FlexWrapper from '../../../utils/Wrapper/FlexWrapper'
import Button from '../../../utils/Form/Button'
import { RemoveRedEye } from '@material-ui/icons'
import { TextField, InputAdornment } from '@material-ui/core'
import { notValidatePassword } from '../../../../constants/regex'
import Toast from '../../../utils/Toast'

const Password = ({
  errorPassword,
  successPassword,
  error = null,
  isLoad = false,
  dismissError = null,
  mandatoryFieldsNotCompletedError = null,
  push = () => {},
  periodicChangePassword = () => {},
  onCancel = () => {},
  desSenha = null,
}) => {
  const { id } = JSON.parse(localStorage.getItem('user'))

  const [errors, setErrors] = useState({})
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [values, setValues] = useState({})
  const [showToast, setShowToast] = useState(false)

  const closeToast = () => {
    dismissError()
    setShowToast(false)
  }

  /**
   * Show toast if error have returned
   */
  useEffect(() => {
    if (!showToast && errorPassword) setShowToast(true)
  }, [errorPassword])

  useEffect(() => {
    if (!showToast && successPassword) setShowToast(true)
  }, [successPassword])

  const changePassword = () => (
    <form
      onSubmit={e => {
        e.preventDefault()

        handleSubmit()
      }}
    >
      <span>
        Sua senha deve conter no mínimo <b>8</b> caracteres.
      </span>
      <br />

      <TextField
        error={errors.newPassword}
        helperText={errors.newPassword}
        id="newPassword"
        style={{ marginTop: '25px', width: '20%' }}
        variant="standard"
        type={!showNewPassword ? 'password' : 'text'}
        className="input-newPassword"
        label="Digite a nova senha"
        value={values.newPassword}
        onChange={e => {
          setValues({
            ...values,
            newPassword: e.target.value,
          })
          setErrors({
            ...errors,
            newPassword: notValidatePassword(e.target.value),
          })
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ color: '#3f51b5', cursor: 'pointer' }}
            >
              <RemoveRedEye
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            </InputAdornment>
          ),
        }}
      />
      <br />
      <TextField
        error={errors.confirmPassword}
        helperText={errors.confirmPassword}
        id="confirmPassword"
        style={{ marginTop: '25px', width: '20%' }}
        variant="standard"
        type={!showConfirmPassword ? 'password' : 'text'}
        className="input-newPassword"
        label="Repita a senha"
        value={values.confirmPassword}
        onChange={e => {
          setValues({
            ...values,
            confirmPassword: e.target.value,
          })
          setErrors({
            ...errors,
            confirmPassword:
              e.target.value === values.newPassword
                ? false
                : 'As senhas devem ser semelhantes',
          })
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ color: '#3f51b5', cursor: 'pointer' }}
            >
              <RemoveRedEye
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </InputAdornment>
          ),
        }}
      />

      <FlexWrapper>
        <FlexItemWrapper>
          <Button label="Cancelar" onClick={onCancel} />
        </FlexItemWrapper>

        <FlexItemWrapper right>
          <span>
            <Button
              color="primary"
              label="Salvar"
              type="submit"
              onClick={() => handleSubmit()}
            ></Button>
          </span>
        </FlexItemWrapper>
      </FlexWrapper>

      <Toast
        error={errorPassword}
        onClose={closeToast}
        open={showToast}
        success={successPassword}
      />
    </form>
  )

  const validarCamposObrigatorios = () => {
    let camposSemPreenchimento = ''
    let qtdCampos = 0

    if (
      values.newPassword === null ||
      values.newPassword === undefined ||
      values.newPassword === ''
    ) {
      camposSemPreenchimento +=
        (camposSemPreenchimento !== '' ? ', ' : '') + 'nova senha'
      qtdCampos++
    }
    if (
      values.confirmPassword === null ||
      values.confirmPassword === undefined ||
      values.confirmPassword === ''
    ) {
      camposSemPreenchimento +=
        (camposSemPreenchimento !== '' ? ' e ' : '') + 'confirmação de senha'
      qtdCampos++
    }

    if (camposSemPreenchimento !== '') {
      if (qtdCampos === 1) {
        mandatoryFieldsNotCompletedError(
          'O campo ' + camposSemPreenchimento + ' deve ser informado.'
        )
      } else {
        mandatoryFieldsNotCompletedError(
          'Os campos ' + camposSemPreenchimento + ' devem ser informados.'
        )
      }
      return false
    }
    return true
  }

  const handleSubmit = () => {
    if (validarCamposObrigatorios()) {
      if (values.confirmPassword === values.newPassword) {
        const params = {
          id,
          desSenha: desSenha,
          newSenha: values.newPassword,
          confirmSenha: values.confirmPassword,
          resetPassword: false,
        }
        const notValidPassword = notValidatePassword(values.confirmPassword)
        if (!notValidPassword) {
          return periodicChangePassword(params, push)
        }
      }
    }
  }

  return changePassword()
}

const mapStateToProps = ({ loadReducer, loginReducer }) => ({
  ...loadReducer,
  ...loginReducer,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { periodicChangePassword, dismissError, mandatoryFieldsNotCompletedError },
    dispatch
  )

Password.propTypes = {
  error: PropTypes.string,
  periodicChangePassword: PropTypes.func,
  isLoad: PropTypes.bool,
  dismissError: PropTypes.func,
  mandatoryFieldsNotCompletedError: PropTypes.func,
  successPassword: PropTypes.string,
  errorPassword: PropTypes.string,
  desSenha: PropTypes.string,
  push: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Password)
