import { types } from '../../config/action_types'

const DEFAULT_STATE = {
  listEmployeeFilter: [],
  error: null,
  success: null,
  totalElementsEmployee: 0,
  totalHours: 0,
}
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
     case types.employee.getFilter.success:
      const { resultList } = action.payload

      return {
        ...state,
        listEmployeeFilter: resultList || [],
      }

    case types.employee.getFilter.error:
      return {
        ...state,
        error: 'Erro ao filtrar colaboradores',
      }

    case types.employee.get.success:
      return {
        ...state,
        list: action.payload.content || action.payload,
      }

    case types.employee.get.error:
      return {
        ...state,
        error: 'Erro ao buscar colaboradores',
      }

    case types.employee.save.success:
      return {
        ...state,
        success: 'Salvo com sucesso',
      }

    case types.employee.save.error:
      return {
        ...state,
        error: action.payload,
      }

    case types.employee.delete.success:
      return {
        ...state,
        success: 'Deletado com sucesso',
      }

    case types.employee.delete.error:
      return {
        ...state,
        error: 'Erro ao deletar colaborador',
      }

    case types.employee.update.success:
      return {
        ...state,
        success: 'Atualizado com sucesso',
      }

    case types.employee.update.error:
      return {
        ...state,
        error: action.payload,
      }

    case types.employee.clearMessages:
      return {
        ...state,
        success: '',
        error: '',
      }

    default:
      return state
  }
}
