import Http from '../../config/Http'
import URL from '../../config/URL'
import { types, trigger } from '../../config/action_types'
import axios from 'axios'

export const setPeriod = (period) => async (dispatch) => {
  dispatch(
    trigger(types.closureClients.setPeriod, period)
  )
}

export const setProposal = (proposal) => async (dispatch) => {
  dispatch(
    trigger(types.closureClients.setProposal, proposal)
  )
}

export const generateClosure = (period) => async (dispatch, getState) => {
  const {
    closureClientsReducer: { proposal },
  } = getState()
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: user.dsToken,
    }
    const idProposta = proposal.value ? proposal.value : "";

    const param = {
      idPeriod: period.id,
      idProposal: idProposta,
    }

    dispatch(trigger(types.load.start))
    await axios.post(URL.closureClients, param, { headers: headers })
    dispatch(trigger(types.load.stop))
    return dispatch(trigger(types.closureClients.generateClosure.success))

  } catch (e) {
    return dispatch(trigger(types.closureClients.generateClosure.error))
  }
}

export const clearFilters = () => async (dispatch) => {
  dispatch(
    trigger(types.closureClients.clearFilters)
  )
}

export const getProposal = () => async dispatch => {
  try {
    const response = await new Http(dispatch).get(`${URL.proposal}`);

    return dispatch(trigger(types.closureClients.getProposal.success, response.data));
  } catch (error) {
    return dispatch(trigger(types.closureClients.getProposal.error, error));
  }
}

export const getPeriod = param => async dispatch => {
  try {
    const response = await new Http(dispatch).get(URL.period + '/filter', param)

    return dispatch(trigger(types.closureClients.getPeriod.success, response))
  } catch (error) {
    return dispatch(trigger(types.closureClients.getPeriod.error, error))
  }
}
