import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import AddIcon from '@material-ui/icons/Add'
import { TableContainer, Paper, Box, Grid, Fab } from '@material-ui/core'

import Form from './components/Form'
import Main from '../Main'
import * as actionClient from '../../actions/client'
import * as actionProject from '../../actions/project'
import * as actionPeriod from '../../actions/period'
import * as actionUserPeriod from '../../actions/userPeriod'
import { get as getUsers } from '../../actions/user'
import TablePagination from '../../utils/Table/TablePagination'
import Dialog from '../../utils/Dialog'
import Toast from '../../utils/Toast'
import Filter from '../../utils/Filter/Filter'
import { globalStyles } from '../../styles/global'
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_SIZE,
} from '../../../constants/pagination'

const SORT = 'id'

const DEFAULT_PARAM = {
  id: null,
  periodId: '',
  projectId: '',
  qtdHoras: '',
  userId: '',
}

const UserPeriod = ({
  /** Actions */
  getClients,
  getPeriods,
  getProjects,
  getFilter,
  get,
  remove,
  save,
  update,
  /** Reducer props */
  clients,
  projects,
  periods,
  errorUserPeriod,
  listUserPeriodFilter,
  successUserPeriod,
  totalElementsUserPeriod,
  listUsers,
  getUsers,
}) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const { id: idUsuario } = JSON.parse(localStorage.getItem('user'))
  const styles = globalStyles()
  const [page, setPage] = useState(DEFAULT_PAGINATION_PAGE)
  const [size, setSize] = useState(DEFAULT_PAGINATION_SIZE)

  const [showDialog, setShowDialog] = useState(false)

  const [param, setParam] = useState(DEFAULT_PARAM)

  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const [primaryButtonColor, setPrimaryButtonColor] = useState(null)
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState(null)
  const [onSubmit, setOnSubmit] = useState(() => save)
  const [title, setTitle] = useState('Novo registro')

  const [showToast, setShowToast] = useState(false)

  const [selectState, setSelectState] = useState({ fields: [] })

  const [valuesFilter, setValuesFilter] = useState({})

  const handleFilter = values => {
    const { userId, periodId, idClient, projectId } = values

    setValuesFilter(values)

    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idUsuario &&
        user.office.tipCargo === 'OPERAÇÃO' && { userId: idUsuario }),
      ...(userId && { userId }),
      ...(periodId && { periodId }),
      ...(idClient && { clientId: idClient }),
      ...(projectId && { projectId }),
    })

    setPage(DEFAULT_PAGINATION_PAGE)
  }

  let filterProps = {
    onSubmit: handleFilter,
    fields: [
      {
        type: 'select',
        name: 'userId',
        label: 'Usuário',
        initial: idUsuario,
        options: listUsers.map(dev => ({
          value: dev.id,
          label: dev.desUsuario,
        })),
        disabled: user.office.tipCargo === 'OPERAÇÃO',
      },
      {
        type: 'select',
        name: 'periodId',
        label: 'Período',
        options: periods.map(period => ({
          value: period.id,
          label: period.desPeriodo,
        })),
      },
      {
        type: 'select',
        name: 'idClient',
        label: 'Cliente',
        options: clients.map(client => ({
          value: client.id,
          label: client.descCliente,
        })),
      },
      {
        type: 'select',
        name: 'projectId',
        label: 'Projeto',
        options: projects
          .filter(project => project.idClient === selectState.fields.idClient)
          .map(({ id, desProjeto }) => ({
            label: desProjeto,
            value: id,
          })),
        disabled: !selectState.fields.idClient,
      },
    ],
    monitoreState: state => setSelectState(state),
  }

  const changePage = page => {
    const { userId, periodId, idClient, projectId } = valuesFilter

    setPage(page)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idUsuario &&
        user.office.tipCargo === 'OPERAÇÃO' && { userId: idUsuario }),
      ...(userId && { userId }),
      ...(periodId && { periodId }),
      ...(idClient && { clientId: idClient }),
      ...(projectId && { projectId }),
    })
  }

  const changeSize = size => {
    const { userId, periodId, idClient, projectId } = valuesFilter

    setSize(size)
    getFilter({
      pageFilter: page + 1,
      sizeFilter: size,
      sort: SORT,
      ...(idUsuario &&
        user.office.tipCargo === 'OPERAÇÃO' && { userId: idUsuario }),
      ...(userId && { userId }),
      ...(periodId && { periodId }),
      ...(idClient && { clientId: idClient }),
      ...(projectId && { projectId }),
    })
  }

  const convertRecord = record => {
    return {
      ...record,
      periodId: record.periodId,
      projectId: record.projectId,
      qtdHoras: record.qtdHoras * 1,
      userId: record.userId,
    }
  }

  const query = () => {
    getFilter({
      pageFilter: DEFAULT_PAGINATION_PAGE + 1,
      sizeFilter: DEFAULT_PAGINATION_SIZE,
      sort: SORT,
      ...(idUsuario && { userId: idUsuario }),
    })
    getProjects()
    getPeriods()
    getClients()
    get()
  }

  const handleSubmit = async values => {
    setShowDialog(false)

    await onSubmit(values)

    handleFilter(valuesFilter)

    setShowToast(true)

    return null
  }

  const startSave = () => {
    setParam(convertRecord(DEFAULT_PARAM))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Salvar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => save)
    setTitle('Novo registro')

    setShowDialog(true)
  }

  const startDelete = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(true)
    setPrimaryButtonLabel('Deletar')
    setPrimaryButtonColor('secondary')
    setOnSubmit(() => remove)
    setTitle(
      `Você deseja deletar à(s) ${record.qtdHoras} hora(s) do período ${record.desPeriodo} para a(o) usuária(o) ${record.desUsuario}?`
    )

    setShowDialog(true)
  }

  const startEdit = record => {
    setParam(convertRecord(record))

    setIsDeleteAction(false)
    setPrimaryButtonLabel('Alterar')
    setPrimaryButtonColor('primary')
    setOnSubmit(() => update)
    setTitle('Alterar registro')

    setShowDialog(true)
  }

  useEffect(() => {
    query()
  }, [])

  useEffect(() => {
    requestUser();
  }, [])

  const requestUser = async () => {
    await getUsers()
  }

  return (
    <Main title="Manutenção Usuário por Período">
      <Grid item xs={12}>
        <Box component="div" className={styles.box} xs={12}>
          <Fab onClick={startSave} color="primary">
            <AddIcon />
          </Fab>
        </Box>

        <Box component="div" className={styles.filter} xs={12}>
          <Filter {...filterProps} />
        </Box>

        <TableContainer component={Paper}>
          <TablePagination
            titleTable="userPeriod"
            doNotShow={['periodId', 'projectId', 'id', 'userId']}
            count={totalElementsUserPeriod}
            headers={[
              'Usuário',
              'Período',
              'Cliente',
              'Projeto',
              'Quantidade de Horas',
            ]}
            onChangePage={(_, page) => changePage(page)}
            onChangeSize={event => changeSize(event.target.value)}
            page={page}
            rows={listUserPeriodFilter}
            size={size}
            startDelete={startDelete}
            startEdit={startEdit}
            alignTitle={['center', 'center', 'center', 'center', 'center']}
            alignItens={[
              null,
              null,
              'center',
              null,
              'center',
              null,
              'center',
              'center',
              'center',
            ]}
            maskFunction={{
              qtdHoras: val =>
                parseFloat(val).toLocaleString('pt-BR', {
                  maximumFractionDigits: 2,
                }),
            }}
          />
        </TableContainer>
      </Grid>

      <Dialog
        onClose={() => setShowDialog(false)}
        open={showDialog}
        title={title}
      >
        <Form
          isDeleteAction={isDeleteAction}
          onCancel={() => setShowDialog(false)}
          onSubmit={handleSubmit}
          param={param}
          primaryButtonColor={primaryButtonColor}
          primaryButtonLabel={primaryButtonLabel}
          devs={listUsers}
          periods={periods}
          projects={projects}
        />
      </Dialog>

      <Toast
        autoHideDuration={4000}
        error={errorUserPeriod}
        onClose={() => setShowToast(false)}
        open={showToast}
        success={successUserPeriod}
      />
    </Main>
  )
}

const mapStateToProps = ({
  userPeriodReducer,
  clientReducer,
  projectReducer,
  periodReducer,
  devReducer,
  userReducer,
}) => ({
  ...userPeriodReducer,
  ...clientReducer,
  ...projectReducer,
  ...periodReducer,
  ...devReducer,
  ...userReducer,
  listUsers: userReducer.listUsers,

})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionUserPeriod,
      getClients: actionClient.get,
      getProjects: actionProject.get,
      getPeriods: actionPeriod.get,
      getUsers,
    },
    dispatch
  )

UserPeriod.propTypes = {
  /** Actions */
  getUsers: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getPeriods: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  /** Reducer props */
  clients: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  periods: PropTypes.array.isRequired,
  listUserPeriod: PropTypes.array.isRequired,
  listUserPeriodFilter: PropTypes.array.isRequired,
  errorUserPeriod: PropTypes.string,
  successUserPeriod: PropTypes.string,
  totalElementsUserPeriod: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPeriod)
