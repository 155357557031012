import { types, trigger } from '../../config/action_types'
import Http from '../../config/Http'
import URL from '../../config/URL'
import moment from 'moment'

const LOCAL_STORAGE_USER = 'user'
const SESSION_STORAGE_TOKEN = 'token'

export const login = (param, push) => async dispatch => {
  try {
    localStorage.clear()
    const response = await new Http(dispatch).post(URL.authentication, param)

    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(response.data))
    sessionStorage.setItem(
      SESSION_STORAGE_TOKEN,
      moment()
        .add(30, 'minutes')
        .toString()
    )

    const {
      resetPassword,
      indUserWithNoPasswordChangeInTheLastSixMonths
    } = response.data

    if (resetPassword || indUserWithNoPasswordChangeInTheLastSixMonths) {
      return dispatch({
        type: types.login.resetUserPassword,
        payload: param,
      })
    } else {
      await dispatch({
        type: types.login.success,
        payload: response.data,
      })

      push('/home')
    }
  } catch (e) {
    let message = e.response ? e.response.data.message : 'Erro ao fazer login'
    if (message === 'No value present') {
      message = 'Usuário ou senha incorretos'
    }

    return dispatch(trigger(types.login.error, message))
  }
}

export const cancelarTrocaSenha = param => dispatch => {
  return dispatch({
    type: types.login.cancelResetUserPassword,
    payload: param,
  })
}

export const password = param => dispatch => {
  return new Http(dispatch)
    .put(URL.password, param, true)
    .then(result => {
      if (result.cdStatus === 200) {
        dispatch({
          type: types.password.success,
          payload: result,
        })
      } else {
        dispatch({
          type: types.password.error,
          payload: result.data.message,
        })
      }
    })
    .catch(err => {
      dispatch({
        type: types.password.error,
        payload: err.message.messageError,
      })
    })
}

export const periodicChangePassword = (param, push) => async dispatch => {
  return new Http(dispatch)
    .post(URL.periodicChangePassword, param)
    .then(result => {
      if (result.cdStatus === 200) {
        dispatch({
          type: types.login.hiddenChangePassword,
          payload: param,
        })

        const paramsLogin = {
          desSenha: param.confirmSenha,
          desEmail: result.data.desEmail,
        }

        dispatch({
          type: types.login.periodicPasswordChangePerformed,
          payload: paramsLogin,
        })

        push('/home')
      } else {
        dispatch({
          type: types.password.error,
          payload: result.data.message,
        })
      }
    })
    .catch(err => {
      dispatch({
        type: types.password.error,
        payload: err.message.messageError,
      })
    })
}

export const dismissError = () => trigger(types.login.dismissError)

export const mandatoryFieldsNotCompletedError = msg => ({
  type: types.password.mandatoryFieldsNotCompletedError,
  payload: msg,
})
