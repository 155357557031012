import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

const Input = ({ name, label, setValueToField, stateValues, disabled }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <TextField
        value={stateValues && stateValues.length > 0 ? stateValues : ''}
        label={label}
        name={name}
        variant="outlined"
        onChange={({ target: { value } }) => {
          setValueToField(name, value)
        }}
        disabled={disabled}
      />
    </FormControl>
  )
}

export default Input
